import React from 'react';

import { useParams } from 'react-router';
import { useFetch } from '../../../utils';
import { Loading } from '../../../Component/index';
import { ProcessMoniteringInfo, ProcessMoniteringGraph } from '../index';

const ProcessMoniteringComponent = ({
  // ProcessData,
  LoadingThemeColor,
  // setProcessData,

  // fixedProcessdata,

  RecipeName,

  ProcessGraphData,
  // setFixedProcessData,
}) => {
  const params = useParams();
  // ========================================================================================

  let ProcessDataChildrenNodeName = ProcessGraphData?.map((e, i) => {
    let name = `${e?.obj?.value}:${e?.class_obj.value}`;
    let data = {
      id: i + 1,
      name: name,
      edgeType: e?.prop?.value,
      node_value_obj: e?.value_obj?.value,
      node_value_rec: e?.value_rec.value,
      typeOf: e?.class_obj.value,
      typeofValue: e?.obj?.value,
      searchFilter: '',
      typeOfResult: '',
    };
    return data;
  });

  let parentNodes = {
    id: 0,
    name: ProcessGraphData[0]?.sub?.value,
    edgeType: ProcessGraphData[0]?.prop?.value,
    node_value_obj: '',
    node_value_rec: '',
    typeOf: '',
    typeofValue: '',
    searchFilter: '',
    typeOfResult: '',
  };

  let ProcessDataNodeName = [parentNodes, ...ProcessDataChildrenNodeName];
  // console.log(ProcessDataNodeName);
  let ProcessDatalinkName = ProcessDataNodeName.map(e => {
    let data = {
      source: parentNodes?.id,
      target: e?.id,
      edgeType: e?.edgeType,
      name: e?.name,
      node_value_obj: e?.node_value_obj,
    };
    return data;
  });
  ProcessDatalinkName.shift();
  //console.logProcessDatalinkName);
  let ProcessDataLinksAndNodesData = {
    nodes: ProcessDataNodeName,
    links: ProcessDatalinkName,
  };
  // setProcessData(ProcessDataLinksAndNodesData);
  // setFixedProcessData(ProcessDataLinksAndNodesData);
  /* eslint-disable no-unused-vars */
  const [fixedProcessdata, setFixedProcessData] = React.useState(
    ProcessDataLinksAndNodesData
  );
  const [ProcessData, setProcessData] = React.useState(
    ProcessDataLinksAndNodesData
  );
  const SelectedRecipeDetails = params.item
    .replaceAll('.$', '<')
    .replaceAll('$', '>');
  // const iri = '<eg:recipe-pad-thai-recipe>';
  const generator_data_in_process_monitoring_query_id_first_point =
    // eslint-disable-next-line no-undef
    process.env
      .REACT_APP_PROCESS_MONITORING_GENERATOR_QUERY_IN_PROCESS_MONITORING_ID_POINT_ONE;
  const generator_data_in_process_monitoring_query_id_last_point =
    // eslint-disable-next-line no-undef
    process.env
      .REACT_APP_PROCESS_MONITORING_GENERATOR_QUERY_IN_PROCESS_MONITORING_ID_POINT_TWO;
  const [sb2ProcessStatus, sb2ProcessData] = useFetch(
    `${generator_data_in_process_monitoring_query_id_first_point}${SelectedRecipeDetails}${generator_data_in_process_monitoring_query_id_last_point}`
  );
  const [passedParameters, setPassedParameters] = React.useState([]);

  const timeAndTempValues = {
    timeValues: [],
    tempValues: [],
  };
  const [changedTimeAndTempValues, setChangedTimeAndTempValues] =
    React.useState(timeAndTempValues);
  //console.logchangedTimeAndTempValues);

  const getFinalOutPutInSb2 = () => {
    const apiParameters = sb2ProcessData.rows.map((ek, i) => {
      const Data = {
        p_iri: ek.sb1_iri_Value,
        temp: changedTimeAndTempValues.tempValues[i],
        time: changedTimeAndTempValues.timeValues[i],
      };
      return Data;
    });
    setPassedParameters(apiParameters);
  };

  const process_generator_and_Process_monitoring_time_temp_caluculation_first_point =
    // eslint-disable-next-line no-undef
    process.env
      .REACT_APP_PROCESS_MONITORING_AND_PROCESS_GENERATOR_TIME_AND_TEMP_CALUCULATION_QUERY_ID_POINT_ONE;
  const process_generator_and_Process_monitoring_time_temp_caluculation_last_point =
    // eslint-disable-next-line no-undef
    process.env
      .REACT_APP_PROCESS_MONITORING_AND_PROCESS_GENERATOR_TIME_AND_TEMP_CALUCULATION_QUERY_ID_POINT_TWO;
  const [finalOutputSb1Status, finalOutputProcessData] =
    useFetch(`${process_generator_and_Process_monitoring_time_temp_caluculation_first_point}
    BIND (${
      passedParameters[0] === undefined ? `<>` : passedParameters[0].p_iri
    } as ?p1) .  BIND (${
      passedParameters[0] === undefined ? 0 : passedParameters[0].time
    } as ?p1_time) .  BIND (${
      passedParameters[0] === undefined ? 0 : passedParameters[0].temp
    } as ?p1_temp) .  BIND (${
      passedParameters[1] === undefined ? `<>` : passedParameters[1].p_iri
    } as ?p2) .  BIND (${
      passedParameters[1] === undefined ? 0 : passedParameters[1].time
    } as ?p2_time) .  BIND (${
      passedParameters[1] === undefined ? 0 : passedParameters[1].temp
    } as ?p2_temp) .  BIND (${
      passedParameters[2] === undefined ? `<>` : passedParameters[2].p_iri
    } as ?p3) .  BIND (${
      passedParameters[2] === undefined ? 0 : passedParameters[2].time
    } as ?p3_time) .  BIND (${
      passedParameters[2] === undefined ? 0 : passedParameters[2].temp
    } as ?p3_temp) .  BIND (${
      passedParameters[3] === undefined ? `<>` : passedParameters[3].p_iri
    } as ?p4) .  BIND (${
      passedParameters[3] === undefined ? 0 : passedParameters[3].time
    } as ?p4_time) .  BIND (${
      passedParameters[3] === undefined ? 0 : passedParameters[3].temp
    } as ?p4_temp) .  BIND (${
      passedParameters[4] === undefined ? `<>` : passedParameters[4].p_iri
    } as ?p5) .  BIND (${
      passedParameters[4] === undefined ? 0 : passedParameters[4].time
    } as ?p5_time) .  BIND (${
      passedParameters[4] === undefined ? 0 : passedParameters[4].temp
    } as ?p5_temp) .  BIND (${
      passedParameters[5] === undefined ? `<>` : passedParameters[5].p_iri
    } as ?p6) .  BIND (${
      passedParameters[5] === undefined ? 0 : passedParameters[5].time
    } as ?p6_time) .  BIND (${
      passedParameters[5] === undefined ? 0 : passedParameters[5].temp
    } as ?p6_temp) .  BIND (${
      passedParameters[6] === undefined ? `<>` : passedParameters[6].p_iri
    } as ?p7) .  BIND (${
      passedParameters[6] === undefined ? 0 : passedParameters[6].time
    } as ?p7_time) .  BIND (${
      passedParameters[6] === undefined ? 0 : passedParameters[6].temp
    }${process_generator_and_Process_monitoring_time_temp_caluculation_last_point}`);

  // console.info(finalOutputSb1Status);
  return (
    <>
      {ProcessData?.length !== 0 && (
        <ProcessMoniteringGraph
          ProcessData={ProcessData}
          LoadingThemeColor={LoadingThemeColor}
          setProcessData={setProcessData}
          fixedProcessdata={fixedProcessdata}
          ProcessDataLinksAndNodesData={ProcessDataLinksAndNodesData}
        />
      )}

      <div className="rightSideFixedContainerInHood">
        {sb2ProcessStatus === 'success' && (
          <ProcessMoniteringInfo
            sb2ProcessData={sb2ProcessData}
            changedTimeAndTempValues={changedTimeAndTempValues}
            setChangedTimeAndTempValues={setChangedTimeAndTempValues}
            getFinalOutPutInSb2={getFinalOutPutInSb2}
            finalOutputProcessData={finalOutputProcessData}
            passedParameters={passedParameters}
            setProcessData={setProcessData}
            ProcessData={ProcessData}
            RecipeName={RecipeName}
          />
        )}
        {sb2ProcessStatus !== 'success' && (
          <Loading color={LoadingThemeColor} />
        )}
      </div>
    </>
  );
};

export default ProcessMoniteringComponent;
