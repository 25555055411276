import React from 'react';

import qs from 'qs';
import axios from 'axios';

import ApplicationToken from './ApplicationToken';

// var tokenKey = ApplicationToken(false);
// var tokenKey = JSON.parse(window.localStorage.getItem('applicationToken'));

const useFetchCardsData = query => {
  // const { tokenKey, setTokenKey1 } = React.useContext(authContext);
  const [status, setStatus] = React.useState('idle');
  const [result, setResult] = React.useState([]);

  var data = qs.stringify({
    query: `${query}`,
  });

  // eslint-disable-next-line no-undef
  const Query = window?._env_?.REACT_APP_QUERY;

  const queryCall = React.useCallback(
    async token => {
      if (!token) {
        return;
      } else {
        setStatus('Loading');

        var config = {
          method: 'POST',
          url: `${Query}`,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization:
              token?.data?.token_type + ' ' + token?.data?.access_token,
          },
          data: data,
        };

        axios(config)
          .then(response => {
            setResult(response.data.results.bindings);

            setStatus('success');
          })
          .catch(function (error) {
            setStatus('error');
            if (error.response.status === 401) {
              ApplicationToken(true, queryCall);
              // tokenKey = JSON.parse(
              //   window.localStorage.getItem('applicationToken')
              // );
              // queryCall(tokenKey);
            }
          });
      }
    },
    [data]
  );

  React.useEffect(() => {
    if (!JSON.parse(window.localStorage.getItem('applicationToken'))) {
      ApplicationToken(true, queryCall);
      // tokenKey = ApplicationToken(true, '');
    } else {
      queryCall(
        JSON.parse(window.localStorage.getItem('applicationToken')),
        ''
      );
    }
  }, [query]);

  return [status, result];
};

export default useFetchCardsData;
