/* eslint-disable no-useless-escape */
import React from 'react';

import Graph from '../SubstitutionGraph/Index';
import '../SubstitutionGraph/SubstitutionGraph.css';
const SubstitutionGraphComponent = ({
  data,
  setData,
  setSubKey,
  subnodes,
  subLink,
  index,
  subGraphdata,
  substituteStatus,
  setIndex,
  setNodeClickStatus,
  defaultData,
  selectedSubstituteNodes,
  changeSave,
  setChangeSave,
  nodeClickStatus,
  deleteNodes,
  setDeleteNodes,
  setCheckedState,
  setCheckeBoxdState,
  length,
  setSubGraphTitle,
  setSubGraphTitleValue,
  RecipeQuerry,
  setSubstituteInfo,
  setIsGraphUpdate,
  checkedBoxState,
}) => {
  let sorceIndex;
  const substituteGraphRef = React.useRef(null);

  React.useEffect(() => {
    if (!data) {
      return;
    }
    // substituteSubOptionPropertyData

    if (nodeClickStatus.includes(true)) {
      let newfetchNodes = subGraphdata.map((e, i) => {
        let slashSplit = e.p.value.split('/');

        let hashtagSplit = slashSplit[slashSplit.length - 1].split('#');
        const specialCharectorRemovedNode =
          hashtagSplit[hashtagSplit.length - 1];
        let slashSplit1 = e.class.value.split('/');
        let hashtagSplit1 = slashSplit1[slashSplit1.length - 1].split('#');
        const specialCharectorRemovedNode1 =
          hashtagSplit1[hashtagSplit1.length - 1];
        let data = {
          id: index + `${i + 1}`,
          name: `${e.object.value}:${specialCharectorRemovedNode1}`,
          value: e.value.value,
          property: specialCharectorRemovedNode,
          actualName: e.object.value,
          edgeTypes: specialCharectorRemovedNode,
          status: e.status,
        };
        return data;
      });

      sorceIndex = index;

      let sourcePropertyLink = newfetchNodes.map(e => {
        let data = {
          source: sorceIndex,
          target: e.id,
          edgeTypes: e.property,
          name: e.name,
        };
        return data;
      });
      data.nodes.length = subnodes.length;
      data.links.length = subLink.length;
      let parentsNode = data.nodes;
      let parentsLink = data.links;

      let originalLink = [...parentsLink, ...sourcePropertyLink];
      let originalNodes = [...parentsNode, ...newfetchNodes];

      let substituteOriginalData = {
        nodes: originalNodes,
        links: originalLink,
      };

      setData(substituteOriginalData);
    }
  }, [subGraphdata]);

  return (
    <div
      className="graphMainContainer"
      style={{ height: '95%', width: '100%' }}
      ref={substituteGraphRef}
    >
      <Graph
        data={data}
        id="graph"
        setSubKey={setSubKey}
        setIndex={setIndex}
        substituteStatus={substituteStatus}
        subGraphdata={subGraphdata}
        setNodeClickStatus={setNodeClickStatus}
        defaultData={defaultData}
        selectedSubstituteNodes={selectedSubstituteNodes}
        setData={setData}
        changeSave={changeSave}
        setChangeSave={setChangeSave}
        deleteNodes={deleteNodes}
        setDeleteNodes={setDeleteNodes}
        subnodes={subnodes}
        setCheckedState={setCheckedState}
        setCheckeBoxdState={setCheckeBoxdState}
        length={length}
        nodeClickStatus={nodeClickStatus}
        setSubGraphTitle={setSubGraphTitle}
        setSubGraphTitleValue={setSubGraphTitleValue}
        RecipeQuerry={RecipeQuerry}
        setSubstituteInfo={setSubstituteInfo}
        setIsGraphUpdate={setIsGraphUpdate}
        substituteGraphRef={substituteGraphRef}
        checkedBoxState={checkedBoxState}
      />
    </div>
  );
};

export default SubstitutionGraphComponent;
