export const setDatainLocalMemory = (key, value) => {
  window.localStorage.setItem(`${key}`, JSON.stringify(value));
};
export const deleteItemFromMemory = key => {
  window.localStorage.removeItem(`${key}`);
};

export const getItemFromMemory = key => {
  let data = JSON.parse(window.localStorage.getItem(`${key}`));
  return data;
};
