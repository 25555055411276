import React from 'react';
import {
  BsHandIndexThumb,
  BsFillDiagram3Fill,
  GiSquareBottle,
} from '../../../../icon';
import './SubstituteButtonModel.css';

const SubstituteButtonModel = ({ property }) => {
  const rows = property;
  let deleteFirstSpecialCheractor = rows.split('[');

  let deleteSecondSpecialCharacter = deleteFirstSpecialCheractor[1].split(']');

  let actaualRowDatainString = deleteSecondSpecialCharacter[0];
  let originalRows = actaualRowDatainString.split(',');

  // const columns = match;
  // let deleteFirstSpecialCheractorColumns = columns.split('[');

  // let deleteSecondSpecialCharacterColumns =
  //   deleteFirstSpecialCheractorColumns[1].split(']');

  // let actaualRowDatainStringColumns = deleteSecondSpecialCharacterColumns[0];
  // let originalRowsColumns = actaualRowDatainStringColumns.split(',');

  return (
    <>
      <div className="subOptionContainer">
        <div className="subIconOptionContainer">
          <div className="vinegarOptionsTexture">
            <BsHandIndexThumb
              className="vinegarOptionIconsHegith"
              style={{ color: '#57BFFF' }}
            />

            <div className="vinegarOptionText">{originalRows[1]}</div>
          </div>
          <div className="vinegarOptionsTexture">
            <GiSquareBottle
              className="vinegarOptionIconsHegith"
              style={{ color: '#D34C89' }}
            />
            <div className="vinegarOptionText">{originalRows[0]}</div>
          </div>

          <div className="vinegarOptionsTexture">
            <BsFillDiagram3Fill
              className="vinegarOptionIconsHegith"
              style={{
                transform: 'rotate(270deg)',
                color: '#2CCAB4',
              }}
            />
            <div className="vinegarOptionText">{originalRows[2]}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubstituteButtonModel;
