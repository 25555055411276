import React from 'react';

/**
 * [Component] InventoryStartButton
 *
 * @description Button for navigation to inventory challenge
 
 * @param {*} getInventoryClick  renders inventory component on function call
 
* @returns  start cooking  button
 */

const InventoryStartButton = ({ getInventoryClick }) => {
  return (
    <>
      <button className="invStartButton" onClick={getInventoryClick}>
        Start Cooking!
      </button>
    </>
  );
};

export default InventoryStartButton;
