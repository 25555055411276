import React from 'react';
import { Inventorychallange, InventoryHomeTitle } from '../index';
import '../../style/Home.css';
import {
  inventorySvgInHome,
  processSvgInHome,
  firstStepSvgImgInInventoryChallenge,
  secondStepSvgImgInInventoryChallenge,
  thirdStepSvgImgInInventoryChallenge,
  forthStepSvgImgInInventoryChallenge,
  processChallengeStepFirstImage,
  processChallengeStepSecondImage,
  processChallengeStepThreeImage,
  processChallengeStepFourImage,
} from '../../assets';
import Tour from 'reactour';
import { useNavigate } from 'react-router';
import {
  invProcessStepActions,
  cascadingScreenActions,
  useDispatch,
  useSelector,
  cuisineScreenActions,
} from '../../Redux';

import { getItemFromMemory, setDatainLocalMemory } from '../../utils';
import { authAction } from '../../Redux/Slices/AuthSlides/AuthenticationStates';

/**
 * [Component] Home
 * @description renders the component.
 
 * @param {*} getTimeClick renders Time component screen on function call.
 * @param {*} getInventoryClick renders Inventory component screen on function call.
 * @param {*} getHealthClick renders Health component screen on function call.
 * @param {*} getIngredientClick renders Ingredient component screen on function call.
 
 * @returns Displays the entry page for time, ingredient, inventory and health challenge pages are available.
*/

const Home = ({ steps }) => {
  const dispatch = useDispatch();
  const authenticationStore = useSelector(state => state.authStore);

  const navigate = useNavigate();
  const refreshInventoryClick = () => {
    dispatch(cuisineScreenActions.clearSelectedCuisineContainer());
    dispatch(cascadingScreenActions.clearSelectedIngredients());
    dispatch(invProcessStepActions.gotoInitialStateRefresh());
    dispatch(cascadingScreenActions.getHideTopInput());
    dispatch(
      cascadingScreenActions.changeIngredientTitle(
        `To view the corresponding recipes, first select the ingredients you want to use. Add an ingredient either by browsing the available ingredients by category or by searching for a specific ingredient in the search box on the right. After ingredient selection, click on the ‘Next’ button to go to cuisine selection..`
      )
    );

    navigate('/inventory');
  };
  const refreshProcessClick = () => {
    dispatch(cuisineScreenActions.clearSelectedCuisineContainer());
    dispatch(cascadingScreenActions.clearSelectedIngredients());
    dispatch(invProcessStepActions.gotoInitialStateRefresh());
    dispatch(cascadingScreenActions.getHideTopInput());
    dispatch(
      cascadingScreenActions.changeIngredientTitle(
        `To view the corresponding recipes, first select the ingredients you want to use. Add an ingredient either by browsing the available ingredients by category or by searching for a specific ingredient in the search box on the right. After ingredient selection, click on the ‘Next’ button to go to cuisine selection..`
      )
    );

    navigate('/process');
  };

  const currentUserForApp = getItemFromMemory('applicationUser');

  if (
    typeof authenticationStore.userPersonalDetails?.username === 'string' &&
    currentUserForApp === null
  ) {
    setDatainLocalMemory(
      'applicationUser',
      authenticationStore.userPersonalDetails
    );
  }

  return (
    <div className="homeContainer">
      <InventoryHomeTitle inventoryTitle="Curry Company (beta)" />
      <Inventorychallange
        processTitle="Inventory Challenge"
        containerClassName="inventoryChallange"
        getInventoryClick={refreshInventoryClick}
        stepsTitleClassName="Inventory_Block_steps"
        challegeSvgImage={inventorySvgInHome}
        challangeImageClassName="inventoryFleximg"
        stepsBelowFirstImage={firstStepSvgImgInInventoryChallenge}
        stepsBelowSecondImage={secondStepSvgImgInInventoryChallenge}
        stepsBelowThirdImage={thirdStepSvgImgInInventoryChallenge}
        stepsBelowFourthImage={forthStepSvgImgInInventoryChallenge}
        content1="Select the ingredients from  an 
        assortment of the given categories."
        content2="Feed in your preferences based on the 
        cuisine you want"
        content3="Pick and choose any recipe from 
        your personalised recipe catalogue."
        content4="Customise your recipe by choosing 
        alternative ingredients for the given!"
        stepClass="second-step"
      />
      <Inventorychallange
        processTitle="Process Challenge"
        containerClassName="processChallenge"
        getInventoryClick={refreshProcessClick}
        stepsTitleClassName="processBlockSteps"
        challegeSvgImage={processSvgInHome}
        challangeImageClassName="processFleximg"
        stepsBelowFirstImage={processChallengeStepFirstImage}
        stepsBelowSecondImage={processChallengeStepSecondImage}
        stepsBelowThirdImage={processChallengeStepThreeImage}
        stepsBelowFourthImage={processChallengeStepFourImage}
        content1="Select the ingredients from  an 
        assortment of the given categories."
        content2="Feed in your preferences based on the 
        cuisine you want."
        content3="Pick and choose any recipe from 
        your personalised recipe catalogue."
        content4="Alter your cooking conditions and check what the outcome is going to be."
        stepClass="fourth-step"
      />

      <Tour
        steps={steps}
        isOpen={authenticationStore.isTourOpen}
        onRequestClose={() => dispatch(authAction.setIstourOpen(false))}
      />
    </div>
  );
};

export default Home;
