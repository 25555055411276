import React from 'react';
import './AuthErrorMessage.css';
import { MdOutlineError } from '../../../icon';

const AuthErrorMessage = ({ errorMessage }) => {
  return (
    <div className="authErrorStatus">
      <div className="authErrorStatusIconColor">
        <MdOutlineError />
      </div>
      <div>{errorMessage}</div>
    </div>
  );
};

export default AuthErrorMessage;
