export const AppColors = {
  input_blur_color: '#d7dae2',
  input_inActive_bg_color: '#f2f2f2',
  inventory: {
    inv_base_color: '#f28c1c',
    inv_base_light_color: '#fff1e1',
    input_active_color: '#ffc789',
    input_inactive_search_color: '#d7dae2',
  },
  process: {
    base_color: '#0174b2',
    base_light_color: '#e9f7ff',
  },
};
