import React from 'react';
import { RiArrowRightSLine } from '../../../icon';
import { ToolTipInfo } from '../../../lib';

import { useLocation, useNavigate } from 'react-router';

const UnderTheHoodPageBanner = ({
  title,
  mainText,
  titleClass,
  mainTextClass,
  color,

  titleAndbacktoRecipeFlex,
  backToRecipeContainerButton,
  showGraphTitleInBanner,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const BackToRecipeClick = () => {
    navigate(
      `${location.pathname.split('/')[0]}/${location.pathname.split('/')[1]}/${
        location.pathname.split('/')[2]
      }/${location.pathname.split('/')[3]}/${location.pathname.split('/')[4]}`
    );
  };
  return (
    <div className={titleAndbacktoRecipeFlex}>
      <div className="Tittle-paraGraph-flex">
        <div
          className="breadcrumInHoodBanner"
          //   style={{ backgroundColor: 'red' }}
        >
          <div className={titleClass}>{title}</div>
          <div
            style={{ padding: '0.20rem', fontSize: '2em', marginTop: '.4em' }}
          >
            <RiArrowRightSLine color={color} />
          </div>
          <div className="breadCrumRightName">
            <>
              {showGraphTitleInBanner === 'Raw Data' ? (
                <>
                  {showGraphTitleInBanner}
                  <ToolTipInfo title="Data being utilised to realise the current use-case" />{' '}
                </>
              ) : showGraphTitleInBanner === 'Process Monitoring' ? (
                <>
                  {showGraphTitleInBanner}
                  <ToolTipInfo title="Data being utilised to realise the current use-case" />{' '}
                </>
              ) : (
                showGraphTitleInBanner
              )}
            </>
          </div>
        </div>
        <p className={mainTextClass}>{mainText}</p>
      </div>
      <button
        onClick={BackToRecipeClick}
        className={`${backToRecipeContainerButton} bannerBackButtonInUnderTheHood`}
      >
        Back To Recipe Information
      </button>
    </div>
  );
};

export default UnderTheHoodPageBanner;
