import * as d3 from 'd3';
import { GraphFitScreen } from '../lib';
const Zoomed = (zoominLevel, zoomOutLevel, zoomResetBtn, svg, zoom, view) => {
  d3.select(zoominLevel).on('click', function () {
    // console.log(svg);
    zoom.scaleBy(svg.transition().duration(750), 1.2);
  });
  d3.select(zoomOutLevel).on('click', function () {
    zoom.scaleBy(svg.transition().duration(750), 0.8);
  });
  d3.select(zoomResetBtn).on('click', function () {
    GraphFitScreen(svg, zoom, view);
  });
};

export default Zoomed;
