function NutritionBoxsInRawData({
  title,

  value1,
  value2,
  value3,
  value4,
  property1,
  property2,
  property3,
  property4,
  bordercolor,
  image,
  index,
}) {
  // title={el.Title}
  // value1={el.value1}
  // value2={el.value2}
  // value3={el.value3}
  // value4={el.value4}
  // property1={el.property1}
  // property2={el.property2}
  // property3={el.property3}
  // property4={el.property4}
  // bordercolor={el.bordercolor}
  // image={el.image}
  // index={i}

  // marginbottom,
  // marginright,
  // margintop,
  // @media only screen and (min-width: 750px) {
  //   .MainBarContainer-container {
  //     width: 85%;
  //   }
  //   .LabelAndBarFlex {
  //     display: flex;
  //     flex-direction: row;
  //     align-items: center;
  //     justify-content: space-between;
  //     height: 1.5em;
  //   }
  //   .progressBarborder {
  //     background-color: black;
  //     height: 6em;
  //     width: 0.4em;
  //     position: absolute;
  //     right: 29.5%;
  //   }
  // }

  // console.log(index);
  return (
    <div
      style={{ border: `1px solid ${bordercolor} ` }}
      className="flexBoxBorders"
    >
      <div className="RecipeFlexBoxHeading">{title}</div>
      <div className="imgContainerInRowData">
        <img
          src={image}
          alt="nutrition"
          className={
            index === 0
              ? 'RecipeFlexBoxImage0'
              : index === 1
              ? 'RecipeFlexBoxImage1'
              : 'RecipeFlexBoxImage2'
          }
          style={
            {
              // width: width,
              // height: height,
            }
          }
        />
      </div>
      <div className="RecipeFlexBoxListImage">
        <div className="RecipeFlexBoxInformation">
          {property1 !== null && (
            <li className="RecipeFlexBoxList">
              <span className="RecipeFlexBoxInformation-point-maker">
                {property1} : {value1}
              </span>
            </li>
          )}
          {property2 !== null && (
            <li className="RecipeFlexBoxList">
              <span className="RecipeFlexBoxInformation-point-maker">
                {property2} : {value2}
              </span>
            </li>
          )}
          {property3 !== null && (
            <li className="RecipeFlexBoxList">
              <span className="RecipeFlexBoxInformation-point-maker">
                {property3} : {value3}
              </span>
            </li>
          )}
          {property4 !== null && (
            <li className="RecipeFlexBoxList">
              <span className="RecipeFlexBoxInformation-point-maker">
                {property4} : {value4}
              </span>
            </li>
          )}
        </div>
      </div>
    </div>
  );
}

export default NutritionBoxsInRawData;
