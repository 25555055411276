/* eslint-disable no-useless-escape */
import React from 'react';
import { AuthInput, AuthPassword, AuthButton } from '../../../lib';
import { LinkedinSignUpOptionWithEmailHeader } from '../../index';
import './AuthUserId.css';
import { BsCheck } from '../../../icon';
import { getDatabase, ref, child, get } from 'firebase/database';
import { useDispatch, useSelector } from 'react-redux';
import { authAction } from '../../../Redux/Slices/AuthSlides/AuthenticationStates';

const AuthUserId = ({ setShowEmailError }) => {
  const dispatch = useDispatch();
  const authenticationStore = useSelector(s => s.authStore);
  function validateEmail(emailAdress) {
    // eslint-disable-next-line no-useless-escape
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (emailAdress.match(regexEmail)) {
      return true;
    } else {
      return false;
    }
  }
  const getConfirmPassword = e => {
    dispatch(authAction.getConfirmPassword(e.target.value));

    if (
      e.target.value === authenticationStore.signUpDetails.userPassword &&
      strongPassword.test(authenticationStore.signUpDetails.userPassword) &&
      validateEmail(authenticationStore.signUpDetails.email) &&
      authenticationStore.signUpDetails.userName.length > 2
    ) {
      dispatch(authAction.setRegisterError(''));
      dispatch(authAction.setErrorStatus(false));
    } else {
      dispatch(authAction.setErrorStatus(true));
      return false;
    }
  };

  function isHavingBothUpperCaseAndLowerCase(string) {
    const upper = /[A-Z]/.test(string),
      lower = /[a-z]/.test(string);

    return upper && lower;
  }

  let strongPassword = new RegExp(
    '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'
  );
  let specialCharacter = new RegExp('(?=.*[0-9])(?=.*[^A-Za-z0-9])');
  const saveUserData = () => {
    if (authenticationStore.signUpDetails.email.length < 4) {
      return;
    } else if (!validateEmail(authenticationStore.signUpDetails.email)) {
      setShowEmailError(true);

      dispatch(authAction.setErrorStatus(false));
      setEmailRegister('Enter valid email');
    } else {
      const dbRef = ref(getDatabase());
      get(child(dbRef, `users`))
        .then(snapshot => {
          if (snapshot.exists()) {
            let snapValue = snapshot.val();

            let r = Object.values(snapValue);

            let combineUserEmail = r?.map(e => {
              return e.email === undefined ? 'data not updated' : e?.email;
            });

            if (
              combineUserEmail.includes(authenticationStore.signUpDetails.email)
            ) {
              setShowEmailError(true);

              dispatch(authAction.setErrorStatus(false));

              setEmailRegister('email already in use');
            } else {
              setShowEmailError(false);
              setEmailRegister('');

              dispatch(
                authAction.setProgressStep(authenticationStore.progressStep + 1)
              );
            }
          } else {
            // console.log('No data available');
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  };
  const [passWordNotification, setPassWordNotification] = React.useState(false);

  const getPasswowrNotification = () => {
    setPassWordNotification(true);
    setCPassWordNotification(true);
  };
  const getPasswowrBlurNotification = () => {
    setPassWordNotification(false);
  };

  const [CpassWordNotification, setCPassWordNotification] =
    React.useState(false);

  const getCPasswowrNotification = () => {
    setCPassWordNotification(true);
  };
  const getCPasswowrBlurNotification = () => {
    setCPassWordNotification(false);
  };

  const isEmailValid = validateEmail(authenticationStore.signUpDetails.email)
    ? true
    : '';

  const issamePassword =
    authenticationStore.signUpDetails.userPassword ===
    authenticationStore.signUpDetails.userConfirmPassword
      ? true
      : '';
  const isStrongPassword = !strongPassword.test(
    authenticationStore.signUpDetails.userConfirmPassword
  )
    ? ''
    : true;

  let totalData = [
    authenticationStore.signUpDetails.userName,
    authenticationStore.signUpDetails.userPassword,
    authenticationStore.signUpDetails.userConfirmPassword,
    isEmailValid,
    issamePassword,
    isStrongPassword,
  ];

  const [emailRegister, setEmailRegister] = React.useState('');

  const checkUserAlreadyExits = async () => {
    if (authenticationStore.signUpDetails.email.length < 4) {
      return;
    } else if (!validateEmail(authenticationStore.signUpDetails.email)) {
      setShowEmailError(true);

      dispatch(authAction.setErrorStatus(false));

      setEmailRegister('Enter valid email');
    } else {
      const dbRef = ref(getDatabase());
      get(child(dbRef, `users`))
        .then(snapshot => {
          if (snapshot.exists()) {
            let snapValue = snapshot.val();

            let r = Object.values(snapValue);

            let combineUserEmail = r?.map(e => {
              return e.email === undefined ? 'data not updated' : e?.email;
            });
            // console.log(combineUserEmail);

            if (
              combineUserEmail.includes(authenticationStore.signUpDetails.email)
            ) {
              setShowEmailError(true);

              dispatch(authAction.setErrorStatus(false));
              setEmailRegister('email already in use');
            } else {
              setShowEmailError(false);
              setEmailRegister('');
            }
          } else {
            // console.log('No data available');
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  const getEmailOnFocus = () => {
    setEmailRegister('');
  };
  return (
    <div className="AuthUserIdContainer">
      <LinkedinSignUpOptionWithEmailHeader />
      <div className="AuthUserIdInputFieldContainer">
        <div className="numberInputPlace">
          <AuthInput
            value={authenticationStore.signUpDetails.userName}
            placeHolder="Profile name*"
            onChange={e => dispatch(authAction.getUserName(e.target.value))}
            type="text"
            onFocus={() => console.log('hello')}
            onBlur={() => console.log('hey')}
          />
        </div>
        <div className="emailInputPlace">
          <AuthInput
            value={authenticationStore.signUpDetails.email}
            placeHolder="Email ID*"
            onChange={e => dispatch(authAction.getEmail(e.target.value))}
            type="email"
            onFocus={getEmailOnFocus}
            onBlur={checkUserAlreadyExits}
          />
          {emailRegister === 'email already in use' && (
            <div className="emailErrorWhileSignUp" style={{ color: '#C28681' }}>
              {emailRegister}
            </div>
          )}

          {emailRegister === 'Enter valid email' && (
            <div className="emailErrorWhileSignUp" style={{ color: '#C28681' }}>
              {emailRegister}
            </div>
          )}
        </div>

        <div className="createPasswordInputPlace">
          <AuthPassword
            onChange={e => dispatch(authAction.getPassword(e.target.value))}
            password={authenticationStore.signUpDetails.userPassword}
            placeHolder="Password*"
            labelSize="0.87em"
            padding="0.5%"
            infoSvg=""
            verificationLabel1=""
            onFocus={getCPasswowrNotification}
            onBlur={getCPasswowrBlurNotification}
            notification={CpassWordNotification}
            passwordErrorStatus={
              authenticationStore.signUpDetails.userPassword.length < 2
                ? false
                : !strongPassword.test(
                    authenticationStore.signUpDetails.userPassword
                  )
                ? true
                : false
            }
            passwordSuccessStatus={
              strongPassword.test(
                authenticationStore.signUpDetails.userPassword
              )
                ? true
                : false
            }
          />
          {authenticationStore.signUpDetails.userPassword === '' && (
            <>
              {CpassWordNotification && (
                <div className="AuthNotificationContainer">
                  <div>
                    <b>Create a password that:</b>
                  </div>
                  <div className="AuthPageUserPasswordMessageContainerWithotEnteringPassword">
                    <ul className="AuthPageUserPasswordMessageContainerWithotEnteringPasswordUnorderList">
                      <li>Contains at least 8 characters</li>
                      <li>Contains both lower and upper case letters</li>
                      <li>Contains at least one number and a symbol</li>
                    </ul>
                  </div>
                </div>
              )}
            </>
          )}

          {authenticationStore.signUpDetails.userPassword !== '' && (
            <>
              {CpassWordNotification && (
                <div className="AuthNotificationContainer">
                  <div>
                    <b>Create a password that:</b>
                  </div>
                  <div className="AuthPageUserPasswordMessageContainer">
                    <div
                      className="AuthpasswordMessageContainer"
                      style={{
                        color:
                          authenticationStore.signUpDetails.userPassword.split(
                            ''
                          ).length < 1
                            ? 'gray'
                            : authenticationStore.signUpDetails.userPassword.split(
                                ''
                              ).length < 8
                            ? '#C28681'
                            : '#9FDDBB',
                      }}
                    >
                      <div className="AuthpasswordMessageContainerIcon">
                        <BsCheck />
                      </div>
                      <div>Contains at least 8 characters </div>
                    </div>
                    <div
                      className="AuthpasswordMessageContainer"
                      style={{
                        color:
                          authenticationStore.signUpDetails.userPassword.split(
                            ''
                          ) < 2
                            ? 'gray'
                            : isHavingBothUpperCaseAndLowerCase(
                                authenticationStore.signUpDetails.userPassword
                              )
                            ? '#9FDDBB'
                            : '#C28681',
                      }}
                    >
                      <div className="AuthpasswordMessageContainerIcon">
                        <BsCheck />
                      </div>
                      <div>Contains both lower and upper case letters</div>
                    </div>

                    <div
                      className="AuthpasswordMessageContainer"
                      style={{
                        color:
                          authenticationStore.signUpDetails.userPassword.split(
                            ''
                          ) < 2
                            ? 'gray'
                            : specialCharacter.test(
                                authenticationStore.signUpDetails.userPassword
                              )
                            ? '#9FDDBB'
                            : '#C28681',
                      }}
                    >
                      <div className="AuthpasswordMessageContainerIcon">
                        <BsCheck />
                      </div>
                      <div>Contains at least one number and a symbol</div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="conformPassWordAndMessage">
          <AuthPassword
            onChange={getConfirmPassword}
            password={authenticationStore.signUpDetails.userConfirmPassword}
            placeHolder="Confirm password*"
            labelSize="0.87em"
            padding="0.5%"
            infoSvg=""
            verificationLabel1=""
            onFocus={getPasswowrNotification}
            onBlur={getPasswowrBlurNotification}
            notification={passWordNotification}
            passwordErrorStatus={''}
          />
          {authenticationStore.errorStatus &&
            authenticationStore.registerError === 'Passwords do not match' && (
              <div
                className="messageInCreatePassword"
                style={{
                  color:
                    authenticationStore.registerError ===
                    'Passwords do not match'
                      ? '#C28681'
                      : null,
                }}
              >
                {authenticationStore.registerError}
              </div>
            )}

          {issamePassword === '' && (
            <div
              className="messageInCreatePassword"
              style={{
                color: '#C28681',
                opacity:
                  authenticationStore.signUpDetails.userConfirmPassword.length >
                    4 &&
                  authenticationStore.signUpDetails.userPassword.length > 4
                    ? 1
                    : 0,
              }}
            >
              Passwords do not match
            </div>
          )}
          <div className="AuthUseridButton">
            <AuthButton
              disabled={totalData.includes('')}
              buttonText="Next"
              onClick={saveUserData}
              type="button"
              width="5.8em"
              height="3em"
              diableColor={
                totalData.includes('') || emailRegister !== ''
                  ? '#e8eaed'
                  : 'orange'
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthUserId;
