import React from 'react';

/**
 * [Component] InventorySelectButton
 
 * @description select specific data from API

 * @param {*} value accepts string as button title
 * @param {*} onClick add data in array on function call
 * 
 * @returns the data with first character in uppercase
 */

export const IngredientSelectButton = ({ value, onClick }) => {
  return (
    <button className="IngredientSelectButton" onClick={onClick}>
      {/* {value[0].toUpperCase() + value.substring(1)} */}
      {value}
    </button>
  );
};
// string[0].toUpperCase()
