import * as d3 from 'd3';

function getData(parentIDs, width, height) {
  var rawData = [];
  rawData.push({ id: 'root' });
  parentIDs.forEach(d => {
    rawData.push({ id: d.key, parentId: 'root', size: d.values });
    d3.range(0, d.values).forEach(el => {
      rawData.push({
        id: el,
        parentId: d.key,
        size: 1,
      });
    });
  });

  const vData = d3.stratify()(rawData);
  const vLayout = d3.pack().size([width, height]).padding(0);
  const vRoot = d3.hierarchy(vData).sum(function (d) {
    return d.data.size;
  });
  const vNodes = vLayout(vRoot);
  const data = vNodes.descendants().slice(1);

  return data;
}

export default getData;
