import React, { useRef } from 'react';
import * as d3 from 'd3';
import './VocabularyGraph.css';
import { Zoomed } from '../../../utils';
import { vocabularyComponentActions, useDispatch } from '../../../Redux';
import { convertRemToPixels, ZoomButton } from '../../../lib';

const VocabularyGraph = ({ nodes, link_data, mapExternlLinksVocabGraph }) => {
  // console.log(nodes);
  const dispatch = useDispatch();
  const allSourceNodeIds = link_data.map(el => {
    return el.source;
  });
  const allTargetNodesIds = link_data.map(ek => {
    return ek.target;
  });
  const allLinksIds = [...allSourceNodeIds, ...allTargetNodesIds];

  var showLinksData = [];
  const svgRef = useRef(null);
  var width = convertRemToPixels(50);

  var margin = width * 0.1;

  React.useEffect(() => {
    var element = svgRef.current;
    var transform = d3.zoomIdentity
      .translate(element.clientWidth, element.clientHeight)
      .scale(convertRemToPixels(0.008));
    var zoom = d3.zoom().on('zoom', handleZoom);

    var svg = d3.select(`#myVocabulary`).call(zoom);

    zoom.scaleBy(svg.transition().duration(0), convertRemToPixels(0.008));
    var zoomable = svg
      .append('g')
      .attr('class', 'zoomable')
      .attr('transform', transform); // Applies initial transform
    //apply the hierarchy
    function handleZoom(event) {
      if (zoomable) {
        zoomable.attr('transform', event.transform);
      }
    }

    const value_max = d3.max(nodes, d => d3.max(d.children, m => m.value));
    const radius_scale = d3
      .scaleLinear()
      .domain([0, value_max])
      .range([10, 20]);
    const link_scale = d3.scaleLinear().domain([0.1, 1]).range([0.5, 6]);

    //apply the pack
    let pack_data = d3
      .pack()
      .size([width * 0.6, width * 0.6])
      .radius(d => radius_scale(d.data.value))
      .padding(0)(
      d3
        .hierarchy({ name: 'root', children: nodes })
        .sum(d => d.value)
        .sort((a, b) => b.value - a.value)
    );

    pack_data.children.forEach(function (d) {
      const my_x = d.x;
      const my_y = d.y;
      d.pack_x = d.r;
      d.pack_y = d.r;
      d.category = d.data.category;
      for (let c in d.children) {
        d.children[c].pack_x = d.children[c].x - my_x + d.r;
        d.children[c].pack_y = d.children[c].y - my_y + d.r;
        d.children[c].category = d.data.category;
      }
    });
    //now the links.  Hard copy so no issue with refreshing (whien link.source === link.source.id)
    // let links = JSON.parse(JSON.stringify(link_data));
    //++++++++++++++++++++++++++++++loop through and add source and target category ids+++++++++++++++++++++
    // let links = link_data;
    //console.loglink_data);
    let links = link_data;
    for (let l in links) {
      var my_source = pack_data.children.find(d =>
        d.children.find(f => f.data.id === links[l].source)
      );
      links[l].source_cid = my_source.data.id;
      var my_target = pack_data.children.find(d =>
        d.children.find(f => f.data.id === links[l].target)
      );
      links[l].target_cid = my_target.data.id;
    }
    //now loop through categories and add a new link for each category combination
    // ++++++++++++++++++++++++++++++parent Nodes Attachments +++++++++++++++++++++++++++++++++++++
    // const category_ids = [...new Set(Array.from(nodes, (d) => d.id))];
    const category_ids = mapExternlLinksVocabGraph;

    const ParentLinks = category_ids.map((el, i) => {
      const Data = {
        id: `1${i}`,
        source: el.source,
        target: el.target,
        rating: 0.5,
        parent: true,
      };
      return Data;
    });

    // console.log(links);
    links = links.concat(ParentLinks);
    // console.log(links);
    // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    //we're ready - links for all depth === 2 and depth === 1 nodes
    //pack data just needs to be collapsed so one row for every circle.
    pack_data = pack_data.descendants().slice(1);

    //links first (separate join as separate dataset)
    // +++++++++++++++++++++++++++++++++++++++++++++++++++++++create links Group with className+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    var link_group = zoomable
      .selectAll('.link_group')
      .data(links)
      .join(function (group) {
        var enter = group.append('g').attr('class', 'link_group');
        enter.append('line').attr('class', 'link_line');
        enter.append('text').attr('class', 'link_Label');
        return enter;
      });
    //   ++++++++++++++++++++++++++++append Line++++++++++++++++++++++++++++++++++++++++++

    link_group
      .select('.link_line')
      .attr('id', d => d.source + ':' + d.target)
      .attr(
        'class',
        d => 'link_line ' + 'link_' + d.source + ' link_' + d.target
      )
      //   .attr("stroke", (d) => (d.parent === undefined ? "#F0F0F0" : "#D0D0D0"))
      .attr('visibility', d => (d.parent !== undefined ? 'visible' : 'hidden'))
      .attr('stroke', 'black')
      .attr('opacity', 0.2)
      .attr('stroke-width', d => link_scale(d.rating));
    //   +++++++++++++++++++++++++++++append Label ++++++++++++++++++++++++++++++++++++
    link_group
      .select('.link_Label')
      .attr('id', d => d.source + ':' + d.target)
      .attr(
        'class',
        d => `link_Label linkLabel_${d.source} linkLabel_${d.target}`
      )
      .attr('pointer-events', 'none')
      // .attr('visibility', 'hidden')
      .attr('opacity', 0)
      .attr('fill', 'black')

      .attr('x', 0)
      .attr('y', 6)
      .attr('text-anchor', 'middle')
      .attr('font-size', '60px')

      .text((d, i) => `Link_${i}`);
    //   +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++create Group for Nodes With className++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

    //now nodes
    var my_group = zoomable
      .selectAll('.node_group')
      .data(pack_data)
      .join(function (group) {
        var enter = group.append('g').attr('class', 'node_group');
        enter.append('circle').attr('class', 'pack_circle');
        enter.append('path').attr('class', 'pack_label_path');
        enter
          .append('text')
          .attr('class', 'pack_text')
          .append('textPath')
          .attr('class', 'pack_textpath');
        return enter;
      });
    zoomable
      .selectAll('.node_group')
      .append('text')
      .attr('class', 'nodeLabel')

      .text(ek =>
        ek.depth === 1 && ek.data.children.length !== 0
          ? ek.data.category
          : null
      )
      .attr('font-size', '60px')
      .attr('dx', d => d.r)
      .attr('dy', d => 2 * d.r + 70)
      .style('text-anchor', 'middle')
      .attr('fill', ek =>
        ek.depth === 1 && ek.data.colorCode !== 'External'
          ? '#F28C1C'
          : '#005481'
      )
      .attr('font-weight', 'normal');

    d3.selectAll('.link_line');

    // ++++++++++++++++++++++++++++++++++++++append Circle ++++++++++++++++++++++++++++++++++++++++++++++
    my_group
      .select('.pack_circle')
      .attr('id', d => 'node_' + d.data.id)
      .attr('cx', d => d.pack_x)
      .attr('cy', d => d.pack_y)
      .attr('r', d => (d.depth === 1 ? d.r : 10))
      .attr('fill', d =>
        d.depth === 1
          ? // ? pickColor(d.data.category, 96)
            d.data.colorCode !== 'External'
            ? '#FFFAF4'
            : '#EFF9FF'
          : allLinksIds.includes(d.data.id)
          ? 'black'
          : // : pickColor(d.parent.category, 60)
          d.parent.data.colorCode !== 'External'
          ? '#F28C1C'
          : '#56aad7'
      )
      .attr('stroke', d =>
        d.depth === 1
          ? d.data.colorCode !== 'External'
            ? '#F28C1C'
            : '#005481'
          : ''
      )
      .attr('stroke-width', d => (d.depth === 1 ? 3 : 0.2))
      .attr(
        'visibility',
        d => d.depth === 1 && d.data.children.length === 0 && 'hidden'
      )
      .on('click', function (d, ek) {
        showLinksData = [];
        if (ek.depth === 1) {
          dispatch(vocabularyComponentActions.setVicabUri(ek.data.name));

          dispatch(
            vocabularyComponentActions.setVocabularyContentStateHandler(
              'antologyNode'
            )
          );
        } else {
          dispatch(vocabularyComponentActions.setVicabUri(ek.parent.data.name));
          dispatch(
            vocabularyComponentActions.setVocabularyContentStateHandler(
              'childNodeVocabulary'
            )
          );
          // dispatch(vocabularyComponentActions.setAntologyName(ek.category));
        }

        dispatch(vocabularyComponentActions.setFindDepth(ek.depth));
        dispatch(vocabularyComponentActions.setInitialVocabularyContent(false));
        dispatch(vocabularyComponentActions.setAntologyName(ek.category));
        dispatch(
          vocabularyComponentActions.getFindTatgetedNodes({
            link_data: link_data,
            ref: ek.data,
          })
        );

        dispatch(
          vocabularyComponentActions.getFindSourceNodes({
            link_data: link_data,
            ref: ek.data,
          })
        );

        // +++++++++++++++++++++++++++++++++++++++hide Every thing Intially+++++++++++++++++++++++++++++
        d3.selectAll('.pack_circle').attr('opacity', 0.2);
        d3.selectAll('.link_line').attr('visibility', 'hidden');
        d3.selectAll('.pack_textpath').attr('visibility', 'hidden');

        //++++++++++++++++++++++++++++++++++++++++show only nodes+++++++++++++++++++++++++++++++++++++++

        d3.selectAll('#node_' + ek.data.id).attr('opacity', '1');
        d3.selectAll('#node_' + ek.data.id).attr(
          'stroke-opacity',
          d => d.depth === 2 && 1
        );
        d3.selectAll('#node_' + ek.data.id).attr(
          'stroke-width',
          d => d.depth === 1 && '4'
        );
        d3.selectAll('.nodeLabel')
          .filter(el => el.depth === 1 && el)
          .attr('font-weight', d =>
            d.category === ek.category ? 'bolder' : 'normal'
          );

        //+++++++++++++++++++++++++++++++++++++++++show Parent nodes Label+++++++++++++++++++++++++++++++++
        d3.selectAll('textPath#node_' + ek.data.id)
          .attr('visibility', 'visible')
          .attr('font-weight', 'lighter');

        // ++++++++++++++++++++++++++++++++++++++++++ find links, nodes and text while hover+++++++++++++++++++
        d3.selectAll('.link_' + ek.data.id).attr('visibility', 'visible');
        // d3.selectAll('.linkLabel_' + ek.data.id).attr('visibility', 'hdden');

        d3.selectAll('.link_' + ek.data.id)
          .attr(
            'stroke',
            ek.depth === 1
              ? 'gray'
              : ek.parent.data.colorCode !== 'External'
              ? '#F28C1C'
              : '#005481'
          )

          .each(function (e) {
            //console.loge);
            // console.log(e);
            showLinksData.push(e);
            if (e.source.data.id === ek.data.id) {
              d3.selectAll('#node_' + e.target.data.id).attr('opacity', '1');
              d3.selectAll('.link_' + ek.data.id).attr('opacity', '1');

              // showLinksData = [];
            } else {
              d3.selectAll('#node_' + e.source.data.id).attr('opacity', '1');
              d3.selectAll('.link_' + ek.data.id).attr('opacity', '1');
            }
          });
        if (ek.depth === 1) {
          //for 'sub category' nodes, hide count label and show pack circles and labels
          d3.select('.pack_count#node_' + ek.data.id).attr(
            'visibility',
            'hidden'
          );
          d3.selectAll('.pack_circle')
            .filter(function (f) {
              return f.parent.data.id === ek.data.id;
            })
            .each(function (f) {
              d3.selectAll('#node_' + f.data.id).attr('visibility', 'visible');
              d3.selectAll('textPath#node_' + f.data.id).attr('fill', 'white');
            });
        }
      });

    //   //    +++++++++++++++++++++++++++++++++clear every thing while mouse out except nodes+++++++++++++++++++++++++++
    my_group.select('.pack_circle').on('mouseout', function () {
      d3.selectAll('.link_Label').attr('opacity', 0);
    });
    // });
    d3.selectAll('.pack_circle').attr('opacity', 1);
    d3.selectAll('.pack_count').attr('visibility', 'hidden');
    d3.selectAll('.link_line').attr('opacity', l => (l.parent ? 0.3 : 0));

    //count labels

    //invisible path for circle labels
    my_group
      .select('.pack_label_path')
      .attr('id', d => 'pack_path' + d.data.id)
      .attr('d', draw_path)

      .attr('stroke-width', 0)
      .attr('fill', 'none');

    function draw_path(d) {
      return (
        'M' +
        (d.pack_x - d.r - 3) +
        ',' +
        d.pack_y +
        ' A' +
        d.r +
        ',' +
        d.r +
        ' 0 0,1 ' +
        (d.pack_x + d.r + 3) +
        ',' +
        d.pack_y
      );
    }

    Zoomed(
      '#zoom_in_force5',
      '#zoom_out_force5',
      '#vocabGraphZoomReset',
      svg,
      zoom,
      zoomable
    );
    const simulation = d3
      .forceSimulation(pack_data)

      .force(
        'link',
        d3
          .forceLink(links)
          .id(d => d.data.id)
          .strength(0)
      )
      .force(
        'collide',
        d3.forceCollide(d => (d.depth === 1 ? d.r * 2 : 0)).strength(0.6)
      )
      .force(
        'center',
        d3.forceCenter(
          svgRef.current.clientWidth / 4,
          svg._groups[0][0].clientHeight / 2
        )
      );

    simulation.on('tick', () => {
      //node items
      //   +++++++++++++++++++++++++++++++++++++place nodes with tick function++++++++++++++++++++++++++
      my_group.attr('transform', get_node_translate);

      //   ++++++++++++++++++++++++++++++++++++place links with tick function+++++++++++++++++++++++++
      d3.selectAll('.link_line')
        .attr(
          'x1',
          d => d.source.pack_x + margin + get_link_extra(d, 'source', 'x')
        )
        .attr(
          'x2',
          d => d.target.pack_x + margin + get_link_extra(d, 'target', 'x')
        )
        .attr(
          'y1',
          d => d.source.pack_y + margin / 2 + get_link_extra(d, 'source', 'y')
        )
        .attr(
          'y2',
          d => d.target.pack_y + margin / 2 + get_link_extra(d, 'target', 'y')
        );
      // +++++++++++++++++++++++++++++++++++++++plaxe link label by mathamatic caluculation++++++++++++++++++++++++

      // d3.selectAll('.link_Label').attr('transform', function (d) {
      //   var x1 = d.source.pack_x + margin + get_link_extra(d, 'source', 'x');
      //   var x2 = d.target.pack_x + margin + get_link_extra(d, 'target', 'x');
      //   var y1 =
      //     d.source.pack_y + margin / 2 + get_link_extra(d, 'source', 'y');
      //   var y2 =
      //     d.target.pack_y + margin / 2 + get_link_extra(d, 'target', 'y');

      //   var angle = (Math.atan((y1 - y2) / (x1 - x2)) * 180) / Math.PI;
      //   return (
      //     'translate(' +
      //     [(x1 + x2) / 2, (y1 + y2) / 2] +
      //     ')rotate(' +
      //     angle +
      //     ')'
      //   );
      // });
      //   ++++++++++++++++++++++++++++++++++++++++++++++++++ align based on margin function +++++++++++++++++++++++++++++++++
      function get_node_translate(d) {
        //depending on depth use x or the parent's (ie coords provided by d3.pack)
        if (d.depth === 1) {
          return 'translate(' + (d.x + margin) + ',' + (d.y + margin / 2) + ')';
        } else {
          return (
            'translate(' +
            (d.parent.x + margin) +
            ',' +
            (d.parent.y + margin / 2) +
            ')'
          );
        }
      }

      function get_link_extra(d, type, coord) {
        //depending on depth use x or the parent's (ie coords provided by d3.pack)
        return d.parent === undefined ? d[type].parent[coord] : d[type][coord];
      }
    });

    // invalidation.then(() => simulation.stop());

    return svg.node();
  }, []);

  return (
    <>
      <div className="GraphContainer" ref={svgRef}>
        <svg
          // align="center"
          id="myVocabulary"
          className="ContainerSvg"
          width="100%"
          height="97%"
        ></svg>
        <ZoomButton
          zoomOutId={'zoom_out_force5'}
          zoomInId={'zoom_in_force5'}
          zoomResetId={'vocabGraphZoomReset'}
          zoomCenter="vocabSetCenter"
        />
      </div>
    </>
  );
};
export default VocabularyGraph;
