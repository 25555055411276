import React from 'react';
import './BackButton.css';
const BackButton = ({ onClick, className }) => {
  return (
    <button onClick={onClick} className={className}>
      Back
    </button>
  );
};

export default BackButton;
