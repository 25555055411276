import React from 'react';
import { useGraphDataFetch } from '../../utils';
import { PageBanner, ProcessMoniteringStats } from '..';

const Statististics = () => {
  const _stats_smart_substitution_classes_QueryId_first_Id =
    // eslint-disable-next-line no-undef
    process.env
      .REACT_APP_STATS_SMART_SUBSTITUTE_CLASSES_INFO_QUERY_ID_POINT_ONE;

  const _stats_smart_substitution_classes_QueryId_second_Id =
    // eslint-disable-next-line no-undef
    process.env
      .REACT_APP_STATS_SMART_SUBSTITUTE_CLASSES_INFO_QUERY_ID_POINT_TWO;

  const _stats_process_challange_classes_QueryId_first_Id =
    // eslint-disable-next-line no-undef
    process.env
      .REACT_APP_STATS_PROCESS_MONITORING_CLASSES_INFO_QUERY_ID_POINT_ONE;

  const _stats_process_challange_classes_QueryId_second_Id =
    // eslint-disable-next-line no-undef
    process.env
      .REACT_APP_STATS_PROCESS_MONITORING_CLASSES_INFO_QUERY_ID_POINT_TWO;

  // REACT_APP_STATS_PROCESS_MONITORING_CLASSES_INFO_QUERY_ID_POINT_ONE
  // REACT_APP_STATS_PROCESS_MONITORING_CLASSES_INFO_QUERY_ID_POINT_TWO

  const statusSunstituteKey =
    '<http://vocab.curryconfigurator.org/smartsubstitution/>';
  const statusProcessKey =
    '<http://vocab.curryconfigurator.org/processmonitoring/>';
  const [StatsSubstitutionStatus, StatsSubstitutionData] = useGraphDataFetch(
    `${_stats_smart_substitution_classes_QueryId_first_Id}${
      statusSunstituteKey === '' ? `<>` : statusSunstituteKey
    } WHERE {  BIND (${
      statusSunstituteKey === '' ? `<>` : statusSunstituteKey
    }${_stats_smart_substitution_classes_QueryId_second_Id}`
  );
  const [statsProcessStatus, statsProcessData] = useGraphDataFetch(
    `${_stats_process_challange_classes_QueryId_first_Id}${
      statusProcessKey === '' ? `<>` : statusProcessKey
    } WHERE {  BIND (${
      statusProcessKey === '' ? `<>` : statusProcessKey
    }${_stats_process_challange_classes_QueryId_second_Id}`
  );
  return (
    <div className="InvContainer">
      <PageBanner
        title="Knowledge Statistics"
        src={null}
        mainText="You can reuse the knowledge you’ve acquired so far to resolve any new use cases! Keep on adding new use cases to make the knowledge base richer! Because, the more use cases you add, the more future-ready you get!"
        titleClass="InventorttitleClass"
        mainTextClass="InventorymainTextClass"
        imgClass="InventoryimgClass"
        bannerClass="inventoryTitleImgFlex"
      />
      <ProcessMoniteringStats
        LoadingThemeColor={'orange'}
        substituteReuseNavigation={null}
        ProcessReuseNavigation={null}
        StatsSubstitutionStatus={StatsSubstitutionStatus}
        statsProcessStatus={statsProcessStatus}
        StatsSubstitutionData={StatsSubstitutionData}
        statsProcessData={statsProcessData}
      />
    </div>
  );
};

export default Statististics;
