const setWidth = originallength => {
  switch (true) {
    case originallength <= 3:
      return `${originallength * 30}`;
    case originallength < 4:
      return `${originallength * 28}`;
    case originallength > 20:
      return `${originallength * 8}`;
    case originallength > 13:
      return `${originallength * 9}`;
    case originallength > 10:
      return `${originallength * 9}`;

    case originallength < 10:
      return `${originallength * 20}`;

    default:
      return `${originallength * 20}`;
  }
};

export default setWidth;
