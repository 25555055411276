import React from 'react';
import { ToolTipInfo } from '../../../lib';

const AlterCookingConditionSb1 = ({
  showGeneratorFunction,
  sb1ProcessData,
}) => {
  function replaceJSX(str, find, replace, replacedWord) {
    let parts = str.split(find);

    parts.splice(parts.indexOf(replacedWord), 1, replace);
    const mapJSx = parts.map(el => {
      return <>{el} </>;
    });
    return mapJSx;
  }
  return (
    <>
      <div className="recipeInstructionsContentContainer">
        <div className="processChallengeInstructionTitleFlex">
          <h2 className="sb1ProcessHedding">
            Process{' '}
            <ToolTipInfo
              title="Different Techniques involved in cooking, with various methods using differing levels of heat and vary in cooking time"
              placement="right"
            />{' '}
          </h2>
          <button
            onClick={showGeneratorFunction}
            className="alterCookingButton"
          >
            Manage Cooking Conditions
          </button>
        </div>

        <ul>
          {sb1ProcessData.rows.length !== 0 &&
            sb1ProcessData.rows.map((el, i) => {
              if (el === '') {
                return;
              }
              return (
                <li key={i}>
                  {replaceJSX(
                    el.ins,
                    ' ',
                    <span>{el.value_ins}</span>,
                    el.value_ins
                  )}
                </li>
              );
            })}
        </ul>
      </div>
    </>
  );
};

export default AlterCookingConditionSb1;
