import React from 'react';

/**
 * [component] InventoryRemoveButton
 
 * @description displays the button to remove ingredient

 * @param {string} value accepts string as button title
 * @param {*} onClick remove ingredient on function call
 * @param {*} removeIcon accepts svg
 
 * @returns removes the selected data from the array
 */

const IngredientRemoveButton = ({ value, onClick, removeIcon }) => {
  return (
    <button className="IngredientRemoveButton" onClick={onClick}>
      <span style={{ padding: '0em .5em', paddingBottom: '.6em' }}>
        {' '}
        {value}
      </span>
      <span
        style={{
          padding: '0em .5em',
          fontSize: '.9em',
          color: 'rgba(90, 197, 138, 0.6)',
          fontWeight: 'bolder',
        }}
      >
        {removeIcon}
      </span>
    </button>
  );
};

export default IngredientRemoveButton;
