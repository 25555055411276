import React from 'react';
import { IoIosStats } from 'react-icons/io';
import { BiMenu } from 'react-icons/bi';
import {
  StatisticsPropertiesComponent,
  ShowOntologiesList,
  ReuseComponent,
  DonutComponent,
} from '../../Component/index';

import { useStatsProcessFetch } from '../../utils';

import { useSelector } from 'react-redux';
import { AppColors } from '../../Constants/AppColors';
const StatisticsAnalysisComponent = ({
  StatsSubstitutionData,
  statsProcessData,
}) => {
  const radiusRef = React.useRef(null);
  // console.log(StatsSubstitutionData, statsProcessData);
  const statsPageStore = useSelector(state => state.statsPageStore);
  const substituteAntologyData =
    StatsSubstitutionData?.data?.results?.bindings.map(el => {
      const Data = {
        antology: el.vocab_label.value,
        publisher: el.publisher.value,
        vocabValue: el.vocab_value.value,
      };
      return Data;
    });

  const substituteMappedAntologyList = substituteAntologyData.map((ej, i) => {
    return (
      <li
        className={ej.publisher === 'external' ? 'external' : 'internal'}
        key={i}
      >
        {ej.antology}
      </li>
    );
  });
  const ProcessAntologyData = statsProcessData?.data?.results?.bindings.map(
    el => {
      const Data = {
        antology: el.vocab_label.value,
        publisher: el.publisher.value,
        vocabValue: el.vocab_value.value,
      };
      return Data;
    }
  );
  const processMappedAntologyList = ProcessAntologyData.map((ej, i) => {
    return (
      <li
        className={ej.publisher === 'external' ? 'external' : 'internal'}
        key={i}
      >
        {ej.antology}
      </li>
    );
  });
  // ========================================================================================================================
  const RecursiveSubstituteFunction = substituteAntologyData => {
    const [substituteLoopStatus, result, findChildNodes] = useStatsProcessFetch(
      substituteAntologyData
    );

    return [substituteLoopStatus, result, findChildNodes];
  };

  const [substituteLoopStatus, results, recursiveResult] =
    RecursiveSubstituteFunction(substituteAntologyData);
  var substituteTotalClasses;
  if (substituteLoopStatus === 'LoopEnd') {
    const combineData = [...results, ...recursiveResult];
    // console.log(combineData);
    const combineTotalVocabularies = combineData
      .map(ek => parseInt(ek.class_count.value))
      .reduce((prv, curr) => {
        return prv + curr;
      });
    substituteTotalClasses = combineTotalVocabularies;
  }

  // =======================================================================================================================================
  const RecursiveProcessFunction = substituteAntologyData => {
    const [processLoopStatus, result, findChildNodes] = useStatsProcessFetch(
      substituteAntologyData
    );

    return [processLoopStatus, result, findChildNodes];
  };

  const [processLoopStatus, processResult, recursiveProcessResult] =
    RecursiveProcessFunction(ProcessAntologyData);
  var processTotalClasses;
  if (processLoopStatus === 'LoopEnd') {
    const combineData = [...processResult, ...recursiveProcessResult];
    // console.log(combineData);
    const combineTotalVocabularies = combineData
      .map(ek => parseInt(ek.class_count.value))
      .reduce((prv, curr) => {
        return prv + curr;
      });
    processTotalClasses = combineTotalVocabularies;
  }

  var reusePercentage;
  var RepurposePercentage;
  if ((substituteLoopStatus === 'LoopEnd', processLoopStatus === 'LoopEnd')) {
    const findInternalClasses =
      ProcessAntologyData.filter(eg => eg.publisher === 'internal' && eg)
        .length -
      substituteAntologyData.filter(eg => eg.publisher === 'internal' && eg)
        .length;
    const addAntology = ProcessAntologyData.length - findInternalClasses;
    const devide = addAntology / ProcessAntologyData.length;
    reusePercentage = devide * 100;
    const extraClasses = processTotalClasses - substituteTotalClasses;
    const addClasses = processTotalClasses - extraClasses;
    const devideClasses = addClasses / processTotalClasses;
    RepurposePercentage = devideClasses * 100;
  }
  const title = ['New', 'Re-purposed from'];
  const titleHeadind = ['New', 'Re-used from'];

  return (
    <>
      {/* {substituteLoopStatus !== 'LoopEnd' &&
        processLoopStatus !== 'LoopEnd' && (
          <Loading color={LoadingThemeColor} />
        )} */}
      {/* {substituteLoopStatus === 'LoopEnd' && processLoopStatus === 'LoopEnd' && ( */}
      {statsPageStore.substitutionCheck && (
        <div className="statisticsTitleContainer">
          <IoIosStats color={`${AppColors.process.base_color}`} size={'1rem'} />
          <div className="statsTitle">Statistics</div>
        </div>
      )}

      <div className="compareStatisticsContainer">
        <div className="chartsAndStatsFlex">
          <div className="statsBlockContainer">
            {statsPageStore.substitutionCheck && (
              <StatisticsPropertiesComponent
                title="Smart substitution"
                totalVocabulariesUsed={substituteAntologyData.length}
                internal={
                  substituteAntologyData.filter(
                    eg => eg.publisher === 'internal' && eg
                  ).length
                }
                external={
                  substituteAntologyData.filter(
                    eg => eg.publisher === 'external' && eg
                  ).length
                }
                classesUsed={
                  substituteLoopStatus === 'LoopEnd'
                    ? substituteTotalClasses
                    : 'Loading..'
                }
              />
            )}

            {statsPageStore.processCheck && (
              <>
                <StatisticsPropertiesComponent
                  title="Process monitoring"
                  totalVocabulariesUsed={ProcessAntologyData.length}
                  external={
                    ProcessAntologyData.filter(
                      eg => eg.publisher === 'external' && eg
                    ).length
                  }
                  internal={
                    ProcessAntologyData.filter(
                      eg => eg.publisher === 'internal' && eg
                    ).length
                  }
                  classesUsed={
                    processLoopStatus === 'LoopEnd'
                      ? processTotalClasses
                      : 'Loading..'
                  }
                />
                {/* <StatisticsPropertiesComponent
                title="Overall statistics"
                totalVocabulariesUsed={
                  ProcessAntologyData.length + substituteAntologyData.length
                }
                external={6}
                internal={2}
                classesUsed={2082}
              /> */}
                <ReuseComponent
                  title="Overall statistics"
                  totalVocabulariesUsed={ProcessAntologyData.length}
                  ReusePercentage={
                    substituteLoopStatus === 'LoopEnd' &&
                    processLoopStatus === 'LoopEnd'
                      ? `${reusePercentage.toFixed(2) + '%'}`
                      : 'Loading..'
                  }
                  RepurposePercentage={
                    substituteLoopStatus === 'LoopEnd' &&
                    processLoopStatus === 'LoopEnd'
                      ? `${RepurposePercentage.toFixed(2) + '%'}`
                      : 'Loading..'
                  }
                  classesUsed={
                    substituteLoopStatus === 'LoopEnd' &&
                    processLoopStatus === 'LoopEnd'
                      ? processTotalClasses
                      : 'Loading..'
                  }
                />
              </>
            )}
          </div>
        </div>
        {statsPageStore.processCheck && (
          <div className="piChartsMainTopBlockContainer">
            <div
              className="piChartsMainBlockContainer"
              ref={radiusRef}
              // style={{ backgroundColor: 'green' }}
            >
              <DonutComponent
                percentageValue={
                  substituteLoopStatus === 'LoopEnd' &&
                  processLoopStatus === 'LoopEnd' &&
                  typeof reusePercentage === 'number' &&
                  reusePercentage.toFixed(2)
                }
                arrayColors={['#8A52EA', '#C9B0F4']}
                projects={['vocabularies ', 'other projects']}
                id="1"
                internal={[
                  `Internal : ${
                    substituteAntologyData.filter(
                      eg => eg.publisher === 'internal' && eg
                    ).length
                  }`,
                  `External : ${
                    substituteAntologyData.filter(
                      eg => eg.publisher === 'external' && eg
                    ).length
                  }`,
                ]}
                external={[
                  `External: ${
                    ProcessAntologyData.filter(
                      eg => eg.publisher === 'external' && eg
                    ).length -
                    substituteAntologyData.filter(
                      eg => eg.publisher === 'external' && eg
                    ).length
                  }`,
                  `Internal :  ${
                    ProcessAntologyData.filter(
                      eg => eg.publisher === 'internal' && eg
                    ).length -
                    substituteAntologyData.filter(
                      eg => eg.publisher === 'internal' && eg
                    ).length
                  }`,
                ]}
                title={titleHeadind}
                radiusRef={radiusRef}
              />
              <DonutComponent
                percentageValue={
                  substituteLoopStatus === 'LoopEnd' &&
                  processLoopStatus === 'LoopEnd' &&
                  typeof RepurposePercentage === 'number' &&
                  RepurposePercentage.toFixed(2)
                }
                arrayColors={['#3E5463A7', '#4FD1C5']}
                projects={['classes : 32', 'other projects : 2050']}
                id="2"
                internal={['', '']}
                external={['', '']}
                title={title}
                radiusRef={radiusRef}
              />
            </div>
          </div>
        )}
        {statsPageStore.substitutionCheck && (
          <div className="ontologyListBlock">
            <div className="OntologyAndExternalInternalFlex">
              <div className="statisticsTitleContainer">
                <BiMenu
                  color={`${AppColors.process.base_color}`}
                  size={'1.7rem'}
                />
                <div className="statsTitle antologyTitle">Ontology list</div>
              </div>
              <ul className="ecternalInternalColorCodeExprestionTitle">
                <li className="InternalList">Internal ontology</li>
                <li className="externalList">External ontology</li>
              </ul>
            </div>
            <div className="ontologiesPropertiesGrid">
              <ShowOntologiesList
                antologyList={substituteMappedAntologyList}
                title=" Ontologies used in smart substitution "
              />

              {statsPageStore.processCheck && (
                <ShowOntologiesList
                  antologyList={processMappedAntologyList}
                  title=" Ontologies used in process monitoring"
                />
              )}
            </div>
          </div>
        )}
      </div>

      {/* )} */}
    </>
  );
};

export default StatisticsAnalysisComponent;
{
  /* <div className="DonutCollection">
 {/* <div className="douhnutContainer"> */
}

{
  /* <div className="VocabulariesTextBelowChart">
    {reusePercentage ? 'Reuse' : null}
   </div> */
}
{
  /* </div> */
}
<div className="douhnutContainer leftDouhnutContainer">
  {/* <div className="VocabulariesTextBelowChart">
//       {RepurposePercentage ? 'Repurpose' : null}
//     </div> */}
</div>;
// </div> */}

// `External : ${
//   ProcessAntologyData.filter(
//     eg => eg.publisher === 'external' && eg
//   ).length -
//   substituteAntologyData.filter(
//     eg => eg.publisher === 'external' && eg
//   ).length
// }`,
