import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AuthInput,
  PageDivider,
  AuthBackButton,
  AuthButton,
  AuthPassword,
} from '../../../../lib';
import { authAction } from '../../../../Redux/Slices/AuthSlides/AuthenticationStates';

const AuthUserDataSavePage = ({ settypeOfSignUp }) => {
  const authenticationStore = useSelector(s => s.authStore);
  const [passWordNotification, setPassWordNotification] = React.useState(false);
  const dispatch = useDispatch();
  const getPasswowrNotification = () => {
    setPassWordNotification(true);
  };
  const getPasswowrBlurNotification = () => {
    setPassWordNotification(false);
  };

  const [CpassWordNotification, setCPassWordNotification] =
    React.useState(false);

  const getCPasswowrNotification = () => {
    setCPassWordNotification(true);
  };
  const getCPasswowrBlurNotification = () => {
    setCPassWordNotification(false);
  };
  let initialState = {
    name: '',
    password: '',
    cPassword: '',
  };
  const [employeDetails, setEmployeDetails] = React.useState(initialState);

  const setUserName = e => {
    setEmployeDetails(p => {
      return {
        name: e.target.value,
        password: p.password,
        cPassword: p.cPassword,
      };
    });
  };

  const setUserPassword = e => {
    setEmployeDetails(p => {
      return {
        name: p.name,
        password: e.target.value,
        cPassword: p.cPassword,
      };
    });
  };

  const setCpassword = e => {
    setEmployeDetails(p => {
      return {
        name: p.name,
        password: p.password,
        cPassword: e.target.value,
      };
    });
  };
  //console.logemployeDetails);

  // let totalData = [
  //   employeDetails.name,
  //   employeDetails.password,
  //   employeDetails.cPassword,
  // ];

  let strongPassword = new RegExp(
    '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'
  );
  const setSignUpUsingEmail = () => {
    settypeOfSignUp('email');
    // setProgressStep(0);
    dispatch(authAction.setProgressStep(0));
  };
  const getSuccessFulPage = () => {
    // setIsSigninSuccessfull(true);
    dispatch(authAction.setIsSigninSuccessfull(true));
    // setProgressStep(p => p + 1);
    dispatch(authAction.setProgressStep(authenticationStore.progressStep + 1));
  };
  return (
    <div className="AuthuserInfoByLinkedIn">
      <div>
        <div className="AuthuserInfoByLinkedInHeading">
          <b>Sign up with Linkedin </b>
        </div>
        <div className="AuthuserInfoByLinkedInInputContainer">
          <AuthInput
            value={employeDetails.name}
            placeHolder="Username"
            onChange={setUserName}
            type="text"
            // onFocus={() => console.log('hello')}
            // onBlur={() => console.log('hey')}
          />

          <AuthPassword
            onChange={setUserPassword}
            password={employeDetails.password}
            placeHolder="Select password"
            labelSize="0.87em"
            padding="0.5%"
            infoSvg=""
            verificationLabel1=""
            onFocus={getCPasswowrNotification}
            onBlur={getCPasswowrBlurNotification}
            notification={CpassWordNotification}
            passwordErrorStatus={''}
          />

          <AuthPassword
            onChange={setCpassword}
            password={employeDetails.cPassword}
            placeHolder="Confirm password"
            labelSize="0.87em"
            padding="0.5%"
            infoSvg=""
            verificationLabel1=""
            onFocus={getPasswowrNotification}
            onBlur={getPasswowrBlurNotification}
            notification={passWordNotification}
            passwordErrorStatus={''}
          />
        </div>
        <PageDivider text="Or" />
      </div>
      <div className="SignupWithEmailLinkedInContainer">
        <div className="SignupWithEmailLinkedInContainerHeading">
          <b>SignUp with email</b>
        </div>
        <div
          className="SignupWithEmailLinkedInContainerHeadingButton"
          style={{ color: 'orange' }}
          onClick={setSignUpUsingEmail}
        >
          Continue
        </div>
      </div>
      <div className="SignupWithEmailLinkedInContainerHeadingButtonContainer">
        <AuthBackButton
          onClick={() =>
            dispatch(
              authAction.setProgressStep(authenticationStore.progressStep + 1)
            )
          }
        />
        <AuthButton
          disabled={
            strongPassword.test(employeDetails.cPassword)
              ? employeDetails.password === employeDetails.cPassword
                ? false
                : true
              : true
          }
          buttonText="Next"
          onClick={getSuccessFulPage}
          type="button"
          width="5.8em"
          height="3em"
        />
      </div>
    </div>
  );
};

export default AuthUserDataSavePage;
