import React from 'react';
import { useLocation, useParams } from 'react-router';
import {
  rawAndProcessDataActions,
  useDispatch,
  useSelector,
} from '../../../Redux';
import { useGraphFetch, useGraphDataFetch } from '../../../utils';
import { Loading } from '../..';
import {
  HoodVerticalNavigation,
  UnderTheHoodMiddleGraphContainer,
  UnderTheHoodPageBanner,
} from '../index';
// import styled from 'styled-components';

const UnderTheHoodContainer = ({
  titleAndbacktoRecipeFlex,
  backToRecipeContainerButton,
  LoadingThemeColor,
}) => {
  const params = useParams();

  const SelectedRecipeDetailsinUth = params.item
    .replaceAll('.$', '<')
    .replaceAll('$', '>');

  // conditionally render data for substitute graph with based on share link method
  const subGraphdata_Recipe_QerryId_First_End =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_SMART_SUBSTITUTION_GRAPH_INITIAL_QUERY_ID_POINT_ONE;
  const subGraphdata_Recipe_QuerryId_Last_End =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_SMART_SUBSTITUTION_GRAPH_INITIAL_QUERY_ID_POINT_TWO;
  const [substituteStatusData, subGraphdataData] = useGraphFetch(
    `${subGraphdata_Recipe_QerryId_First_End}${
      SelectedRecipeDetailsinUth === '' ? `<>` : SelectedRecipeDetailsinUth
    }${subGraphdata_Recipe_QuerryId_Last_End}`
  );

  const location = useLocation();
  const dispatch = useDispatch();
  const preferenceStore = useSelector(s => s.preferenceStore);

  const substituteGraphData = useSelector(s => s.substituteGraphStore);
  const [isGraphUpdate, setIsGraphUpdate] = React.useState(false);
  const [showGraphTitleInBanner, setshowGraphTitleInBanner] = React.useState(
    location.pathname.split('/')[1] === 'process' ? 'Process Data' : 'Raw Data'
  );
  const [subGraphTitleValue, setSubGraphTitleValue] = React.useState(
    preferenceStore.selectedRecipeData.Recipes
  );
  const [vocabularyContentStateHandler, setVocabularyContentStateHandler] =
    React.useState('introduction');
  const [data, setData] = React.useState([]);
  const [nodeClickStatus, setNodeClickStatus] = React.useState([]);

  let subnodes = [];
  let subLink = [];
  let oData;
  let subMapWithLinkShare = [];
  if (substituteStatusData === 'success') {
    let subParentsNode = {
      id: '0',

      name:
        substituteGraphData?.subGraphdata?.length === 0
          ? subGraphdataData[0]?.Recipe?.value
          : substituteGraphData?.subGraphdata[0]?.Recipe?.value,
    };
    // console.log('heyyy baby');
    subMapWithLinkShare =
      substituteGraphData?.subGraphdata?.length === 0
        ? subGraphdataData
        : substituteGraphData?.subGraphdata;
    let subChildNode = subMapWithLinkShare.map((e, i) => {
      let data = {
        id: `${i + 1}`,
        name: `${e.food.value}:${e.quantity.value}`,
        quantity: e.quantity.value,
        value: e.value.value,
        p: e.property.value,
        edgeTypes: e.property.value,
        actualName: e.food.value,
        status: e.sub_status.value,
      };
      return data;
    });

    subnodes = [subParentsNode, ...subChildNode];

    let sourceLink = `${subnodes[0].id}`;

    subLink = subnodes.map(e => {
      let data = {
        source: sourceLink,
        target: e.id,
        edgeTypes: e.edgeTypes,
        name: e.name,
      };

      return data;
    });

    subLink.shift();
    oData = {
      nodes: subnodes,
      links: subLink,
    };
  }

  React.useEffect(() => {
    setData(oData);
    setNodeClickStatus(new Array(subnodes.length).fill(false));
    if (location.pathname.split('/')[6] === 'smartSubstitution') {
      setshowGraphTitleInBanner('Smart Substitution');
    }
  }, [substituteStatusData]);

  let nodesFromApi = [
    {
      id: '0',
      name: 'Pad Thai',
    },

    {
      id: '1',
      name: 'Fry1:Process',
    },
    {
      id: '2',
      name: 'Fry2:Process',
    },
    {
      id: '3',
      name: 'Boil:Process',
    },
    {
      id: '4',
      name: 'Cook:Process',
    },
    {
      id: '5',
      name: 'Saute:Process',
    },
  ];

  // *********************************************process data api****************************************************************
  const processMonitoring_Initial_Call_First_Query_Id =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_PROCESS_MONITORING_GRAPH_INITIAL_QUERY_ID_POINT_ONE;

  const processMonitoring_Initial_Call_Last_Query_Id =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_PROCESS_MONITORING_GRAPH_INITIAL_QUERY_ID_POINT_TWO;

  // eslint-disable-next-line no-unused-vars
  const [ProcessGraphStatus, ProcessGraphData] = useGraphFetch(
    `${processMonitoring_Initial_Call_First_Query_Id}${
      SelectedRecipeDetailsinUth === '' ? `<>` : SelectedRecipeDetailsinUth
    }${processMonitoring_Initial_Call_Last_Query_Id}`
  );

  // sb2ProcessData.rows
  // console.info(ProcessGraphStatus);
  const RecipeNodeId = nodesFromApi[0].id;
  const links = nodesFromApi.map(el => {
    let data = {
      source: RecipeNodeId,
      target: el.id,
      edgeType: 'hasIng',
      name: el.name,
    };
    return data;
  });
  links.shift();

  const [subKey, setSubKey] = React.useState('');
  const selectedRecipeUri = params.item
    .replaceAll('.$', '<')
    .replaceAll('$', '>');
  const processData_Query_First_Id =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_PROCESS_DATA_QUERY_ID_POINT_ONE;
  const processData_Query_Last_Id =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_PROCESS_DATA_QUERY_ID_POINT_TWO;
  const [processDataStatus, underTheHoodProcessData] = useGraphDataFetch(
    `${processData_Query_First_Id}${
      selectedRecipeUri === undefined ? `<>` : selectedRecipeUri
    }${processData_Query_Last_Id}`
  );
  // console.log(underTheHoodProcessData);
  React.useEffect(() => {
    dispatch(
      rawAndProcessDataActions.getApiProcessIntitalData({
        data: underTheHoodProcessData,
        status: processDataStatus,
      })
    );
    setData(oData);
  }, [processDataStatus, location.pathname.split('/').length]);

  return (
    <div className="underTheHoodMainContainer">
      <UnderTheHoodPageBanner
        backToRecipeContainerButton={backToRecipeContainerButton}
        titleAndbacktoRecipeFlex={titleAndbacktoRecipeFlex}
        title="Under The Hood"
        className="bannerHealthLogoSize"
        marginTopForHedding="2em"
        marginTopForText="-1em"
        titleClass="hoodTitleClass"
        mainTextClass="hoodMainTextClass"
        imgClass="healthImgClass"
        mainText="Capturing and digitising curry knowledge"
        color={LoadingThemeColor}
        showGraphTitleInBanner={showGraphTitleInBanner}
      />
      <>
        {ProcessGraphData?.length === 0 && subGraphdataData.length === 0 && (
          <div className="middleGraphContainer">
            <Loading color={LoadingThemeColor} />
          </div>
        )}{' '}
        <HoodVerticalNavigation
          setNodeClickStatus={setNodeClickStatus}
          data={data}
          setData={setData}
          subnodes={subnodes}
          setSubKey={setSubKey}
          showGraphTitleInBanner
          setshowGraphTitleInBanner={setshowGraphTitleInBanner}
          setIsGraphUpdate={setIsGraphUpdate}
          setVocabularyContentStateHandler={setVocabularyContentStateHandler}
          setSubGraphTitleValue={setSubGraphTitleValue}
        />
        {ProcessGraphData?.length !== 0 && (
          <>
            {subMapWithLinkShare?.length !== 0 &&
              subGraphdataData.length !== 0 && (
                <UnderTheHoodMiddleGraphContainer
                  LoadingThemeColor={LoadingThemeColor}
                  oData={oData}
                  subnodes={subnodes}
                  subLink={subLink}
                  data={data}
                  setData={setData}
                  nodeClickStatus={nodeClickStatus}
                  setNodeClickStatus={setNodeClickStatus}
                  subKey={subKey}
                  setSubKey={setSubKey}
                  isGraphUpdate={isGraphUpdate}
                  setIsGraphUpdate={setIsGraphUpdate}
                  vocabularyContentStateHandler={vocabularyContentStateHandler}
                  setVocabularyContentStateHandler={
                    setVocabularyContentStateHandler
                  }
                  setshowGraphTitleInBanner={setshowGraphTitleInBanner}
                  nodesFromApi={nodesFromApi}
                  links={links}
                  ProcessGraphData={ProcessGraphData}
                  subGraphTitleValue={subGraphTitleValue}
                  setSubGraphTitleValue={setSubGraphTitleValue}
                  substituteStatusData={substituteStatusData}
                />
              )}
          </>
        )}
      </>
    </div>
  );
};

export default UnderTheHoodContainer;
