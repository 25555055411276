import React from 'react';
import {
  minusButton,
  plusButton,
  handInAlter,
  jarIcon,
  AverageIcon,
} from '../../../assets';
import { CombineProgress } from '../../../lib';
import { ProcessMoniteringDiscription } from '../../../Component/index';
import { AppColors } from '../../../Constants/AppColors';
const ProcessMoniteringInfo = ({
  sb2ProcessData,

  setChangedTimeAndTempValues,
  getFinalOutPutInSb2,
  finalOutputProcessData,
  passedParameters,
  setProcessData,
  ProcessData,
  RecipeName,
}) => {
  //console.logfinalOutputProcessData);
  // sb2ProcessData.rows[0].final
  const [changableData, setChangableData] = React.useState(sb2ProcessData.rows);
  const [isButtonReset, setIsButtonReset] = React.useState(true);

  const loadedData = {
    timeValues: sb2ProcessData.rows.map(el => el.time),
    tempValues: sb2ProcessData.rows.map(el => el.temp),
  };
  React.useEffect(() => {
    setChangedTimeAndTempValues(loadedData);
  }, []);

  const [showAlterCookingCondition, setShowAlterCookingCondition] =
    React.useState('ProcessDiscription');

  const increaseTempValues = (item, position) => {
    setIsButtonReset(false);
    const updatedCheckedState = changableData.map((item, index) => {
      let data = index === position ? item.temp + 1 : item.temp;
      return data;
    });
    const insertChangedValues = changableData.map((el, i) => {
      const Data = {
        ...el,
        temp: updatedCheckedState[i],
      };
      return Data;
    });
    setChangedTimeAndTempValues(p => {
      return {
        tempValues: updatedCheckedState,
        timeValues: p.timeValues,
      };
    });
    setChangableData(insertChangedValues);
  };
  const decreaseTempValueValues = (item, position) => {
    setIsButtonReset(false);
    const updatedCheckedState = changableData.map((item, index) => {
      let data = index === position ? item.temp - 1 : item.temp;
      return data;
    });
    const insertChangedValues = changableData.map((el, i) => {
      const Data = {
        ...el,
        temp: updatedCheckedState[i],
      };
      return Data;
    });
    setChangedTimeAndTempValues(p => {
      return {
        tempValues: updatedCheckedState,
        timeValues: p.timeValues,
      };
    });
    setChangableData(insertChangedValues);
  };
  const increaseTimeCount = (item, position) => {
    setIsButtonReset(false);
    const updatedCheckedState = changableData.map((item, index) => {
      let data = index === position ? item.time + 1 : item.time;
      return data;
    });
    const insertChangedValues = changableData.map((el, i) => {
      const Data = {
        ...el,
        time: updatedCheckedState[i],
      };
      return Data;
    });
    setChangedTimeAndTempValues(p => {
      return {
        timeValues: updatedCheckedState,
        tempValues: p.tempValues,
      };
    });

    setChangableData(insertChangedValues);
    // console.log(item, position);
  };
  const decreaseTimeCount = (item, position) => {
    setIsButtonReset(false);
    const updatedCheckedState = changableData.map((item, index) => {
      let data = index === position ? item.time - 1 : item.time;
      return data;
    });
    const insertChangedValues = changableData.map((el, i) => {
      const Data = {
        ...el,
        time: updatedCheckedState[i],
      };
      return Data;
    });
    setChangedTimeAndTempValues(p => {
      return {
        timeValues: updatedCheckedState,
        tempValues: p.tempValues,
      };
    });

    setChangableData(insertChangedValues);
  };

  //console.logchangableData);

  React.useEffect(() => {
    if (finalOutputProcessData?.rows?.length === 1) {
      // ProcessData.links.length = 2;
      // setProcessData(ProcessData);
      // console.log(ProcessData);

      let originalNodeData = ProcessData.nodes.map(el => {
        let data = {
          edgeType: el.edgeType,
          id: el.id,
          index: el.index,
          name: el.name,
          node_value_obj: el.node_value_obj,
          node_value_rec: el.node_value_rec,
          typeOf: el.typeOf,
          typeofValue: el.typeofValue,
          searchFilter: el.searchFilter,
          typeOfResult: el.typeOfResult,
        };
        return data;
      });
      let originalLinksData = ProcessData.links.map(el => {
        let data = {
          source: el?.source?.id,
          target: el?.target?.id,
          edgeType: el?.edgeType,
          name: el?.name,
          node_value_obj: el?.node_value_obj,
        };
        return data;
      });

      // e?.class_obj.value === 'Flavor';
      //       ? renameNodeData.length === 0
      //         ? e?.obj?.value
      //         : renameNodeData?.rows[0]?.final_flavour === undefined
      //         ? e?.obj?.value
      //         : renameNodeData?.rows[0]?.final_flavour
      //       : e?.class_obj.value === 'Texture'
      //       ? renameNodeData.length === 0
      //         ? e?.obj?.value
      //         : renameNodeData?.rows[0]?.texture === undefined
      //         ? e?.obj?.value
      //         : renameNodeData?.rows[0]?.texture
      //       : e?.obj?.value
      //   }:${e?.class_obj.value}`;
      //console.logfinalOutputProcessData);
      let updateNode = originalNodeData.map(el => {
        const filterData = changableData.filter(
          e => e.value === el.searchFilter
        );
        //console.logfilterData);
        let data = {
          edgeType: el.edgeType,
          id: el.id,
          index: el.index,
          name:
            el?.typeOf === 'Flavor'
              ? finalOutputProcessData.length === 0
                ? el.name
                : finalOutputProcessData?.rows[0]?.final_flavour === undefined
                ? el.name
                : `${finalOutputProcessData?.rows[0]?.final_flavour}:Flavor`
              : el?.typeOf === 'Texture'
              ? finalOutputProcessData.length === 0
                ? el.name
                : finalOutputProcessData?.rows[0]?.texture === undefined
                ? el.name
                : `${finalOutputProcessData?.rows[0]?.texture}:Flavor`
              : el.typeOfResult === ' mins'
              ? `${filterData[0].time}:Result`
              : el.typeOfResult === 'c'
              ? `${filterData[0].temp}:Result`
              : el.name,
          node_value_obj: el.node_value_obj,
          node_value_rec: el.node_value_rec,
          typeOf: el.typeOf,
          typeofValue: el.typeofValue,
          searchFilter: el.searchFilter,
          typeOfResult: el.typeOfResult,
        };
        return data;
      });
      let updatedData = {
        nodes: updateNode,
        links: originalLinksData,
      };

      //console.logupdatedData);

      // setProcessData(updatedData);
      setProcessData(updatedData);
      //console.logoriginalLinksData);
    }
  }, [finalOutputProcessData]);

  const myMappedArray = changableData.map((ek, i) => (
    <div key={i}>
      <div className="BlockTitleInBox">{ek.process}</div>
      <div className="tempAndButtonFlex">
        <div>Temperature (˚C)</div>
        <div className="controllerContainer">
          <img
            src={minusButton}
            className="controlButtons"
            alt="minusButton"
            onClick={
              ek.temp <= 20 ? null : () => decreaseTempValueValues(ek, i)
            }
          />

          <input className="inputForControllers" value={ek.temp} />

          <img
            src={plusButton}
            className="controlButtons"
            alt="plusButton"
            onClick={ek.temp >= 500 ? null : () => increaseTempValues(ek, i)}
          />
        </div>
      </div>
      <div className="tempAndButtonFlex">
        <div>Time (min)</div>
        <div className="controllerContainer widthControll">
          <img
            src={minusButton}
            className="controlButtons"
            alt="minusButton"
            onClick={ek.time <= 1 ? null : () => decreaseTimeCount(ek, i)}
          />

          <input className="inputForControllers" value={ek.time} />

          <img
            src={plusButton}
            className="controlButtons"
            alt="plusButton"
            onClick={ek.time >= 25 ? null : () => increaseTimeCount(ek, i)}
          />
        </div>
      </div>
    </div>
  ));
  return (
    <>
      {showAlterCookingCondition === 'ProcessDiscription' && (
        <ProcessMoniteringDiscription
          setShowAlterCookingCondition={setShowAlterCookingCondition}
          RecipeName={RecipeName}
        />
      )}
      {/* {showAlterCookingCondition === 'RecipeOutcome' && (
          <>
            <h2 className="moniterTitle">Recipe outcome:</h2>
            <div className="MoniteringDetails">
              Final outcome : <span className="IdleColor"> Ideal</span>
              <div className="MoniteringDetailsOutCome">
                Deviation from the current recipe :
                <span className="moniteringOutPutPer">0%</span>
              </div>
            </div>
          </>
        )} */}
      {showAlterCookingCondition === 'cookingConditions' && (
        <>
          {/* ================================= */}
          <h2 className="moniterTitle">Recipe Outcome:</h2>
          <div className="MoniteringDetails">
            Final outcome :
            <span className="moniteringOutPutPer">
              {finalOutputProcessData?.rows[0] === undefined
                ? sb2ProcessData?.rows[0].final
                : finalOutputProcessData?.rows[0]?.total}
            </span>
          </div>
          <div className="MoniteringDetails">
            Deviation from the current recipe :
            <span className="moniteringOutPutPer">
              {finalOutputProcessData?.rows[0] === undefined
                ? `${0}%`
                : `${finalOutputProcessData?.rows[0]?.delta}%`}
            </span>
          </div>
          <div className="subOptionContainerInProcessChallenge">
            <div className="subIconOptionContainer">
              <div className="vinegarOptionsTexture">
                {/* <BsHandIndexThumb
                    className="vinegarOptionIconsHegith"
                    style={{ color: '#57BFFF' }}
                  /> */}
                <img
                  src={handInAlter}
                  alt="hand"
                  className="vinegarOptionIconsHegith icon1InProcess"
                />
                <div className="vinegarOptionText">Texture</div>
              </div>
              <div className="vinegarOptionsTexture">
                {/* <GiSquareBottle
                    className="vinegarOptionIconsHegith"
                    style={{ color: '#D34C89' }}
                  /> */}
                <img
                  src={jarIcon}
                  alt="hand"
                  className="vinegarOptionIconsHegith icon2InProcess"
                />
                <div className="vinegarOptionText">Flavor</div>
              </div>
              <div className="vinegarOptionsTexture">
                <img
                  src={AverageIcon}
                  className="vinegarOptionIconsHegith icon2InProcess"
                />
                <div className="vinegarOptionText">Average</div>
              </div>
            </div>
            <CombineProgress
              texture={
                passedParameters.length === 0
                  ? 100
                  : finalOutputProcessData?.rows[0]?.texture_stats !== undefined
                  ? finalOutputProcessData?.rows[0]?.texture_stats
                  : 100
              }
              flavor={
                passedParameters.length === 0
                  ? 100
                  : finalOutputProcessData?.rows[0]?.flavour_stats !== undefined
                  ? finalOutputProcessData?.rows[0]?.flavour_stats
                  : 100
              }
              classValue={
                passedParameters.length === 0
                  ? 100
                  : finalOutputProcessData?.rows[0]?.average_stats
              }
              textValue="average"
            />
          </div>
          <div className="lineBelowProgress"></div>
          <div className="cookingTitleAndApplyButtonFlex">
            <h2 className="cookingTitle">Cooking Variables:</h2>
            <button
              onClick={getFinalOutPutInSb2}
              className="applyButtonInProcessChallengeGenerator alignApplyButton"
              disabled={isButtonReset}
              style={{
                backgroundColor: isButtonReset
                  ? `${AppColors.process.base_color}`
                  : `${AppColors.process.base_color}`,
                opacity: isButtonReset ? 0.5 : 1,
              }}
            >
              Apply
            </button>
          </div>
          <div className="manipulatorContainerinUTH">{myMappedArray}</div>
        </>
      )}
    </>
  );
};

export default ProcessMoniteringInfo;
// delta,
// flavour_stats,
// texture_stats
