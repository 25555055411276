import React from 'react';

import { Loading, RecipeCard, IngredientRenderContainer } from '../../index';
import '../../../style/Preferences.css';
import { useFetchCardsData } from '../../../utils';
// import { BackButton } from '../../../lib';
import { Outlet, Route, Routes, useLocation, useParams } from 'react-router';
import {
  invProcessStepActions,
  cascadingScreenActions,
  useDispatch,
} from '../../../Redux';

/**
 * [Component] Preferences
 * @description Renders the cards of selected data.
 
 * @param {*} setIngredientTitle sets the title of component.
 * @param {*} setIngredientsSecondPageSelection set the state when function call.
 * @param {*} setIngredientsLastPageSelection set the state when function call.
 * @param {String} preferenceNextPage navigates to next page.
 * @param {*} setPreferenceNextPage set the state when function call.
 * @param {Array} totalSelectResult it accepts the selected item.
 * @param {*} setIsSuccess set the state when function call.
 * @param {} recipeListStatus changes state depending on the API fetch.
 * @param {} allRecipeListData stores the data after fetching the API.
 * @param {} Posterror displays error when data is not fetched.
 * @param {*} setSelectedRecipeData add or remove data in array on function call.
 
 * @returns recipe cards according to selection of ingredients and cusine.
*/

const RecipeCardContainer = ({
  LoadingColor,
  // cardsBackButtonClass,
  // getPreferencesButtonClick,
}) => {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  // eslint-disable-next-line no-undef
  const preference_First_QueryId =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_GETTING_RECIPE_CARDS_QUERY_ID_POINT_ONE;

  const preference_Second_QueryId =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_GETTING_RECIPE_CARDS_QUERY_ID_POINT_TWO;
  // eslint-disable-next-line no-undef
  const preference_Third_QueryId =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_GETTING_RECIPE_CARDS_QUERY_ID_POINT_THREE;

  let CusineStore = id.split('=');

  const selectionCountry = CusineStore[0].split('+').toString();
  const SelectRecipeIngredient = CusineStore[1]
    .replaceAll('.$', '<')
    .replaceAll('$', '>')
    .split('&')
    .toString();
  const [recipeListDynamicStatus, allRecipeDynamicCardListData] =
    useFetchCardsData(
      `${preference_First_QueryId} ${SelectRecipeIngredient}${preference_Second_QueryId} ${selectionCountry}${preference_Third_QueryId}`
    );

  const convertedData = allRecipeDynamicCardListData.map(e => {
    const Data = {
      Recipes: e.Recipes.value,
      property:
        e.property.value === 'http://purl.org/heals/food/serves'
          ? e.property.value.replace('http://purl.org/heals/food/', '')
          : e.property.value ===
            'http://www.curryconfigurator.org/cc#hasCookingTime'
          ? e.property.value.replace('http://www.curryconfigurator.org/cc#', '')
          : e.property.value.replace(
              'http://www.curryconfigurator.org/cc#',
              ''
            ),
      value: e.value.value.replace('http://www.curryconfigurator.org/cc#', ''),
      iri: e.iri.value,
      img: e.img.value,
      cuisine: e.cuisine.value,
      ingredient_count: e.ingredient_count.value,
    };
    return Data;
  });

  const output = convertedData.reduce((prev, curr) => {
    const tmp = prev.find(e => e.Recipes === curr.Recipes);

    if (tmp) {
      tmp.property.push(curr.property);
      tmp.value.push(curr.value);
    } else {
      prev.push({
        Recipes: curr.Recipes,
        property: [curr.property],
        value: [curr.value],
        iri: curr.iri,
        img: curr.img,
        energy: curr.energy,
        cuisine: curr.cuisine,
        // ingredient_count: curr.value,
        ingredient_count: curr.ingredient_count,
      });
    }
    return prev;
  }, []);
  output.map(ek => {
    const Data = {
      Recipes: ek.Recipes,
      cuisine: ek.cuisine,
      img: ek.img,
      iri: ek.iri,
      property: ek.property.push('Ingredient Count'),
      value: ek.value.push(ek.ingredient_count),
    };
    return Data;
  });

  React.useEffect(() => {
    if (location.pathname.split('/').length === 4) {
      dispatch(
        cascadingScreenActions.changeIngredientTitle(
          'Here are the recipes based on the ingredients you’ve selected. Click on the card for recipe instructions and ingredient substitution:'
        )
      );
      dispatch(invProcessStepActions.catalogueRecipe());
    }
  }, [location]);

  return (
    <>
      <>
        {location.pathname.split('/').length === 4 && (
          // <button onClick={() => navigate(`${hey}`)}>GoRecipeDetails</button>
          <div className="preferenceContainer">
            {recipeListDynamicStatus === 'error' && (
              <div className="preferenceLoadingContainer">
                <Loading color={LoadingColor} />
              </div>
            )}
            {recipeListDynamicStatus === 'Loading' && (
              <div className="preferenceLoadingContainer">
                <Loading color={LoadingColor} />
              </div>
            )}

            {recipeListDynamicStatus === 'success' && (
              // preferenceNextPage === 'firstPage' && (

              <div className="preferenceContainerFlex">
                <RecipeCard
                  dynamicCardRecipesData={output}
                  LoadingColor={LoadingColor}
                />
              </div>
            )}
          </div>
        )}
      </>
      <Routes>
        <Route path="/" element={<DetailsLayout />}>
          <Route
            path={`:item/*`}
            element={<IngredientRenderContainer LoadingColor={LoadingColor} />}
          />
        </Route>
      </Routes>
    </>
  );
};
const DetailsLayout = () => {
  return <Outlet />;
};

export default RecipeCardContainer;
