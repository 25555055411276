import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ingredientsSelection: 'idle',
  ingredientsSecondPageSelection: 'idle',
  ingredientsLastPageSelection: 'idle',
  proceedTonextPage: 'idle',
  cuisinePageSelection: 'idle',
  stepIconStatus: false,
};
const invProcessStepsSlice = createSlice({
  name: 'processSteps',
  initialState,
  reducers: {
    selectTheIngredientsInitial: state => {
      state.proceedTonextPage = 'idle';
      state.cuisinePageSelection = 'idle';
      state.ingredientsSelection = 'idle';
      state.ingredientsLastPageSelection = 'idle';
      state.ingredientsSecondPageSelection = 'idle';
    },

    selectTheIngredients: state => {
      state.proceedTonextPage = 'idle';
      state.cuisinePageSelection = 'idle';
      state.ingredientsSelection = 'progress';
      state.ingredientsLastPageSelection = 'idle';
      state.ingredientsSecondPageSelection = 'idle';
    },
    selectCuisineType: state => {
      state.cuisinePageSelection = 'progress';
      state.ingredientsSecondPageSelection = 'idle';
      state.ingredientsSelection = 'success';
      state.ingredientsLastPageSelection = 'idle';
      // state.ingredientsSecondPageSelection = 'idle';
    },
    catalogueRecipe: state => {
      state.ingredientsSecondPageSelection = 'progress';
      state.ingredientsSelection = 'success';
      state.ingredientsLastPageSelection = 'idle';
      state.cuisinePageSelection = 'success';
    },
    // substituteIngredients: (state, action) => {},
    gotoInitialStateRefresh: state => {
      state.ingredientsSelection = 'idle';
      state.ingredientsSecondPageSelection = 'idle';
      state.ingredientsLastPageSelection = 'idle';
      state.cuisinePageSelection = 'idle';
      state.proceedTonextPage = 'idle';
    },

    setAllStatusSuccess: state => {
      state.ingredientsSecondPageSelection = 'success';
      state.ingredientsSelection = 'success';
      state.ingredientsLastPageSelection = 'success';
      state.cuisinePageSelection = 'success';
    },

    gotoCuisinePage: state => {
      state.ingredientsSelection = 'success';
      state.cuisinePageSelection = 'progress';
      state.proceedTonextPage = 'nativePage';
    },
    whileSelectIngredientStatus: state => {
      state.ingredientsSelection = 'progress';
    },
    breadScrumClick: state => {
      state.ingredientsSelection = 'success';
      state.ingredientsSecondPageSelection = 'progress';
      state.ingredientsLastPageSelection = 'idle';
    },
    goToNextFromCuisinePage: state => {
      state.cuisinePageSelection = 'success';
      state.ingredientsSecondPageSelection = 'progress';
    },
    clickRecipeCard: state => {
      state.ingredientsSecondPageSelection = 'success';
    },
    whileShifflingInvToProcess: state => {
      state.ingredientsLastPageSelection = 'idle';
    },
    lastprocessStepIdleStatus: state => {
      state.ingredientsLastPageSelection = 'idle';
    },

    lastprocessStepProgressStatus: state => {
      state.ingredientsLastPageSelection = 'progress';
    },
    lastprocessStepSuccessStatus: state => {
      state.ingredientsLastPageSelection = 'success';
    },
    setStepIcon: (state, { payload }) => {
      state.stepIconStatus = payload;
    },
  },
});

export const invProcessStepActions = invProcessStepsSlice.actions;
export const invPRocessStepReducers = invProcessStepsSlice.reducer;
