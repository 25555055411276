import React from 'react';

import '../../../style/App.css';
import Graph from './index';

const ClassLayoutGraph = ({
  status,

  linksDataForForceLayOut,
  nodesDataForForceLayOut,
}) => {
  if (status !== 'success') {
    return;
  }
  let newNetworkData = {
    nodes: nodesDataForForceLayOut,
    links: linksDataForForceLayOut,
  };

  return (
    <div className="classLayoutContainer">
      <Graph data={newNetworkData} id="graph" />
    </div>
  );
};

export default ClassLayoutGraph;
