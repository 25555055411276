import React from 'react';
import * as d3 from 'd3';
import '../../../style/App.css';
import '../../../style/Graph.css';
import RecipeGraphSimulationSetting from './RecipeGraphSimulationSetting';
import drag from './DragOption';
import getData from './GetData';
import { ZoomButton, convertRemToPixels } from '../../../lib';

import { Zoomed } from '../../../utils';

const ForceSimulationWithCirclePack = ({ recipeGraphData, linksEdges }) => {
  const circlePackRef = React.useRef(null);

  const width = 1900;
  const height = 1050;

  React.useEffect(() => {
    // var scaleX =
    //   circlePackRef.current.getBoundingClientRect().height /
    //   circlePackRef.current.offsetHeight;
    // console.log(scaleX / 2);
    const links = linksEdges.map(d => Object.create(d));
    const nodes = recipeGraphData.map(d => Object.create(d));
    const nodeRadiusScale = d3.scaleSqrt().domain([0, 50]).range([10, 50]);

    var transform = d3.zoomIdentity.scale(convertRemToPixels(0.028));
    // console.log(VocabZoomMediaQuery(element.clientWidth));
    var zoom = d3.zoom().on('zoom', handleZoom);

    var svg = d3.select(`#myRecipe`).call(zoom);

    zoom.scaleTo(svg.transition().duration(0), convertRemToPixels(0.028));
    var g = svg
      .append('g')
      .attr('class', 'zoomable')
      .attr('transform', transform); // Applies initial transform

    function handleZoom(event) {
      if (g) {
        g.attr('transform', event.transform);
      }
    }

    var linksColor = d3.scaleOrdinal(d3.schemeCategory10);

    let simulation = RecipeGraphSimulationSetting(
      nodes,
      links,
      width,
      height,
      svg
    );

    const linkss = g
      .append('g')
      .selectAll('g')
      .data(links)
      .join('g')
      .attr('class', 'link')

      .attr('class', 'link-line')

      .attr('stroke-opacity', 1)
      .append('line')
      .attr('stroke', 'black')
      .style('opacity', '.1')
      .attr('stroke-width', d => Math.sqrt(d.value));
    var linkText = g
      .selectAll('.link-line')
      .append('text')
      .attr('class', 'link-label')
      .attr('fill', 'Black')
      .attr('dy', 5)

      .attr('text-anchor', 'middle')
      .attr('font-size', '10px')

      .attr('alignment-baseline', 'middle')
      .attr('dy', d => nodeRadiusScale(d.value) - 24);

    var backGroundColorRectangle = g
      .selectAll('.link-line')
      .insert('rect', 'text')
      .attr('class', 'rectangleBackground');

    const nodeG = g
      .append('g')
      .selectAll('g')
      .data(nodes)
      .join('g')
      .call(drag(simulation));

    nodeG
      .append('circle')
      .attr('r', d => nodeRadiusScale(d.value))

      .attr('fill', (d, i) => {
        return linksColor(i + 10);
      })
      .on('click', addText);

    nodeG
      .append('text')
      .attr('class', 'NodeName')
      .attr('fill', 'Black')
      .attr('text-anchor', 'middle')
      .text(d => d.id)
      .attr('font-weight', 'bolder')
      .attr('font-size', '18px')
      .attr('dy', d => nodeRadiusScale(d.value) + 20);

    function addText(event, d) {
      linkss.style('stroke-width', function (l) {
        if (d === l.source || d === l.target) return 2;
        else return 1;
      });
      linkss.style('opacity', function (l) {
        if (d === l.source || d === l.target) return '1';
        else return '.1';
      });

      linkss.style('stroke', function (l) {
        if (d === l.source || d === l.target) return linksColor(d.index + 10);
        else return 'black';
        // '#69b3a2;'
      });
      linkText
        .text(function (l) {
          // console.log(d);
          if (d === l.source || d === l.target) return `${l.linkName}`;
          else return;
        })
        .call(getBB);
      backGroundColorRectangle
        .attr('width', function (d) {
          return d.bbox.width;
        })
        .attr('height', function (d) {
          return d.bbox.height;
        })
        .style('fill', 'white')
        .attr('x', function (d) {
          return d.bbox.x;
        })
        .attr('y', function (d) {
          return d.bbox.y;
        });

      function getBB(selection) {
        selection.each(function (d) {
          d.bbox = this.getBBox();
        });
      }
    }

    nodeG.append('g').each(function (d) {
      drawHexagons(
        d3.select(this),
        [{ key: d.id, values: d.value, pairs: d.pairs }],
        {
          width: nodeRadiusScale(d.value),
          height: nodeRadiusScale(d.value),
          nodeColor: 'white',
          borderColor: 'white',
          nodeTextColor: 'black',
        }
      );
    });
    // zoomed('#zoom_in', '#zoom_out', svg);
    Zoomed('#zoom_in', '#zoom_out', '#recipeGraphZoomReset', svg, zoom, g);

    simulation.on('tick', () => {
      linkss
        .attr('x1', d => d.source.x)
        .attr('y1', d => d.source.y)
        .attr('x2', d => d.target.x)
        .attr('y2', d => d.target.y);

      nodeG.attr('transform', d => `translate(${d.x}, ${d.y})`);

      linkText.attr('transform', function (d) {
        //calcul de l'angle du label
        var angle =
          (Math.atan((d.source.y - d.target.y) / (d.source.x - d.target.x)) *
            180) /
          Math.PI;
        return (
          'translate(' +
          [(d.source.x + d.target.x) / 2, (d.source.y + d.target.y) / 2] +
          ')rotate(' +
          angle +
          ')'
        );
      });
      backGroundColorRectangle.attr('transform', function (d) {
        //calcul de l'angle du label
        var angle =
          (Math.atan((d.source.y - d.target.y) / (d.source.x - d.target.x)) *
            180) /
          Math.PI;
        return (
          'translate(' +
          [(d.source.x + d.target.x) / 2, (d.source.y + d.target.y) / 2] +
          ')rotate(' +
          angle +
          ')'
        );
      });
    });

    function drawHexagons(nodeElement, parentIds, options) {
      const borderColor = options.borderColor;

      const width = options.width;
      const height = options.height;
      const data = getData(parentIds, width * 2, height * 2);

      const node = nodeElement.insert('g');

      const nodeData = node.selectAll('g').data(data);
      //   console.log(data);
      const nodesEnter = nodeData
        .enter()
        .append('g')
        .attr('id', (d, i) => 'node-group-' + i)
        .attr('transform', d => `translate(${d.x - width},${d.y - height})`);

      nodesEnter
        .filter(d => d.height === 0)
        .append('circle')
        .attr('class', 'node pie')
        .attr('r', 1)
        .attr('stroke', borderColor)
        .attr('stroke-width', 1)
        .attr('fill', 'white');
    }

    return svg.node();
  }, []);

  return (
    <div className="graph-container" ref={circlePackRef}>
      <svg id="myRecipe" width="100%" height="100%"></svg>
      <ZoomButton
        zoomOutId={'zoom_out'}
        zoomInId={'zoom_in'}
        zoomResetId={'recipeGraphZoomReset'}
        zoomCenter={'recipeCenter'}
      />
    </div>
  );
};

export default ForceSimulationWithCirclePack;
