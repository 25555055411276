import React from 'react';

/**
 * [Component] nutrition vizuvalizers
 * @description Shows the nutrient details visualization of the selected card.
 
 * @param {String} text Change the value on function call.
 * @param {String} color Set the text color.
 * @param {String} bgcolor set the text background colors.
 * @param {Array} fullRecipeDetails stores the data after fetching the API.
 * @param {String} units sets the units of nutrional information.
 
 * @returns selected recipe card nutritional information.
*/

function NutritionVisualizer({
  text,
  color,

  fullRecipeDetails,
  units,
  className,
}) {
  const result = Math.floor(fullRecipeDetails);

  return (
    <>
      <div className={className}>
        <div style={{ color: color, fontSize: '1.2em', fontWeight: 'bolder' }}>
          {result} {units}
        </div>
        <div style={{ marginTop: '.1em', fontWeight: 'bolder' }}>{text}</div>
      </div>
    </>
  );
}

export default NutritionVisualizer;
