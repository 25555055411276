import React from 'react';
import './SubstituteGraphStaticSidebar.css';
import { forwaedIcon as forwardIcon } from '../../../../assets';

const SubstituteGraphStaticSidebar = () => {
  const substituteGraphData = [
    {
      data: 'Click on the substitute button',
      icon: forwardIcon,
    },
    {
      data: 'The modal will show the alternative options  (and it’s texture, smell, quantity and the class  that it belongs to) for the selected ingredient.',
      icon: '',
    },
    {
      data: 'Click on the check box to show your selection on the graph',
      icon: '',
    },
    {
      data: 'Click on the ‘save’ button to replace the original ingredient with your selection. ',
      icon: '',
    },
  ];
  return (
    <>
      <div className="SubstituteGraphStaticSidebarDescription">
        Here’s how you can substitute any of the ingredients in this recipe:
      </div>
      <div className="SubstituteGraphStaticSidebarDescriptionContainer">
        {substituteGraphData.map((el, i) => {
          return (
            <ul key={i} className="linkSub">
              <li className="liSub">
                <div className="SubstituteGraphStaticSidebartext">
                  <div>{el.data}</div>
                  <div
                    className="SubstituteGraphStaticSidebarimg"
                    style={{ backgroundImage: `url(${el.icon})` }}
                  ></div>
                </div>
              </li>
            </ul>
          );
        })}
      </div>
    </>
  );
};

export default SubstituteGraphStaticSidebar;
// <div className="SubstituteGraphStaticSidebarcircle"></div>
// <div
//   className="SubstituteGraphStaticSidebartext"
//   style={{ marginTop: el.icon !== '' ? '-1.26em' : '-0.4em' }}
// >
//   <div>{el.data}</div>
//   {el.icon !== '' && (
//     <div
//       className="SubstituteGraphStaticSidebarimg"
//       style={{ backgroundImage: `url(${el.icon})` }}
//     ></div>
//   )}
// </div>
