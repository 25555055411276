import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

export default function CustomizedProgressBars({ color, value }) {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: '.35rem',
    width: '3rem',

    borderRadius: '.04rem',
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: '.04rem',
      backgroundColor: theme.palette.mode === 'light' ? color : 'red',
    },
  }));
  return (
    <Box sx={{ flexGrow: 1 }}>
      <BorderLinearProgress variant="determinate" value={value} />
    </Box>
  );
}
