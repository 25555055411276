import React from 'react';
import { ToolTipInfo } from '../../lib';

const ReuseComponent = ({
  title,
  totalVocabulariesUsed,
  classesUsed,
  ReusePercentage,
  RepurposePercentage,
}) => {
  return (
    <div className="statsProtertiesContainer">
      <div className="PropertiesMainUseCaseTitle">{title}</div>
      <div className="useVocabularyCount">
        Total vocabularies used : <span>{totalVocabulariesUsed}</span>
      </div>
      <div className="externalInternalUse reuse">
        Reuse percentage : <span>{ReusePercentage}</span>
        <ToolTipInfo title="Reuse = (Total vocabularies - New internal voabularies) / Total vocabularies." />
      </div>
      <div className="externalInternalUse reuse">
        Repurpose percentage : <span>{RepurposePercentage}</span>
        <ToolTipInfo title="Repurpose = (Total classes - New classes added) / Total classes" />
      </div>
      <div className="useVocabularyCount totalCountClass">
        Classes used : <span>{classesUsed}</span>
      </div>
    </div>
  );
};

export default ReuseComponent;
