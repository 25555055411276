import React from 'react';
import { BiArrowBack } from '../../../icon';
import { NavLink } from 'react-router-dom';
const AuthArrowBack = ({ onClick }) => {
  return (
    <NavLink
      to="/login"
      className={isActive => (!isActive ? '' : 'unselectedBackContent')}
    >
      <div
        onClick={onClick}
        className="buttonFlexBackArrow"
        style={{ color: 'orange' }}
      >
        <BiArrowBack />

        <div className="backContent" style={{ color: 'orange' }}>
          Back
        </div>
      </div>
    </NavLink>
  );
};

export default AuthArrowBack;
