import React from 'react';
import './AuthPassword.css';
import { BiHide, BiShow } from '../../../icon';

const AuthPassword = ({
  onChange,
  password,
  placeHolder,
  onFocus,
  onBlur,
  notification,
  passwordErrorStatus,
  passwordSuccessStatus,
}) => {
  const [hidePassword, setHidePassword] = React.useState(true);
  // let strongPassword = new RegExp(
  //   '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'
  // );
  // strongPassword.test(password);
  // password.split('').length < 1
  return (
    <div className="groupPassword ">
      <input
        type={hidePassword ? 'password' : 'text'}
        required
        value={password}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        className={
          passwordSuccessStatus
            ? 'passwordwithSuccess'
            : passwordErrorStatus
            ? 'passwordStatusWithError'
            : 'passwordWithoutError'
        }
      />
      <div onClick={() => setHidePassword(p => !p)} className="showAuthIcon">
        {hidePassword ? (
          <BiHide size={'1.4rem'} color={notification ? 'orange' : 'gray'} />
        ) : (
          <BiShow size={'1.4rem'} color={notification ? 'orange' : 'gray'} />
        )}
      </div>
      <span className="Passwordhighlight"></span>
      <span className="Passwordbar"></span>
      <label className="Authpasswordlabel">{placeHolder}</label>
    </div>
  );
};

export default AuthPassword;
