import React from 'react';
import { useSelector } from 'react-redux';

const ProcessMoniteringDiscription = ({ setShowAlterCookingCondition }) => {
  const preferenceStore = useSelector(state => state.preferenceStore);
  const AlterCookingConditions = () => {
    setShowAlterCookingCondition('cookingConditions');
  };
  return (
    <>
      <div className="recipeAndButtonFlex">
        <h2 className="recipeGraphHeading">Recipe</h2>
        <div
          onClick={AlterCookingConditions}
          className="alterCookingConditionsBtn"
        >
          Manage Cooking conditions
        </div>
      </div>
      <div className="padThai recipeTitleProcessChallenge">
        {preferenceStore.Recipe}
      </div>
      <h2 className="moniterTitle">Process monitoring</h2>
      <div className="discriptionPara discriptionPara-Process">
        Here’s how you can change the conditions of the cooking process and
        checkout the effects on the flavour and texture of the recipe :
      </div>
      <ul className="listLinProcessMonitering">
        <li>Click on the ‘ Manage Cooking conditions’ button.</li>
        <li>
          The modal will show the conditions that you can change for all the
          processes (node) of the recipe.
        </li>
        <li>
          Click on the ‘Apply’ button after altering the given temperature and
          time.
        </li>
        <li>
          After the altered conditions are applied to the recipe processes, the
          deviation of the recipe can be observed.
        </li>
      </ul>
      <h2 className="moniterTitle">Interacting with the graph:</h2>
      <ul className="listLinProcessMonitering">
        <li>
          You can click on the process nodes to check details like ideal
          temperature, time, ingredients, etc. associated with it.
        </li>
        <li>
          After the changes are applied to the temperature and time, change in
          the flavour and texture can also be observed in those respective
          nodes.
        </li>
      </ul>
    </>
  );
};

export default ProcessMoniteringDiscription;
