import React from 'react';
import { Loading } from '../../index';
import Graph from './Index';
import {
  cookSubstitution as logoSvgInSubstitution,
  RecipeNode,
} from '../../../assets';

export const ProcessGraphcontext = React.createContext();

const ProcessMoniteringGraph = ({
  ProcessData,
  LoadingThemeColor,
  setProcessData,

  fixedProcessdata,
  ProcessDataLinksAndNodesData,
}) => {
  // const [nodeClickStatus, setNodeClickStatus] = React.useState(
  //   new Array(ProcessData.nodes.length).fill(false)
  // );
  const processMoniteringCotainer = React.useRef(null);

  let GraphProps = {
    data: ProcessData,
    id: 'ProcessGraph',
    RecipeNode: RecipeNode,
    logoSvgInSubstitution: logoSvgInSubstitution,
    forwaedIcon: '',
    forwardActiveIcon: '',
    // nodeClickStatus: nodeClickStatus,
    // setNodeClickStatus: setNodeClickStatus,

    fixedProcessdata,
    initialData: ProcessDataLinksAndNodesData,
    setProcessData: setProcessData,
  };
  // {
  //   nutritionDataStatusInRawData !== 'success' && (
  //     <Loading color={LoadingThemeColor} />
  //   );
  // }

  //console.lognodeClickStatus);
  return (
    <div className="processMonetetingContainer" ref={processMoniteringCotainer}>
      {ProcessData.length === 0 && <Loading color={LoadingThemeColor} />}
      {ProcessData.length !== 0 && (
        <ProcessGraphcontext.Provider value={GraphProps}>
          <Graph processMoniteringCotainer={processMoniteringCotainer} />
        </ProcessGraphcontext.Provider>
      )}
    </div>
  );
};

export default ProcessMoniteringGraph;
