import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Loading } from '../../../Component/index';

const RecipeGraphInfnfo = ({ LoadingThemeColor }) => {
  const recipeAndProcessComponentStore = useSelector(
    s => s.recipeAndProcessGraph
  );
  const location = useLocation();
  const preferenceStore = useSelector(state => state.preferenceStore);
  return (
    <>
      <div className="rightSideFixedContainerInHood">
        <div className="discriptionContainerInVocabulary">
          <div className="recipeGraphHeading">
            {location.pathname.split('/')[1] === 'process'
              ? 'Process Graph'
              : 'Recipe Graph'}
          </div>
          <div className="padThai">
            {preferenceStore.selectedRecipeData.Recipes}
          </div>
          <div className="padThaiParagraph">
            A knowledge graph showcasing all the classes that the above
            mentioned recipe has. It shows the mapping of semantic data to
            ontology.
          </div>
          {recipeAndProcessComponentStore.packCircleNodes.length === 0 && (
            <Loading color={LoadingThemeColor} />
          )}
          {recipeAndProcessComponentStore.packCircleNodes.length !== 0 &&
            recipeAndProcessComponentStore.packCircleNodes.map(ek => {
              return (
                <>
                  <h3 className="recipeAndProcessNodeNameHedding">{ek.id}</h3>
                  <div className="recipeAndProcessNodeDiscription">
                    {ek.objDef}
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default RecipeGraphInfnfo;
