import * as d3 from 'd3';

const RecipeGraphSimulationSetting = (nodes, links, width, height, svg) => {
  const nodeRadiusScale = d3.scaleSqrt().domain([0, 50]).range([10, 50]);
  const simulation = d3
    .forceSimulation(nodes)
    // .force("x", d3.forceX(width / 2).strength(0.4))
    // .force("y", d3.forceY(height / 2).strength(0.6))

    .force(
      'link',
      d3
        .forceLink(links)
        .id(d => d.id)
        .distance(100)
    )
    .force(
      'charge',
      d3
        .forceManyBody()
        .strength(function (d, i) {
          var a = i == 0 ? -0 : -6000;
          return a;
        })
        .distanceMin(100)
        .distanceMax(1000)
    )
    .force(
      'collide',
      d3.forceCollide(function (d) {
        return nodeRadiusScale(d.value);
      })
    )
    .force(
      'center',
      d3.forceCenter(
        svg._groups[0][0].clientWidth / 2,
        svg._groups[0][0].clientHeight / 2
      )
    );

  return simulation;
};

export default RecipeGraphSimulationSetting;
