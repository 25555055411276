import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import './Modal.css';
import { MdOutlineCancel } from '../../icon';

const Modal = props => {
  const closeOnEscapeKeyDown = e => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onCloseCancel();
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  }, []);

  return ReactDOM.createPortal(
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
    >
      <div
        className={`modal ${props.mainClassModal}`}
        onClick={props.onCloseCancel}
      >
        <div
          className={`modal-content ${props.customClass}`}
          onClick={e => e.stopPropagation()}
          style={{
            width: props.width,
            height: props.height,
            marginTop: props.marginTop,
            marginLeft: props.marginleft,
          }}
        >
          {props.title !== '' && (
            <div className="modal-header">
              <div>
                <h1 className="modal-title">{props.title}</h1>
              </div>
              {props.closeIcon && (
                <div onClick={props.onCloseCancel} className="modal-icon-click">
                  <MdOutlineCancel color="#bcc2ca" size="1.5rem" />
                </div>
              )}
            </div>
          )}
          {props.title === '' && (
            <div
              onClick={props.onCloseCancel}
              className="modal-icon-click-success"
            >
              <MdOutlineCancel
                color="#bcc2ca"
                size="2.1em"
                style={{ cursor: 'pointer' }}
              />
            </div>
          )}

          <div className="modal-body">{props.children}</div>
          {props.isButton && (
            <div className="modal-footer">
              <button
                disabled={props.isdiable}
                onClick={props.onClose}
                className="Modal-button"
                style={{
                  background: props.color,
                  border: `1px solid ${props.color}`,
                }}
              >
                Submit
              </button>
            </div>
          )}
        </div>
      </div>
    </CSSTransition>,
    document.getElementById('root')
  );
};

export default Modal;
