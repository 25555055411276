import React from 'react';
import './PageDivider.css';
const PageDivider = ({ text, marginTop }) => {
  return (
    <div className="separatorContainer" style={{ marginTop: marginTop }}>
      <div className="separator">{text}</div>
    </div>
  );
};

export default PageDivider;
