import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { AiFillInfoCircle } from '../../icon';
import './ToolTipInfo.css';

const ToolTipInfo = ({ title, placement }) => {
  return (
    <>
      <Tooltip title={title} placement={placement}>
        <button className="ToltipInfoIcon">
          <div className="ToltipInfoIconContent">
            <AiFillInfoCircle
              className="infoIconSize"
              size={'1.1rem'}
              color="#f5a349"
            />
          </div>
        </button>
      </Tooltip>
    </>
  );
};

export default ToolTipInfo;
