import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { AuthCart } from '../../lib';
import { AppForgetPasswordFooter } from '../index';
import AppSignUp from './AppSignUp';

const SignUp = () => {
  const [radioButtonValue, setRadioButtonValue] = React.useState('idle');
  const authenticationStore = useSelector(s => s.authStore);

  if (authenticationStore.currentUserForApp) {
    return <Navigate to={'/home'} replace />;
  }
  return (
    <>
      {authenticationStore.forgetPasswordStatus && (
        <AuthCart isLogin={true}>
          <AppForgetPasswordFooter />
        </AuthCart>
      )}
      {!authenticationStore.forgetPasswordStatus && (
        <AuthCart>
          <AppSignUp
            radioButtonValue={radioButtonValue}
            setRadioButtonValue={setRadioButtonValue}
          />
        </AuthCart>
      )}
    </>
  );
};

export default SignUp;
