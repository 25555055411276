import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { authAction } from '../../Redux/Slices/AuthSlides/AuthenticationStates';
import './GuestModal.css';
const GuestPopupBox = ({ className }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const signUpInGuestPopBox = () => {
    dispatch(authAction.getUserName(''));
    dispatch(authAction.getEmail(''));
    dispatch(authAction.getPassword(''));
    dispatch(authAction.getConfirmPassword(''));
    dispatch(authAction.getMobileNumber(''));
    dispatch(authAction.getCompanyName(''));
    dispatch(authAction.getDomineName(''));
    navigate('/signup');
  };
  const cancelInGuestPopBox = () => {
    navigate(-1);
  };
  const signInInGuestPopBox = () => {
    dispatch(authAction.getUserName(''));
    dispatch(authAction.getEmail(''));
    dispatch(authAction.getPassword(''));
    dispatch(authAction.getConfirmPassword(''));
    dispatch(authAction.getMobileNumber(''));
    dispatch(authAction.getCompanyName(''));
    dispatch(authAction.getDomineName(''));
    navigate('/login');
  };
  return (
    <div className="GuestModalContainer">
      <div className="GuestModalDilogueBox">
        <h2>Please sign in or sign up to continue</h2>
        <div className="paraInGuest">
          To access <span className="UTHExpression">‘Under the hood’</span>{' '}
          please sign in into your existing account or sign up <br /> to a new
          one by clicking on the button below.
        </div>
        <div className="BtnsContainerInGuest">
          <button
            onClick={signUpInGuestPopBox}
            className={`loginBtn ${className}`}
          >
            Sign Up
          </button>
          <button onClick={cancelInGuestPopBox} className="cancelBtnInGuest">
            Cancel
          </button>
        </div>
        <div className="alreadyAnAccountInGuest">
          Already have an account?{' '}
          <a onClick={signInInGuestPopBox} className="signInButtonInGuest">
            Sign in
          </a>
        </div>
      </div>
    </div>
  );
};

export default GuestPopupBox;
