import React from 'react';
import './SelectBox.css';
import { IoIosArrowDropdownCircle } from '../../icon';
import SelectBoxOption from './SelectBoxOption';

const SelectBox = ({
  setSelectCompanyName,

  selectBoxMessage,
  selectBoxMessageIcon,
  setIsFocuse,

  setUserPersonalDetalis,
}) => {
  // const [isFocuse, setIsFocuse] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');

  let [showInfo1, setShowInfo1] = React.useState(false);

  // const getNotification = () => {
  //   setIsFocuse(P => !P);
  // };
  // const getNotification1 = () => {
  //   setIsFocuse(false);
  // };
  // const handleChange = e => {
  //   //console.log'country Selected!!');
  //   // this.setState(e.target.value);
  //   setIsFocuse(p => !p);
  //   setSelectCompanyName(e.target.value);
  // };
  const setGroupInputValue = el => {
    setUserPersonalDetalis(p => {
      return {
        companyName: p.companyName,
        partner: p.partner,
        domain: p.domain,
        phoneNumber: p.phoneNumber,
        group: el,
      };
    });
    setSelectCompanyName(el);
    setInputValue(el);
    // console.log('eyMan');
    setIsFocuse(false);
    setShowInfo1(true);
  };

  return (
    <div className="Auth-select-container">
      <div
        className={
          showInfo1
            ? 'selectBoxContainer selectInputFocus'
            : 'selectBoxContainer'
        }
      >
        <IoIosArrowDropdownCircle
          onClick={() => {
            setShowInfo1(P => !P);
          }}
          className={showInfo1 ? ' dropUpIcon' : 'dropDownIcon'}
        />
        <label className="GroupLabel">Group</label>
        <input
          className={'selectInput'}
          value={inputValue}
          onClick={() => {
            setShowInfo1(true);
          }}
        />
        <SelectBoxOption
          setShowInfo1={setShowInfo1}
          setGroupInputValue={setGroupInputValue}
          onClickOutside={() => {
            setShowInfo1(false);
          }}
          show={showInfo1}
          setUserPersonalDetalis={setUserPersonalDetalis}
        />

        <div className="AuthSignUpMessage">
          <div className="AuthErrorIconColor">{selectBoxMessageIcon}</div>
          <div>{selectBoxMessage}</div>
        </div>
      </div>
    </div>
  );
};

export default SelectBox;
