import React from 'react';

import qs from 'qs';
import axios from 'axios';
import ApplicationToken from './ApplicationToken';

// import { tokenKey } from '../App';
// var tokenKey = ApplicationToken(false);
// var tokenKey = JSON.parse(window.localStorage.getItem('applicationToken'));
const useFetch = query => {
  const [status, setStatus] = React.useState('idle');
  const [result, setResult] = React.useState([]);

  var data = qs.stringify({
    query: `${query}`,
  });

  // eslint-disable-next-line no-undef
  const Query = window?._env_?.REACT_APP_QUERY;

  const queryCall = React.useCallback(
    async token => {
      if (!token) {
        return;
      } else {
        setStatus('Loading');

        var config = {
          method: 'POST',
          url: `${Query}`,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization:
              token?.data?.token_type + ' ' + token?.data?.access_token,
          },
          data: data,
        };

        axios(config)
          .then(response => {
            const rows = response.data.results.bindings.map(e => {
              return {
                value:
                  e.value !== undefined
                    ? e.value.value
                    : e.iri !== undefined
                    ? e.iri.value
                    : '',
                searchfilter:
                  e.searchfilter === undefined ? '' : e.searchfilter.value,
                ingredients:
                  e.ingredients === undefined ? '' : e.ingredients.value,

                serving: e.serving === undefined ? '' : e.serving.value,
                ctime: e.ctime === undefined ? '' : e.ctime.value,
                img: e.img === undefined ? '' : e.img.value,
                Recipes: e.Recipes === undefined ? '' : e.Recipes.value,
                ptime: e.ptime === undefined ? '' : e.ptime.value,
                ingredient_count:
                  e.ingredient_count === undefined
                    ? ''
                    : e.ingredient_count.value,
                tdtime: e.tdtime === undefined ? '' : e.tdtime.value,
                cui: e.cui === undefined ? '' : e.cui.value,

                ing_value: e.ing_value === undefined ? '' : e.ing_value.value,
                ingredient:
                  e.ingredient === undefined ? '' : e.ingredient.value,
                ingredient_details:
                  e.ingredient_details === undefined
                    ? ''
                    : e.ingredient_details.value,
                instructions:
                  e.instructions === undefined ? '' : e.instructions.value,

                recipie_value:
                  e.recipie_value === undefined ? '' : e.recipie_value.value,

                carbohydrates:
                  e.carbohydrates === undefined ? '' : e.carbohydrates.value,
                energy: e.energy === undefined ? '' : e.energy.value,
                fats: e.fats === undefined ? '' : e.fats.value,
                protiens: e.protiens === undefined ? '' : e.protiens.value,
                ingredient_label:
                  e.ingredient_label === undefined
                    ? ''
                    : e.ingredient_label.value,
                cuisine: e.cuisine === undefined ? '' : e.cuisine.value,
                final: e.final === undefined ? '' : e.final.value,
                flavor: e.flavor === undefined ? '' : e.flavor.value,
                ingredientInSb1:
                  e.ingredient === undefined ? '' : e.ingredient.value,
                process: e.process === undefined ? '' : e.process.value,
                recipe_iri:
                  e.recipe_iri === undefined ? '' : e.recipe_iri.value,
                temp_dataType: e.temp === undefined ? '' : e.temp.datatype,
                temp: e.temp === undefined ? '' : parseInt(e.temp.value),
                texture: e.texture === undefined ? '' : e.texture.value,
                time_dataType: e.time === undefined ? '' : e.time.datatype,
                time: e.time === undefined ? '' : parseInt(e.time.value),
                sb1_iri_Value: e.value === undefined ? '' : e.value.value,
                delta:
                  e.delta_stats === undefined
                    ? ''
                    : parseInt(e.delta_stats.value),
                final_flavour: e.flavour === undefined ? '' : e.flavour.value,
                flavour_stats:
                  e.flavour_stats === undefined
                    ? ''
                    : parseInt(e.flavour_stats.value),
                final_texture:
                  e.final_texture === undefined ? '' : e.final_texture.value,
                texture_stats:
                  e.texture_stats === undefined
                    ? ''
                    : parseInt(e.texture_stats.value),
                total: e.total === undefined ? '' : e.total.value,
                value_ins: e.value_ins === undefined ? '' : e.value_ins.value,
                ins: e.ins === undefined ? '' : e.ins.value,
                sub_status:
                  e.sub_status === undefined ? '' : e.sub_status.value,
                average_stats:
                  e.average_stats === undefined ? '' : e.average_stats.value,
              };
            });

            const Data = {
              columns: [
                {
                  friendly_name: 'ingredients',
                  type: 'string',
                  name: 'ingredients',
                },
                { friendly_name: 'value', type: 'string', name: 'value' },
                {
                  friendly_name: 'searchfilter',
                  type: 'string',
                  name: 'searchfilter',
                },
              ],
              rows: [...rows],
            };

            setResult(Data);

            setStatus('success');
          })
          .catch(function (error) {
            // console.log(error.response.status);
            setStatus('error');
            if (error?.response?.status === 401) {
              ApplicationToken(true, queryCall);
              // tokenKey = JSON.parse(
              //   window.localStorage.getItem('applicationToken')
              // );
              // queryCall(tokenKey);
            }
          });
      }
    },
    [data]
  );
  React.useEffect(() => {
    if (!JSON.parse(window.localStorage.getItem('applicationToken'))) {
      ApplicationToken(true, queryCall);
      // tokenKey = ApplicationToken(true, '');
    } else {
      queryCall(
        JSON.parse(window.localStorage.getItem('applicationToken')),
        ''
      );
    }
  }, [query]);

  return [status, result];
};

export default useFetch;
