import React from 'react';
import * as d3 from 'd3';
const DonutComponent = ({
  percentageValue,
  arrayColors,
  projects,
  id,
  internal,
  external,
  title,
  radiusRef,
}) => {
  React.useEffect(() => {
    if (!percentageValue) {
      return null;
    }
    function convertRemToPixels(rem) {
      return (
        rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
      );
    }

    // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
    // const radius = Math.min(width, height) / 2 - margin;

    // append the svg object to the div called 'my_dataviz'

    const svg = d3
      .select('#my_dataviz')
      .append('svg')
      .attr('background-color', 'yellow')
      .attr('class', `${id === '2' ? 'leftChart' : 'rightChart'}`)
      .attr('width', '30rem')
      .attr('height', '15rem')
      .append('g')
      .attr(
        'transform',
        `translate(${radiusRef?.current?.clientWidth / 3},${
          radiusRef?.current?.clientHeight / 2.5
        })`
      );

    // console.log(svg.select('.leftChart'));

    // Create dummy data
    let floatFormat = 100 - parseFloat(percentageValue);
    let roundNumber = Math.round((floatFormat + Number.EPSILON) * 100) / 100;
    const data = {
      count: roundNumber,
      count1: parseFloat(percentageValue).toFixed(2),
    };

    // set the color scale
    const color = d3
      .scaleOrdinal()
      .domain(['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'])
      .range(arrayColors);

    // Compute the position of each group on the pie:
    const pie = d3
      .pie()
      .sort(null) // Do not sort group by size
      .value(d => d[1]);
    const data_ready = pie(Object.entries(data));
    // console.log(data_ready);

    // The arc generator
    const arc = d3
      .arc()
      .innerRadius(0) // This is the size of the donut hole
      .outerRadius(radiusRef?.current?.clientWidth * 0.12 * 0.8);

    // Another arc that won't be drawn. Just for labels positioning
    const outerArc = d3
      .arc()
      .innerRadius(radiusRef?.current?.clientWidth * 0.12 * 0.9)
      .outerRadius(radiusRef?.current?.clientWidth * 0.12 * 0.9);

    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
    svg
      .selectAll('allSlices')
      .data(data_ready)
      .join('path')
      .attr('d', arc)
      .attr('fill', d => color(d.data[1]))
      .attr('stroke', 'white')
      .style('stroke-width', '.4px')
      .style('opacity', 0.7);

    // Add the polylines between chart and labels:
    svg
      .selectAll('allPolylines')
      .data(data_ready)
      .join('polyline')
      .attr('stroke', 'black')
      .style('fill', 'none')
      .attr('stroke-width', 1)
      .attr('points', function (d) {
        // console.log(d);
        const posA = arc.centroid(d); // line insertion in the slice
        const posB = outerArc.centroid(d); // line break: we use the other arc generator that has been built only for that
        const posC = outerArc.centroid(d); // Label position = almost the same as posB
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2; // we need the angle to see if the X position will be at the extreme right or extreme left
        posC[0] =
          radiusRef?.current?.clientWidth *
          0.12 *
          0.85 *
          (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left
        return [posA, posB, posC];
      });

    // Add the polylines between chart and labels:
    svg

      .selectAll('allLabels')
      .data(data_ready)
      .join('text')
      .text((d, i) => title[i])
      .attr('font-size', '.7rem')
      .attr('font-weight', 'bolder')
      .attr('transform', function (d) {
        const pos = outerArc.centroid(d);
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        pos[0] =
          radiusRef?.current?.clientWidth *
          0.12 *
          0.89 *
          (midangle < Math.PI ? 1 : -1);
        // const x = pos[0] - 0;
        // const y = pos[1] + 20;
        return `translate(${pos})`;
      })
      .style('text-anchor', function (d) {
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        return midangle < Math.PI ? 'start' : 'end';
      });
    // Add the polylines between chart and labels:
    svg

      .selectAll('allLabels')
      .data(data_ready)
      .join('text')
      .text((d, i) => projects[i])
      .attr('font-size', '.7rem')
      .attr('font-weight', 'bolder')
      .attr('transform', function (d) {
        const pos = outerArc.centroid(d);
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        pos[0] =
          radiusRef?.current?.clientWidth *
          0.12 *
          0.89 *
          (midangle < Math.PI ? 1 : -1);
        const x = pos[0] - 0;
        const y = pos[1] + convertRemToPixels(0.9);
        return 'translate(' + x + ',' + y + ')';
        // return `translate(${pos})`;
      })
      .style('text-anchor', function (d) {
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        return midangle < Math.PI ? 'start' : 'end';
      });
    // Add the polylines between chart and labels:
    // ===========================

    svg

      .selectAll('allLabelsExternal')
      .data(data_ready)
      .join('text')
      .attr('font-size', '.68rem')
      .text((d, i) => external[i])
      .attr('transform', function (d) {
        const pos = outerArc.centroid(d);
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        pos[0] =
          radiusRef?.current?.clientWidth *
          0.12 *
          0.89 *
          (midangle < Math.PI ? 1 : -1);
        const x = pos[0] - 0;
        const y = pos[1] + convertRemToPixels(2.7);
        // const position = pos + 203;

        return 'translate(' + x + ',' + y + ')';
        // return `translate(${position})`;
      })
      .style('text-anchor', function (d) {
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        return midangle < Math.PI ? 'start' : 'end';
      });
    svg

      .selectAll('allLabelsExternal')
      .data(data_ready)
      .join('text')
      .attr('font-size', '.68rem')
      .text((d, i) => internal[i])
      .attr('transform', function (d) {
        const pos = outerArc.centroid(d);
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        pos[0] =
          radiusRef?.current?.clientWidth *
          0.12 *
          0.89 *
          (midangle < Math.PI ? 1 : -1);
        const x = pos[0] - 0;
        const y = pos[1] + convertRemToPixels(1.8);

        return 'translate(' + x + ',' + y + ')';
      })
      .style('text-anchor', function (d) {
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        return midangle < Math.PI ? 'start' : 'end';
      });

    // ================
    svg
      .selectAll('nextText')
      .data(data_ready)
      .join('text')
      .text(d => `${d.data[1]}%`)
      .attr('font-size', '.7rem')
      .attr('font-weight', 'bolder')
      .attr('transform', function (d) {
        const pos = arc.centroid(d);
        const x = pos[0] - 0;
        const y = pos[1] + 0;
        // return `translate(${pos})`;
        return 'translate(' + x + ',' + y + ')';
      });
    if (!percentageValue) {
      return null;
    }
    return () => {
      svg.remove();
    };
  });
  return <div id="my_dataviz"></div>;
};

export default DonutComponent;
