import React from 'react';
import { minusButton, plusButton } from '../../../assets';
import { AppColors } from '../../../Constants/AppColors';
import { ToolTipInfo } from '../../../lib';
const ProcessGeneratorManipulator = ({
  sb1ProcessData,

  setChangedTimeAndTempValues,
  getFinalOutPut,
  setResetButtonClick,
}) => {
  const [changableData, setChangableData] = React.useState(sb1ProcessData.rows);

  const loadedData = {
    timeValues: sb1ProcessData.rows.map(el => el.time),
    tempValues: sb1ProcessData.rows.map(el => el.temp),
  };
  const storeData = sb1ProcessData.rows;

  const resetTempAndTimeValues = () => {
    // const resettedData = changableData.map((el, i) => {
    //   const Data = {
    //     ...el,
    //     temp: loadedData.tempValues[i],
    //     time: loadedData.timeValues[i],
    //   };
    //   return Data;
    // });
    setChangableData(storeData);
    setChangedTimeAndTempValues(loadedData);
    getFinalOutPut();
    setResetButtonClick(true);
  };
  React.useEffect(() => {
    setChangedTimeAndTempValues(loadedData);
  }, []);
  //console.logchangableData);
  function replaceJSX(str, find, replace, replacedWord) {
    let parts = str.split(find);

    parts.splice(parts.indexOf(replacedWord), 1, replace);
    const mapJSx = parts.map(el => {
      return <>{el} </>;
    });
    return mapJSx;
  }

  const [isButtonReset, setIsButtonReset] = React.useState(true);

  const mappedBoxes = changableData.map((el, i) => (
    <div key={i} className="ManipulatorBox">
      <div className="ManipulatorBoxTitle">{el.process}</div>
      <div className="instructionBox">
        <div className="ingredientShowStatusBox">
          <div className="instructionParaInProcessGenerator">
            {/* {el.ins.replace(el.value_ins, `<span >${el.value_ins}</span>`)} */}
            {replaceJSX(el.ins, ' ', <span>{el.value_ins}</span>, el.value_ins)}
          </div>
          <div className="ingredientsShowPara">
            <strong>Ingredients :</strong>
            {el.ingredientInSb1}
          </div>
        </div>
        <div className="controllersBox">
          <div className="tempControllerBox">
            <div className="controllTitles">IoT temperature (°C) :</div>
            <div className="controllerContainer">
              <img
                src={minusButton}
                className="controlButtons"
                alt="minusButton"
                onClick={
                  el.temp <= 20 ? null : () => decreaseTempValueValues(el, i)
                }
              />
              <input className="inputForControllers" value={el.temp} />
              <img
                src={plusButton}
                className="controlButtons"
                alt="plusButton"
                onClick={
                  el.temp >= 500 ? null : () => increaseTempValues(el, i)
                }
              />
            </div>
          </div>
          <div className="timeControllerBox">
            <div className="controllTitles">IoT time (mins) :</div>
            <div className="controllerContainer">
              <img
                src={minusButton}
                className="controlButtons"
                alt="minusButton"
                onClick={el.time <= 1 ? null : () => decreaseTimeCount(el, i)}
              />

              <input className="inputForControllers" value={el.time} />

              <img
                src={plusButton}
                className="controlButtons"
                alt="plusButton"
                onClick={el.time >= 25 ? null : () => increaseTimeCount(el, i)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ));

  const increaseTempValues = (item, position) => {
    setIsButtonReset(false);
    const updatedCheckedState = changableData.map((item, index) => {
      let data = index === position ? item.temp + 1 : item.temp;
      return data;
    });
    const insertChangedValues = changableData.map((el, i) => {
      const Data = {
        ...el,
        temp: updatedCheckedState[i],
      };
      return Data;
    });
    setChangedTimeAndTempValues(p => {
      return {
        tempValues: updatedCheckedState,
        timeValues: p.timeValues,
      };
    });
    setChangableData(insertChangedValues);
  };
  const decreaseTempValueValues = (item, position) => {
    setIsButtonReset(false);
    const updatedCheckedState = changableData.map((item, index) => {
      let data = index === position ? item.temp - 1 : item.temp;
      return data;
    });
    const insertChangedValues = changableData.map((el, i) => {
      const Data = {
        ...el,
        temp: updatedCheckedState[i],
      };
      return Data;
    });
    setChangedTimeAndTempValues(p => {
      return {
        tempValues: updatedCheckedState,
        timeValues: p.timeValues,
      };
    });
    setChangableData(insertChangedValues);
  };
  const increaseTimeCount = (item, position) => {
    setIsButtonReset(false);
    const updatedCheckedState = changableData.map((item, index) => {
      let data = index === position ? item.time + 1 : item.time;
      return data;
    });
    const insertChangedValues = changableData.map((el, i) => {
      const Data = {
        ...el,
        time: updatedCheckedState[i],
      };
      return Data;
    });
    setChangedTimeAndTempValues(p => {
      return {
        timeValues: updatedCheckedState,
        tempValues: p.tempValues,
      };
    });

    setChangableData(insertChangedValues);
    // console.log(item, position);
  };
  const decreaseTimeCount = (item, position) => {
    setIsButtonReset(false);
    const updatedCheckedState = changableData.map((item, index) => {
      let data = index === position ? item.time - 1 : item.time;
      return data;
    });
    const insertChangedValues = changableData.map((el, i) => {
      const Data = {
        ...el,
        time: updatedCheckedState[i],
      };
      return Data;
    });
    setChangedTimeAndTempValues(p => {
      return {
        timeValues: updatedCheckedState,
        tempValues: p.tempValues,
      };
    });

    setChangableData(insertChangedValues);
  };

  return (
    <>
      <div className="processsAndSaveButtonFlex">
        <div className="processMainTitleInLeftContainer">
          Process
          <ToolTipInfo
            title="Different Techniques involved in cooking, with various methods using differing levels of heat and vary in cooking time"
            placement="right"
          />{' '}
        </div>
        <div className="buttonsFlexInProcessGenerator">
          <button
            onClick={resetTempAndTimeValues}
            className="resetButtonInProcessChallengeGenerator"
            disabled={isButtonReset}
            style={{
              color: isButtonReset
                ? `${AppColors.process.base_color}`
                : `${AppColors.process.base_color}`,
              opacity: isButtonReset ? 0.5 : 1,
            }}
          >
            Cancel
          </button>
          <button
            onClick={getFinalOutPut}
            className="applyButtonInProcessChallengeGenerator"
            disabled={isButtonReset}
            style={{
              backgroundColor: isButtonReset
                ? `${AppColors.process.base_color}`
                : `${AppColors.process.base_color}`,
              opacity: isButtonReset ? 0.5 : 1,
            }}
          >
            Apply
          </button>
        </div>
      </div>
      <div className="mainManipulatorScrollContainer">{mappedBoxes}</div>
    </>
  );
};

export default ProcessGeneratorManipulator;
