import React from 'react';
import '../OTPField/OtpInput.css';
import { AiFillInfoCircle } from '../../../icon';
import OTPInput from 'otp-input-react';
import AuthButton from '../../../lib/Auth/AuthButton/AuthButton';
const OtpComponent = ({ setForgrtStep }) => {
  const [OTP, setOTP] = React.useState('');

  const GotoCreatePassWord = () => {
    setForgrtStep(3);
  };
  //console.logOTP.split(''));
  return (
    <>
      {/* <form className="OTP-Input-Flex"> */}
      <>
        <div className="OtpInputContainer">
          <OTPInput
            value={OTP}
            onChange={setOTP}
            autoFocus={false}
            OTPLength={4}
            otpType="number"
            disabled={false}
            secure
            inputStyles={{
              width: '30px',
              height: '26px',
              border: '1px solid gray',

              outline: 'none',
              borderRadius: '5px',
              marginTop: '1em',
              display: 'flex',
            }}
          />
        </div>
      </>
      {/* <h4 className="step-1">Step 2</h4> */}
      {/* <div style={{ width: '1300%', display: 'flex' }}></div> */}
      {/* </form> */}
      <div className="OtpInput" style={{ color: 'rgba(36, 39, 49, 0.8)' }}>
        <div>
          <AiFillInfoCircle size=".9em" />
        </div>
        <div className="errorText">
          Enter 4-digit verification code sent to the above Email ID
        </div>
      </div>
      {/* <div className="Otp-Send-ButtonContainer"> */}
      {/* <button onClick={getforgetPassword} className="BactToLoginLink">
          Back to login
        </button> */}
      {/* <button className={'Otp-Send-Button '}>Resend OTP</button> */}
      {/* </div> */}
      <div className="AuthUseridButton">
        <AuthButton
          disabled={OTP.split('').length === 4 ? false : true}
          buttonText="Validate"
          onClick={GotoCreatePassWord}
          type="button"
          width="5.8em"
          height="3em"
        />
      </div>
    </>
  );
};

export default OtpComponent;
