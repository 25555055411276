import React from 'react';
import {
  ProcessSteps,
  Arrow,
  UnderTheHoodContainer,
  ProcessStepWithDropDown,
  PageBanner,
} from '..';
import '../../style/Inventory.css';
import '../../style/DemandForecasting.css';
// import { createFilterOptions } from '@mui/material/Autocomplete';

import {
  bannerSvgImageInProcessChallenge,
  successIcon,
  processChallengeStepFirstImage,
  processChallengeStepSecondImage,
  processChallengeStepThreeImage,
  processChallengeStepFourImage,
} from '../../assets';

import {
  firstProcessStepInProcessChallenge,
  secondProcessStepInProcessChallenge,
  thirdProcessStepInProcessChallenge,
  fourthProcessStepInProcessChallenge,
  firstProcessArrowInProcessChallenge,
  secondProcessArrowInProcessChallenge,
  thirdProcessArrowInProcessChallenge,
  // useFetch,
} from '../../utils';
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router';

import {
  invProcessStepActions,
  cascadingScreenActions,
  useDispatch,
  useSelector,
} from '../../Redux';
import { AppColors } from '../../Constants/AppColors';
import GuestPopupBox from '../../lib/GuestComponent/GuestPopupBox';
export const createProcessItemContext = React.createContext();

const DemandForecasting = ({
  getIngredientButtonClick,
  getRecipeCatalogueButtonClick,
  getPreferencesButtonClick,
}) => {
  const invProcessBarSteps = useSelector(state => state.inventoryProcessSteps);
  const cascadingsSreenStore = useSelector(state => state.cascadingStore);
  // const cuisineStore = useSelector(state => state.cuisineStore);

  const dispatch = useDispatch();
  const location = useLocation();

  React.useEffect(() => {
    dispatch(cascadingScreenActions.getConvertQueryKey());
  }, [cascadingsSreenStore.selectedIngredients]);
  const navigate = useNavigate();
  // const objectiveFormatSelection =
  //   cascadingsSreenStore.totalSelectResult.toString();

  // const cascading_First_QueryId =
  //   // eslint-disable-next-line no-undef
  //   process.env.REACT_APP_CASCADING_SELECTION_CLASSIFICATION_QUERY_ID_POINT_ONE;
  // // eslint-disable-next-line no-undef
  // const cascading_Second_QueryId =
  //   // eslint-disable-next-line no-undef
  //   process.env.REACT_APP_CASCADING_SELECTION_CLASSIFICATION_QUERY_ID_POINT_TWO;
  // // eslint-disable-next-line no-undef
  // const cascading_Third_QueryId =
  //   // eslint-disable-next-line no-undef
  //   process.env
  //     .REACT_APP_CASCADING_SELECTION_CLASSIFICATION_QUERY_ID_POINT_THREE;

  // const no_Selection_Results_QuaryId =
  //   // eslint-disable-next-line no-undef
  //   process.env.REACT_APP_CASCADING_NO_SELECTION_CLASSIFICATION_QUERY_ID;
  // const Food_Classification_Cascading_QueryId = `${cascading_First_QueryId} ${
  //   objectiveFormatSelection === undefined ? `<>` : objectiveFormatSelection
  // } ${cascading_Second_QueryId}${
  //   objectiveFormatSelection === undefined ? `<>` : objectiveFormatSelection
  // }${cascading_Third_QueryId}`;
  // const [cascadingStatus, cascadingData] = useFetch(
  //   cascadingsSreenStore.totalSelectResult.length === 0
  //     ? no_Selection_Results_QuaryId
  //     : Food_Classification_Cascading_QueryId
  // );
  // React.useEffect(() => {
  //   dispatch(
  //     cascadingScreenActions.avoidLateLoadingWhileSelectingIngredient(
  //       cascadingStatus
  //     )
  //   );
  // }, [cascadingStatus]);

  // const ristrition = cascadingStatus === 'success' ? cascadingData.rows : [];

  // const output = ristrition.reduce((prev, curr) => {
  //   const tmp = prev.find(e => e.searchfilter === curr.searchfilter);
  //   if (tmp) {
  //     tmp.ingredient_label.push(curr.ingredient_label);
  //     tmp.value.push(curr.value);
  //   } else {
  //     prev.push({
  //       searchfilter: curr.searchfilter,
  //       ingredient_label: [curr.ingredient_label],
  //       value: [curr.value],
  //       cuisine: curr.cuisine,
  //     });
  //   }

  //   return prev;
  // }, []);

  // const saperatedData = output;

  // const mixedIngredients = saperatedData
  //   .flatMap(x => x.ingredient_label)
  //   .filter((el, i, arr) => i === arr.indexOf(el) && el);

  // const duplicateCusineList = saperatedData.map(el => {
  //   const Data = el.cuisine;
  //   return Data;
  // });

  //   const uniqueCusineList = duplicateCusineList.filter(
  //     (el, index, array) => index === array.indexOf(el)
  //   );

  //   ============================
  // const uniqueCusineList = ['INDIA', 'THAI'];
  // const fakeRecipeResult = ['<eg:ing-coconut>'];

  //   ===========================

  // let uniqueCusine = uniqueCusineList.join(' ');
  const nextProcess = () => {
    dispatch(
      cascadingScreenActions.changeIngredientTitle(
        'Select your preferred cuisine:'
      )
    );
    dispatch(invProcessStepActions.gotoCuisinePage());
    dispatch(cascadingScreenActions.getShowTopInput());
    navigate(`countrylist`);
    // ${uniqueCusine}_${fakeRecipeResult.join('_')}
  };

  // ======================================================InputCode for Cusine==============================================================================

  // const [inputValue, setInputValue] = React.useState('');
  // const [open, setOpen] = React.useState(false);

  // const getFocus = () => {
  //   dispatch(cascadingScreenActions.setTrueInvNotification());
  // };
  // const getBlur = () => {
  //   dispatch(cascadingScreenActions.setFalseInvNotification());
  // };

  // const getSelect = e => {
  //   dispatch(
  //     cascadingScreenActions.getSelectIngredientFromRightSideSearch({
  //       element: e.target.textContent,
  //       mixedIngredients: mixedIngredients,
  //       otherDispatch: dispatch(
  //         invProcessStepActions.whileSelectIngredientStatus()
  //       ),
  //     })
  //   );
  // };

  // const firstLetterToUpperCase =
  //   inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
  // const OPTIONS_LIMIT = 5;
  // const defaultFilterOptions = createFilterOptions();

  // const filterOptions = (options, state) => {
  //   return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  // };

  React.useEffect(() => {
    if (location.pathname.split('/').length === 2) {
      dispatch(
        cascadingScreenActions.changeIngredientTitle(
          `To view the corresponding recipes, first select the ingredients you want to use. Add an ingredient either by browsing the available ingredients by category or by searching for a specific ingredient in the search box on the right. After ingredient selection, click on the ‘Next’ button to go to cuisine selection..`
        )
      );
      if (cascadingsSreenStore.totalSelectResult.length !== 0) {
        dispatch(invProcessStepActions.selectTheIngredients());
      } else {
        dispatch(invProcessStepActions.selectTheIngredientsInitial());
      }
      dispatch(cascadingScreenActions.getHideTopInput());
    } else {
      dispatch(cascadingScreenActions.getShowTopInput());
    }
  }, [location]);

  // ==========================================================================================================================================================
  // const fakeUrlForForcasting = ['list', 'cusine'];
  return (
    <>
      {location.pathname.split('/')[5] !== 'uth' && (
        <div className="InvContainer ProcessContainer">
          <PageBanner
            title="Demand Forecasting"
            src={bannerSvgImageInProcessChallenge}
            mainText="Tweak the process and see how the dish turns out."
            titleClass="InventorttitleClass"
            mainTextClass="InventorymainTextClass"
            imgClass="InventoryimgClass"
            bannerClass="demandTitleImgFlex"
          />
          <div className="height_Flex_Container">
            <div className="processStep-cards-flex">
              <div className="process-step-with-arrow">
                <ProcessSteps
                  processName="Select the ingredients"
                  ingredientsSelection={invProcessBarSteps.ingredientsSelection}
                  selectClass={firstProcessStepInProcessChallenge(
                    invProcessBarSteps.ingredientsSelection
                  )}
                  classForImgRoundContainer={
                    invProcessBarSteps.ingredientsSelection === 'success'
                      ? 'imgRoundSuccessContainer'
                      : 'imgRoundIdleContainerInProcess'
                  }
                  stepIcon={
                    invProcessBarSteps.ingredientsSelection === 'success' ? (
                      <img src={successIcon} className="successRightIconSize" />
                    ) : (
                      <img
                        src={processChallengeStepFirstImage}
                        className="stepIconSize"
                      />
                    )
                  }
                  processClick={getIngredientButtonClick}
                />
                <Arrow
                  selectClass={firstProcessArrowInProcessChallenge(
                    invProcessBarSteps.ingredientsSelection
                  )}
                />
              </div>
              <div className="process-step-with-arrow">
                <ProcessSteps
                  processName="Select cuisine type"
                  selectClass={secondProcessStepInProcessChallenge(
                    invProcessBarSteps.cuisinePageSelection
                  )}
                  classForImgRoundContainer={
                    invProcessBarSteps.cuisinePageSelection === 'success'
                      ? 'imgRoundSuccessContainer'
                      : 'imgRoundIdleContainerInProcess'
                  }
                  stepIcon={
                    invProcessBarSteps.cuisinePageSelection === 'success' ? (
                      <img src={successIcon} className="successRightIconSize" />
                    ) : (
                      <img
                        src={processChallengeStepSecondImage}
                        className="stepIconSize"
                      />
                    )
                  }
                  processClick={getPreferencesButtonClick}
                />
                <Arrow
                  selectClass={secondProcessArrowInProcessChallenge(
                    invProcessBarSteps.cuisinePageSelection
                  )}
                />
              </div>
              <div className="process-step-with-arrow">
                <ProcessSteps
                  processName="Select a recipe from the catalogue"
                  selectClass={thirdProcessStepInProcessChallenge(
                    invProcessBarSteps.ingredientsSecondPageSelection
                  )}
                  classForImgRoundContainer={
                    invProcessBarSteps.ingredientsSecondPageSelection ===
                    'success'
                      ? 'imgRoundSuccessContainer'
                      : 'imgRoundIdleContainerInProcess'
                  }
                  stepIcon={
                    invProcessBarSteps.ingredientsSecondPageSelection ===
                    'success' ? (
                      <img src={successIcon} className="successRightIconSize" />
                    ) : (
                      <img
                        src={processChallengeStepThreeImage}
                        className="stepIconSize"
                      />
                    )
                  }
                  processClick={getRecipeCatalogueButtonClick}
                  // setStepIcon={setStepIcon}
                />
                <Arrow
                  selectClass={thirdProcessArrowInProcessChallenge(
                    invProcessBarSteps.ingredientsSecondPageSelection
                  )}
                />
              </div>

              <div className="process-step-with-arrow">
                <ProcessStepWithDropDown
                  processName="Process monitoring"
                  selectClass={fourthProcessStepInProcessChallenge(
                    invProcessBarSteps.ingredientsLastPageSelection
                  )}
                  classForImgRoundContainer={
                    invProcessBarSteps.ingredientsLastPageSelection ===
                    'success'
                      ? 'imgRoundSuccessContainer'
                      : 'imgRoundIdleContainerInProcess'
                  }
                  stepIcon={
                    invProcessBarSteps.ingredientsLastPageSelection ===
                    'success' ? (
                      <img src={successIcon} className="successRightIconSize" />
                    ) : (
                      <img
                        src={processChallengeStepFourImage}
                        className="stepIconSize"
                      />
                    )
                  }
                />
              </div>
            </div>
          </div>

          <Routes>
            <Route path="/" element={<Layout />}>
              <Route
                index
                element={
                  <>
                    <div
                      style={{
                        width: '100%',
                        height: '55%',
                        backgroundColor: 'red',
                      }}
                    ></div>
                    <button
                      onClick={nextProcess}
                      text="Next"
                      className="processForwordButtonInProcessChallenge processBtn"
                    >
                      Next
                    </button>
                  </>
                }
              />
              <Route
                path={`countrylist/*`}
                element={
                  <>
                    <div
                      style={{
                        width: '90%',
                        height: '50%',
                        margin: 'auto auto',
                        backgroundColor: 'green',
                      }}
                    ></div>
                    <div className="backButtonAndNextButton">
                      <button
                        className={
                          'cusineProceedButtonFromProcessChallenge cusineProceedButtonFromProcessChallengeActive'
                        }
                        onClick={() => {
                          navigate(`cardScreen`);
                          // if (cuisineStore.cuisineContainer.length !== 0) {

                          // }
                          dispatch(
                            invProcessStepActions.goToNextFromCuisinePage()
                          );
                        }}
                      >
                        Next==
                      </button>
                    </div>
                  </>
                }
              />
              <Route
                path="cardScreen/*"
                element={
                  // <Preferences
                  //   LoadingColor={`${AppColors.process.base_color}`}
                  //   cardsBackButtonClass={'preferenceCardsBackButtonInProcess'}
                  //   getPreferencesButtonClick={getPreferencesButtonClick}
                  // />
                  <div
                    style={{
                      width: '90%',
                      height: '50%',
                      margin: 'auto auto',
                      backgroundColor: 'yellow',
                    }}
                  ></div>
                }
              />
            </Route>
          </Routes>
        </div>
      )}

      <Routes>
        <Route
          path=":cuisine/:recipe/:item/uth/*"
          element={
            <>
              {
                location.pathname.split('/')[5] === 'uth' && (
                  // substiteGraphStore.subGraphdata.length !== 0 && (
                  <UnderTheHoodContainer
                    titleAndbacktoRecipeFlex="titleAndbacktoRecipeFlexInProcessChallange"
                    backToRecipeContainerButton="backToRecipeContainerButtonWithProcess"
                    LoadingThemeColor={`${AppColors.process.base_color}`}
                  />
                )
                // )
              }
            </>
          }
        />

        <Route
          path=":cuisine/:recipe/:item/timeStatistics/guestPage"
          element={
            <>
              <GuestPopupBox
                LoadingThemeColor={`${AppColors.process.base_color}`}
                className={'guestInProcess'}
              />
            </>
          }
        />
      </Routes>
    </>
  );
};
const Layout = () => {
  return <Outlet />;
};

export default DemandForecasting;
