import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  showForceGraph: false,
  hoodTimeLIneNavigation: '',
  showVerticalNavigation: '',
  mainData: [],
  parentData: [],
  loopStatus: 'idle',
  finalNodes: [],
  nodesConnectionLink: [],
  convertedNodes: [],
  packCircleNodes: [],
  packCircleLinksConnection: [],
  findRelatedLinks: [],
  forceLayoutNodes: [],
  finalLinkData: [],
  finalNodesData: [],
  totalData: [],
  loadingStatus: 'idle',
};
const recipeAndProcessDataSlice = createSlice({
  name: 'recipeAndProcessState',
  initialState,
  reducers: {
    getGraphMainDataAndStatus: (state, { payload }) => {
      state.mainData = payload.mainData;
      state.parentData = payload.parentData;

      state.loadingStatus = payload.loadingStatus;
      if (payload.loopStatus === 'loopEnd') {
        // console.log(state.mainData);
        state.totalData.push(...state.parentData, ...state.mainData);

        const filterAllClasses = state.totalData
          .filter(ek => ek.objClass !== undefined && ek)
          .map(el => {
            const Data = {
              target: el.obj.value,
              prop: el.prop.value
                .split('/')
                [el.prop.value.split('/').length - 1].split('#')[
                el.prop.value
                  .split('/')
                  [el.prop.value.split('/').length - 1].split('#').length - 1
              ],
              source: el.sub.value,
              objDatatype: el.objClass.value
                .split('/')
                [el.objClass.value.split('/').length - 1].split('#')[
                el.objClass.value
                  .split('/')
                  [el.objClass.value.split('/').length - 1].split('#').length -
                  1
              ],
              subClass: el.subClass.value
                .split('/')
                [el.subClass.value.split('/').length - 1].split('#')[
                el.subClass.value
                  .split('/')
                  [el.subClass.value.split('/').length - 1].split('#').length -
                  1
              ],
              objDef: el.objDef.value,
              subDef: el.subDef.value,
            };
            return Data;
          })
          .filter(
            (ek, i, arr) =>
              i ===
              arr.findIndex(
                t => ek.target === t.target && ek.source === t.source && t
              )
          );
        // console.log(filterAllClasses);

        // ===========================================================================
        const filterLinksFromClassObjects = filterAllClasses
          .map(ek => {
            const Data = {
              source: ek.subClass,
              target: ek.objDatatype,
              linkName: ek.prop,
              // objDef: ek.objDef,
            };
            return Data;
          })
          .filter(
            (el, i, arr) =>
              i ===
              arr.findIndex(
                ek => ek.source === el.source && ek.target === el.target && el
              )
          );
        state.finalLinkData = filterLinksFromClassObjects;

        // console.log(filterLinksFromClassObjects);
        //console.logfilterAllClasses);

        const findClassWise = filterAllClasses.reduce((prev, curr) => {
          const tmp = prev.find(e => e.objDatatype === curr.objDatatype);

          if (tmp) {
            tmp.source.push(curr.source);
            tmp.target.push(curr.target);
          } else {
            prev.push({
              objDatatype: curr.objDatatype,
              source: [curr.source],
              target: [curr.target],
              subClass: curr.subClass,
              prop: curr.prop,
              objDef: curr.objDef,
              subDef: curr.subDef,
            });
          }
          return prev;
        }, []);
        // console.log(findClassWise);

        const findClassWis2 = filterAllClasses.reduce((prev, curr) => {
          const tmp = prev.find(e => e.subClass === curr.subClass);

          if (tmp) {
            tmp.source.push(curr.source);
            tmp.target.push(curr.target);
          } else {
            prev.push({
              subClass: curr.subClass,
              source: [curr.source],
              target: [curr.target],
              objDatatype: curr.objDatatype,
              prop: curr.prop,
              objDef: curr.objDef,
              subDef: curr.subDef,
            });
          }
          return prev;
        }, []);
        // console.log(findClassWis2);
        const cenetrNode = {
          objDatatype: findClassWis2[0].subClass,
          source: [],
          target: [],
          subClass: 'Recipe',
          prop: findClassWis2[0].prop,
          objDef: findClassWis2[0].subDef,
          subDef: findClassWis2[0].subDef,
        };

        state.finalNodesData = [cenetrNode, ...findClassWise];

        // =======================================================================

        // ======================================================================

        state.packCircleNodes = state.finalNodesData.map(ek => {
          const Data = {
            id: ek.objDatatype,
            pairs: ek.source,
            value: ek.source.length,
            link: ek.prop,
            objDef: ek.objDef,
          };
          return Data;
        });

        // =============================================================================

        state.nodesConnectionLink = filterAllClasses.filter(
          (ek, i, arr) => i === arr.findIndex(t => ek.target === t.target && t)
        );

        const filterAllInSideCircleNodes = state.totalData
          .filter(ek => ek.objDatatype !== undefined && ek)
          .map(el => {
            const Data = {
              obj: el.obj.value,
              prop: el.prop.value,
              sub: el.sub.value,
              objDatatype: el.objDatatype.value,
            };
            return Data;
          });

        const output = filterAllInSideCircleNodes.reduce((prev, curr) => {
          const tmp = prev.find(e => e.sub === curr.sub);

          if (tmp) {
            tmp.obj.push(curr.obj);
          } else {
            prev.push({
              sub: curr.sub,
              obj: [curr.obj],
              prop: curr.prop,
            });
          }
          return prev;
        }, []);

        state.finalNodes = output.map((ek, i) => {
          const Data = {
            id: ek.sub,
            pairs: ek.obj,
            value: ek.obj.length,
            link:
              state.nodesConnectionLink[i] === undefined
                ? 'classifiedAs'
                : state.nodesConnectionLink[i].prop,
          };
          return Data;
        });

        const sourceNodes = filterAllClasses.map(el => {
          const Data = {
            id: el.source,
            pairs: [],
            value: 0,
            link: 'classifiedAs',
          };
          return Data;
        });
        const targetNodes = filterAllClasses.map(el => {
          const Data = {
            id: el.target,
            pairs: [],
            value: 0,
            link: 'classifiedAs',
          };
          return Data;
        });
        let totalNodes = [...sourceNodes, ...targetNodes];

        const removeCommonNames = totalNodes.filter(
          (ek, i, arr) => i === arr.findIndex(t => ek.id === t.id && t)
        );

        state.convertedNodes = removeCommonNames.map(item => {
          let found = state.finalNodes.find(i => i.id === item.id);
          return {
            ...item,
            pairs: found ? found.pairs : item.pairs,
            value: found ? found.value : item.value,
            link: found ? found.link : item.link,
          };
        });
        // console.log(totalData);
        //console.logconvertedNodes);

        // ============================ links and nodes for forceLayout ==========
        const findRelatedNodes = state.totalData
          .filter(
            (el, i, arr) =>
              i === arr.findIndex(t => t.obj.value === el.obj.value)
          )
          .map((el, i) => {
            const Data = {
              name: el.obj.value,
              id: i,
            };
            return Data;
          });

        // console.log(findRelatedNodes);
        const forceLayOutSourceNode = state.totalData.map(el => el.sub.value);
        const forceLayOutTargetNode = state.totalData.map(el => el.obj.value);
        const combineSourceAndTargetNode = [
          ...forceLayOutSourceNode,
          ...forceLayOutTargetNode,
        ]
          .filter((ele, i, arr) => i === arr.indexOf(ele))
          .map((el, d) => {
            const Data = {
              name: el,
              id: d,
            };
            return Data;
          });
        // console.log(combineSourceAndTargetNode);

        state.forceLayoutNodes = combineSourceAndTargetNode.map(item => {
          let found = findRelatedNodes.find(i => i.name === item.name);
          return {
            ...item,
            name: found ? found.name : item.name,
            id: found ? found.id : item.id,
          };
        });
        state.findRelatedLinks = state.totalData.map(el => {
          const Data = {
            source: el.sub.value,
            target: el.obj.value,
          };
          return Data;
        });
        state.loopStatus = 'loopEnd';
      }
    },
    clearDataInPackCircleNodes: (state, { payload }) => {
      state.packCircleNodes = payload;
    },
  },
});
export const recipeAndProcessComponentActions =
  recipeAndProcessDataSlice.actions;
export const recipeAndProcessComponentReducers =
  recipeAndProcessDataSlice.reducer;
