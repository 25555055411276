import React from 'react';

/**
 * [Component] InventoryHomeTitle
  
 * @description responsible for displaying title of the homepage

 * @param {string} inventoryTitle accepts string as title

 * @returns title of the page
 */

function InventoryHomeTitle({ inventoryTitle }) {
  return <div className="curry_Configurator_Title">{inventoryTitle}</div>;
}

export default InventoryHomeTitle;
