import Modal from '../../../lib/Modal/Modal';
import React from 'react';
import { dropDownShiffleForChangeInventoryAndProcess } from '../../../assets';
import { Tooltip } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import {
  fullRecipeDetailsActions,
  invProcessStepActions,
  useDispatch,
  useSelector,
} from '../../../Redux';
const ProcessStepWithDropDown = ({
  processName,
  selectClass,
  classForImgRoundContainer,
  stepIcon,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const invProcessBarStepsStore = useSelector(
    state => state.inventoryProcessSteps
  );
  const shiffleBetweenInventoryAndProcess = () => {
    dispatch(fullRecipeDetailsActions.getChangeIngredientTeminologyShow(false));
    dispatch(invProcessStepActions.whileShifflingInvToProcess());
    if (location.pathname.split('/')[1] === 'inventory') {
      navigate(
        `${location.pathname.split('/')[0]}/process/${
          location.pathname.split('/')[2]
        }/${location.pathname.split('/')[3]}/${location.pathname.split('/')[4]}`
      );
    }
    if (location.pathname.split('/')[1] === 'process') {
      navigate(
        `${location.pathname.split('/')[0]}/inventory/${
          location.pathname.split('/')[2]
        }/${location.pathname.split('/')[3]}/${location.pathname.split('/')[4]}`
      );
    }
  };
  const [show, setShow] = React.useState(false);

  const getModelClose = () => {
    setShow(false);
    // setFeedbackAdded(true);
  };
  const getModelIconClose = () => {
    setShow(false);
  };

  const getFeedback = () => {
    setShow(true);
  };
  return (
    <>
      <div className={selectClass}>
        {/* <div> */}
        <div className={classForImgRoundContainer}>{stepIcon}</div>
        {/* </div> */}
        <div className="inventoryProcessCardIdleContent">{processName}</div>
        {invProcessBarStepsStore.stepIconStatus && (
          <Tooltip
            title="Click on the button to switch to a different usecase (for the selected recipe)."
            placement="left-start"
          >
            <button className="dropDownChangeButtonClass">
              <img
                // onClick={shiffleBetweenInventoryAndProcess}
                onClick={getFeedback}
                src={dropDownShiffleForChangeInventoryAndProcess}
                alt="dropDown"
                id="shifflePopUp"
                // className="shiffleIconToggler"
              />
            </button>
          </Tooltip>
        )}
        <Modal
          isdiable={true}
          title=""
          onClose={() => getModelClose()}
          onCloseCancel={() => getModelIconClose()}
          show={show}
          width="15rem"
          height="6rem"
          marginTop="3em"
          isButton={false}
          closeIcon={false}
          color={'red'}
          customClass={'shifleInvToPc'}
          mainClassModal={'shiffleContainer'}
        >
          <div className="ShifleContainer">
            {location.pathname.split('/')[1] === 'process' && (
              <>
                <button className="processShiffleButton">
                  Process Monitoring
                </button>
                <button
                  className="clickableButton InventoryClick"
                  onClick={shiffleBetweenInventoryAndProcess}
                >
                  Ingredient Substitution
                </button>
              </>
            )}
            {location.pathname.split('/')[1] === 'inventory' && (
              <>
                <button className="inventoryShiffleButton">
                  Ingredient Substitution
                </button>
                <button
                  className="clickableButton processClick"
                  onClick={shiffleBetweenInventoryAndProcess}
                >
                  Process Monitoring
                </button>
              </>
            )}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ProcessStepWithDropDown;
