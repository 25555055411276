import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedIngredients: [],
  saperatedData: [],
  cascadingStatus: 'idle',
  activeCategoriesButton: null,
  clickCategory: [],
  LoadingColor: '',
  categoriesButtonActive: null,
  cascadingLoadStatus: 'idle',
  selectedCategoryTitle: '',
  notifaction: false,
  mixedIngredients: [],
  invNotifaction: false,
  totalSelectResult: [],
  hidetopInput: true,
  ingredientTitle: ``,
};

const cascadingScreenSlice = createSlice({
  name: 'cascadingStateManagement',
  initialState,
  reducers: {
    clearSelectedIngredients: state => {
      state.selectedIngredients = [];
    },
    getElementFromIngredientSelect: (state, { payload }) => {
      // console.log(payload);
      if (!state.selectedIngredients.includes(payload.element)) {
        state.cascadingLoadStatus = 'Loading';
        state.selectedIngredients = [
          ...state.selectedIngredients,
          payload.element,
        ];
        const func = () => {
          return payload.otherDispatch;
        };
        func();
        state.clickCategory = [];
        state.activeCategoriesButton = '';
        state.selectedCategoryTitle = '';
      } else {
        state.selectedIngredients = [...state.selectedIngredients];
      }
    },
    removeSelectedIngredients: (state, { payload }) => {
      state.cascadingLoadStatus = 'Loading';
      const remainingItemsFromSelectedItems = state.selectedIngredients.filter(
        r => r !== payload
      );

      state.selectedIngredients = [...remainingItemsFromSelectedItems];
      state.activeCategoriesButton = '';
      state.selectedCategoryTitle = '';
      state.clickCategory = [];
    },
    getSelectFromOverAllInputSearch: (state, { payload }) => {
      const data = state.selectedIngredients.filter(
        el => el !== payload.element
      );
      // console.log(payload);
      state.cascadingLoadStatus = 'Loading';
      if (state.clickCategory.includes(payload.element)) {
        state.selectedIngredients = [...data, payload.element];
        // console.log(state.selectedIngredients);
        const func = () => {
          return payload.otherDispatch;
        };
        func();
        state.activeCategoriesButton = '';
        state.clickCategory = [];
        state.notifaction = false;
      }
    },
    getIngredientDetailsClickFromCategories: (state, { payload }) => {
      state.selectedCategoryTitle = payload.element.searchfilter;
      state.clickCategory = [];
      state.clickCategory = payload.element.ingredient_label;
      state.activeCategoriesButton = payload.index;
    },
    avoidLateLoadingWhileSelectingIngredient: (state, { payload }) => {
      state.cascadingLoadStatus = payload;
    },
    getSelectIngredientFromRightSideSearch: (state, { payload }) => {
      const data = state.selectedIngredients.filter(
        el => el !== payload.element
      );
      if (payload.mixedIngredients.includes(payload.element)) {
        state.selectedIngredients = [...data, payload.element];

        const hey = () => {
          return payload.otherDispatch;
        };
        hey();

        state.activeCategoriesButton = '';
        state.clickCategory = [];
        state.invNotifaction = false;
      }
    },
    setFalseInvNotification: state => {
      state.invNotifaction = false;
    },
    setTrueInvNotification: state => {
      state.invNotifaction = true;
    },
    setFalseNotification: state => {
      state.notifaction = false;
    },
    setTrueNotification: state => {
      state.notifaction = true;
    },
    getConvertQueryKey: state => {
      state.totalSelectResult = state.selectedIngredients.map(el => {
        let i = 0,
          strLength = el.length;
        for (i; i < strLength; i++) {
          const str = el.replaceAll(' ', '-');
          return `<eg:ing-${str.toLowerCase()}>`;
        }
      });
    },
    getHideTopInput: state => {
      state.hidetopInput = true;
    },
    getShowTopInput: state => {
      state.hidetopInput = false;
    },
    changeIngredientTitle: (state, { payload }) => {
      state.ingredientTitle = payload;
    },
  },
});

export const cascadingScreenActions = cascadingScreenSlice.actions;
export const cascadingScreenReducer = cascadingScreenSlice.reducer;
