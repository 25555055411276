import React from 'react';
import Loader from 'react-js-loader';

/**
 * [Component] Loading
 * @description responsible for loading animation while fetching the data from api.
 
 * @param {*} color accepts the color.
 
 * @returns loading animation 
 */

const Loading = ({ color }) => {
  return (
    <div
      className={'row'}
      style={{
        height: '40vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className={'item'}>
        <Loader type="bubble-scale" bgColor={color} color="#f7962c" size={50} />
      </div>
    </div>
  );
};

export default Loading;
