import React from 'react';
import { RiArrowRightSLine } from '../../../icon';
import '../../../style/UnderTheHood.css';
import { useLocation, useNavigate } from 'react-router';
import {
  invProcessStepActions,
  cascadingScreenActions,
  useDispatch,
  useSelector,
  fullRecipeDetailsActions,
} from '../../../Redux';
import { setDatainLocalMemory } from '../../../utils';
import { auth } from '../../../firebase';

/**
 * [Component] Ingredient Render Container Title
 * @description Displays the card recipe name. 
 
 * @param {String} data Set the recipe name on function call. 
 
 * @returns the title of selected recipe.
*/

const IngredientRenderContainerTitle = ({ color, recipeFetchedStatus }) => {
  // console.log(substituteStatus);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const substiteGraphStore = useSelector(state => state.substituteGraphStore);
  const fullRecipeDetailsStore = useSelector(
    state => state.fullRecipeDetailsStore
  );
  // fullRecipeDetailsStore.fullRecipeDetails;
  // console.log(fullRecipeDetailsStore);
  const getBAckWithBreadScrum = () => {
    dispatch(fullRecipeDetailsActions.setSubstitutionStatus(false));
    dispatch(
      cascadingScreenActions.changeIngredientTitle(
        'Here are the recipes based on the ingredients you’ve selected. Click on the card for recipe instructions and ingredient substitution:'
      )
    );

    dispatch(invProcessStepActions.setStepIcon(false));

    dispatch(invProcessStepActions.breadScrumClick());
    navigate(
      `${location.pathname.split('/')[0]}/${location.pathname.split('/')[1]}/${
        location.pathname.split('/')[2]
      }/${location.pathname.split('/')[3]}`
    );
  };
  const sandBoxLogin = window._env_.REACT_APP_SANDBOX;

  const underTheHoodButtonClick = e => {
    dispatch(fullRecipeDetailsActions.getChangeIngredientTeminologyShow(false));
    e.preventDefault();
    if (auth?.currentUser || sandBoxLogin === 'true') {
      navigate(`uth`);
    } else {
      navigate(`timeStatistics/guestPage`);
    }

    setDatainLocalMemory(
      'url',
      `${location.pathname.split('/')[0]}/${location.pathname.split('/')[1]}/${
        location.pathname.split('/')[2]
      }/${location.pathname.split('/')[3]}/${
        location.pathname.split('/')[4]
      }/uth`
    );
  };
  return (
    <div className="breadcrumAndUnderTheHoodFlex">
      <div className="ingredientRenderContainerTitleContainer healthTimeIngredient">
        <div className="ingredientRenderContainerTitleContainerTitleName">
          <b className="breadScrum" onClick={getBAckWithBreadScrum}>
            {' '}
            Recipe catalogue{' '}
          </b>
        </div>
        <div style={{ padding: '0.20rem', fontSize: '1em' }}>
          <RiArrowRightSLine color={color} />
        </div>
        <div className="ingredientRenderContainerTitleContainerTitleValue">
          <b> {fullRecipeDetailsStore?.fullRecipeDetails?.rows[0]?.Recipes}</b>
        </div>
      </div>
      <button
        disabled={
          substiteGraphStore.substituteStatus.length === 0 ? true : false
        }
        style={{
          pointerEvents: recipeFetchedStatus !== 'success' ? 'none' : 'auto',
          opacity: substiteGraphStore.substituteStatus.length === 0 ? 0.5 : 1,
        }}
        onClick={underTheHoodButtonClick}
        className={
          location.pathname.split('/')[1] === 'inventory'
            ? 'underTheHoodInInventory'
            : 'underTheHood'
        }
      >
        Under The Hood
      </button>
    </div>
  );
};

export default IngredientRenderContainerTitle;
