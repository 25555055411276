import React from 'react';
import { useDispatch } from 'react-redux';
import { authAction } from '../../../Redux/Slices/AuthSlides/AuthenticationStates';
import './RadioButton.css';

const RadioButton = () => {
  const dispatch = useDispatch();
  const [toggleButtonValueIsBelongTo, settoggleButtonValueIsBelongTo] =
    React.useState(true);
  const [toggleButtonValueIsNotBelongTo, settoggleButtonValueIsNotBelongTo] =
    React.useState(false);

  const getCheckisBelongsTo = () => {
    settoggleButtonValueIsBelongTo(true);
    settoggleButtonValueIsNotBelongTo(false);

    dispatch(
      authAction.getCheckBelongingsTo({ partner: 'yes', belongsTo: true })
    );

    // setUserPersonalDetalis(p => {
    //   return {
    //     companyName: p.companyName,
    //     partner: 'yes',
    //     domine: p.domine,
    //     phoneNumber: p.phoneNumber,
    //     group: p.group,
    //     agreePolicy: p.agreePolicy,
    //   };
    // });
    // setUserpersonalInfo(p => {
    //   return {
    //     userName: p.userName,
    //     companyName: p.companyName,
    //     phoneNumber: p.phoneNumber,
    //     domine: p.domine,
    //     belongsTo: true,
    //     agreePolicy: p.agreePolicy,
    //   };
    // });
  };

  const getCheckisNotBelongsTo = () => {
    settoggleButtonValueIsBelongTo(false);
    settoggleButtonValueIsNotBelongTo(true);
    dispatch(
      authAction.getUnCheckBelongingsTo({ partner: 'no', belongsTo: false })
    );

    // setUserPersonalDetalis(p => {
    //   return {
    //     companyName: p.companyName,
    //     partner: 'no',
    //     domine: p.domine,
    //     phoneNumber: p.phoneNumber,
    //     group: p.group,
    //     agreePolicy: p.agreePolicy,
    //   };
    // });
    // setUserpersonalInfo(p => {
    //   return {
    //     userName: p.userName,
    //     companyName: p.companyName,
    //     phoneNumber: p.phoneNumber,
    //     domine: p.domine,
    //     belongsTo: false,
    //     agreePolicy: p.agreePolicy,
    //   };
    // });
  };
  return (
    <div className="example">
      <label className="radio-button">
        <input
          type="radio"
          className="radio-button__input"
          id="choice1-1"
          name="choice1"
          onChange={getCheckisBelongsTo}
          checked={toggleButtonValueIsBelongTo}
        />
        <span className="radio-button__control"></span>
        <span className="radio-button__label">Yes</span>
      </label>
      <label className="radio-button">
        <input
          type="radio"
          className="radio-button__input"
          id="choice1-2"
          name="choice1"
          onChange={getCheckisNotBelongsTo}
          checked={toggleButtonValueIsNotBelongTo}
        />
        <span className="radio-button__control"></span>
        <span className="radio-button__label">No</span>
      </label>
    </div>
  );
};

export default RadioButton;
