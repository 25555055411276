import React from 'react';
import './AuthSocialMediaIcon.css';

const AuthSocialMediaIcon = ({ icon, text, color }) => {
  return (
    <div
      className="AuthSocialMediaIconContainer"
      style={{ backgroundColor: color }}
    >
      <div>{icon}</div>
      <div className="AuthSocialMediaText">{text}</div>
    </div>
  );
};

export default AuthSocialMediaIcon;
