import React from 'react';
import './AuthInput.css';

const AuthInput = ({
  value,
  placeHolder,
  onChange,
  type,
  onFocus,
  onBlur,
  required,
}) => {
  return (
    <div className="group">
      <input
        value={value}
        type={type}
        required={required}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        className="inputStatusWithoutError"
      />
      <span className="inputhighlight"></span>
      <span className="Inputbar"></span>
      <label className="inputWithLabel">{placeHolder}</label>
    </div>
  );
};

export default AuthInput;
