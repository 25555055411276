import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useLocation } from 'react-router';
import { setDatainLocalMemory } from '../../utils/ManageLocalStorage';

const ProtectedRoutes = ({ isAllowed, defaultPath = '/login', children }) => {
  const location = useLocation();
  setDatainLocalMemory('url', location);
  if (!isAllowed) {
    return <Navigate to={defaultPath} replace />;
  }

  return <>{!children ? <Outlet /> : children}</>;
};

export default ProtectedRoutes;
