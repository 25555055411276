import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ToolTipInfo } from '../../lib';
import { statsSliceActions, useDispatch, useSelector } from '../../Redux';
import { Loading } from '../../Component/index';
import { ProcessCheckButtonComponent, StatisticsAnalysisComponent } from '../';
import './ProcessStats.css';

const ProcessMoniteringStats = ({
  LoadingThemeColor,
  StatsSubstitutionStatus,
  statsProcessStatus,
  StatsSubstitutionData,
  statsProcessData,
  setshowGraphTitleInBanner,
}) => {
  const statsPageStore = useSelector(state => state.statsPageStore);

  // console.log(statsPageStore);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const getSubstituteChange = () => {
    dispatch(statsSliceActions.getSubstituteChange());
  };
  const getProcessChange = () => {
    dispatch(statsSliceActions.getProcessChange());
  };

  //console.logshowSubstitutionStatus);
  const substituteReuseNavigation = () => {
    navigate(
      // eslint-disable-next-line no-restricted-globals
      `${location.pathname.split('/')[0]}/inventory/${
        // eslint-disable-next-line no-restricted-globals
        location.pathname.split('/')[2]
        // eslint-disable-next-line no-restricted-globals
      }/${location.pathname.split('/')[3]}/${location.pathname.split('/')[4]}/${
        // eslint-disable-next-line no-restricted-globals
        location.pathname.split('/')[5]
      }/smartSubstitution`
    );
    setshowGraphTitleInBanner('Smart Substitution');
  };
  const ProcessReuseNavigation = () => {
    navigate(
      // eslint-disable-next-line no-restricted-globals
      `${location.pathname.split('/')[0]}/process/${
        location.pathname.split('/')[2]
      }/${location.pathname.split('/')[3]}/${location.pathname.split('/')[4]}/${
        location.pathname.split('/')[5]
      }/processMonitoring`
    );
    setshowGraphTitleInBanner('Process Monitoring');
  };

  return (
    <>
      {StatsSubstitutionStatus !== 'success' &&
        statsProcessStatus !== 'success' && (
          <div className={`loadingGettingGraph homeStatsLoading`}>
            <Loading color={LoadingThemeColor} />
          </div>
        )}
      {StatsSubstitutionStatus === 'success' &&
        statsProcessStatus === 'success' && (
          <div
            className={`${'statsContainer'} ${
              location.pathname.split('/')[1] === 'stats' &&
              'statsFromInventory'
            }`}
          >
            {location.pathname.split('/')[1] !== 'stats' && (
              <div className="titleAndButtonFlexInStats">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div className="statsMainTitle">Knowledge Statistics</div>
                  <ToolTipInfo title="You can reuse the knowledge you’ve acquired so far to resolve any new use cases! Keep on adding new use cases to make the knowledge base richer! Because, the more use cases you add, the more future-ready you get!" />
                  {/* <button className="flexButtoninStats">
                
              Back to Vocabulary graph
            </button> */}
                </div>
              </div>
            )}
            <p className="paraInStats">
              Select the use-case / usecases for which you want to view the
              statistics:
            </p>
            <div className="checkButtonsFlex">
              <ProcessCheckButtonComponent
                title="Smart substitution"
                checkBoxStatus={statsPageStore.substitutionCheck}
                getChange={getSubstituteChange}
                getClick={substituteReuseNavigation}
                tolTiptitle="Find substitutes for the selected ingredients in a recipe, based on its texture, flavour and class."
                classNameForBorder={
                  statsPageStore.substitutionCheck
                    ? 'useCaseAndTitleFlex borderForCheckState'
                    : 'useCaseAndTitleFlex'
                }
              />
              <ProcessCheckButtonComponent
                title="Process monitoring"
                checkBoxStatus={statsPageStore.processCheck}
                getChange={getProcessChange}
                getClick={ProcessReuseNavigation}
                tolTiptitle="Tweak the cooking conditions and check what the outcome is going to be."
                classNameForBorder={
                  statsPageStore.processCheck
                    ? 'useCaseAndTitleFlex borderForCheckState'
                    : 'useCaseAndTitleFlex'
                }
              />
            </div>

            <StatisticsAnalysisComponent
              StatsSubstitutionData={StatsSubstitutionData}
              statsProcessData={statsProcessData}
            />
          </div>
        )}
    </>
  );
};

export default ProcessMoniteringStats;
