import React from 'react';

/**
 * [Component] ProcessSteps
 
 *@description Displays the inventory process card

 * @param {string} processName accepts string as the container text.
 * @param {*} svg accepts svg
 * @param {*} proceedTonextPage changes state on function call
 * @param {*} selectClass Changes Css Classname
 * @param {*} classForImgRoundContainer Changes Css Classname
 * @param {*} stepIcon On function call changes icon state
 * @param {*} processClick calls function to navigate to previous state
 
 * @returns Changes the state of card on function call
 */

function ProcessSteps({
  processName,
  selectClass,
  classForImgRoundContainer,
  stepIcon,
  processClick,
}) {
  return (
    <div className={selectClass} onClick={processClick}>
      {/* <div> */}
      <div className={classForImgRoundContainer}>{stepIcon}</div>
      {/* </div> */}
      <div className="inventoryProcessCardIdleContent">{processName}</div>
      {/* <img src={substituteIngredientLogoInStepBar} alt="dropDown" /> */}
    </div>
  );
}

export default ProcessSteps;
