import React from 'react';
import { useSelector } from 'react-redux';
import { ToolTipInfo } from '..';

/**
 * [Component] Paragraph
 
 * @description displays the description of Paragraph

 * @param {string} value accepts string as the paragraph description
 
 * @returns changes description state on function call
 */

function Paragraph() {
  const cascadingScreenStore = useSelector(state => state.cascadingStore);
  return (
    <div className="paragraphText">
      {cascadingScreenStore.ingredientTitle ===
      'Select your preferred cuisine:' ? (
        <>
          {cascadingScreenStore.ingredientTitle}
          <ToolTipInfo
            title="You can select one, many or all cuisines, according to which the recipes will be shown in the next step. Click on the next button to go to the next step."
            placement="bottom"
          />
        </>
      ) : cascadingScreenStore.ingredientTitle ===
        'Here are the recipes based on the ingredients you’ve selected. Click on the card for recipe instructions and ingredient substitution:' ? (
        <>
          {cascadingScreenStore.ingredientTitle}
          <ToolTipInfo
            title="Given below, each recipe card shows the properties of that particular recipe, which comes from the knowledge graph itself."
            placement="left"
          />
        </>
      ) : (
        cascadingScreenStore.ingredientTitle
      )}
    </div>
  );
}

export default Paragraph;
