export const VocabZoomMediaQuery = value => {
  switch (true) {
    case value < 500:
      return {
        Vscale: 0.06,
        Cscale: 0.23,
        VX_: 150,
        VY_: 90,
        CX_: 150,
        CY_: 90,
        SubScalse: 0.4,
        PMScale: 0.4,
      };
    case value < 600:
      return {
        Vscale: 0.08,
        Cscale: 0.25,
        VX_: 120,
        VY_: 90,
        CX_: 170,
        CY_: 90,
        SubScalse: 0.473,
        PMScale: 0.476,
      };
    case value < 700:
      return {
        Vscale: 0.1,
        Cscale: 0.35,
        VX_: 170,
        VY_: 110,
        CX_: 180,
        CY_: 110,
        SubScalse: 0.55,
        PMScale: 0.55,
      };
    case value < 800:
      return {
        Vscale: 0.1,
        Cscale: 0.35,
        VX_: 180,
        VY_: 120,
        CX_: 190,
        CY_: 120,
        SubScalse: 0.55,
        PMScale: 0.56,
      };
    case value < 900:
      return {
        Vscale: 0.12,
        Cscale: 0.38,
        VX_: 200,
        VY_: 134,
        CX_: 220,
        CY_: 134,
        SubScalse: 0.64444,
        PMScale: 0.6444,
      };
    case value < 1000:
      return {
        Vscale: 0.13,
        Cscale: 0.38,
        VX_: 200,
        VY_: 150,
        CX_: 250,
        CY_: 145,
        SubScalse: 0.733,
        PMScale: 0.733,
      }; // CX = 240 , 145
    case value < 1100:
      return {
        Vscale: 0.15,
        Cscale: 0.44,
        VX_: 200,
        VY_: 160,
        CX_: 240,
        CY_: 145,
        SubScalse: 0.7666,
        PMScale: 0.7666,
      }; // CX = 230 , 145
    case value < 1200:
      return {
        Vscale: 0.16,
        Cscale: 0.46,
        VX_: 200,
        VY_: 160,
        CX_: 240,
        CY_: 145,
        SubScalse: 0.7999,
        PMScale: 0.7999,
      }; // CX = 230 , 145
    case value < 1300:
      return {
        Vscale: 0.17,
        Cscale: 0.48,
        VX_: 200,
        VY_: 170,
        CX_: 250,
        CY_: 170,
        SubScalse: 0.911,
        PMScale: 0.911,
      }; // 170 / CX= 230
    case value < 1400:
      return {
        Vscale: 0.2,
        Cscale: 0.4,
        VX_: 200,
        VY_: 160,
        CX_: 200,
        CY_: 160,
        SubScalse: 1.001,
        PMScale: 1.001,
      };
    case value < 1500:
      return {
        Vscale: 0.2,
        Cscale: 0.44,
        VX_: 200,
        VY_: 160,
        CX_: 200,
        CY_: 160,
        SubScalse: 1.111,
        PMScale: 1.111,
      };
    case value < 1600:
      return {
        Vscale: 0.2,
        Cscale: 0.41,
        VX_: 200,
        VY_: 160,
        CX_: 200,
        CY_: 160,
        SubScalse: 1.222,
        PMScale: 1.222,
      };
    case value < 1700:
      return {
        Vscale: 0.24,
        Cscale: 0.8,
        VX_: 200,
        VY_: 160,
        CX_: 210,
        CY_: 170,
        SubScalse: 1.3333,
        PMScale: 1.3333,
      }; // 170 / CX= 210
    case value < 1800:
      return {
        Vscale: 0.25,
        Cscale: 0.59,
        VX_: 200,
        VY_: 160,
        CX_: 200,
        CY_: 160,
        SubScalse: 1.444,
        PMScale: 1.444,
      };
    case value < 1900:
      return {
        Vscale: 0.26,
        Cscale: 0.45,
        VX_: 200,
        VY_: 160,
        CX_: 200,
        CY_: 160,
        SubScalse: 1.555,
        PMScale: 1.555,
      };
    case value < 2600:
      return {
        Vscale: 0.36,
        Cscale: 0.95,
        VX_: 200,
        VY_: 180,
        CX_: 160,
        CY_: 156,
        SubScalse: 1.999,
        PMScale: 2,
      }; // 180 / CY = 160
    case value < 3600:
      return {
        Vscale: 0.45,
        Cscale: 1,
        VX_: 190,
        VY_: 160,
        CX_: 20,
        CY_: 0,
        SubScalse: 2.444,
        PMScale: 2.444,
      }; // x= 190 / 170 / 145
    case value < 3800:
      return {
        Vscale: 0.5,
        Cscale: 0.5,
        VX_: 200,
        VY_: 180,
        CX_: 200,
        CY_: 180,
        SubScalse: 2,
        PMScale: 2,
      };
    case value < 4000:
      return {
        Vscale: 0.5,
        Cscale: 0.5,
        VX_: 200,
        VY_: 180,
        CX_: 200,
        CY_: 180,
        SubScalse: 2.2,
        PMScale: 2.2,
      };
    case value < 4700:
      return {
        Vscale: 0.6,
        Cscale: 0.6,
        VX_: 200,
        VY_: 180,
        CX_: 200,
        CY_: 180,
        SubScalse: 2.5,
        PMScale: 2.5,
      };
    case value < 5300:
      return {
        Vscale: 0.64,
        Cscale: 0.64,
        VX_: 200,
        VY_: 180,
        CX_: 200,
        CY_: 180,
        SubScalse: 2.5,
        PMScale: 2.5,
      };
    case value < 6000:
      return {
        Vscale: 0.7,
        Cscale: 0.7,
        VX_: 200,
        VY_: 180,
        CX_: 200,
        CY_: 180,
        SubScalse: 2.7,
        PMScale: 2.6,
      };
    case value < 7000:
      return {
        Vscale: 0.74,
        Cscale: 0.74,
        VX_: 200,
        VY_: 180,
        CX_: 200,
        CY_: 180,
        SubScalse: 2.8,
        PMScale: 2.8,
      };
    case value < 7500:
      return {
        Vscale: 0.78,
        Cscale: 0.78,
        VX_: 200,
        VY_: 180,
        CX_: 200,
        CY_: 180,
        SubScalse: 2.88,
        PMScale: 2.88,
      };

    default:
      return {
        Vscale: 0.78,
        Cscale: 0.78,
        VX_: 200,
        VY_: 180,
        CX_: 200,
        CY_: 180,
        SubScalse: 2.7,
        PMScale: 3,
      };
  }
};

export const convertRemToPixels = rem => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};
