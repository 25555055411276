import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  vocabularyContentStateHandler: 'introduction', //
  selectedNode: '',
  targetNodes: [],
  condition: false,
  selectedNodeDiscription: '',
  sourceNodes: [],
  vocabInfo: '',
  findDepth: null, //
  initialVocabularyContent: '', //
  vocabUrl: '<http://purl.org/heals/>', //
  arrTargetNodes: [],
  arrSourceNodes: [],
  nodeDiscription: '',
  antologyName: '',
};
const vocabularyComponentSlice = createSlice({
  name: 'vocabularyComponentState',
  initialState,
  reducers: {
    setVicabUri: (state, { payload }) => {
      state.vocabUrl = payload;
    },
    setVocabularyContentStateHandler: (state, { payload }) => {
      state.vocabularyContentStateHandler = payload;
    },
    setFindDepth: (state, { payload }) => {
      state.findDepth = payload;
    },
    setInitialVocabularyContent: (state, { payload }) => {
      state.initialVocabularyContent = payload;
    },
    getFindTatgetedNodes: (state, { payload }) => {
      payload.link_data.filter(el => {
        if (payload.ref.id === el.target.data.id) {
          state.targetNodes.push(el);

          state.selectedNode = payload.ref.name;
          state.nodeDiscription =
            state.selectedNodeDiscription === ''
              ? el.targetDiscription
              : state.nodeDiscription;
        }
      });
    },
    getFindSourceNodes: (state, { payload }) => {
      payload.link_data.filter(el => {
        if (payload.ref.id === el.source.data.id) {
          state.sourceNodes.push(el);

          state.selectedNode = payload.ref.name;
          state.nodeDiscription =
            state.selectedNodeDiscription === ''
              ? el.sourceDiscription
              : state.nodeDiscription;
        }
      });
    },
    setAntologyName: (state, { payload }) => {
      state.antologyName = payload;
    },
  },
});
export const vocabularyComponentActions = vocabularyComponentSlice.actions;
export const vocabularyComponentReducers = vocabularyComponentSlice.reducer;
