import React from 'react';
import ProgressWithText from './ProgressWithText';
import './CombineProgress.css';

const CombineProgress = ({ texture, flavor, classValue, textValue }) => {
  return (
    <div className="progressBar">
      <div className="MainBarContainer-container">
        <div className="MainBarContainer">
          <ProgressWithText color="#35b2ff" value={texture} />
          <ProgressWithText color="#c51162" value={flavor} />
          <ProgressWithText
            color={textValue === 'average' ? '#64dd17' : '#1bc5ae'}
            value={classValue}
          />
        </div>
      </div>
      {/* <div className="progressBarborderInProcessChallenge"></div> */}
    </div>
  );
};

export default CombineProgress;
