/* eslint-disable no-undef */
import React, { memo } from 'react';
import {
  useFetch,
  useGraphDataFetch,
  useSubstituteGraphFetch,
  useGraphFetch,
} from '../../../utils/index';
import { Loading, ProcessMoniteringStats } from '../../index';
import {
  SubstitutionGraphComponent,
  RawDataPageInProcessChallenge,
  RecipeGraphsComponent,
  VocabularyComponent,
  ProcessMoniteringComponent,
  RawDataPageInUnderTheHood,
  UnderTheHoodRightSideFixedContainer,
} from '../index';

import { Outlet, Route, Routes, useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';

const UnderTheHoodMiddleGraphContainer = ({
  LoadingThemeColor,
  oData,
  subnodes,
  subLink,
  data,
  setData,
  nodeClickStatus,
  setNodeClickStatus,
  subKey,
  setSubKey,
  isGraphUpdate,
  setIsGraphUpdate,
  setshowGraphTitleInBanner,
  nodesFromApi,
  links,
  ProcessGraphData,
  subGraphTitleValue,
  setSubGraphTitleValue,
  substituteStatusData,
}) => {
  const location = useLocation();

  const params = useParams();

  const rawAndProcessStorefromStore = useSelector(s => s.rawAndProcessStore);
  const dataRef = React.useRef({ bool: false });
  const [index, setIndex] = React.useState('');
  const subOptionGraphData_Querry_Id_First_End =
    process.env
      .REACT_APP_SMART_SUBSTITUTION_SUBSTITUTE_OPTIONS_QUERY_ID_POINT_ONE;
  const subOptionGraphData_Querry_Id_Last_End =
    process.env
      .REACT_APP_SMART_SUBSTITUTION_SUBSTITUTE_OPTIONS_QUERY_ID_POINT_TWO;

  // eslint-disable-next-line no-unused-vars
  const [substituteOptionStatus, subOptionGraphdata, length] =
    useSubstituteGraphFetch(
      `${subOptionGraphData_Querry_Id_First_End}${
        subKey === '' ? `<>` : subKey
      }${subOptionGraphData_Querry_Id_Last_End}`
    );

  const subGraphdata_Api_First_End =
    process.env.REACT_APP_SMART_DATA_GRAPH_FIRST_API_CALL_FIRST_END;
  const subGraphdata_Api_Last_End =
    process.env.REACT_APP_SMART_DATA_GRAPH_FIRST_API_CALL_LAST_END;
  const [substituteStatus, subGraphdata] = useGraphFetch(
    `${subGraphdata_Api_First_End}${
      subKey === '' ? `<>` : subKey
    }${subGraphdata_Api_Last_End}`
  );

  // console.warn(`number of nodes ${subGraphDatalength}`);

  const [selectedSubstituteNodes, setSelectedSubstituteNodes] = React.useState(
    []
  );

  const [changeSave, setChangeSave] = React.useState(false);
  const [deleteNodes, setDeleteNodes] = React.useState('');
  // let length = subOptionGraphdata.length;

  const [checkedState, setCheckedState] = React.useState(
    new Array(length).fill(false)
  );
  const [checkedBoxState, setCheckeBoxdState] = React.useState(
    new Array(length).fill(false)
  );
  const [subGraphTitle, setSubGraphTitle] = React.useState('Recipe');

  const [substituteInfo, setSubstituteInfo] = React.useState(
    'Ingredient substitution'
  );

  // ==================================================================================

  const _stats_smart_substitution_classes_QueryId_first_Id =
    // eslint-disable-next-line no-undef
    process.env
      .REACT_APP_STATS_SMART_SUBSTITUTE_CLASSES_INFO_QUERY_ID_POINT_ONE;

  const _stats_smart_substitution_classes_QueryId_second_Id =
    // eslint-disable-next-line no-undef
    process.env
      .REACT_APP_STATS_SMART_SUBSTITUTE_CLASSES_INFO_QUERY_ID_POINT_TWO;

  const _stats_process_challange_classes_QueryId_first_Id =
    // eslint-disable-next-line no-undef
    process.env
      .REACT_APP_STATS_PROCESS_MONITORING_CLASSES_INFO_QUERY_ID_POINT_ONE;

  const _stats_process_challange_classes_QueryId_second_Id =
    // eslint-disable-next-line no-undef
    process.env
      .REACT_APP_STATS_PROCESS_MONITORING_CLASSES_INFO_QUERY_ID_POINT_TWO;

  const statusSunstituteKey =
    '<http://vocab.curryconfigurator.org/smartsubstitution/>';
  const statusProcessKey =
    '<http://vocab.curryconfigurator.org/processmonitoring/>';
  const [StatsSubstitutionStatus, StatsSubstitutionData] = useGraphDataFetch(
    `${_stats_smart_substitution_classes_QueryId_first_Id}${
      statusSunstituteKey === '' ? `<>` : statusSunstituteKey
    } WHERE {  BIND (${
      statusSunstituteKey === '' ? `<>` : statusSunstituteKey
    }${_stats_smart_substitution_classes_QueryId_second_Id}`
  );
  const [statsProcessStatus, statsProcessData] = useGraphDataFetch(
    `${_stats_process_challange_classes_QueryId_first_Id}${
      statusProcessKey === '' ? `<>` : statusProcessKey
    } WHERE {  BIND (${
      statusProcessKey === '' ? `<>` : statusProcessKey
    }${_stats_process_challange_classes_QueryId_second_Id}`
  );
  const Ingredient_substitute_Query_First_Point =
    // eslint-disable-next-line no-undef
    process.env
      .REACT_APP_RECIPE_FULL_INFORMATION_AFTER_CLICKING_RECIPE_CARD_QUERY_ID_POINT_ONE;
  const Ingredient_substitute_Query_Second_Point =
    // eslint-disable-next-line no-undef
    process.env
      .REACT_APP_RECIPE_FULL_INFORMATION_AFTER_CLICKING_RECIPE_CARD_QUERY_ID_POINT_TWO;
  // const SelectedRecipeDetails = preferenceStore.selectedRecipeData.iri;
  const SelectedRecipeDetails = params.item
    .replaceAll('.$', '<')
    .replaceAll('$', '>');

  const [recipeFetchedStatus, fullRecipeDetails] = useFetch(
    `${Ingredient_substitute_Query_First_Point}${
      SelectedRecipeDetails === undefined ? `<>` : SelectedRecipeDetails
    }${Ingredient_substitute_Query_Second_Point}`
  );

  const rawAndProcessStore =
    rawAndProcessStorefromStore?.rawDataPageData.length === 0
      ? recipeFetchedStatus === 'success'
        ? { rawDataPageData: fullRecipeDetails }
        : []
      : rawAndProcessStorefromStore;

  return (
    <div
      className={`middleGraphContainer ${
        location.pathname.split('/')[1] === 'process' &&
        'processGraphContainers'
      }`}
    >
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            index
            // path="/"
            element={
              <>
                {location.pathname.split('/')[1] === 'inventory' && (
                  <>
                    {rawAndProcessStore.length === 0 && (
                      <Loading color={LoadingThemeColor} />
                    )}
                    {rawAndProcessStore.length !== 0 && (
                      <div className="rawDataContainer">
                        <RawDataPageInUnderTheHood
                          rawAndProcessStore={rawAndProcessStore}
                        />
                      </div>
                    )}
                  </>
                )}

                {location.pathname.split('/')[1] === 'process' && (
                  <>
                    {rawAndProcessStorefromStore.processDataStatus !==
                      'success' && <Loading color={LoadingThemeColor} />}
                    {rawAndProcessStorefromStore.processDataStatus ===
                      'success' && (
                      <RawDataPageInProcessChallenge
                        LoadingThemeColor={LoadingThemeColor}
                      />
                    )}
                  </>
                )}
              </>
            }
          />
          <Route
            path="rawData"
            element={
              <>
                {location.pathname.split('/')[1] === 'inventory' && (
                  <>
                    {rawAndProcessStore.length === 0 && (
                      <Loading color={LoadingThemeColor} />
                    )}
                    {rawAndProcessStore.length !== 0 && (
                      <div className="rawDataContainer">
                        <RawDataPageInUnderTheHood
                          rawAndProcessStore={rawAndProcessStore}
                        />
                      </div>
                    )}
                  </>
                )}

                {location.pathname.split('/')[1] === 'process' && (
                  <>
                    {rawAndProcessStorefromStore.processDataStatus !==
                      'success' && <Loading color={LoadingThemeColor} />}
                    {rawAndProcessStorefromStore.processDataStatus ===
                      'success' && (
                      <RawDataPageInProcessChallenge
                        LoadingThemeColor={LoadingThemeColor}
                      />
                    )}
                  </>
                )}
              </>
            }
          />
          <Route
            path="smartSubstitution"
            element={
              <div className="smartSubstituteContainer">
                <div className="smartSubstitutionGraphContainer">
                  <SubstitutionGraphComponent
                    data={data}
                    setData={setData}
                    setSubKey={setSubKey}
                    subnodes={subnodes}
                    subLink={subLink}
                    index={index}
                    subGraphdata={subGraphdata}
                    substituteStatus={substituteStatus}
                    setIndex={setIndex}
                    setNodeClickStatus={setNodeClickStatus}
                    defaultData={oData}
                    selectedSubstituteNodes={selectedSubstituteNodes}
                    changeSave={changeSave}
                    setChangeSave={setChangeSave}
                    nodeClickStatus={nodeClickStatus}
                    deleteNodes={deleteNodes}
                    setDeleteNodes={setDeleteNodes}
                    setCheckedState={setCheckedState}
                    setCheckeBoxdState={setCheckeBoxdState}
                    length={length}
                    setSubGraphTitle={setSubGraphTitle}
                    setSubGraphTitleValue={setSubGraphTitleValue}
                    setSubstituteInfo={setSubstituteInfo}
                    setIsGraphUpdate={setIsGraphUpdate}
                    LoadingThemeColor={LoadingThemeColor}
                    checkedBoxState={checkedBoxState}
                    substituteStatusData={substituteStatusData}
                  />
                </div>
                <UnderTheHoodRightSideFixedContainer
                  dataRef={dataRef}
                  subOptionGraphdata={subOptionGraphdata}
                  subnodes={subnodes}
                  index={index}
                  subGraphdata={subGraphdata}
                  subLink={subLink}
                  setData={setData}
                  nodeClickStatus={nodeClickStatus}
                  setSelectedSubstituteNodes={setSelectedSubstituteNodes}
                  data={data}
                  setChangeSave={setChangeSave}
                  deleteNodes={deleteNodes}
                  length={length}
                  checkedState={checkedState}
                  setCheckedState={setCheckedState}
                  checkedBoxState={checkedBoxState}
                  setCheckeBoxdState={setCheckeBoxdState}
                  subGraphTitle={subGraphTitle}
                  subGraphTitleValue={subGraphTitleValue}
                  substituteInfo={substituteInfo}
                  isGraphUpdate={isGraphUpdate}
                />
              </div>
            }
          />
          <Route
            path="processMonitoring"
            element={
              <>
                <ProcessMoniteringComponent
                  LoadingThemeColor={LoadingThemeColor}
                  nodesFromApi={nodesFromApi}
                  links={links}
                  ProcessGraphData={ProcessGraphData}
                />
              </>
            }
          />
          <Route
            path="reuseStatsPage"
            element={
              <>
                <ProcessMoniteringStats
                  LoadingThemeColor={LoadingThemeColor}
                  StatsSubstitutionStatus={StatsSubstitutionStatus}
                  statsProcessStatus={statsProcessStatus}
                  StatsSubstitutionData={StatsSubstitutionData}
                  statsProcessData={statsProcessData}
                  setshowGraphTitleInBanner={setshowGraphTitleInBanner}
                />
              </>
            }
          />
        </Route>
      </Routes>

      <RecipeGraphsComponent
        LoadingThemeColor={LoadingThemeColor}
        setshowGraphTitleInBanner={setshowGraphTitleInBanner}
      />

      <VocabularyComponent LoadingThemeColor={LoadingThemeColor} />
    </div>
  );
};
const Layout = () => {
  return <Outlet />;
};

export default memo(UnderTheHoodMiddleGraphContainer);
