export const stickerBackgroundColor = el => {
  switch (el) {
    case 'INDIAN':
      return '#eff9ff';

    case 'INDONESIAN':
      return '#fffaef';

    case 'CHINESE':
      return '#fff4f4';

    case 'FRENCH':
      return '#f2fffc';

    case 'KOREAN':
      return '#f0f0f0';

    case 'MEXICAN':
      return '#f7f1fe';

    case 'AMERICAN':
      return '#fff5f7';

    default:
      return '#f0fff4';
  }
};
export const stickerColor = el => {
  switch (el) {
    case 'INDIAN':
      return '#8cc1de';

    case 'INDONESIAN':
      return '#e8d39e';

    case 'CHINESE':
      return '#e1b3b3';

    case 'FRENCH':
      return '#99e5dd';

    case 'KOREAN':
      return '#adb3bc';

    case 'MEXICAN':
      return '#e9ddfa';

    case 'AMERICAN':
      return '#f9c8da';

    default:
      return '#aee9c4';
  }
};
export const firstProcessStep = ingredientsSelection => {
  switch (ingredientsSelection) {
    case 'idle':
      return 'inventoryProcessCardIdle';
    case 'progress':
      return 'inventoryProcessCardIdleProgress';
    case 'success':
      return 'inventoryProcessCardIdleSuccess';
    default:
      return 'inventoryProcessCardIdle';
  }
};

export const secondProcessStep = cuisinePageSelection => {
  switch (cuisinePageSelection) {
    case 'idle':
      return 'inventoryProcessCardIdle';
    case 'progress':
      return 'inventoryProcessCardIdleProgress';
    case 'success':
      return 'inventoryProcessCardIdleSuccess';
    default:
      return 'inventoryProcessCardIdle';
  }
};
export const thirdProcessStep = ingredientsSecondPageSelection => {
  switch (ingredientsSecondPageSelection) {
    case 'idle':
      return 'inventoryProcessCardIdle';
    case 'progress':
      return 'inventoryProcessCardIdleProgress';
    case 'success':
      return 'inventoryProcessCardIdleSuccess';
    default:
      return 'inventoryProcessCardIdle';
  }
};

export const fourthProcessStep = ingredientsLastPageSelection => {
  switch (ingredientsLastPageSelection) {
    case 'idle':
      return 'inventoryProcessCardIdle';
    case 'progress':
      return 'inventoryProcessCardIdleProgress';
    case 'success':
      return 'inventoryProcessCardIdleSuccess';
    default:
      return 'inventoryProcessCardIdle';
  }
};
export const firstProcessArrow = ingredientsSelection => {
  switch (ingredientsSelection) {
    case 'idle':
      return 'processArrowIdle';
    case 'progress':
      return 'processArrowProgress';
    case 'success':
      return 'processArrowSuccess';
    default:
      return 'processArrowIdle';
  }
};
export const secondProcessArrow = cuisinePageSelection => {
  switch (cuisinePageSelection) {
    case 'idle':
      return 'processArrowIdle';
    case 'progress':
      return 'processArrowProgress';
    case 'success':
      return 'processArrowSuccess';
    default:
      return 'processArrowIdle';
  }
};
export const thirdProcessArrow = ingredientsSecondPageSelection => {
  switch (ingredientsSecondPageSelection) {
    case 'idle':
      return 'processArrowIdle';
    case 'progress':
      return 'processArrowProgress';
    case 'success':
      return 'processArrowSuccess';
    default:
      return 'processArrowIdle';
  }
};
// ===============================================================================================================================
export const firstProcessStepInProcessChallenge = ingredientsSelection => {
  switch (ingredientsSelection) {
    case 'idle':
      return 'processChallengeProcessCardIdle';
    case 'progress':
      return 'processChallengeProcessCardIdleProgress';
    case 'success':
      return 'processChallengeProcessCardIdleSuccess';
    default:
      return 'processChallengeProcessCardIdle';
  }
};

export const secondProcessStepInProcessChallenge = cuisinePageSelection => {
  switch (cuisinePageSelection) {
    case 'idle':
      return 'processChallengeProcessCardIdle';
    case 'progress':
      return 'processChallengeProcessCardIdleProgress';
    case 'success':
      return 'processChallengeProcessCardIdleSuccess';
    default:
      return 'processChallengeProcessCardIdle';
  }
};
export const thirdProcessStepInProcessChallenge =
  ingredientsSecondPageSelection => {
    switch (ingredientsSecondPageSelection) {
      case 'idle':
        return 'processChallengeProcessCardIdle';
      case 'progress':
        return 'processChallengeProcessCardIdleProgress';
      case 'success':
        return 'processChallengeProcessCardIdleSuccess';
      default:
        return 'processChallengeProcessCardIdle';
    }
  };

export const fourthProcessStepInProcessChallenge =
  ingredientsLastPageSelection => {
    switch (ingredientsLastPageSelection) {
      case 'idle':
        return 'processChallengeProcessCardIdle';
      case 'progress':
        return 'processChallengeProcessCardIdleProgress';
      case 'success':
        return 'processChallengeProcessCardIdleSuccess';
      default:
        return 'processChallengeProcessCardIdle';
    }
  };
export const firstProcessArrowInProcessChallenge = ingredientsSelection => {
  switch (ingredientsSelection) {
    case 'idle':
      return 'processArrowIdle';
    case 'progress':
      return 'processArrowProgressProcessChallenge';
    case 'success':
      return 'processArrowSuccess';
    default:
      return 'processArrowIdle';
  }
};
export const secondProcessArrowInProcessChallenge = cuisinePageSelection => {
  switch (cuisinePageSelection) {
    case 'idle':
      return 'processArrowIdle';
    case 'progress':
      return 'processArrowProgressProcessChallenge';
    case 'success':
      return 'processArrowSuccess';
    default:
      return 'processArrowIdle';
  }
};
export const thirdProcessArrowInProcessChallenge =
  ingredientsSecondPageSelection => {
    switch (ingredientsSecondPageSelection) {
      case 'idle':
        return 'processArrowIdle';
      case 'progress':
        return 'processArrowProgressProcessChallenge';
      case 'success':
        return 'processArrowSuccess';
      default:
        return 'processArrowIdle';
    }
  };
// ==================================================================================================================================
export var stringToColour = function (str) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (var j = 0; j < 3; j++) {
    var value = (hash >> (j * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
};
