import qs from 'qs';
import axios from 'axios';

const ApplicationToken = (IsError, callBack, ar, index) => {
  // eslint-disable-next-line no-undef
  const Grant = window?._env_?.REACT_APP_GRANT;
  // eslint-disable-next-line no-undef
  const Client = window?._env_?.REACT_APP_CLIENT;
  // eslint-disable-next-line no-undef
  const Key = window?._env_?.REACT_APP_KEY;
  // eslint-disable-next-line no-undef
  const Auth = window?._env_?.REACT_APP_AUTH;
  // eslint-disable-next-line no-undef

  if (IsError) {
    let data = qs.stringify({
      grant_type: `${Grant}`,
      client_id: `${Client}`,
      client_secret: `${Key}`,
    });
    let config = {
      method: 'POST',
      url: `${Auth}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: data,
    };

    axios(config)
      .then(response => {
        window.localStorage.setItem(
          'applicationToken',
          JSON.stringify(response)
        );
        if (typeof callBack === 'function') {
          if (ar && index) {
            callBack(response, ar, index);
          } else {
            callBack(response);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    return JSON.parse(window.localStorage.getItem('applicationToken'));
  } else {
    return JSON.parse(window.localStorage.getItem('applicationToken'));
  }
};

export default ApplicationToken;
