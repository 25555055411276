import React from 'react';
import {
  ProcessSteps,
  Paragraph,
  ProcessButtons,
  Arrow,
  RecipeCardContainer,
  CuisineSelection,
  UnderTheHoodContainer,
  ProcessStepWithDropDown,
  CascadingContainer,
  PageBanner,
} from '..';
import '../../style/Inventory.css';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { BiSearch } from '../../icon';
import {
  bannerSvgImageInProcessChallenge,
  successIcon,
  processChallengeStepFirstImage,
  processChallengeStepSecondImage,
  processChallengeStepThreeImage,
  processChallengeStepFourImage,
} from '../../assets';

import {
  firstProcessStepInProcessChallenge,
  secondProcessStepInProcessChallenge,
  thirdProcessStepInProcessChallenge,
  fourthProcessStepInProcessChallenge,
  firstProcessArrowInProcessChallenge,
  secondProcessArrowInProcessChallenge,
  thirdProcessArrowInProcessChallenge,
  useFetch,
} from '../../utils';
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router';

import {
  invProcessStepActions,
  cascadingScreenActions,
  useDispatch,
  useSelector,
  cuisineScreenActions,
} from '../../Redux';
import { AppColors } from '../../Constants/AppColors';
import GuestPopupBox from '../../lib/GuestComponent/GuestPopupBox';
export const createProcessItemContext = React.createContext();

const ProcessChallenge = ({
  goBackClick,
  getIngredientButtonClick,
  getRecipeCatalogueButtonClick,
  getPreferencesButtonClick,
}) => {
  const invProcessBarSteps = useSelector(state => state.inventoryProcessSteps);
  const cascadingsSreenStore = useSelector(state => state.cascadingStore);

  const dispatch = useDispatch();
  const location = useLocation();

  React.useEffect(() => {
    dispatch(cascadingScreenActions.getConvertQueryKey());
  }, [cascadingsSreenStore.selectedIngredients]);
  const navigate = useNavigate();
  const objectiveFormatSelection =
    cascadingsSreenStore.totalSelectResult.toString();

  const cascading_First_QueryId =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_CASCADING_SELECTION_CLASSIFICATION_QUERY_ID_POINT_ONE;
  // eslint-disable-next-line no-undef
  const cascading_Second_QueryId =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_CASCADING_SELECTION_CLASSIFICATION_QUERY_ID_POINT_TWO;
  // eslint-disable-next-line no-undef
  const cascading_Third_QueryId =
    // eslint-disable-next-line no-undef
    process.env
      .REACT_APP_CASCADING_SELECTION_CLASSIFICATION_QUERY_ID_POINT_THREE;

  const no_Selection_Results_QuaryId =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_CASCADING_NO_SELECTION_CLASSIFICATION_QUERY_ID;
  const Food_Classification_Cascading_QueryId = `${cascading_First_QueryId} ${
    objectiveFormatSelection === undefined ? `<>` : objectiveFormatSelection
  } ${cascading_Second_QueryId}${
    objectiveFormatSelection === undefined ? `<>` : objectiveFormatSelection
  }${cascading_Third_QueryId}`;
  const [cascadingStatus, cascadingData] = useFetch(
    cascadingsSreenStore.totalSelectResult.length === 0
      ? no_Selection_Results_QuaryId
      : Food_Classification_Cascading_QueryId
  );
  React.useEffect(() => {
    dispatch(
      cascadingScreenActions.avoidLateLoadingWhileSelectingIngredient(
        cascadingStatus
      )
    );
  }, [cascadingStatus]);

  const ristrition = cascadingStatus === 'success' ? cascadingData.rows : [];

  const output = ristrition.reduce((prev, curr) => {
    const tmp = prev.find(e => e.searchfilter === curr.searchfilter);
    if (tmp) {
      tmp.ingredient_label.push(curr.ingredient_label);
      tmp.value.push(curr.value);
    } else {
      prev.push({
        searchfilter: curr.searchfilter,
        ingredient_label: [curr.ingredient_label],
        value: [curr.value],
        cuisine: curr.cuisine,
      });
    }

    return prev;
  }, []);

  const saperatedData = output;

  const mixedIngredients = saperatedData
    .flatMap(x => x.ingredient_label)
    .filter((el, i, arr) => i === arr.indexOf(el) && el);

  const duplicateCusineList = saperatedData.map(el => {
    const Data = el.cuisine;
    return Data;
  });

  const uniqueCusineList = duplicateCusineList.filter(
    (el, index, array) => index === array.indexOf(el)
  );

  let uniqueCusine = uniqueCusineList.join('+');
  const nextProcess = () => {
    dispatch(cuisineScreenActions.clearSelectedCuisineContainer());
    dispatch(
      cascadingScreenActions.changeIngredientTitle(
        'Select your preferred cuisine:'
      )
    );
    dispatch(invProcessStepActions.gotoCuisinePage());
    dispatch(cascadingScreenActions.getShowTopInput());
    navigate(
      `${uniqueCusine}=${cascadingsSreenStore.totalSelectResult
        .join('&')
        .replaceAll('<', '.$')
        .replaceAll('>', '$')}`
    );
  };

  // ======================================================InputCode for Cusine==============================================================================

  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const getFocus = () => {
    dispatch(cascadingScreenActions.setTrueInvNotification());
  };
  const getBlur = () => {
    dispatch(cascadingScreenActions.setFalseInvNotification());
  };

  const getSelect = e => {
    dispatch(
      cascadingScreenActions.getSelectIngredientFromRightSideSearch({
        element: e.target.textContent,
        mixedIngredients: mixedIngredients,
        otherDispatch: dispatch(
          invProcessStepActions.whileSelectIngredientStatus()
        ),
      })
    );
  };

  const firstLetterToUpperCase =
    inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
  const OPTIONS_LIMIT = 5;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  React.useEffect(() => {
    if (location.pathname.split('/').length === 2) {
      dispatch(
        cascadingScreenActions.changeIngredientTitle(
          `To view the corresponding recipes, first select the ingredients you want to use. Add an ingredient either by browsing the available ingredients by category or by searching for a specific ingredient in the search box on the right. After ingredient selection, click on the ‘Next’ button to go to cuisine selection..`
        )
      );
      if (cascadingsSreenStore.totalSelectResult.length !== 0) {
        dispatch(invProcessStepActions.selectTheIngredients());
      } else {
        dispatch(invProcessStepActions.selectTheIngredientsInitial());
      }
      dispatch(cascadingScreenActions.getHideTopInput());
    } else {
      dispatch(cascadingScreenActions.getShowTopInput());
    }
  }, [location]);

  // ==========================================================================================================================================================

  return (
    <>
      {location.pathname.split('/')[5] !== 'uth' && (
        <div className="InvContainer ProcessContainer">
          <PageBanner
            title="Process Challenge"
            src={bannerSvgImageInProcessChallenge}
            mainText="Tweak the process and see how the dish turns out."
            titleClass="InventorttitleClass"
            mainTextClass="InventorymainTextClass"
            imgClass="InventoryimgClass"
            bannerClass="processTitleImgFlex"
          />
          <div className="height_Flex_Container">
            <div className="processStep-cards-flex">
              <div className="process-step-with-arrow">
                <ProcessSteps
                  processName="Select the ingredients"
                  ingredientsSelection={invProcessBarSteps.ingredientsSelection}
                  selectClass={firstProcessStepInProcessChallenge(
                    invProcessBarSteps.ingredientsSelection
                  )}
                  classForImgRoundContainer={
                    invProcessBarSteps.ingredientsSelection === 'success'
                      ? 'imgRoundSuccessContainer'
                      : 'imgRoundIdleContainerInProcess'
                  }
                  stepIcon={
                    invProcessBarSteps.ingredientsSelection === 'success' ? (
                      <img src={successIcon} className="successRightIconSize" />
                    ) : (
                      <img
                        src={processChallengeStepFirstImage}
                        className="stepIconSize"
                      />
                    )
                  }
                  processClick={getIngredientButtonClick}
                />
                <Arrow
                  selectClass={firstProcessArrowInProcessChallenge(
                    invProcessBarSteps.ingredientsSelection
                  )}
                />
              </div>
              <div className="process-step-with-arrow">
                <ProcessSteps
                  processName="Select cuisine type"
                  selectClass={secondProcessStepInProcessChallenge(
                    invProcessBarSteps.cuisinePageSelection
                  )}
                  classForImgRoundContainer={
                    invProcessBarSteps.cuisinePageSelection === 'success'
                      ? 'imgRoundSuccessContainer'
                      : 'imgRoundIdleContainerInProcess'
                  }
                  stepIcon={
                    invProcessBarSteps.cuisinePageSelection === 'success' ? (
                      <img src={successIcon} className="successRightIconSize" />
                    ) : (
                      <img
                        src={processChallengeStepSecondImage}
                        className="stepIconSize"
                      />
                    )
                  }
                  processClick={getPreferencesButtonClick}
                />
                <Arrow
                  selectClass={secondProcessArrowInProcessChallenge(
                    invProcessBarSteps.cuisinePageSelection
                  )}
                />
              </div>
              <div className="process-step-with-arrow">
                <ProcessSteps
                  processName="Select a recipe from the catalogue"
                  selectClass={thirdProcessStepInProcessChallenge(
                    invProcessBarSteps.ingredientsSecondPageSelection
                  )}
                  classForImgRoundContainer={
                    invProcessBarSteps.ingredientsSecondPageSelection ===
                    'success'
                      ? 'imgRoundSuccessContainer'
                      : 'imgRoundIdleContainerInProcess'
                  }
                  stepIcon={
                    invProcessBarSteps.ingredientsSecondPageSelection ===
                    'success' ? (
                      <img src={successIcon} className="successRightIconSize" />
                    ) : (
                      <img
                        src={processChallengeStepThreeImage}
                        className="stepIconSize"
                      />
                    )
                  }
                  processClick={getRecipeCatalogueButtonClick}
                  // setStepIcon={setStepIcon}
                />
                <Arrow
                  selectClass={thirdProcessArrowInProcessChallenge(
                    invProcessBarSteps.ingredientsSecondPageSelection
                  )}
                />
              </div>

              <div className="process-step-with-arrow">
                <ProcessStepWithDropDown
                  processName="Process monitoring"
                  selectClass={fourthProcessStepInProcessChallenge(
                    invProcessBarSteps.ingredientsLastPageSelection
                  )}
                  classForImgRoundContainer={
                    invProcessBarSteps.ingredientsLastPageSelection ===
                    'success'
                      ? 'imgRoundSuccessContainer'
                      : 'imgRoundIdleContainerInProcess'
                  }
                  stepIcon={
                    invProcessBarSteps.ingredientsLastPageSelection ===
                    'success' ? (
                      <img src={successIcon} className="successRightIconSize" />
                    ) : (
                      <img
                        src={processChallengeStepFourImage}
                        className="stepIconSize"
                      />
                    )
                  }
                />
              </div>
            </div>
          </div>
          {cascadingsSreenStore.ingredientTitle === '' ? null : (
            <div className="paraGraphTitleAndInputFlexForCascading">
              <>
                <Paragraph />
                {cascadingsSreenStore.hidetopInput && (
                  <div className="searchInputInCascadingScreenInInventory">
                    <Autocomplete
                      key={cascadingsSreenStore.selectedIngredients}
                      ListboxProps={{
                        style: {
                          maxHeight: '14em',
                          fontSize: '0.7em',
                          fontFamily: `'Roboto', sans-serif`,
                        },
                      }}
                      noOptionsText={
                        <span
                          style={{
                            color: 'gray',
                            fontSize: '.7em',

                            fontFamily: `'Roboto', sans-serif`,
                          }}
                        >
                          Search not found
                        </span>
                      }
                      clearOnBlur
                      selectOnFocus
                      onChange={getSelect}
                      onBlur={getBlur}
                      onFocus={getFocus}
                      open={open}
                      onOpen={() => {
                        if (inputValue) {
                          setOpen(true);
                        }
                      }}
                      onClose={() => setOpen(false)}
                      inputValue={firstLetterToUpperCase}
                      onInputChange={(e, value) => {
                        setInputValue(value);

                        if (!value) {
                          setOpen(false);
                        }
                      }}
                      onClick={() => console.log('hello')}
                      sx={{
                        display: 'inline-block',
                        '& input': {
                          width: '12rem',

                          bgcolor: 'background.paper',
                          color: theme =>
                            theme.palette.getContrastText(
                              theme.palette.background.paper
                            ),
                        },
                      }}
                      id="custom-input-demo"
                      filterOptions={filterOptions}
                      options={mixedIngredients}
                      renderInput={params => (
                        <div
                          className="input-iconflex topInputSearch"
                          ref={params.InputProps.ref}
                        >
                          <div
                            className="iconContainerInInputSearchInInventoryy"
                            style={{
                              backgroundColor:
                                location.pathname.split('/')[1] === 'process'
                                  ? cascadingsSreenStore.invNotifaction
                                    ? `${AppColors.process.base_color}`
                                    : '#f2f2f2'
                                  : cascadingsSreenStore.invNotifaction
                                  ? `${AppColors.inventory.input_active_color}`
                                  : '#f2f2f2',
                              border:
                                location.pathname.split('/')[1] === 'process'
                                  ? cascadingsSreenStore.invNotifaction
                                    ? `.08rem solid ${AppColors.process.base_color}`
                                    : `.08rem solid ${AppColors.input_blur_color}`
                                  : cascadingsSreenStore.invNotifaction
                                  ? `1px solid ${AppColors.inventory.input_active_color}`
                                  : `1px solid ${AppColors.input_blur_color}`,
                            }}
                          >
                            <BiSearch
                              size={'1.3em'}
                              className="icon"
                              style={{
                                color: cascadingsSreenStore.invNotifaction
                                  ? 'white'
                                  : `${AppColors.input_blur_color}`,
                              }}
                            />
                          </div>
                          <input
                            className="input-materialui"
                            placeholder={`Search ingredients...`}
                            type="text"
                            {...params.inputProps}
                            style={{
                              border:
                                location.pathname.split('/')[1] === 'process'
                                  ? cascadingsSreenStore.invNotifaction
                                    ? `.08rem solid ${AppColors.process.base_color}`
                                    : `.08rem solid ${AppColors.input_blur_color}`
                                  : cascadingsSreenStore.invNotifaction
                                  ? `.08rem solid ${AppColors.inventory.input_active_color}`
                                  : `.08rem solid ${AppColors.input_blur_color}`,
                            }}
                          />
                        </div>
                      )}
                    />
                  </div>
                )}
              </>
            </div>
          )}
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route
                index
                element={
                  <>
                    <CascadingContainer
                      saperatedData={saperatedData}
                      cascadingStatus={cascadingStatus}
                      LoadingColor={`${AppColors.process.base_color}`}
                      categoriesButtonActive="categoriesButtonsActiveInProcessWay"
                    />
                    <div className="proccedButtonsBackButtonsFlex">
                      <ProcessButtons
                        nextProcess={nextProcess}
                        goBackClick={goBackClick}
                        text="Next"
                        buttonClassName="processForwordButtonInProcessChallenge processBtn"
                      />
                    </div>
                  </>
                }
              />
              <Route
                path={`:id`}
                element={
                  <CuisineSelection
                    saperatedData={saperatedData}
                    navigationButtonClassName="cusineProceedButtonFromProcessChallenge"
                    navigationButtonClassNameActive="cusineProceedButtonFromProcessChallenge cusineProceedButtonFromProcessChallengeActive"
                    LoadingColor={`${AppColors.process.base_color}`}
                    cuisineBackButtonClass="cusineBackButtonInProcessChallenge"
                    getIngredientButtonClick={getIngredientButtonClick}
                  />
                }
              />
              <Route
                path=":id/:ig/*"
                element={
                  <RecipeCardContainer
                    LoadingColor={`${AppColors.process.base_color}`}
                    cardsBackButtonClass={'preferenceCardsBackButtonInProcess'}
                    getPreferencesButtonClick={getPreferencesButtonClick}
                  />
                }
              />
            </Route>
          </Routes>
        </div>
      )}

      <Routes>
        <Route
          path=":id/:ig/:item/uth/*"
          element={
            <>
              {
                location.pathname.split('/')[5] === 'uth' && (
                  // substiteGraphStore.subGraphdata.length !== 0 && (
                  <UnderTheHoodContainer
                    titleAndbacktoRecipeFlex="titleAndbacktoRecipeFlexInProcessChallange"
                    backToRecipeContainerButton="backToRecipeContainerButtonWithProcess"
                    LoadingThemeColor={`${AppColors.process.base_color}`}
                  />
                )
                // )
              }
            </>
          }
        />

        <Route
          path=":id/:ig/:childId/timeStatistics/guestPage"
          element={
            <>
              <GuestPopupBox
                LoadingThemeColor={`${AppColors.process.base_color}`}
                className={'guestInProcess'}
              />
            </>
          }
        />
      </Routes>
    </>
  );
};
const Layout = () => {
  return <Outlet />;
};

export default ProcessChallenge;
