import React from 'react';
import './AuthCart.css';
import { AiFillCheckCircle, MdCheckCircle } from '../../../icon';
import { eccencaLogo, AuthCurryLogo } from '../../../assets';
import { Alert, Rating, Snackbar } from '@mui/material';
import Modal from '../../../lib/Modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { authAction } from '../../../Redux/Slices/AuthSlides/AuthenticationStates';
import { getItemFromMemory } from '../../../utils';
import { addDoc, collection } from 'firebase/firestore';
import { fireStoreDb } from '../../../firebase';
import { useLocation } from 'react-router';

const AuthCart = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const authenticationStore = useSelector(s => s.authStore);

  const [show, setShow] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');
  let emailSendAt = new Date().toLocaleString();
  const userData = getItemFromMemory('applicationUser');
  React.useEffect(() => {
    let FeedBackEmail = userData !== undefined ? userData?.email : '';
    setEmail(FeedBackEmail);
  }, [userData]);

  const getEmailAddress = e => {
    setEmail(e.target.value);
  };
  const getmessage = e => {
    setMessage(e.target.value);
  };
  const totalInfo = [email];
  const [feedbackAdded, setFeedbackAdded] = React.useState(false);

  const getModelClose = () => {
    const feedbackCollectionRef = collection(fireStoreDb, 'feedback');
    addDoc(feedbackCollectionRef, {
      to: ['feedback-cc@eccenca.com'],
      replyTo: `${email === '' ? 'agent@gmail.com' : email}`,
      message: {
        subject: `feedback from ${
          authenticationStore.userPersonalDetails.length === 0
            ? 'agent'
            : authenticationStore.userPersonalDetails?.username
        }`,
        html: `<!DOCTYPE html>
        <body>
        <tr>
            <td align="center" valign="top">
                <table width="556" border="0" cellspacing="0" cellpadding="0" align="center"
                    class="m_-8417353500393176574em_wrapper" style="width:556px">
                    <tbody>
                        <tr>
                            <td align="center" valign="top" width="55" style="width:55px">
                                &nbsp;</td>
                            <td align="center" valign="top">
                                <table width="100%" border="0" cellspacing="0" cellpadding="0" align="center">
                                    <tbody>
                                        <h1 style="font-family:'Graphik Web',Helvetica,Arial,sans-serif;color:#f7962c">Curry Company</h1>
                                    </tbody>
                                </table>
                            </td>
                            <td align="center" valign="top" width="55" style="width:55px">
                                &nbsp;</td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td align="center" valign="top">
                <table width="556" border="0" cellspacing="0" cellpadding="0" align="center"
                    class="m_-8417353500393176574em_wrapper" style="width:556px">
                    <tbody>
                        <tr>
                            <td align="center" valign="top" width="55" style="width:55px">
                                &nbsp;</td>
                            <td align="center" valign="top">
                                <table width="100%" border="0" cellspacing="0" cellpadding="0" align="center">
                                    <tbody>
                                        <tr>
                                            <td align="left" class="m_-8417353500393176574em_defaultlink" valign="top"
                                                style="font-family:'Graphik Web',Helvetica,Arial,sans-serif;font-size:16px;line-height:22px;color:#000000">
                                                <div style="width:100%; display:flex "> 
                                                <div style='marging-top:15px;'>feedback</div>   
                                                <div style='text-align:right ; margin-left:72%'>
                                                <span>&#11088;</span>${value}
                                                </div> 
                                            </div> 
                                                <hr>
                                                <span id="res" style="font-size:14px;color:#363636">
                                                ${message}    
                                                </span>
        
                                                <br><br>
                                                <span style="font-size:14px;color:#363636">
                                                    This email was sent at ${emailSendAt}</span> 
                                                    <br>
                                                    <br>
                                                    <span style="font-size:14px;color:#363636">
                                                        from,
                                                        <br>
                                                        ${
                                                          authenticationStore
                                                            .userPersonalDetails
                                                            .length === 0
                                                            ? 'agent'
                                                            : authenticationStore
                                                                .userPersonalDetails
                                                                ?.username
                                                        }
                                                 <br>
                                                 ${
                                                   email === ''
                                                     ? 'agent@gmail.com'
                                                     : email
                                                 }</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td align="center" valign="top" width="55" style="width:55px">
                                &nbsp;</td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        </body>
        </html>`,
      },
    })
      .then(response => {
        console.log(response);
      })
      .catch(e => {
        console.log(e);
      });
    // };

    setShow(false);
    setFeedbackAdded(true);
  };
  const getModelIconClose = () => {
    setShow(false);
  };
  const getModelNotificationIconClose = () => {
    setFeedbackAdded(false);
  };
  const getFeedback = () => {
    setShow(true);
    setShow(true);
    setMessage('');
    setEmail('');
    setValue(0);
    // getModelClose();
    setFeedbackAdded(false);
  };

  return (
    <div className="authContainer">
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={
          authenticationStore.forgetPasswordTostSuccessMessage === 'success'
            ? true
            : false
        }
        autoHideDuration={6000}
        onClose={() =>
          dispatch(authAction.setForgetPasswordTostSuccessMessage('idle'))
        }
      >
        <Alert onClose={''} severity="success" sx={{ width: '100%' }}>
          A password reset link has been sent to email ID.
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={
          authenticationStore.forgetPasswordTostSuccessMessage === 'Error'
            ? true
            : false
        }
        autoHideDuration={6000}
        onClose={() =>
          dispatch(authAction.setForgetPasswordTostSuccessMessage('idle'))
        }
      >
        <Alert onClose={''} severity="error" sx={{ width: '100%' }}>
          Please enter a valid email
        </Alert>
      </Snackbar>

      <div
        className="authContainerHeadingContainer"
        style={{ left: authenticationStore.isLogin ? '4%' : '4.76%' }}
      >
        {/* <div className="authContainerHeading">eccenca education services</div>
        <div>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book.
        </div> */}
        <div className="svgLogosInauth">
          <img className="authSVGLogo" src={AuthCurryLogo} alt="authSvgLogo" />
        </div>
      </div>
      <div
        className="authCartContainer"
        style={{
          width:
            location.pathname.split('/')[1] === 'signup' ||
            location.pathname.split('/')[1] === 'usernamerecovery'
              ? '30%'
              : '27%',
        }}
      >
        {(location.pathname.split('/')[1] === 'signup' ||
          location.pathname.split('/')[1] === 'usernamerecovery') && (
          <>
            {authenticationStore.typeofSignUp === 'email' && (
              <div className="authSignUPStepContainer">
                <div className="authSignUPStep">
                  <div
                    style={{
                      color:
                        authenticationStore.progressStep >= 0
                          ? 'orange'
                          : 'white',
                    }}
                  >
                    Step-1
                  </div>
                  <div
                    style={{
                      color:
                        authenticationStore.progressStep >= 1
                          ? 'orange'
                          : 'white',
                    }}
                  >
                    <div>.</div>
                    <div>.</div>
                    <div>.</div>
                    <div>.</div>
                    <div>.</div>
                    <div>.</div>
                    <div>.</div>
                  </div>
                </div>
                <div className="authSignUPStep">
                  <div
                    style={{
                      color:
                        authenticationStore.progressStep >= 1
                          ? 'orange'
                          : 'white',
                    }}
                  >
                    Step-2
                  </div>
                  <div
                    style={{
                      color:
                        authenticationStore.progressStep >= 2
                          ? 'orange'
                          : 'white',
                    }}
                  >
                    <div>.</div>
                    <div>.</div>
                    <div>.</div>
                    <div>.</div>
                    <div>.</div>
                    <div>.</div>
                    <div>.</div>
                  </div>
                </div>
                <div className="authSignUPStep">
                  <div
                    style={{
                      color:
                        authenticationStore.progressStep >= 2
                          ? 'orange'
                          : 'white',
                    }}
                  >
                    <MdCheckCircle />
                  </div>
                </div>
              </div>
            )}

            {authenticationStore.typeofSignUp === 'linkedin' && (
              <div className="authSignUPStepContainerLinkedin">
                <div className="authSignUPStep">
                  <div
                    style={{
                      color:
                        authenticationStore.progressStep >= 1
                          ? 'orange'
                          : 'white',
                    }}
                  >
                    Step-1
                  </div>
                  <div
                    style={{
                      color:
                        authenticationStore.progressStep >= 2
                          ? 'orange'
                          : 'white',
                    }}
                  >
                    <div>.</div>
                    <div>.</div>
                    <div>.</div>
                    <div>.</div>
                    <div>.</div>
                    <div>.</div>
                    <div>.</div>
                  </div>
                </div>
                <div className="authSignUPStep">
                  <div
                    style={{
                      color:
                        authenticationStore.progressStep >= 2
                          ? 'orange'
                          : 'white',
                    }}
                  >
                    Step-2
                  </div>
                  <div
                    style={{
                      color:
                        authenticationStore.progressStep >= 3
                          ? 'orange'
                          : 'white',
                    }}
                  >
                    <div>.</div>
                    <div>.</div>
                    <div>.</div>
                    <div>.</div>
                    <div>.</div>
                    <div>.</div>
                    <div>.</div>
                  </div>
                </div>
                <div className="authSignUPStep">
                  <div
                    style={{
                      color:
                        authenticationStore.progressStep >= 3
                          ? 'orange'
                          : 'white',
                    }}
                  >
                    <MdCheckCircle />
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        <div className="AuthChild">
          <div className="AuthInfo">{children}</div>
          <div className="AuthFixImg">
            <img src={eccencaLogo} alt="essencaLogo" width="70%" />
            <div className="feedBackAtAuth five-step" onClick={getFeedback}>
              <div className="sideNavBarFeedback-button-text">
                <div> Feedback/Support</div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isdiable={value > 0 || message.length > 0 ? false : true}
          title="Feedback / Support"
          onClose={() => getModelClose()}
          onCloseCancel={() => getModelIconClose()}
          show={show}
          width="20rem"
          height={`28rem`}
          marginTop="10vh"
          isButton={true}
          closeIcon={true}
          marginleft={'10em'}
          customClass={'feedBackMsg'}
          color={value > 0 || message.length > 0 ? '#ffa500' : '#e8eaed'}
          mainClassModal={'AuthFeedBack'}
        >
          <div className="sidebar-model-content">
            <div className="sidebar-model-content-header-text-rating">
              <div className="sidebar-model-content-header-text">
                Your feedback allows us to improve the experience we provide.
              </div>
              <div className="sidebar-model-content-header-rating">
                <Rating
                  name="simple-controlled"
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                />
              </div>
            </div>
            <div>
              <div className="model-input-email">
                <label className="modelInputLabel" htmlFor="modelInput">
                  Email ID<span className="modelInputLabel-star">*</span>
                </label>
                <input
                  id="modelInput"
                  placeholder="Enter your Email ID"
                  value={email}
                  onChange={getEmailAddress}
                />
              </div>
              <div className="molal-email-validation">
                Email address is required.
              </div>
            </div>

            <div className="model-message">
              <div className="model-input-email">
                <label className="modelInputLabel" htmlFor="modelMessage">
                  Message
                </label>
                <textarea
                  id="modelMessage"
                  placeholder="Leave us your message…"
                  value={message}
                  onChange={getmessage}
                />
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          isdiable={totalInfo.includes('') ? true : false}
          title=""
          onClose={() => getModelClose()}
          onCloseCancel={() => getModelNotificationIconClose()}
          show={feedbackAdded}
          width="20rem"
          height="14rem"
          marginTop="40vh"
          isButton={false}
          closeIcon={true}
          marginleft={'10vw'}
          customClass={'feedBackMsg thankYouBox'}
          mainClassModal={'AuthFeedBack'}
        >
          <div className="sidebar-model-content-notifaction">
            <div>
              <AiFillCheckCircle
                size="3em"
                style={{ background: 'green', borderRadius: '60%' }}
                color="#e8fadc"
              />
            </div>
            <div>
              <h1 className="thankYouTitle">Thank you!</h1>
            </div>
            <div className="sidebar-model-content-notifaction-message">
              Your feedback allows us to improve the experience we provide.
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default AuthCart;
