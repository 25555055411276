export const tick = (node, link, linkLabel, Background) => {
  link
    .attr('x1', function (d) {
      return d.source.x;
    })
    .attr('y1', function (d) {
      return d.source.y;
    })
    .attr('x2', function (d) {
      return d.target.x;
    })
    .attr('y2', function (d) {
      return d.target.y;
    });
  node.style('transform', d => `translate(${d.x}px, ${d.y}px)`);
  linkLabel
    .attr('x', function (d) {
      return (d.source.x + d.target.x) / 2;
    })
    .attr('y', function (d) {
      return (d.source.y + d.target.y) / 2;
    });
  Background.attr('x', function (d) {
    return (d.source.x + d.target.x) / 2.1;
  }).attr('y', function (d) {
    return (d.source.y + d.target.y) / 2.07;
  });
};
