import React from 'react';

import qs from 'qs';
import axios from 'axios';
import ApplicationToken from './ApplicationToken';

// var tokenKey = ApplicationToken(false);

const useRecurseFetch = query => {
  // const { tokenKey, setTokenKey1 } = React.useContext(authContext);
  var collectArray = [];
  var originalResult = [];
  let containerStorage = [];

  const [status, setStatus] = React.useState('idle');
  const [result, setResult] = React.useState([]);
  // const [findClass, setFindClass] = React.useState([]);

  const [parentLoopData, setParentLoopData] = React.useState([]);
  const [loopStatus, setLoopStatus] = React.useState('loopStart');

  const processGraph_Recursive_Query_point_one =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_PROCESS_GRAPH_QUERY_POINT_ONE;
  const processGraph_Recursive_Query_point_two =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_PROCESS_GRAPH_QUERY_POINT_TWO;
  const recipeGraph_Recursive_Query_point_one =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_RECIPE_GRAPH_QUERY_POINT_ONE;
  const recipeGraph_Recursive_Query_point_two =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_RECIPE_GRAPH_QUERY_POINT_TWO;

  const InitialFunction = () => {
    var data = qs.stringify({
      query: query.Condition
        ? `${processGraph_Recursive_Query_point_one}${query.query}${processGraph_Recursive_Query_point_two}`
        : `${recipeGraph_Recursive_Query_point_one}${query.query}${recipeGraph_Recursive_Query_point_two}`,
    });
    // eslint-disable-next-line no-undef
    var Query = window?._env_?.REACT_APP_QUERY;

    const queryCall = React.useCallback(
      async token => {
        if (!token) {
          return;
        } else {
          setStatus('Loading');

          var config = {
            method: 'POST',
            url: `${Query}`,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization:
                token?.data?.token_type + ' ' + token?.data?.access_token,
            },
            data: data,
          };

          axios(config)
            .then(response => {
              setParentLoopData(response.data.results.bindings);
              setResult(response.data.results.bindings);
              // dispatch(
              //   recipeAndProcessComponentActions.getGraphparentData(
              //     response.data.results.bindings
              //   )
              // );
              setResult(response.data.results.bindings);
              // dispatch(
              //   recipeAndProcessComponentActions.getGraphMainData(
              //     response.data.results.bindings
              //   )
              // );

              originalResult = response.data.results.bindings
                .filter(ek => ek.objClass !== undefined && ek)
                .map(el => el.obj.value);
              // setFindClass(originalResult);
              //console.logresponse.data.results.bindings);

              setStatus('success');
              // console.log(originalResult);
              RecursiveFunction(originalResult, 0);
            })
            .catch(function (error) {
              setStatus('error');
              if (error.response.status === 401) {
                ApplicationToken(true, queryCall);
                // tokenKey = ApplicationToken(true);
                // queryCall(tokenKey);
              }
            });
        }
      },
      [data]
    );

    React.useEffect(() => {
      if (!JSON.parse(window.localStorage.getItem('applicationToken'))) {
        ApplicationToken(true, queryCall);
        // tokenKey = ApplicationToken(true, '');
      } else {
        queryCall(
          JSON.parse(window.localStorage.getItem('applicationToken')),
          ''
        );
      }
    }, [query.query]);
  };
  const RecursiveFunction = async (piArray, i) => {
    // console.log('hello');
    if (piArray.length > i) {
      // console.log('inside');
      // console.log(piArray);

      // eslint-disable-next-line no-undef
      var Query = window._env_.REACT_APP_QUERY;

      const queryCall1 = async (token, piArray, i) => {
        // console.log(piArray);
        var data;

        data = qs.stringify({
          query: query.Condition
            ? `${processGraph_Recursive_Query_point_one}${piArray[i]}${processGraph_Recursive_Query_point_two}`
            : `${recipeGraph_Recursive_Query_point_one}${piArray[i]}${recipeGraph_Recursive_Query_point_two}`,
        });
        // console.log(data);

        if (!token) {
          return;
        } else {
          setStatus('Loading');

          var config = {
            method: 'POST',
            url: `${Query}`,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization:
                token?.data?.token_type + ' ' + token?.data?.access_token,
            },
            data: data,
          };

          axios(config)
            .then(response => {
              collectArray.push(...response.data.results.bindings);

              containerStorage.push(...response.data.results.bindings);
              setResult(containerStorage);
              // console.log(containerStorage);
              setStatus('success');
              RecursiveFunction(piArray, i + 1);
            })
            .catch(function (error) {
              setStatus('error');
              if (error.response.status === 401) {
                ApplicationToken(true, queryCall1, piArray, i);
              }
            });
        }
      };
      queryCall1(
        JSON.parse(window.localStorage.getItem('applicationToken')),
        piArray,
        i
      );
    } else {
      const secondIterationMapKeys = collectArray
        .filter(ek => ek.objClass !== undefined && ek)
        .map(el => el.obj.value);

      if (secondIterationMapKeys.length !== 0) {
        const removeDuplicates = secondIterationMapKeys.filter(
          (el, i, arr) => i === arr.indexOf(el) && el
        );
        // console.log(removeDuplicates);
        piArray = removeDuplicates;
        collectArray = [];
        await RecursiveFunction(piArray, 0);
      } else {
        setLoopStatus('loopEnd');
      }
    }
  };

  InitialFunction();

  return [status, result, parentLoopData, loopStatus];
};

export default useRecurseFetch;
