import React from 'react';
import CustomizedProgressBars from './ProgressBar';

const ProgressWithText = ({ color, value }) => {
  const convertNumberLength = v => {
    var realNumber;
    if (`${v}`.length < 2) {
      realNumber = '0' + Number.parseFloat(parseInt(v)).toFixed(1);
    } else if (`${v}`.length > 1) {
      realNumber = Number.parseFloat(parseInt(v)).toFixed(1);
    }
    return realNumber;
  };
  return (
    <div className="LabelAndBarFlex">
      <div className="percentageContainer">{convertNumberLength(value)}%</div>
      <div>
        <CustomizedProgressBars
          className="barAdjust"
          color={color}
          value={value}
        />
      </div>
    </div>
  );
};

export default ProgressWithText;
