import React from 'react';

import './AuthSignUp.css';
import { AuthSignUpInitialStep, AuthUserId } from '../index';
import { useSelector } from 'react-redux';
const AppSignUpFooter = ({ setShowEmailError }) => {
  const authenticationStore = useSelector(s => s.authStore);
  return (
    <div className="AuthSignUpPageContainer">
      <div>
        {authenticationStore.progressStep === 0 && (
          <>
            <AuthUserId setShowEmailError={setShowEmailError} />
          </>
        )}
        {authenticationStore.progressStep === 1 && <AuthSignUpInitialStep />}
      </div>
    </div>
  );
};

export default AppSignUpFooter;
