import React from 'react';
import './AuthButton.css';
const AuthBackButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className="AuthBackButton">
      Back
    </button>
  );
};

export default AuthBackButton;
