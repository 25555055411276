import React from 'react';
import * as d3 from 'd3';
import { useSelector } from 'react-redux';
const VocabularyGraphInfo = ({ vocabInfo }) => {
  // console.log(findConnectedNodes);
  const vocabularyComponentStore = useSelector(s => s.vocabularyComponentStore);
  const vocabularyInfoDataWhenClickNode = vocabInfo.map(el => {
    const Data = {
      antologyName: el.vocab.value,
      Discription: el.description.value,
      Author: el.publisher.value,
      Classes: el.class_labels.value,
      NodesCount: el.class_count.value,
      vocabUri: el.vocab_uri_value.value,
    };
    return Data;
  });

  const replaceBraketsInString = string => {
    let deleteFirstSpecialCheractor = string.split('[');

    let deleteSecondSpecialCharacter =
      deleteFirstSpecialCheractor[1].split(']');

    let actaualRowDatainString = deleteSecondSpecialCharacter[0];
    let originalRows = actaualRowDatainString.split(',');
    return originalRows;
  };

  var ReplaceSpecialBtacketArray;
  if (vocabularyInfoDataWhenClickNode.length !== 0) {
    ReplaceSpecialBtacketArray = replaceBraketsInString(
      vocabularyInfoDataWhenClickNode[0].Classes
    );
  }
  // console.log(findConnectedNodes);
  // var targetNodesName = [];
  // if (vocabularyComponentStore?.targetNodes?.length !== 0) {
  //   targetNodesName = vocabularyComponentStore?.targetNodes
  //     .map(el => {
  //       const Data = {
  //         target: el.target.data.name,
  //         parentNodeName: el.target.data.category,
  //         targetDiscription: el.targetDiscription,
  //         sourceDiscription: el.sourceDiscription,
  //       };
  //       return Data;
  //     })
  //     .filter(
  //       (ek, i, arr) => i === arr.findIndex(t => t.target === ek.target && ek)
  //     );
  // }
  // console.log(targetNodesName);
  // console.log(vocabularyComponentStore);
  var colour_scale = d3.scaleOrdinal(d3.schemeCategory10);

  // console.log(vocabularyComponentStore);
  var totalSourceAndTargetNodes = [];
  if (vocabularyComponentStore?.sourceNodes?.length !== 0) {
    const mapWithTargetedDiscription =
      vocabularyComponentStore?.sourceNodes?.map(el => {
        const Data = {
          source: el.source,
          node: {
            Discription: el.targetDiscription,
            nodes: el.target,
          },
        };
        return Data;
      });

    const findConnectedNodeBySource = mapWithTargetedDiscription
      .filter(
        eg =>
          eg?.source?.data?.name === vocabularyComponentStore?.selectedNode &&
          eg.node
      )
      .map(el => el.node);
    // console.log(findConnectedNodeBySource);
    // console.log(mapWithTargetedDiscription);
    totalSourceAndTargetNodes.push(...findConnectedNodeBySource);
  }

  if (vocabularyComponentStore?.targetNodes?.length !== 0) {
    const mapWithSourcedDiscription =
      vocabularyComponentStore?.targetNodes?.map(el => {
        const Data = {
          target: el.target,
          node: {
            Discription: el.sourceDiscription,
            nodes: el.source,
          },
        };
        return Data;
      });
    // console.log(mapWithSourcedDiscription);
    const findConnectedNodeByTarget = mapWithSourcedDiscription
      .filter(
        eg =>
          eg?.target?.data?.name === vocabularyComponentStore?.selectedNode &&
          eg?.node
      )
      .map(el => el.node);
    totalSourceAndTargetNodes.push(...findConnectedNodeByTarget);
    // console.log(findConnectedNodeByTarget);
  }
  if (totalSourceAndTargetNodes.length !== 0) {
    const removeDuplicatedNodes = totalSourceAndTargetNodes.filter(
      (el, i, arr) =>
        i ===
        arr.findIndex(eg => eg.nodes.data.name === el.nodes.data.name && el)
    );
    // console.log(removeDuplicatedNodes);
    totalSourceAndTargetNodes = [];
    totalSourceAndTargetNodes.push(...removeDuplicatedNodes);
  }

  return (
    <div className="rightSideFixedContainerInHood">
      <>
        {vocabularyComponentStore.vocabularyContentStateHandler ===
          'childNodeVocabulary' &&
          vocabularyComponentStore.findDepth === 2 &&
          vocabularyInfoDataWhenClickNode.length !== 0 && (
            <>
              <div className="discriptionContainerInVocabulary">
                <div className="mainRenderText">
                  {vocabularyComponentStore?.antologyName}
                </div>
                <div className="ConnectedNodeLabel">About The Ontology:</div>
                <div className="discriptionPara">
                  {vocabularyInfoDataWhenClickNode[0].Discription}
                </div>
                <div className="ConnectedNodeLabel">Selected Node</div>
                <li
                  style={{
                    color: colour_scale(
                      vocabularyComponentStore?.targetNodes[0]?.source?.category
                    ),
                  }}
                  className="ConnectedNodesInVocabularyListItemSource"
                >
                  <span className="listTextColorInVocabulary">
                    {vocabularyComponentStore?.selectedNode}
                  </span>
                </li>
                <div>{vocabularyComponentStore?.selectedNodeDiscription}</div>

                <div className="ConnectedNodeLabel">Connected Nodes</div>
                {totalSourceAndTargetNodes.map((el, i) => {
                  return (
                    <>
                      <li
                        className="ConnectedNodesInVocabularyListItemTarget"
                        style={{ color: colour_scale(i + 5) }}
                        key={i}
                      >
                        <span className="listTextColorInVocabulary">
                          {el.nodes?.data?.name}
                        </span>
                      </li>
                      <div className="targetNodesDiscription">
                        {el.Discription}
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          )}

        {vocabularyComponentStore.vocabularyContentStateHandler ===
          'antologyNode' &&
          vocabularyInfoDataWhenClickNode.length !== 0 &&
          vocabularyComponentStore.findDepth !== 2 && (
            <>
              <div className="discriptionContainerInVocabulary">
                <div className="mainRenderText">
                  {vocabularyInfoDataWhenClickNode[0].antologyName}
                </div>
                <div className="aboutTheAntology">About the ontology:</div>
                <p className="discriptionPara">
                  {vocabularyInfoDataWhenClickNode[0].Discription}
                </p>
                <div className="numberOfNodes">Number of nodes</div>
                <ul className="numberOfNodesCount">
                  <li>{vocabularyInfoDataWhenClickNode[0].NodesCount}</li>
                </ul>
                <div className="AuthorityHedding">Authored by:</div>
                <div className="aarthSoftwaresText">
                  {vocabularyInfoDataWhenClickNode[0].Author}
                </div>
                <div className="ClassesInFoodOnOntologyContainer">
                  <div className="ClassesInFoodOnOntology">
                    Classes in FoodOn ontology:
                  </div>
                  <ul className="classClassificationContainer">
                    {ReplaceSpecialBtacketArray.map((ek, i) => (
                      <li key={i}>{ek}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </>
          )}
        {vocabularyComponentStore.vocabularyContentStateHandler ===
          'introduction' && (
          <>
            <div className="mainRenderText">About the vocabulary graph</div>
            <div className="discriptionPara">
              An ontology encompasses a representation, formal naming and
              definition of the categories, properties and relations between the
              concepts, data and entities that substantiate one, many, or all
              domains of discourse.
            </div>
            <div className="numberOfNodes">Instructions</div>
            <div className="expression">
              Given below are the ways in which you can interact with the :
            </div>
            <ul className="classClassificationContainer">
              <li>
                Click on the ontology (circle) to know about the ontology and
                its classes.
              </li>
              <li>Click on any node to highlight its edges.</li>
            </ul>
          </>
        )}
      </>
    </div>
  );
};

export default VocabularyGraphInfo;
