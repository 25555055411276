import React from 'react';
import { MdArrowForwardIos } from '../../../icon';

/**
 * [Component] InventoryArrow
 
 * @description displays the arrow component

 * @param {*} selectClass changes css class

 * @returns change the state of arrow component on function call
 */

const Arrow = ({ selectClass }) => {
  return (
    <div className={selectClass}>
      <div className="setArrowWidth">
        <div>
          <MdArrowForwardIos className="arrowinInventorySteps" />
        </div>
      </div>
    </div>
  );
};

export default Arrow;
