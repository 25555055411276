import React from 'react';
import {
  PageBanner,
  ProcessSteps,
  Paragraph,
  CascadingContainer,
  ProcessButtons,
  ProcessStepWithDropDown,
  Arrow,
  RecipeCardContainer,
  CuisineSelection,
  UnderTheHoodContainer,
} from '../index';
import {
  preferenceLogoInStepBar,
  ingredientLogoInStepBar,
  recipeCatalogueLogoInStepBar,
  substituteIngredientLogoInStepBar,
  bannerRightSideImage,
  successIcon,
} from '../../assets';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { BiSearch } from '../../icon';
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router';
import {
  invProcessStepActions,
  cascadingScreenActions,
  useDispatch,
  useSelector,
  cuisineScreenActions,
} from '../../Redux';
import {
  secondProcessStep,
  thirdProcessStep,
  fourthProcessStep,
  firstProcessStep,
  firstProcessArrow,
  secondProcessArrow,
  thirdProcessArrow,
  useFetch,
} from '../../utils';
import { AppColors } from '../../Constants/AppColors';
import '../../style/Inventory.css';

import GuestPopupBox from '../../lib/GuestComponent/GuestPopupBox';

export const createInventoryItemContext = React.createContext();
const Inventory = ({
  getIngredientButtonClick,
  getRecipeCatalogueButtonClick,
  getPreferencesButtonClick,
  // setStepIcon,
}) => {
  const location = useLocation();
  const invProcessBarSteps = useSelector(state => state.inventoryProcessSteps);
  const cascadingsSreenStore = useSelector(state => state.cascadingStore);

  const dispatch = useDispatch();
  // console.log(location);

  React.useEffect(() => {
    dispatch(cascadingScreenActions.getConvertQueryKey());
  }, [cascadingsSreenStore.selectedIngredients]);

  const objectiveFormatSelection =
    cascadingsSreenStore.totalSelectResult.toString();

  // eslint-disable-next-line no-undef
  const cascading_First_QueryId =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_CASCADING_SELECTION_CLASSIFICATION_QUERY_ID_POINT_ONE;
  // eslint-disable-next-line no-undef
  const cascading_Second_QueryId =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_CASCADING_SELECTION_CLASSIFICATION_QUERY_ID_POINT_TWO;
  // eslint-disable-next-line no-undef
  const cascading_Third_QueryId =
    // eslint-disable-next-line no-undef
    process.env
      .REACT_APP_CASCADING_SELECTION_CLASSIFICATION_QUERY_ID_POINT_THREE;

  const no_Selection_Results_QuaryId =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_CASCADING_NO_SELECTION_CLASSIFICATION_QUERY_ID;
  const Food_Classification_Cascading_QueryId = `${cascading_First_QueryId} ${
    objectiveFormatSelection === undefined ? `<>` : objectiveFormatSelection
  } ${cascading_Second_QueryId}${
    objectiveFormatSelection === undefined ? `<>` : objectiveFormatSelection
  }${cascading_Third_QueryId}`;
  const [cascadingStatus, cascadingData] = useFetch(
    cascadingsSreenStore.totalSelectResult.length === 0
      ? no_Selection_Results_QuaryId
      : Food_Classification_Cascading_QueryId
  );
  React.useEffect(() => {
    dispatch(
      cascadingScreenActions.avoidLateLoadingWhileSelectingIngredient(
        cascadingStatus
      )
    );
  }, [cascadingStatus]);

  const ristrition = cascadingStatus === 'success' ? cascadingData.rows : [];

  const output = ristrition.reduce((prev, curr) => {
    const tmp = prev.find(e => e.searchfilter === curr.searchfilter);
    if (tmp) {
      tmp.ingredient_label.push(curr.ingredient_label);
      tmp.value.push(curr.value);
    } else {
      prev.push({
        searchfilter: curr.searchfilter,
        ingredient_label: [curr.ingredient_label],
        value: [curr.value],
        cuisine: curr.cuisine,
      });
    }

    return prev;
  }, []);

  const saperatedData = output;

  const mixedIngredients = saperatedData
    .flatMap(x => x.ingredient_label)
    .filter((el, i, arr) => i === arr.indexOf(el) && el);

  const duplicateCusineList = saperatedData.map(el => {
    const Data = el.cuisine;
    return Data;
  });

  const uniqueCusineList = duplicateCusineList.filter(
    (el, index, array) => index === array.indexOf(el)
  );

  let uniqueCusine = uniqueCusineList.join('+');

  const navigate = useNavigate();
  const nextProcess = () => {
    dispatch(cuisineScreenActions.clearSelectedCuisineContainer());
    dispatch(invProcessStepActions.gotoCuisinePage());
    dispatch(cascadingScreenActions.getShowTopInput());
    navigate(
      `${uniqueCusine}=${cascadingsSreenStore.totalSelectResult
        .join('&')
        .replaceAll('<', '.$')
        .replaceAll('>', '$')}`
    );
    // navigate(`${24}`);
  };

  // ======================================================InputCode for Cusine==============================================================================

  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const getFocus = () => {
    dispatch(cascadingScreenActions.setTrueInvNotification());
  };
  const getBlur = () => {
    dispatch(cascadingScreenActions.setFalseInvNotification());
  };

  const getSelect = e => {
    dispatch(
      cascadingScreenActions.getSelectIngredientFromRightSideSearch({
        element: e.target.textContent,
        mixedIngredients: mixedIngredients,
        otherDispatch: dispatch(
          invProcessStepActions.whileSelectIngredientStatus()
        ),
      })
    );
  };

  const firstLetterToUpperCase =
    inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
  const OPTIONS_LIMIT = 5;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  React.useEffect(() => {
    if (location.pathname.split('/').length === 2) {
      dispatch(
        cascadingScreenActions.changeIngredientTitle(
          `To view the corresponding recipes, first select the ingredients you want to use. Add an ingredient either by browsing the available ingredients by category or by searching for a specific ingredient in the search box on the right. After ingredient selection, click on the ‘Next’ button to go to cuisine selection..`
        )
      );
      dispatch(cascadingScreenActions.getHideTopInput());
    } else {
      dispatch(cascadingScreenActions.getShowTopInput());
    }
  }, [location]);

  return (
    <>
      {location.pathname.split('/')[5] !== 'uth' && (
        <div className="InvContainer">
          <PageBanner
            title="Inventory Challenge"
            src={bannerRightSideImage}
            mainText="Discover all the recipes based on your Ingredient selection."
            titleClass="InventorttitleClass"
            mainTextClass="InventorymainTextClass"
            imgClass="InventoryimgClass"
            bannerClass="inventoryTitleImgFlex"
          />
          <div className="height_Flex_Container">
            <div className="processStep-cards-flex">
              <div className="process-step-with-arrow">
                <ProcessSteps
                  processName="Select the ingredients"
                  ingredientsSelection={invProcessBarSteps.ingredientsSelection}
                  selectClass={firstProcessStep(
                    invProcessBarSteps.ingredientsSelection
                  )}
                  classForImgRoundContainer={
                    invProcessBarSteps.ingredientsSelection === 'success'
                      ? 'imgRoundSuccessContainer'
                      : 'imgRoundIdleContainer'
                  }
                  stepIcon={
                    location.pathname.split('/').length > 2 ? (
                      <img src={successIcon} className="successRightIconSize" />
                    ) : (
                      <img
                        src={preferenceLogoInStepBar}
                        className="stepIconSize"
                      />
                    )
                  }
                  processClick={getIngredientButtonClick}
                />
                <Arrow
                  selectClass={firstProcessArrow(
                    invProcessBarSteps.ingredientsSelection
                  )}
                />
              </div>
              <div className="process-step-with-arrow">
                <ProcessSteps
                  processName="Select cuisine type"
                  selectClass={secondProcessStep(
                    invProcessBarSteps.cuisinePageSelection
                  )}
                  classForImgRoundContainer={
                    invProcessBarSteps.cuisinePageSelection === 'success'
                      ? 'imgRoundSuccessContainer'
                      : 'imgRoundIdleContainer'
                  }
                  stepIcon={
                    location.pathname.split('/').length > 3 ? (
                      <img src={successIcon} className="successRightIconSize" />
                    ) : (
                      <img
                        src={ingredientLogoInStepBar}
                        className="stepIconSize"
                      />
                    )
                  }
                  processClick={getPreferencesButtonClick}
                />
                <Arrow
                  selectClass={secondProcessArrow(
                    invProcessBarSteps.cuisinePageSelection
                  )}
                />
              </div>
              <div className="process-step-with-arrow">
                <ProcessSteps
                  processName="Select a recipe from the catalogue"
                  selectClass={thirdProcessStep(
                    invProcessBarSteps.ingredientsSecondPageSelection
                  )}
                  classForImgRoundContainer={
                    invProcessBarSteps.ingredientsSecondPageSelection ===
                    'success'
                      ? 'imgRoundSuccessContainer'
                      : 'imgRoundIdleContainer'
                  }
                  stepIcon={
                    // ingredientsSecondPageSelection === 'success'
                    location.pathname.split('/').length > 4 ? (
                      <img src={successIcon} className="successRightIconSize" />
                    ) : (
                      <img
                        src={recipeCatalogueLogoInStepBar}
                        className="stepIconSize"
                      />
                    )
                  }
                  processClick={getRecipeCatalogueButtonClick}
                  // setStepIcon={setStepIcon}
                />
                <Arrow
                  selectClass={thirdProcessArrow(
                    invProcessBarSteps.ingredientsSecondPageSelection
                  )}
                />
              </div>

              <div className="process-step-with-arrow">
                <ProcessStepWithDropDown
                  processName="Substitute the ingredients or serve as is!"
                  selectClass={fourthProcessStep(
                    invProcessBarSteps.ingredientsLastPageSelection
                  )}
                  classForImgRoundContainer={
                    invProcessBarSteps.ingredientsLastPageSelection ===
                    'success'
                      ? 'imgRoundSuccessContainer'
                      : 'imgRoundIdleContainer'
                  }
                  stepIcon={
                    invProcessBarSteps.ingredientsLastPageSelection ===
                    'success' ? (
                      // location.pathname.split('/').length > 3
                      <img src={successIcon} className="successRightIconSize" />
                    ) : (
                      <img
                        src={substituteIngredientLogoInStepBar}
                        className="stepIconSize"
                      />
                    )
                  }
                />
              </div>
            </div>
          </div>
          {cascadingsSreenStore.ingredientTitle === '' ? null : (
            <div className="paraGraphTitleAndInputFlexForCascading">
              <>
                <Paragraph value={cascadingsSreenStore.ingredientTitle} />
                {cascadingsSreenStore.hidetopInput && (
                  <div className="searchInputInCascadingScreenInInventory">
                    <Autocomplete
                      key={cascadingsSreenStore.selectedIngredients}
                      ListboxProps={{
                        style: {
                          maxHeight: '26em',
                          fontSize: '.7em',
                          fontFamily: `'Roboto', sans-serif`,
                        },
                      }}
                      noOptionsText={
                        <span
                          style={{
                            color: 'gray',
                            fontSize: '.7em',

                            fontFamily: `'Roboto', sans-serif`,
                          }}
                        >
                          Search not found
                        </span>
                      }
                      clearOnBlur
                      selectOnFocus
                      onChange={getSelect}
                      onBlur={getBlur}
                      onFocus={getFocus}
                      open={open}
                      onOpen={() => {
                        if (inputValue) {
                          setOpen(true);
                        }
                      }}
                      onClose={() => setOpen(false)}
                      inputValue={firstLetterToUpperCase}
                      onInputChange={(e, value) => {
                        setInputValue(value);

                        if (!value) {
                          setOpen(false);
                        }
                      }}
                      onClick={() => console.log('hello')}
                      sx={{
                        display: 'inline-block',
                        '& input': {
                          width: '12rem',

                          bgcolor: 'background.paper',
                          color: theme =>
                            theme.palette.getContrastText(
                              theme.palette.background.paper
                            ),
                        },
                      }}
                      id="custom-input-demo"
                      filterOptions={filterOptions}
                      options={mixedIngredients}
                      renderInput={params => (
                        <div
                          className="input-iconflex topInputSearch"
                          ref={params.InputProps.ref}
                        >
                          <div
                            className="iconContainerInInputSearchInInventoryy"
                            style={{
                              backgroundColor:
                                cascadingsSreenStore.invNotifaction
                                  ? `${AppColors.inventory.input_active_color}`
                                  : `${AppColors.input_inActive_bg_color}`,
                              border: cascadingsSreenStore.invNotifaction
                                ? `.08rem solid ${AppColors.inventory.input_active_color}`
                                : `.08rem solid ${AppColors.input_blur_color}`,
                            }}
                          >
                            <BiSearch
                              size={'1.3em'}
                              className="icon"
                              style={{
                                color: cascadingsSreenStore.invNotifaction
                                  ? `white`
                                  : `${AppColors.input_blur_color}`,
                              }}
                            />
                          </div>
                          <input
                            className="input-materialui"
                            placeholder={`Search ingredients...`}
                            type="text"
                            {...params.inputProps}
                            style={{
                              border: cascadingsSreenStore.invNotifaction
                                ? `.08rem solid ${AppColors.inventory.input_active_color}`
                                : `.08rem solid ${AppColors.input_blur_color}`,
                            }}
                          />
                        </div>
                      )}
                    />
                  </div>
                )}
              </>
            </div>
          )}
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route
                // path="/"
                index
                element={
                  <>
                    <CascadingContainer
                      saperatedData={saperatedData}
                      cascadingStatus={cascadingStatus}
                      LoadingColor="#f79a34"
                      categoriesButtonActive="categoriesButtonsActive"
                    />

                    <div className="proccedButtonsBackButtonsFlex">
                      <ProcessButtons
                        nextProcess={nextProcess}
                        text="Next"
                        buttonClassName="processForwordButton processBtn"
                      />
                    </div>
                  </>
                }
              />
              <Route
                path={`:id`}
                element={
                  <CuisineSelection
                    saperatedData={saperatedData}
                    navigationButtonClassName="cusineProceedButton"
                    navigationButtonClassNameActive="cusineProceedButton cusineProceedButtonActive"
                    LoadingColor="#f79a34"
                    cuisineBackButtonClass="cusineBackButton"
                    getIngredientButtonClick={getIngredientButtonClick}
                  />
                }
              />
              {/* <Route path=":id" element={<CuisineElement />} /> */}
              {/* <Route path=":id/:ig/*" element={<RecipeElement />} /> */}

              <Route
                path=":id/:ig/*"
                element={
                  <RecipeCardContainer
                    LoadingColor="#f79a34"
                    cardsBackButtonClass={
                      'preferenceCardsBackButtonInInventory'
                    }
                    getPreferencesButtonClick={getPreferencesButtonClick}
                  />
                }
              />
            </Route>
          </Routes>
        </div>
      )}
      <Routes>
        <Route path="/" element={<UthLayout />}>
          <Route
            path={`:id/:ig/:item/uth/*`}
            element={
              <>
                {
                  location.pathname.split('/')[5] === 'uth' && (
                    <UnderTheHoodContainer
                      titleAndbacktoRecipeFlex="titleAndbacktoRecipeFlexInInventoryChallange"
                      backToRecipeContainerButton="backToRecipeContainerButtonWithInventory"
                      LoadingThemeColor="#f79a34"
                    />
                  )
                  // )
                }
              </>
            }
          />
          <Route
            path={`:cuisine/:recipe/:childId/timeStatistics/guestPage`}
            element={
              <>
                <GuestPopupBox className="guestInInv" />
              </>
            }
          />
        </Route>
      </Routes>
    </>
  );
};
const Layout = () => {
  return <Outlet />;
};
const UthLayout = () => {
  return <Outlet />;
};

export default Inventory;
