import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthHeader } from '../../lib';
import { authAction } from '../../Redux/Slices/AuthSlides/AuthenticationStates';
import AppLoginFooter from './AppLoginFooter';

import './AuthLogin.css';

const AuthLogin = () => {
  const dispatch = useDispatch();
  const authenticationStore = useSelector(s => s.authStore);
  const typeofActionClick = () => {
    dispatch(authAction.setLogin(!authenticationStore.isLogin));
    // setIsLogin(p => !p);
  };
  // var str = 'Hello^# World.../';
  // var remove = str.replace(/[^.]/g, '');
  // console.log(remove);
  return (
    <div className="AuthLoginContainer">
      <AuthHeader
        title="Sign in"
        userType="New user?"
        typeOfAction="Create an account"
        required={true}
        onClick={typeofActionClick}
      />
      <AppLoginFooter />
    </div>
  );
};

export default AuthLogin;
