import React from 'react';

const ShowOntologiesList = ({ antologyList, title }) => {
  return (
    <div className="ontologiesListComponent">
      <div className="ontologiesTheyAreTitle">{title}</div>
      <ul className="ontologiesULList">{antologyList}</ul>
    </div>
  );
};

export default ShowOntologiesList;
