import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { authAction } from '../../../Redux/Slices/AuthSlides/AuthenticationStates';
import './AuthCountryPhoneInput.css';
const AuthCountryPhoneField = ({ setIsValid }) => {
  const dispatch = useDispatch();
  const authenticationStore = useSelector(s => s.authenticationStore);

  const [selCountryCode, setSelCountryCode] = React.useState('us');
  const [selCountryExpectedLength, setSelCountryExpectedLength] =
    React.useState(0);

  const getPhoneNumberoFuser = phone => {
    dispatch(authAction.getMobileNumber(phone));
  };

  authenticationStore?.signUpDetails?.phoneNumber?.length ===
  selCountryExpectedLength
    ? authenticationStore?.signUpDetails?.phoneNumber?.length !== 0
      ? setIsValid(true)
      : setIsValid(false)
    : setIsValid(false);

  return (
    <div>
      <label className="CountryPhoneNumberLabel">Phone Number*</label>
      <PhoneInput
        dropdownClass="dropDownPannelClass"
        country={'us'}
        value={authenticationStore?.signUpDetails?.phoneNumber}
        countryCodeEditable={false}
        // enableSearch={true}
        placeholder=""
        onChange={(inputPhone, countryData) => {
          // console.log(countryData.format);
          if (countryData.countryCode !== selCountryCode) {
            setIsValid(true);
          } else {
            // setPhone(inputPhone);
            getPhoneNumberoFuser(inputPhone);
          }
          setSelCountryCode(countryData.countryCode);
          setSelCountryExpectedLength(
            countryData.format
              .split('')
              .filter(k => k === '.')
              .join('').length
          );
        }}
      />
    </div>
  );
};

export default AuthCountryPhoneField;
