import React from 'react';
import './AuthButton.css';

const AuthButton = ({
  buttonText,
  width,
  height,
  type,
  onClick,
  disabled,
  diableColor,
}) => {
  return (
    <button
      type={type}
      className="AuthButton"
      style={{ width: width, height: height, backgroundColor: diableColor }}
      onClick={onClick}
      disabled={disabled === undefined ? false : disabled}
    >
      {buttonText}
    </button>
  );
};

export default AuthButton;
