import React from 'react';
import { SubstrituteData, SubstituteReplaceData } from '../../index';
import { ToolTipInfo } from '../../../lib';
import { useLocation } from 'react-router';
import {
  invProcessStepActions,
  fullRecipeDetailsActions,
  useDispatch,
  useSelector,
} from '../../../Redux';

/**
 * [Component] SubstituteGeneratorPage
 * @description renders the substitute and ingredient list.
 
 * @param {*} setIngredientTerminolgyShow accepts the boolean.
 * @param {*} setIngredientsLastPageSelection accepts the string.
 * @param {*} setresultVar stores the data after fetching the API.
 * @param {String} timelineProccesButtonBlock stores the data.
 * @param {*} setArrayOfResult sets the array of data.
 * @param {*} setVarIng sets the array of data.
 * @param {*} setArrayOfdata sets the array of data.
 * @param {String} arrayOfdata accepts the array of data.
 * @param {*} getresetButtonClick stores the data after fetching the API.
 * @param {String} recipeListStatus changes state depending on the API fetch.
 * @param {Array} allRecipeListData stores the data after fetching the API.
 * @param {*} setClicKStatus sets the boolean value.
 * @param {*} setSubIndex it accepts the number.
 * @param {*} setSubstituteDataValue sets the string.
 * @param {*} setStatusDataValue sets the boolean value.
 * @param {*} setGetTittle accepts the string.
 * @param {*} setTimeValueButtons accepts two variables and also set the data.
 * @param {String} buttonClassName returns the string format for the class name.
 * @param {*} arrowClassName stores the data after fetching the API.
 * @param {*} substituteReplacedClassName stores the data after fetching the API.
 * @param {*} hidingTheIcon stores the data after fetching the API.
 
 * @returns the complete ingredients and substitute ingredients list.
*/

function SubstituteGenerator({ LoadingThemeColor }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const fullRecipeDetailsStore = useSelector(s => s.fullRecipeDetailsStore);
  const getresetButtonClick = () => {
    dispatch(fullRecipeDetailsActions.getReset());
    setTimeValueButtons({
      ...timeValueButtons,
      refObject: null,
    });
    dispatch(fullRecipeDetailsActions.changeResetButtonActive(false));
    dispatch(fullRecipeDetailsActions.changeSaveButtonStatus(false));
    dispatch(invProcessStepActions.lastprocessStepProgressStatus());
  };

  const buttonClick = () => {
    if (fullRecipeDetailsStore.resetButtonActive) {
      dispatch(
        fullRecipeDetailsActions.getChangeIngredientTeminologyShow(true)
      );
    } else {
      dispatch(
        fullRecipeDetailsActions.getChangeIngredientTeminologyShow(false)
      );
    }
    dispatch(invProcessStepActions.lastprocessStepSuccessStatus());
  };

  const [getTittle, setGetTittle] = React.useState('');

  const getClickSubData = (e, i) => {
    dispatch(fullRecipeDetailsActions.setSubstitutionOptionsKey(e.ing_value));
    dispatch(fullRecipeDetailsActions.getSteIndex(i));
    dispatch(fullRecipeDetailsActions.getIngredientKey(e.ing));
    setGetTittle(e.ing);
    setTimeValueButtons({
      ...timeValueButtons,
      refObject: timeValueButtons.timeButtonActives[i],
    });
    dispatch(fullRecipeDetailsActions.changeResetButtonActive(true));
    dispatch(fullRecipeDetailsActions.setLateLoadingIssueInOptions('Loading'));
  };

  const getItemClick = e => {
    dispatch(fullRecipeDetailsActions.getItemClick(e));
  };

  const getSaveData = () => {
    dispatch(invProcessStepActions.setStepIcon(false));
    dispatch(
      fullRecipeDetailsActions.getSubstituteIngredientandKey({
        goBackDispatcher: dispatch(
          fullRecipeDetailsActions.getChangeIngredientTeminologyShow(false)
        ),
        successStatusStepBar: dispatch(
          invProcessStepActions.lastprocessStepSuccessStatus()
        ),
      })
    );
    dispatch(fullRecipeDetailsActions.setSubstitutionStatus(true));
  };
  const containerRef = React.useRef();

  React.useLayoutEffect(() => {
    scrollToTop();
  });

  function scrollToTop() {
    containerRef.current.scrollTop = 0;
  }
  const [timeValueButtons, setTimeValueButtons] = React.useState({
    refObject: null,
    timeButtonActives: fullRecipeDetailsStore.arrayOfdata,
  });

  const arrowClassName = i => {
    if (timeValueButtons.timeButtonActives[i] === timeValueButtons.refObject) {
      return 'arrowDefault arrowActiveGreen';
    } else {
      return 'arrowDefault';
    }
  };

  const hidingTheIcon = i => {
    if (timeValueButtons.timeButtonActives[i] === timeValueButtons.refObject) {
      return 'Hide';
    } else {
      return 'Show';
    }
  };

  return (
    <div className="timeStatisticsContainer">
      <div className="saveResetbuttonsAndRecipeListFlexImportent">
        <div className="ingredientListResetSaveChangesFlex">
          <h2
            className="IngredientListTitle"
            // style={{ fontSize: '1.5em', marginTop: '.7em' }}
          >
            Ingredient List{' '}
            <ToolTipInfo
              title="Select the ingredient for which you want to view the substitutes. They will appear on the substitute generator section on the right."
              placement="right"
            />
          </h2>

          <div className="buttonFlexSaveChanges">
            <button
              className={
                location.pathname.split('/')[1] === 'inventory'
                  ? fullRecipeDetailsStore.resetButtonActive
                    ? 'resetButtonActiveInInventory'
                    : 'resetButtonInInventory'
                  : fullRecipeDetailsStore.resetButtonActive
                  ? 'resetButtonActive'
                  : 'resetButton'
              }
              onClick={getresetButtonClick}
              // disabled={fullRecipeDetailsStore.saveButtonActive ? false : true}
            >
              Cancel
            </button>
            <button
              className={
                location.pathname.split('/')[1] === 'inventory'
                  ? fullRecipeDetailsStore.saveButtonActive
                    ? 'saveButtonActiveInInventory'
                    : 'saveButtonInInventory'
                  : fullRecipeDetailsStore.saveButtonActive
                  ? 'saveButtonActive'
                  : 'saveButton'
              }
              onClick={getSaveData}
              disabled={fullRecipeDetailsStore.saveButtonActive ? false : true}
            >
              Save Changes
            </button>
          </div>
        </div>

        <div className="ingredientListArrayMap">
          {/* array.map */}

          <SubstrituteData
            getClickSubData={getClickSubData}
            arrowClassName={arrowClassName}
            hidingTheIcon={hidingTheIcon}
          />
        </div>
      </div>

      {!fullRecipeDetailsStore.saveButtonActive && (
        <div className="backToPage1IngredientContainer">
          <button
            onClick={buttonClick}
            className={
              location.pathname.split('/')[1] === 'inventory'
                ? !fullRecipeDetailsStore.resetButtonActive
                  ? 'backToPage1IngredientActiveInInventory'
                  : 'backToPage1IngredientInInventory'
                : !fullRecipeDetailsStore.resetButtonActive
                ? 'backToPage1IngredientActive'
                : 'backToPage1Ingredient'
            }
          >
            Back
          </button>
        </div>
      )}

      <div
        className={
          location.pathname.split('/')[1] === 'inventory'
            ? 'leftSideContainerSubstituteGenerator leftSideContainerSubstituteGeneratorInInventory'
            : 'leftSideContainerSubstituteGenerator'
        }
      >
        <h3 className="substitiuteGeneratorTitle">
          Substitute Generator
          <ToolTipInfo
            title="All the substitute options for the selected ingredient will appear below. Click on 'Save Changes' button after selecting any to replace it with the original one."
            placement="bottom-start"
          />
        </h3>
        <div className="substitiuteGeneratorLabelName">
          List of substitutes for :
        </div>
        <div
          className={
            location.pathname.split('/')[1] === 'inventory'
              ? 'setTittleInSubstitutionInInventory'
              : 'setTittleInSubstitution'
          }
        >
          {getTittle}
        </div>
        <div className="substituteOptions" ref={containerRef}>
          {fullRecipeDetailsStore.varIng === '' ? null : (
            <>
              <SubstituteReplaceData
                getItemClick={getItemClick}
                LoadingThemeColor={LoadingThemeColor}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default SubstituteGenerator;
