import React from 'react';
import { useLocation } from 'react-router';

/**
 * [Component] InventoryChallengePageBanner
 
 * @description Card component to display the title

 * @param {string} Title accepts string as the title
 * @param {*} src accepts svg
 * @param {int} width accepts integer to set image width
 * @param {string} marginTopForText accepts string to set space between title and description
 * @param {string} marginForHedding accepts string to set title margin
 
 * @returns the topcard containing title and information
 */

function PageBanner({
  title,
  src,

  mainText,

  titleClass,
  mainTextClass,
  imgClass,
  bannerClass,
}) {
  const location = useLocation();
  return (
    <div className={bannerClass}>
      <div className="Tittle-paraGraph-flex">
        <h4 className={titleClass}>{title}</h4>
        <p className={mainTextClass}>{mainText}</p>
      </div>
      {location.pathname.split('/')[1] === 'stats' ? null : (
        <img src={src} className={imgClass} />
      )}
    </div>
  );
}

export default PageBanner;
