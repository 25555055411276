import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  rawDataPageData: [],
  underTheHoodProcessData: [],
  processDataStatus: 'idle',
  scaleValue: '',
};
const rawDataAndProcessDataSlice = createSlice({
  name: 'rawAndProcessDataState',
  initialState,
  reducers: {
    getApiInitialData: (state, { payload }) => {
      state.rawDataPageData = payload;
    },
    getApiProcessIntitalData: (state, { payload }) => {
      if (payload.status === 'success') {
        state.underTheHoodProcessData = payload.data;
      }
      state.processDataStatus = payload.status;
    },
    getScreenSizeValue: (state, { payload }) => {
      state.scaleValue = payload;
    },
  },
});

export const rawAndProcessDataActions = rawDataAndProcessDataSlice.actions;
export const rawAndProcessReducers = rawDataAndProcessDataSlice.reducer;
