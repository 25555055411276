import React from 'react';
import { useLocation } from 'react-router';
import { ToolTipInfo } from '../../../lib';
import {
  fullRecipeDetailsActions,
  invProcessStepActions,
  useDispatch,
  useSelector,
} from '../../../Redux';

/**
 * [Component] timeLineINgredientDetails
 * @description fetches the data of time statistics.
 
 * @param {*} setIngredientTerminolgyShow has a default string value.
 * @param {*} setIngredientsLastPageSelection set the state when function call. 
 * @param {*} value stores the data after fetching the API.
 * @param {*} setTimeStatisticData has a default string value.
 * @param {*}  fullRecipeDetails store specific card ingredient details. 
 * @param {*} arrayOfResult stores  ingredien detais  after fetching the API.
 
 * @returns the complete time statistics page.
*/

function RecipeDetails({ setShowGenerators }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const fullRecipeDetailsStore = useSelector(
    state => state.fullRecipeDetailsStore
  );
  const getClick = () => {
    dispatch(fullRecipeDetailsActions.setSubstitutionOptionsKey(''));
    dispatch(fullRecipeDetailsActions.getSteIndex(null));
    dispatch(fullRecipeDetailsActions.getChangeIngredientTeminologyShow(true));
    setShowGenerators('substituteGenerator');
    dispatch(invProcessStepActions.lastprocessStepProgressStatus());
  };

  //  filter out particular cart data based on iri
  // console.log(output);
  return (
    <>
      <div className="ingredientTitleButtonFlex">
        <div className="staticTitle">
          Ingredient Details
          <ToolTipInfo
            title="Given below are the ingredient related details about the recipe. Click on the 'Substitute an Ingredient' button to find out the substitutes of the mentioned ingredients."
            placement="right"
          />
        </div>
        {location.pathname.split('/')[1] === 'inventory' && (
          <button
            onClick={getClick}
            className={
              location.pathname.split('/')[1] === 'inventory'
                ? 'btnForIngredientSubstituteInInventoryChallenge'
                : location.pathname.split('/')[1] === 'process' &&
                  'btnForIngredientSubstituteInProcessChallenge'
            }
          >
            Substitute an ingredient
          </button>
        )}
      </div>
      <div className="servingsIngredientsFlex">
        <div className="dots">
          {/* ============================================ */}
          <div className="numberOfIngredients" style={{ fontWeight: 'bolder' }}>
            Number of Ingredients
          </div>
          <div className="ingredientCount">
            {
              fullRecipeDetailsStore?.fullRecipeDetails?.rows[0]
                .ingredient_count
            }
          </div>
        </div>
        <div className="servings-container">
          <div style={{ fontWeight: 'bolder' }} className="servings">
            Servings
          </div>
          <div className="ingredientCount">
            {fullRecipeDetailsStore?.fullRecipeDetails?.rows[0].serving}
          </div>
          {/* array.map */}
        </div>
      </div>
      <div className="ingredientTerminology">
        <div className="ingredientQuality" style={{ fontWeight: 'bolder' }}>
          Ingredient Quantity
        </div>
        {fullRecipeDetailsStore.fullRecipeDetails.length === 0 ? (
          <h4>Loading.....</h4>
        ) : (
          <ul id="non" className="ulContainer">
            {fullRecipeDetailsStore.arrayOfResult.map((e, i) => (
              <li key={i}>
                {/* {e.replace(/^(.)|\s+(.)/g, c => c.toUpperCase())} */}
                {e.toLowerCase()}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
}

export default RecipeDetails;
