/* eslint-disable no-undef */
import { select } from 'd3-selection';
import * as d3 from 'd3';
import {
  forceCenter,
  forceLink,
  forceManyBody,
  forceSimulation,
} from 'd3-force';
import React, { useEffect } from 'react';

import { tick } from './events';
// import './graph.css';
import drag from '../RecipeGraph/DragOption';
import { ZoomButton, convertRemToPixels } from '../../../lib';
import { Zoomed } from '../../../utils';
import '../ClassLayout/graph.css';

// TODO: make component independent of restProps
// TODO: handle error when line is not <line>
// TODO: add centering node on click
// TODO: split to separate useEffects and useState

// if you'll try do develop package locally with 'npm link'
// probably you will get error 'Invalid hook call.'
// see https://github.com/facebook/react/issues/15315#issuecomment-479802153

// so better fork and/or use branch name in package.json dependencies like so:
// "react-graph-network": "github:AlyonaShadrina/react-graph-network#<branch>"
// dont't forget to `rm -rf ./node_modules/react-graph-network`, maybe clear your package.json and `npm i`

const Graph = ({
  data,
  id,

  ...restProps
}) => {
  const [name, setName] = React.useState('');
  const forceLayoutRef = React.useRef(null);
  useEffect(() => {
    if (!data) {
      return null;
    }

    let zoom = d3.zoom().on('zoom', zoomed);
    let selectMainGroup = select('._graphZoom');
    var transform = d3.zoomIdentity.scale(convertRemToPixels(0.09));
    const svg = select(`#${id}`).call(zoom);
    zoom.scaleTo(svg.transition().duration(0), convertRemToPixels(0.09));

    let zooIdSelect = selectMainGroup.attr('transform', transform);
    function zoomed(event) {
      if (zooIdSelect) {
        zooIdSelect.attr('transform', event.transform);
      }
    }

    Zoomed(
      '#zoom_in_force',
      '#zoom_out_force',
      '#forceLayoutGraphZoomReset',
      svg,
      zoom,
      selectMainGroup
    );
    const link = svg.selectAll('._graphLine').data(data.links);
    const simulation = forceSimulation(data.nodes)
      .force(
        'link',
        forceLink() // This force provides links between nodes
          .id(function (d) {
            return d.name;
          }) // This provide the id of a node
          .links(data.links)
          .distance(2) // and this the list of links
      )
      .force('charge', forceManyBody().strength(-1 * 30)) // This adds repulsion between nodes. Play with the -400 for the repulsion strength
      .force(
        'center',
        forceCenter(
          svg._groups[0][0].parentElement.clientWidth / 2,
          svg._groups[0][0].parentElement.clientHeight / 2
        )
      )
      .force('x', d3.forceX())
      .force('y', d3.forceY())

      .on('tick', () => tick(node, link));
    const node = svg
      .selectAll('._graphNode')
      .data(data.nodes)
      .call(drag(simulation));

    // add interactions
  }, [data]);

  if (!data) {
    return null;
  }

  return (
    <div className="ClassLayoutGraphContainer">
      <svg
        id={id}
        width="100%"
        height="100%"
        ref={forceLayoutRef}
        {...restProps}
      >
        <g className="_graphZoom">
          {data.links.map((link, i) => {
            return <line stroke="#d6d6d6" key={i} className="_graphLine" />;
          })}
          {data.nodes.map((node, i) => {
            return (
              <g
                key={i}
                className="_graphNode"
                onMouseEnter={() => setName(node.name)}
                onMouseLeave={() => setName('')}
              >
                <circle
                  className={'nodesInforceLayOut'}
                  r={3}
                  style={{ cursor: 'pointer' }}
                />
                {node.name === name ? (
                  <text style={{ fontSize: '10px', cursor: 'pointer' }}>
                    {name}
                  </text>
                ) : null}
              </g>
            );
          })}
        </g>
      </svg>

      <ZoomButton
        zoomOutId={'zoom_out_force'}
        zoomInId={'zoom_in_force'}
        zoomResetId={'forceLayoutGraphZoomReset'}
        zoomCenter="forceLayoutSetCenter"
      />
    </div>
  );
};

export default Graph;
