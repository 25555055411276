import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  data: [],

  saveButtonActive: '',
  subKey: '',
  subnodes: [],
  subLink: [],
  index: '',
  subGraphdata: [],
  substituteStatus: '',
  defaultData: '',
  selectedSubstituteNodes: '',
  changeSave: '',

  deleteNodes: '',
  length: '',
  oData: [],
};
const substitutionSlice = createSlice({
  name: 'substitutionGraphStates',
  initialState,
  reducers: {
    getSubGraphDataFromApi: (state, { payload }) => {
      state.subGraphdata = payload;
    },
    getSubstituteDataStatusFromApi: (state, { payload }) => {
      state.substituteStatus = payload;
      if (state.substituteStatus === 'success') {
        let subParentsNode = {
          id: '0',

          name: state.subGraphdata[0].Recipe.value,
        };

        let subChildNode = state.subGraphdata.map((e, i) => {
          // let slashSplit = e.property.value.split('/');

          // let hashtagSplit = slashSplit[slashSplit.length - 1].split('#');
          // const specialCharectorRemovedNode =
          //   hashtagSplit[hashtagSplit.length - 1];
          let data = {
            id: `${i + 1}`,
            name: `${e.food.value}:${e.quantity.value}`,
            quantity: e.quantity.value,
            value: e.value.value,
            p: e.property.value,
            edgeTypes: e.property.value,
            actualName: e.food.value,
            status: e.sub_status.value,
          };
          return data;
        });

        state.subnodes = [subParentsNode, ...subChildNode];

        let sourceLink = `${state.subnodes[0].id}`;

        state.subLink = state.subnodes.map(e => {
          let data = {
            source: sourceLink,
            target: e.id,
            edgeTypes: e.edgeTypes,
            name: e.name,
          };

          return data;
        });

        state.subLink.shift();
        state.oData = {
          nodes: state.subnodes,
          links: state.subLink,
        };
      }
      state.data = state.oData;
    },
    rawDataClick: (state, { payload }) => {
      state.data.nodes.length = payload;
      let nodesData = state.data.nodes.map(el => {
        let result = {
          id: el.id,
          name: el.name,
          actualName: el.actualName === undefined ? '' : el.actualName,
          edgeTypes: el.edgeTypes === undefined ? '' : el.edgeTypes,
          p: el.p === undefined ? '' : el.p,
          quantity: el.quantity === undefined ? '' : el.quantity,
          value: el.value === undefined ? '' : el.value,
          status: el?.status,
        };
        return result;
      });
      let linksNode = nodesData.map(el => {
        let result = {
          source: nodesData[0].id,
          target: el.id,
          edgeTypes: 'hasIngredient',
          name: el.name,
        };
        return result;
      });
      linksNode.shift();

      let odata = {
        nodes: nodesData,
        links: linksNode,
      };
      state.data = odata;
    },
    recipeGraphClick: (state, { payload }) => {
      state.data.nodes.length = payload;
      let nodesData = state.data.nodes.map(el => {
        let result = {
          id: el.id,
          name: el.name,
          actualName: el.actualName === undefined ? '' : el.actualName,
          edgeTypes: el.edgeTypes === undefined ? '' : el.edgeTypes,
          p: el.p === undefined ? '' : el.p,
          quantity: el.quantity === undefined ? '' : el.quantity,
          value: el.value === undefined ? '' : el.value,
          status: el?.status,
        };
        return result;
      });
      let linksNode = nodesData.map(el => {
        let result = {
          source: nodesData[0].id,
          target: el.id,
          edgeTypes: 'hasIngredient',
          name: el.name,
        };
        return result;
      });
      linksNode.shift();

      let odata = {
        nodes: nodesData,
        links: linksNode,
      };
      state.data = odata;
    },
    vocabularyGraphClick: (state, { payload }) => {
      state.data.nodes.length = payload;
      let nodesData = state.data.nodes.map(el => {
        let result = {
          id: el.id,
          name: el.name,
          actualName: el.actualName === undefined ? '' : el.actualName,
          edgeTypes: el.edgeTypes === undefined ? '' : el.edgeTypes,
          p: el.p === undefined ? '' : el.p,
          quantity: el.quantity === undefined ? '' : el.quantity,
          value: el.value === undefined ? '' : el.value,
          status: el?.status,
        };
        return result;
      });
      let linksNode = nodesData.map(el => {
        let result = {
          source: nodesData[0].id,
          target: el.id,
          edgeTypes: 'hasIngredient',
          name: el.name,
        };
        return result;
      });

      linksNode.shift();

      let odata = {
        nodes: nodesData,
        links: linksNode,
      };
      state.data = odata;
    },
    setDataFromChangedStructure: (state, { payload }) => {
      state.data = payload;
    },
  },
});
export const substituteComponentAction = substitutionSlice.actions;
export const substituteGraphReducers = substitutionSlice.reducer;
