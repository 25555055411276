import React from 'react';
import { useSelector } from 'react-redux';
import { Loading, InstructionsInRawData } from '../..';

const RawDataPageInProcessChallenge = ({ LoadingThemeColor }) => {
  const rawAndProcessStore = useSelector(state => state.rawAndProcessStore);
  // console.log(rawAndProcessStore);
  const ProcessData =
    rawAndProcessStore.underTheHoodProcessData.data.results.bindings;
  const preferenceStore = useSelector(state => state.preferenceStore);

  const Instructions = ProcessData.map(ej => {
    return ej.instructions.value;
  });
  const ProcessInvolved = ProcessData.map(ej => {
    return ej.process.value;
  });
  const IngredientUsed = ProcessData.map(ej => {
    return ej.ingredient.value;
  });

  return (
    <>
      {rawAndProcessStore.processDataStatus !== 'success' && (
        <Loading color={LoadingThemeColor} />
      )}
      {rawAndProcessStore.processDataStatus === 'success' && (
        <>
          <div className="rawDataContainerInProcessChallenge">
            <div className="recipeTitleInprocessRawData headerRecipe">
              <h4 className="recipeTitle">Recipe : </h4>{' '}
              <h4 className="recipeTitle recipeColor">
                {preferenceStore.selectedRecipeData.Recipes}
              </h4>
            </div>
            <div className="recipeInstructionHeading instructionHeddingHeight">
              Recipe Instructions :
            </div>
            <div className="recipeInstruction">
              <InstructionsInRawData
                UnderTheHoodRecipeInstructionMapArray={Instructions}
                className="recipeInstructionDataInProcessChallege"
              />
            </div>
            <div className="recipeInstructionHeading instructionHeddingHeight">
              Cooking variables:
            </div>
            <div className="recipeInstruction">
              <li className="recipeInstructionDataInProcessChallege">
                <span className="recipeRaw-list">Time</span>
              </li>
              <li className="recipeInstructionDataInProcessChallege">
                <span className="recipeRaw-list">Temperature</span>
              </li>
            </div>
            <div className="recipeInstructionHeading">Processes involved :</div>
            <div className="recipeInstruction">
              <InstructionsInRawData
                UnderTheHoodRecipeInstructionMapArray={ProcessInvolved}
                className="recipeInstructionDataInProcessChallege"
              />
            </div>
            <div className="recipeInstructionHeading instructionHeddingHeight">
              Ingredients Used :
            </div>
            <div className="GridContainerInProcessData">
              {IngredientUsed[0].split(',').map((el, i) => (
                <li key={i}>
                  {el
                    .replaceAll('[', '')
                    .replace(']', '')
                    .replaceAll('(', '')
                    .replaceAll(')', '')}
                </li>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RawDataPageInProcessChallenge;
