import React from 'react';

const StatisticsPropertiesComponent = ({
  title,
  totalVocabulariesUsed,
  internal,
  external,
  classesUsed,
}) => {
  return (
    <>
      <div className="statsProtertiesContainer">
        <div className="PropertiesMainUseCaseTitle">{title}</div>
        <div className="useVocabularyCount">
          Total vocabularies used : <span>{totalVocabulariesUsed}</span>
        </div>
        <div className="externalInternalUse">Internal : {internal}</div>
        <div className="externalInternalUse">External : {external}</div>
        <div className="useVocabularyCount totalCountClass">
          Classes used :{' '}
          <span>{classesUsed === undefined ? `Loading...` : classesUsed}</span>
        </div>
      </div>
    </>
  );
};

export default StatisticsPropertiesComponent;
