import React from 'react';
import { AuthButton, AuthInput, AuthPassword } from '../../lib';
import AuthErrorMessage from '../../lib/Auth/AuthErrorMessage/AuthErrorMessage';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase';

import { NavLink, useNavigate } from 'react-router-dom';
import PageDivider from '../../lib/PageDivider/PageDivider';
import AuthSocialMediaIcon from '../../lib/Auth/AuthSocialMediaIcon//AuthSocialMediaIcon';
import {
  getItemFromMemory,
  setDatainLocalMemory,
} from '../../utils/ManageLocalStorage';
import { useDispatch } from 'react-redux';
import { authAction } from '../../Redux/Slices/AuthSlides/AuthenticationStates';

const AppLoginFooter = () => {
  const [emailAddress, setEmailAddress] = React.useState('');
  const [emailSuccess, setEmailSuccess] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const dispatch = useDispatch();

  const getEmailChange = e => {
    setEmailAddress(e.target.value);
    setPasswordErrorStatus(false);
  };

  const getPassword = event => {
    setPassword(event.target.value);
    setPasswordErrorStatus(false);
  };

  const [passwordErrorStatus, setPasswordErrorStatus] = React.useState(false);

  const navigate = useNavigate();
  const RegisterUser = async e => {
    e.preventDefault();
    try {
      const { user } = await signInWithEmailAndPassword(
        auth,
        emailAddress,
        password
      );
      setDatainLocalMemory('applicationUser', user);
      dispatch(authAction.setUser(user));

      dispatch(authAction.getFeedbackCancelIcon(true));
      let url = getItemFromMemory('url');
      navigate(url.pathname === '/' ? '/home' : url.pathname);
      // console.log(url);
    } catch (e) {
      // console.log(e);
      if (e.code !== undefined) {
        setPasswordErrorStatus(true);
      }
    }
  };

  const getNotification = () => {
    setEmailSuccess(false);
  };
  const getNotification1 = () => {};
  const [passWordNotification, setPassWordNotification] = React.useState(false);

  const getPasswowrNotification = () => {
    setPassWordNotification(true);
  };
  const getPasswowrBlurNotification = () => {
    setPassWordNotification(false);
  };
  const forgetFunction = () => {
    dispatch(authAction.setLogin(false));
    dispatch(authAction.setForgetPasswordStatus(true));
  };
  return (
    <div className="LoginContainer">
      <div>
        <form className="AuthLoginFormContainer" onSubmit={RegisterUser}>
          <AuthInput
            value={emailAddress}
            placeHolder="Email ID"
            onChange={getEmailChange}
            type="email"
            onFocus={getNotification}
            onBlur={getNotification1}
          />
          <div className="LoginAuthModuleWithError">
            <AuthPassword
              onChange={getPassword}
              password={password}
              placeHolder="Password"
              labelSize="0.87em"
              padding="0.5%"
              infoSvg=""
              verificationLabel1=""
              onFocus={getPasswowrNotification}
              onBlur={getPasswowrBlurNotification}
              notification={passWordNotification}
              passwordErrorStatus={passwordErrorStatus}
            />
            <div className="errorAndForgetPasswordFlex">
              <div className="erroeMessageInLogin">
                {passwordErrorStatus && (
                  <AuthErrorMessage errorMessage="Invalid email ID or password" />
                )}
              </div>

              <div onClick={forgetFunction} className="forgetPassword">
                <NavLink
                  to="/usernamerecovery"
                  className={isActive => (!isActive ? '' : 'unselected')}
                >
                  <span
                    style={{ color: 'orange', textDecorationColor: 'orange' }}
                  >
                    Forgot Password
                  </span>
                </NavLink>
              </div>
            </div>
          </div>

          <div className="AuthButtonContainer">
            <AuthButton
              buttonText="Sign in"
              emailSuccess={emailSuccess}
              width="7em"
              height="3em"
              type="submit"
            />
          </div>
        </form>
      </div>

      <PageDivider text="" marginTop="1em" />
      <NavLink
        to="/home"
        className={isActive => (!isActive ? '' : 'unselectedBackContent')}
      >
        <div
          className="AuthSocialMediaButtonContainer"
          // onClick={setNavigateUnderTheHoodPage(false)}
        >
          <AuthSocialMediaIcon
            icon={''}
            text="Continue as a guest"
            color="#f5a349"
          />
        </div>
      </NavLink>
    </div>
  );
};

export default AppLoginFooter;
