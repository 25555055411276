function hashCode(str) {
  let hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function pickColor(str, colorvalue) {
  // let color = `hsl(${hashCode(str) % 360}, 100%, 90%)`;
  // console.log(color);
  return `hsl(${hashCode(str) % 360}, 100%, ${colorvalue}%)`;
}

export default pickColor;
