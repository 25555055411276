import React from 'react';
import {
  fullRecipeDetailsActions,
  useDispatch,
  useSelector,
} from '../../../Redux';
import { useSubstituteFetch } from '../../../utils';
import { Loading } from '../../index';

/**
 * [Component] SubsritutereolaceData
 * @description fetches the substitute list of the selected ingredient item.
 
 * @param {Array} allRecipeListData stores the data after fetching the API.
 * @param {*} substituteDataValue stores the data after fetching the API. 
 * @param {*} getItemClick on function call get substitute ingredients of specific ingredient .
 
 * @returns replaceble ingrediets list.
 */

function SubstituteReplaceData({ getItemClick, LoadingThemeColor }) {
  const fullRecipeDetailsStore = useSelector(
    state => state.fullRecipeDetailsStore
  );
  const dispatch = useDispatch();
  const Ingredient_substitute_Result_Firsy_QueryId =
    // eslint-disable-next-line no-undef
    process.env
      .REACT_APP_SUBSTITUTION_OPTIONS_AFTER_CLICKING_INGREDIENT_QUERY_ID_POINT_ONE;
  const Ingredient_substitute_Result_Last_QueryId =
    // eslint-disable-next-line no-undef
    process.env
      .REACT_APP_SUBSTITUTION_OPTIONS_AFTER_CLICKING_INGREDIENT_QUERY_ID_POINT_TWO;

  const [recipeListStatus, substitutebleData] = useSubstituteFetch({
    api: `${Ingredient_substitute_Result_Firsy_QueryId}${fullRecipeDetailsStore.varIng} ${Ingredient_substitute_Result_Last_QueryId}`,
    substituteDataValue: fullRecipeDetailsStore.substituteDataValue,
  });
  React.useEffect(() => {
    dispatch(
      fullRecipeDetailsActions.setLateLoadingIssueInOptions(recipeListStatus)
    );
  }, [recipeListStatus]);

  return (
    <>
      {fullRecipeDetailsStore.substituteReplaceDataStatus === 'Loading' ? (
        <Loading color={LoadingThemeColor} />
      ) : (
        <>
          {substitutebleData.map((e, i) => {
            return (
              <button
                className="replacedByData"
                key={i}
                onClick={() => getItemClick(e)}
              >
                {/* {e.ing_sub.replace(/^(.)|\s+(.)/g, c => c.toUpperCase())} */}
                {e.ing_sub}
              </button>
            );
          })}
        </>
      )}
    </>
  );
}

export default SubstituteReplaceData;
