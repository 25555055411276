import React from 'react';
import { IoIosArrowForward, MdCancel } from '../../../icon';
import { useLocation } from 'react-router';
import {
  fullRecipeDetailsActions,
  useDispatch,
  useSelector,
} from '../../../Redux';

/**
 * [Component] SubstituteData
 * @description fetches according to the selected ingredient data.
 
 * @param {*} getClickSubData on function call get ingredient suboption.
 * @param {*} arrayOfdata stores the  ingredient details. 
 * @param {*} setArrayOfdata change ingredient details on function call.
 * @param {*} buttonClassName has a string value and depends on state set button active or inactive.
 * @param {*} arrowClassName change the color of arrow depends on state. 
 * @param {*} substituteReplacedClassName add cancel icon depends on state
 .
 * @param {*} hidingTheIcon rennder the component depends on state. 
 
 * @returns ingredient list of the selected data.
*/

const SubstrituteData = ({
  getClickSubData,
  arrowClassName,
  hidingTheIcon,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const fullRecipeDetailsStore = useSelector(s => s.fullRecipeDetailsStore);
  const removingItem = e => {
    dispatch(fullRecipeDetailsActions.getRemoveItem(e));
  };

  return (
    <div>
      {fullRecipeDetailsStore.arrayOfdata.map((e, i) => {
        return (
          <div
            className={`substituteDataReplaceData ${
              location.pathname.split('/')[1] === 'inventory'
                ? fullRecipeDetailsStore.substituteButtonToggle === i &&
                  `substituteDataReplaceDataActiveInInventory`
                : fullRecipeDetailsStore.substituteButtonToggle === i &&
                  `substituteDataReplaceDataActive`
            }`}
            key={i}
          >
            <div
              className="ingredientLists"
              style={{
                opacity: e?.sub_status !== 'Inactive' ? '1' : '.4',
                cursor:
                  e?.sub_status !== 'Inactive' ? 'pointer' : 'not-allowed',
              }}
              onClick={() =>
                e?.sub_status !== 'Inactive' ? getClickSubData(e, i) : null
              }
            >
              {e.ing.toLowerCase()}
            </div>

            {e.ing_sub !== '' && e.ing !== '' ? (
              <>
                <span
                  style={{ whiteSpace: 'nowrap', marginLeft: '.5em' }}
                  className={arrowClassName(i)}
                >
                  - - - - - - - - - - - - -
                </span>
                <div>
                  <IoIosArrowForward
                    style={{ marginTop: '.26em', marginRight: '.5em' }}
                    className={arrowClassName(i)}
                  />
                </div>
              </>
            ) : null}
            <div
              className={`substituteColorDefault ${
                location.pathname.split('/')[1] === 'inventory'
                  ? fullRecipeDetailsStore.substituteButtonToggle === i &&
                    `substituteColorActiveInInventory`
                  : fullRecipeDetailsStore.substituteButtonToggle === i &&
                    `substituteColorActive`
              }`}
            >
              {/* {e.ing_sub.replace(/^(.)|\s+(.)/g, c => c.toUpperCase())} */}
              {e.ing_sub.toLowerCase()}
            </div>
            {e.ing_sub !== '' && e.ing !== '' && hidingTheIcon(i) === 'Show' ? (
              <div className="iconBox">
                <MdCancel
                  onClick={() => removingItem(e)}
                  size="1.4em"
                  style={{
                    marginLeft: '.5em',
                    marginTop: '.1em',
                    color: '#E87A7A',
                  }}
                />
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default SubstrituteData;
