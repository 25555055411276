import React from 'react';
import '../../style/Inventory.css';
// import DashBoardReuseIcon from '../../assets/reuseIConInDashBoard.svg';
import { InActiveReuseIcon, ActiveReuseIcon } from '../../assets';

// import { authContext } from '../../App';
import { useLocation, useNavigate } from 'react-router';
import { statsSliceActions, useDispatch } from '../../Redux';

/**
 * [Component] SideBarInventoryButton
 
 * @description Toogle state on the click of Inventory button

 * @param {*} isClickInventory calls the function to render Inventory component 
 * @param {*} setIsClickHome accepts boolean.
 * @param {*} setIsClickInventory accepts boolean.
 * @param {*} setIsClickTime accepts boolean.
 * @param {*} setIsClickIngredient accepts boolean.
 * @param {*} setIsClickHealth  accepts boolean.
 
* @returns Renders the inventory component on function call
 */

const SideBarStatsButton = () => {
  // const { tokenKey, setTokenKey1 } = React.useContext(authContext);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const StatsClick = () => {
    navigate('/stats');
    dispatch(statsSliceActions.clearSubstituteAndProcessChange());
  };
  return (
    <div
      className={
        location.pathname.split('/')[1] === 'stats'
          ? 'statsButtonContainer'
          : 'statsButtonContainer sixe-step'
      }
    >
      <div className="imgAndTitleContent">
        <div
          onClick={StatsClick}
          className={
            location.pathname.split('/')[1] === 'stats'
              ? 'reuseCircleContainer  reuseCircleContainerActive'
              : 'reuseCircleContainer'
          }
        >
          <img
            src={
              location.pathname.split('/')[1] === 'stats'
                ? ActiveReuseIcon
                : InActiveReuseIcon
            }
            alt="reuseIcon"
            className="reuseButtonClassIn"
          />
        </div>
        <div
          className={
            location.pathname.split('/')[1] === 'stats'
              ? 'reuseIconText activeReuseButton'
              : 'reuseIconText '
          }
        >
          Reuse/Repurpose
        </div>
      </div>
    </div>
  );
};

export default SideBarStatsButton;
