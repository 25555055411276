import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isClickHome: false,
  isClickInventory: false,
  isClickTime: false,
  isClickIngredient: false,
  isClickHealth: false,
  isClickProcess: false,
  isClickStats: false,
};

const navSlice = createSlice({
  name: 'navBar',
  initialState,
  reducers: {
    homeClick: state => {
      state.isClickHome = true;
      state.isClickInventory = false;
      state.isClickTime = false;
      state.isClickIngredient = false;
      state.isClickHealth = false;
      state.isClickProcess = false;
      state.isClickStats = false;
    },
    inventoryClick: state => {
      state.isClickHome = false;
      state.isClickInventory = true;
      state.isClickTime = false;
      state.isClickIngredient = false;
      state.isClickHealth = false;
      state.isClickProcess = false;
      state.isClickStats = false;
    },
    timeClick: state => {
      state.isClickHome = false;
      state.isClickInventory = false;
      state.isClickTime = true;
      state.isClickIngredient = false;
      state.isClickHealth = false;
      state.isClickProcess = false;
      state.isClickStats = false;
    },
    ingredientClick: state => {
      state.isClickHome = false;
      state.isClickInventory = false;
      state.isClickTime = false;
      state.isClickIngredient = true;
      state.isClickHealth = false;
      state.isClickProcess = false;
      state.isClickStats = false;
    },
    healthClick: state => {
      state.isClickHome = false;
      state.isClickInventory = false;
      state.isClickTime = false;
      state.isClickIngredient = false;
      state.isClickHealth = true;
      state.isClickProcess = false;
      state.isClickStats = false;
    },
    processClick: state => {
      state.isClickHome = false;
      state.isClickInventory = false;
      state.isClickTime = false;
      state.isClickIngredient = false;
      state.isClickHealth = false;
      state.isClickProcess = true;
      state.isClickStats = false;
    },
    statsClick: state => {
      state.isClickHome = false;
      state.isClickInventory = false;
      state.isClickTime = false;
      state.isClickIngredient = false;
      state.isClickHealth = false;
      state.isClickProcess = false;
      state.isClickStats = true;
    },
  },
});
export const sideNavActions = navSlice.actions;
export const sideNavReducers = navSlice.reducer;
