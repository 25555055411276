import React from 'react';
import { BiTimeFive, BsBookHalf, GiRiceCooker } from '../../../icon';
import { useLocation, useNavigate } from 'react-router';
import {
  fullRecipeDetailsActions,
  invProcessStepActions,
  useDispatch,
  useSelector,
} from '../../../Redux';

/**
 * [Component] timelineProcessNavigation
 * @description Fetches the process data of recipes.
 
 * @param {string} timelineNavigation depends on string render Component.
 * @param {*} settimelineNavigation change timelineNavigation on function call. 
 * @param {*} setIngredientTerminolgyShow stores the data after fetching the API.
 * @param {*} timelineProccesButtonBlock has a default string value.
 * @returns navigates the total process details of the recipe.
*/

function TimelineProcessNavigation() {
  const location = useLocation();
  const dispatch = useDispatch();
  const fullRecipeDetailsStore = useSelector(s => s.fullRecipeDetailsStore);
  const navigate = useNavigate();
  const timeStatisticClick = () => {
    // setTimeLineNavigation('timeStatistic');

    dispatch(fullRecipeDetailsActions.getChangeIngredientTeminologyShow(false));
    navigate('timeStatistics');

    // dispatch(invProcessStepActions.lastprocessStepIdleStatus());
  };
  const ingredientDetailsClick = () => {
    if (!fullRecipeDetailsStore.substituteOccur) {
      dispatch(invProcessStepActions.lastprocessStepIdleStatus());
    }

    dispatch(fullRecipeDetailsActions.getChangeIngredientTeminologyShow(false));
    navigate('ingredient');
  };
  const recipeInstructionsClick = () => {
    // setTimeLineNavigation('recipeInstructions');
    navigate('recipeInstructions');
    // ===================================================================================
    if (location.pathname.split('/')[1] === 'process') {
      dispatch(invProcessStepActions.lastprocessStepProgressStatus());
    }
    if (location.pathname.split('/')[1] === 'inventory') {
      dispatch(invProcessStepActions.lastprocessStepSuccessStatus());
    }

    // ======================================================================================
    // }

    dispatch(fullRecipeDetailsActions.getChangeIngredientTeminologyShow(false));
  };
  // const nutritionInformationClick = () => {
  //   setTimeLineNavigation('nutritionInformation');
  //   if (location.pathname.split('/')[1] === 'process') {
  //     dispatch(invProcessStepActions.lastprocessStepSuccessStatus());
  //   }
  //   // }

  //   dispatch(fullRecipeDetailsActions.getChangeIngredientTeminologyShow(false));
  // };

  return (
    <div className="processNavigatorContainer">
      {location.pathname.split('/')[1] === 'inventory' ? (
        <>
          <div onClick={ingredientDetailsClick} className="flexIconAndText">
            <div
              className={
                // timelineNavigation === 'ingredientDetails'
                location.pathname.split('/')[5] === 'ingredient' ||
                location.pathname.split('/').length === 5
                  ? 'iconContainerTimeline ingredientDetails'
                  : 'iconContainerTimeline '
              }
            >
              <div>
                <BsBookHalf
                  className="timeLIneImageSize"
                  style={{ marginTop: '.2em' }}
                />
              </div>
            </div>
            <div
              className={
                // timelineNavigation === 'ingredientDetails'
                location.pathname.split('/')[5] === 'ingredient' ||
                location.pathname.split('/').length === 5
                  ? 'timelineText ingredientDetailsText'
                  : 'timelineText '
              }
            >
              Ingredient Details
            </div>

            <div className="line"></div>
          </div>
        </>
      ) : (
        <div onClick={recipeInstructionsClick} className="flexIconAndText">
          <div
            className={
              // timelineNavigation === 'recipeInstructions'
              location.pathname.split('/')[5] === 'recipeInstructions' ||
              location.pathname.split('/').length === 5
                ? 'iconContainerTimeline recipeInstructions'
                : 'iconContainerTimeline '
            }
          >
            <div>
              <GiRiceCooker className="timeLIneImageSize" />
            </div>
          </div>
          <div
            className={
              // timelineNavigation === 'recipeInstructions'
              location.pathname.split('/')[5] === 'recipeInstructions' ||
              location.pathname.split('/').length === 5
                ? 'timelineText recipeInstructions1'
                : 'timelineText '
            }
          >
            Process
          </div>

          <div className="line"></div>
        </div>
      )}

      {/* ================================================ */}
      <div className="timelineLine"></div>

      <div onClick={timeStatisticClick} className="flexIconAndText">
        <div
          className={
            // timelineNavigation === 'timeStatistic'
            location.pathname.split('/')[5] === 'timeStatistics'
              ? 'iconContainerTimeline timeStatistic'
              : 'iconContainerTimeline '
          }
        >
          <div>
            <BiTimeFive
              className="timeLIneImageSize"
              style={{ marginTop: '.2em' }}
            />
          </div>
        </div>
        <div
          className={
            // timelineNavigation === 'timeStatistic'
            location.pathname.split('/')[5] === 'timeStatistics'
              ? 'timelineText timeStatisticActive'
              : 'timelineText '
          }
        >
          Time Statistics
        </div>

        <div className="line"></div>
      </div>
      <div className="timelineLine"></div>
      {location.pathname.split('/')[1] !== 'inventory' ? (
        <>
          <div onClick={ingredientDetailsClick} className="flexIconAndText">
            <div
              className={
                location.pathname.split('/')[5] === 'ingredient'
                  ? 'iconContainerTimeline ingredientDetails'
                  : 'iconContainerTimeline '
              }
            >
              <div>
                <BsBookHalf
                  className="timeLIneImageSize"
                  style={{ marginTop: '.2em' }}
                />
              </div>
            </div>
            <div
              className={
                location.pathname.split('/')[5] === 'ingredient'
                  ? 'timelineText ingredientDetailsText'
                  : 'timelineText '
              }
            >
              Ingredient Details
            </div>

            <div className="line"></div>
          </div>
        </>
      ) : (
        <div onClick={recipeInstructionsClick} className="flexIconAndText">
          <div
            className={
              location.pathname.split('/')[5] === 'recipeInstructions'
                ? 'iconContainerTimeline recipeInstructions'
                : 'iconContainerTimeline '
            }
          >
            <div>
              <GiRiceCooker className="timeLIneImageSize" />
            </div>
          </div>
          <div
            className={
              location.pathname.split('/')[5] === 'recipeInstructions'
                ? 'timelineText recipeInstructions1'
                : 'timelineText '
            }
          >
            Recipe Instructions
          </div>

          <div className="line"></div>
        </div>
      )}
    </div>
  );
}

export default TimelineProcessNavigation;
