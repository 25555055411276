import React from 'react';
import '../../style/ProcessChallenge.css';
import { useLocation, useNavigate } from 'react-router';
import {
  invProcessStepActions,
  cascadingScreenActions,
  cuisineScreenActions,
  useDispatch,
} from '../../Redux';

const SideBarProcessButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const ProcessClick = () => {
    navigate('/process');
    dispatch(cuisineScreenActions.clearSelectedCuisineContainer());
    if (location.pathname.split('/')[1] !== 'process') {
      dispatch(invProcessStepActions.gotoInitialStateRefresh());
      dispatch(cascadingScreenActions.clearSelectedIngredients());

      dispatch(cascadingScreenActions.getHideTopInput());
      dispatch(
        cascadingScreenActions.changeIngredientTitle(
          `To view the corresponding recipes, first select the ingredients you want to use. Add an ingredient either by browsing the available ingredients by category or by searching for a specific ingredient in the search box on the right. After ingredient selection, click on the ‘Next’ button to go to cuisine selection..`
        )
      );
    }
  };
  return (
    <button
      className={
        location.pathname.split('/')[1] === 'process'
          ? 'processButton processButtonActive'
          : 'processButton thierd-step'
      }
      onClick={ProcessClick}
    >
      Process Challenge
    </button>
  );
};

export default SideBarProcessButton;
