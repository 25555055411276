import * as d3 from 'd3';
export const getBB = selection => {
  selection.each(function (d) {
    d.bbox = this.getBBox();
  });
};

export const drag = simulation => {
  const dragstarted = (event, d) => {
    if (!event.active) simulation.alphaTarget(0.3).restart();
    d.fx = d.x;
    d.fy = d.y;
  };

  const dragged = (event, d) => {
    d.fx = event.x;
    d.fy = event.y;
  };

  const dragended = (event, d) => {
    if (!event.active) simulation.alphaTarget(0);
    d.fx = event.x;
    d.fy = event.y;
  };

  return d3
    .drag()
    .on('start', dragstarted)
    .on('drag', dragged)
    .on('end', dragended);
};

export const getClick = (
  e,
  i,
  data,
  setclickShowLink,
  setNodeClickStatus,
  nodeClickStatus,
  setProcessData,
  nodesFromApi,
  links,
  setUiriForNextPage,
  setClickedState
) => {
  // node_value_obj: e?.node_value_obj
  if (data?.nodes[0]?.id === e.id) {
    return;
  } else {
    setclickShowLink(i);
    // setUiriForNextPage(e);
    setClickedState(e);

    data.links.filter((el, j) => {
      if (
        e.node_value_obj === el.target.node_value_obj ||
        e.node_value_obj === el.source.node_value_obj
      ) {
        setclickShowLink(j);
      }
    });

    // console.log('you have to click');
  }
};
