/* eslint-disable no-undef */
import React from 'react';
import { FaTimes } from '../../../icon';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ToolTipInfo, BackButton } from '../../../lib';
import {
  cascadingScreenActions,
  cuisineScreenActions,
  invProcessStepActions,
  useDispatch,
  useSelector,
} from '../../../Redux';
import { Loading } from '../../index';
/**
 * [Component] PreferenceSecondPage
 * @description Fetch data from API and shows Cusine list.
 
 * @param {*} names has a default array value.
 * @param {*} setProceedToNextPage set proceedTonextPage on function call.
 * @param {*} setCuisineName stores the data after fetching the API.
 * @param {*} setIngredientTitle sets the title of component.
 * @param {Array} setIngredientsSecondPageSelection change ingredientsSecondPageSelection state  on function call.
 * @param {*} setCuisinePageSelection change cuisinePageSelection state  on function call.
 * @param {*} setIsSuccess set the state when function call.
 * @param {*} setCuisineContainer set the data when function call.

 
 * @returns Cusine page. 
*/

const CuisineSelection = ({
  navigationButtonClassName,
  navigationButtonClassNameActive,
  LoadingColor,
  cuisineBackButtonClass,
  getIngredientButtonClick,
}) => {
  const location = useLocation();
  // console.log(location);
  const dispatch = useDispatch();
  const cuisineStore = useSelector(state => state.cuisineStore);
  React.useEffect(() => {
    if (location.pathname.split('/').length === 3) {
      dispatch(
        cascadingScreenActions.changeIngredientTitle(
          'Select your preferred cuisine:'
        )
      );

      dispatch(invProcessStepActions.selectCuisineType());
    }
  }, [location]);

  const url = useParams();
  let cusineAndInfredientFromUrl = url.id.split('=');

  let cusineFromApi = cusineAndInfredientFromUrl[0];
  let uniqueCusineList = cusineFromApi.split('+');

  React.useEffect(() => {}, [cuisineStore.cuisineContainer]);
  const navigate = useNavigate();
  const getClick = () => {
    if (cuisineStore.cuisineContainer.length !== 0) {
      navigate(uniqueCusineList.join('+'));
      // navigate(`${200}`);
      // navigate('hello');
    }

    dispatch(invProcessStepActions.goToNextFromCuisinePage());
  };
  const getCountryClick = ele => {
    dispatch(cuisineScreenActions.getSelectCountry(ele));
  };

  const countryRemoveAfterSelect = e => {
    dispatch(cuisineScreenActions.countryRemoveAfterSelection(e));
  };
  const selectAllCountry = () => {
    dispatch(cuisineScreenActions.getAllCountries(uniqueCusineList));
  };

  var stringToColour = function (str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var j = 0; j < 3; j++) {
      var value = (hash >> (j * 8)) & 0xff;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  };

  return (
    <div>
      {uniqueCusineList.length === 0 && <Loading color={LoadingColor} />}
      {uniqueCusineList.length !== 0 && (
        <>
          <div className="selectingCountryOption">
            {uniqueCusineList.length === 0 && null}
            {uniqueCusineList.length !== 0 && (
              <>
                {uniqueCusineList.map((ele, i) => (
                  <button
                    className="countryButton"
                    key={ele}
                    onClick={() => getCountryClick(ele, i)}
                  >
                    {ele.slice(0, 1).toUpperCase() + ele.slice(1).toLowerCase()}
                  </button>
                ))}
              </>
            )}
          </div>
          <button onClick={selectAllCountry} className="selectAllButton">
            Select all
          </button>
          {cuisineStore.cuisineContainer.length !== 0 && (
            <h4
              className="yourSelectionsTag"
              style={{ marginLeft: '2em', margintop: '4em', color: 'black' }}
            >
              Your selections:
              <ToolTipInfo
                title="Your selected ingredients will be displayed in this section. You can deselect by clicking on the cross icon."
                placement="right"
              />
            </h4>
          )}
          <div className="countrySelectedContainer">
            {cuisineStore.cuisineContainer.map(e => (
              <div
                onClick={() => countryRemoveAfterSelect(e)}
                key={e}
                className="countryButton"
                style={{
                  color: stringToColour(e),
                  border: `1.5px solid ${stringToColour(e)}`,

                  backgroundColor: `${stringToColour(e)}10`,
                }}
              >
                <div>
                  {e.charAt(0).toUpperCase() + e.slice(1).toLowerCase()}
                </div>
                <FaTimes />
              </div>
            ))}
          </div>
          <div className="backButtonAndNextButton">
            <BackButton
              onClick={getIngredientButtonClick}
              className={cuisineBackButtonClass}
            />
            <button
              className={
                cuisineStore.cuisineContainer.length === 0
                  ? navigationButtonClassName
                  : navigationButtonClassNameActive
              }
              onClick={getClick}
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default CuisineSelection;
