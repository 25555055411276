import { Tooltip } from '@mui/material';
import React from 'react';
import { BiZoomIn, BiZoomOut } from 'react-icons/bi';
import { MdCenterFocusStrong } from 'react-icons/md';

const ZoomButton = ({ zoomInId, zoomOutId, zoomResetId }) => {
  return (
    <div className="zoom-button">
      <div className="group-button">
        <Tooltip placement="top" title="zoom In">
          <button id={zoomInId} className="leftButton">
            <BiZoomIn size={'1rem'} />
          </button>
        </Tooltip>
        <Tooltip placement="top" title="zoom Out">
          <button id={zoomOutId} className="leftButton">
            <BiZoomOut size={'1rem'} />
          </button>
        </Tooltip>
        <Tooltip placement="top" title="Fit To Screen">
          <button id={zoomResetId} className="right-button">
            <MdCenterFocusStrong size={'1rem'} />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default ZoomButton;
