import React from 'react';

import qs from 'qs';
import axios from 'axios';
import ApplicationToken from './ApplicationToken';

// var tokenKey = ApplicationToken(false);
const useVocabularyRecursiveFetch = query => {
  // console.log(query);
  var collectArray = [];
  var originalResult = [];
  let containerStorage = [];

  const [status, setStatus] = React.useState('idle');
  const [result, setResult] = React.useState([]);
  const [findClass, setFindClass] = React.useState([]);
  const [findChildNodes, setFindChildNodes] = React.useState([]);

  const [parentLoopData, setParentLoopData] = React.useState([]);
  const [loopStatus, setLoopStatus] = React.useState('loopStart');

  // console.log(passVariable);
  const InitialFunction = () => {
    var data = qs.stringify({
      query: query.query,
    });

    // eslint-disable-next-line no-undef
    var Query = window?._env_?.REACT_APP_QUERY;

    const queryCall = React.useCallback(
      async token => {
        if (!token) {
          return;
        } else {
          setStatus('Loading');

          var config = {
            method: 'POST',
            url: `${Query}`,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization:
                token?.data?.token_type + ' ' + token?.data?.access_token,
            },
            data: data,
          };

          axios(config)
            .then(response => {
              // console.log(response.data.results.bindings);
              setParentLoopData(response.data.results.bindings);
              setResult(response.data.results.bindings);
              originalResult = response.data.results.bindings
                .filter(ek => ek.value !== undefined && ek)
                .map(el => el.value.value);
              setFindClass(originalResult);
              //   console.log(originalResult);

              setStatus('success');
              // console.log(originalResult);
              RecursiveFunction(originalResult, 0);
            })
            .catch(function (error) {
              setStatus('error');
              if (error.response.status === 401) {
                ApplicationToken(true, queryCall);
                // queryCall;
              }
            });
        }
      },
      [data]
    );

    React.useEffect(() => {
      if (!JSON.parse(window.localStorage.getItem('applicationToken'))) {
        ApplicationToken(true, queryCall);
        // tokenKey = ApplicationToken(true, '');
      } else {
        queryCall(
          JSON.parse(window.localStorage.getItem('applicationToken')),
          ''
        );
      }
    }, [query.query]);
  };
  const RecursiveFunction = async (piArray, i) => {
    // console.log('hello');
    if (piArray.length !== i) {
      // console.log('inside');
      // console.log(piArray);

      // eslint-disable-next-line no-undef
      var Query = window?._env_?.REACT_APP_QUERY;

      const vocavulary_sb1_point_one =
        // eslint-disable-next-line no-undef
        process.env.REACT_APP_VOCABULARY_SB1_RECURSIVE_POINT_ONE;
      const vocavulary_sb1_point_two =
        // eslint-disable-next-line no-undef
        process.env.REACT_APP_VOCABULARY_SB1_RECURSIVE_POINT_TWO;
      const vocavulary_sb2_point_one =
        // eslint-disable-next-line no-undef
        process.env.REACT_APP_VOCABULARY_SB2_RECURSIVE_POINT_ONE;
      const vocavulary_sb2_point_two =
        // eslint-disable-next-line no-undef
        process.env.REACT_APP_VOCABULARY_SB2_RECURSIVE_POINT_TWO;

      const queryCall1 = async (token, piArray, i) => {
        // console.log('call GAain');
        // console.log(piArray);
        var data;

        data = qs.stringify({
          query: query.condition
            ? `${vocavulary_sb1_point_one}${piArray[i]}${vocavulary_sb1_point_two}`
            : `${vocavulary_sb2_point_one}${piArray[i]}${vocavulary_sb2_point_two}`,
        });
        // console.log(data);

        if (!token) {
          return;
        } else {
          setStatus('Loading');

          var config = {
            method: 'POST',
            url: `${Query}`,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization:
                token?.data?.token_type + ' ' + token?.data?.access_token,
            },
            data: data,
          };

          axios(config)
            .then(response => {
              collectArray.push(...response.data.results.bindings);

              containerStorage.push(response.data.results.bindings);
              setFindChildNodes(containerStorage);
              setStatus('success');
              RecursiveFunction(piArray, i + 1);
            })
            .catch(function (error) {
              setStatus('error');
              if (error.response.status === 401) {
                ApplicationToken(true, queryCall1, piArray, i);
              }
            });
        }
      };

      queryCall1(
        JSON.parse(window.localStorage.getItem('applicationToken')),
        piArray,
        i
      );
    } else {
      setLoopStatus('LoopEnd');
    }
  };

  InitialFunction();

  return [
    loopStatus,
    result,
    findChildNodes,
    status,
    findClass,
    parentLoopData,
  ];
};
export default useVocabularyRecursiveFetch;
