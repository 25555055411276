import React from 'react';

import qs from 'qs';
import axios from 'axios';

import ApplicationToken from './ApplicationToken';

// var tokenKey = ApplicationToken(false);

const useSubstituteGraphFetch = query => {
  const [status, setStatus] = React.useState('idle');
  const [result, setResult] = React.useState([]);
  let [length, setLength] = React.useState(0);

  var data = qs.stringify({
    query: `${query}`,
  });

  // eslint-disable-next-line no-undef
  const Query = window._env_.REACT_APP_QUERY;

  const queryCall = React.useCallback(
    async token => {
      if (!token) {
        return;
      } else {
        setStatus('Loading');

        var config = {
          method: 'POST',
          url: `${Query}`,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization:
              token?.data?.token_type + ' ' + token?.data?.access_token,
          },
          data: data,
        };

        axios(config)
          .then(response => {
            // console.log(response.data.results.bindings);
            if (response.data.results.bindings.length === 0) {
              setResult(response.data.results.bindings);
            } else {
              const responseResult = response.data.results.bindings.map(
                (el, i) => {
                  let data = {
                    id: `${100 + i}`,
                    name: el.sub_ing.value,
                    match: el.match.value,
                    properties: el.properties.value,
                    value: el.value.value,
                    fitness: el.fitness.value,
                  };

                  return data;
                }

                // property
              );
              setLength(responseResult.length);
              setResult(responseResult);
            }

            setStatus('success');
          })
          .catch(function (error) {
            setStatus('error');
            if (error.response.status === 401) {
              ApplicationToken(true, queryCall);
            }
          });
      }
    },
    [data]
  );

  React.useEffect(() => {
    if (!JSON.parse(window.localStorage.getItem('applicationToken'))) {
      ApplicationToken(true, queryCall);
    } else {
      queryCall(
        JSON.parse(window.localStorage.getItem('applicationToken')),
        ''
      );
    }
  }, [query]);

  return [status, result, length];
};

export default useSubstituteGraphFetch;
