import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  cuisineName: [],
  cuisineContainer: [],
};
const cuisineSlice = createSlice({
  name: 'cuisineSectionStates',
  initialState,
  reducers: {
    getSelectCountry: (state, { payload }) => {
      if (!state.cuisineContainer.includes(payload)) {
        state.cuisineContainer = [...state.cuisineContainer, payload];
      } else {
        state.cuisineContainer = [...state.cuisineContainer];
      }
    },
    countryRemoveAfterSelection: (state, { payload }) => {
      const newCountryArray = state.cuisineContainer.filter(
        el => el !== payload.toUpperCase()
      );
      state.cuisineContainer = newCountryArray;
    },
    getAllCountries: (state, { payload }) => {
      state.cuisineContainer = [...payload];
    },
    clearSelectedCuisineContainer: state => {
      state.cuisineContainer = [];
    },
  },
});
export const cuisineScreenActions = cuisineSlice.actions;
export const cuisineScreenReducers = cuisineSlice.reducer;
