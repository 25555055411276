import React from 'react';

import qs from 'qs';
import axios from 'axios';
import ApplicationToken from './ApplicationToken';

// var tokenKey = ApplicationToken(false);
const useProcessMonetring = (
  query,
  uriForNextNode,
  fixedProcessdata,
  setProcessData
) => {
  // const { tokenKey, setTokenKey1 } = React.useContext(authContext);
  const [status, setStatus] = React.useState('idle');
  const result = [];
  const [processOriginalGraph, setProcessOriginalGraph] = React.useState([]);
  var GraphUpdateData;
  var data = qs.stringify({
    query: `${query}`,
  });
  // console.log(query);
  const time_query_first_id =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_PROCESS_MONITERING_TIME_QUERY_ID_POINT_ONE;
  const time_query_last_id =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_PROCESS_MONITERING_TIME_QUERY_ID_POINT_TWO;
  const temp_query_first_id =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_PROCESS_MONITERING_TEMPERATURE_QUERY_ID_POINT_ONE;
  const temp_query_last_id =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_PROCESS_MONITERING_TEMPERATURE_QUERY_ID_POINT_TWO;

  // eslint-disable-next-line no-undef
  const Query = window?._env_?.REACT_APP_QUERY;

  const queryCall = React.useCallback(
    async token => {
      if (!token) {
        return;
      } else {
        setStatus('Loading');

        var config = {
          method: 'POST',
          url: `${Query}`,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization:
              token?.data?.token_type + ' ' + token?.data?.access_token,
          },
          data: data,
        };

        axios(config)
          .then(response => {
            // setResult(response.data.results.bindings);

            let ProcessDataChildrenNodeName =
              response.data.results.bindings?.map((e, i) => {
                let name = `${e?.obj?.value}:${e?.class_obj.value}`;
                let data = {
                  id: parseInt(`${uriForNextNode?.id}` + `${i}`),
                  name: name,
                  edgeType: e?.prop?.value,
                  node_value_obj: e?.value_obj?.value,
                  node_value_rec: e?.value_rec.value,
                  typeOf: e?.class_obj.value,
                  typeofValue: e?.obj?.value,
                  searchFilter: '',
                  typeOfResult: '',
                };
                return data;
              });
            // console.log(ProcessDataChildrenNodeName);

            let newLink = ProcessDataChildrenNodeName?.map(el => {
              let data = {
                source: uriForNextNode?.id,
                target: el?.id,
                edgeType: el?.edgeType,
                name: el?.name,
                typeofValue: el?.obj?.value,
                node_value_obj: el?.node_value_obj,
              };
              return data;
            });

            // console.log(ProcessDataChildrenNodeName);
            let timeFilteruri = ProcessDataChildrenNodeName?.filter(
              e => e.name === 'Process Time Observation:Observation'
            );

            let tempratureFilteruri = ProcessDataChildrenNodeName?.filter(
              e => e.name === 'Process Temperature Observation:Observation'
            );

            // we are here so peration  perform first

            getTime(
              timeFilteruri,
              tempratureFilteruri,
              ProcessDataChildrenNodeName,
              newLink
            );

            // setStatus('success');
          })
          .catch(function (error) {
            setStatus('error');
            if (error.response.status === 401) {
              ApplicationToken(true, queryCall);
            }
          });
      }
    },
    [data, uriForNextNode]
  );
  const getTime = (time, temp, ProcessDataChildrenNodeName, newLink) => {
    // console.log('timeKey', time);

    // console.log(ProcessDataChildrenNodeName);

    // console.log('inside');
    // console.log(piArray);

    // eslint-disable-next-line no-undef
    var Query = window._env_.REACT_APP_QUERY;

    const queryCall1 = async (token, time) => {
      // console.log(time);
      var data;

      // console.log(data);
      //   console.log(token);
      if (!token) {
        return;
      } else {
        // setStatus('Loading');
        data = qs.stringify({
          query: `${time_query_first_id}${time[0]?.node_value_obj}${time_query_last_id}`,
        });
        // console.log('hello');

        var config = {
          method: 'POST',
          url: `${Query}`,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization:
              token?.data?.token_type + ' ' + token?.data?.access_token,
          },
          data: data,
        };

        axios(config)
          .then(response => {
            // collectArray.push(...response.data.results.bindings);

            // containerStorage.push(response.data.results.bindings);
            // setFindChildNodes(containerStorage);
            // console.log('Time==>', response.data.results.bindings);

            let timeNodes = response.data.results.bindings?.map((e, i) => {
              let name = `${e?.obj?.value}:${e?.class_obj.value}`;
              let data = {
                id: parseInt(`${time[0]?.id}` + `${i}`),
                name: name,
                edgeType: e?.prop?.value,
                node_value_obj: e?.value_obj?.value,
                node_value_rec: e?.value_rec.value,
                typeOf: e?.class_obj.value,
                typeofValue: e?.obj?.value,
                searchFilter: uriForNextNode?.node_value_obj,
                typeOfResult: e?.obj?.value.match(/\D/g).join(''),
              };
              return data;
            });
            // console.log(timeNodes);
            let timeLink = timeNodes?.map(el => {
              let data = {
                source: time[0]?.id,
                target: el?.id,
                edgeType: el?.edgeType,
                name: el?.name,
                node_value_obj: el?.node_value_obj,
              };
              return data;
            });
            // console.log(timeLink);

            getTemp(
              temp,
              ProcessDataChildrenNodeName,
              newLink,
              timeNodes,
              timeLink
            );
          })
          .catch(function (error) {
            setStatus('error');
            if (error.response.status === 401) {
              ApplicationToken(true, queryCall);
              // queryCall;
            }
          });
        // await authInitiate();
        // console.log(piArray);
      }
    };

    queryCall1(
      JSON.parse(window.localStorage.getItem('applicationToken')),
      time
    );
  };
  const getTemp = (
    temping,
    ProcessDataChildrenNodeName,
    newLink,
    timeNodes,
    timeLink
  ) => {
    const queryCall2 = async (token, temping) => {
      var data;

      if (!token) {
        return;
      } else {
        // setStatus('Loading');
        data = qs.stringify({
          query: `${temp_query_first_id}${temping[0]?.node_value_obj}${temp_query_last_id}`,
        });
        // console.log('hello');

        var config = {
          method: 'POST',
          url: `${Query}`,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization:
              token?.data?.token_type + ' ' + token?.data?.access_token,
          },
          data: data,
        };

        axios(config)
          .then(response => {
            let tempNodes = response.data.results.bindings?.map((e, i) => {
              let name = `${e?.obj?.value}:${e?.class_obj.value}`;
              let data = {
                id: parseInt(`${temping[0]?.id}` + `${i}`),
                name: name,
                edgeType: e?.prop?.value,
                node_value_obj: e?.value_obj?.value,
                node_value_rec: e?.value_rec.value,
                typeOf: e?.class_obj.value,

                typeofValue: e?.obj?.value,
                searchFilter: uriForNextNode?.node_value_obj,
                typeOfResult: e?.obj?.value.match(/\D/g).join(''),
              };
              return data;
            });
            // console.log(timeNodes);
            let tempLink = tempNodes?.map(el => {
              let data = {
                source: temping[0]?.id,
                target: el?.id,
                edgeType: el?.edgeType,
                name: el?.name,
                node_value_obj: el?.node_value_obj,
              };
              return data;
            });

            let nodeData = [
              ...fixedProcessdata?.nodes,
              ...ProcessDataChildrenNodeName,
              ...timeNodes,
              ...tempNodes,
            ];

            let linkData = [
              ...fixedProcessdata?.links,
              ...newLink,
              ...timeLink,
              ...tempLink,
            ];

            GraphUpdateData = {
              nodes: nodeData,
              links: linkData,
            };

            // console.log(GraphUpdateData);
            setProcessOriginalGraph(GraphUpdateData);
            if (GraphUpdateData.nodes.length !== 0) {
              setProcessData(GraphUpdateData);
            }

            setStatus('success');
          })
          .catch(function (error) {
            setStatus('error');
            if (error.response.status === 401) {
              ApplicationToken(true, queryCall);
              // queryCall(tokenKey);
            }
          });
        // await authInitiate();
        // console.log(piArray);
      }
    };

    queryCall2(
      JSON.parse(window.localStorage.getItem('applicationToken')),
      temping
    );
  };
  React.useEffect(() => {
    if (!JSON.parse(window.localStorage.getItem('applicationToken'))) {
      ApplicationToken(true, queryCall);
      // tokenKey = ApplicationToken(true, '');
    } else {
      queryCall(
        JSON.parse(window.localStorage.getItem('applicationToken')),
        ''
      );
    }
  }, [query, uriForNextNode]);

  return [status, result, result.length, processOriginalGraph];
};

export default useProcessMonetring;
