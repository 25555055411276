/* eslint-disable no-useless-escape */
import React from 'react';
import {
  AuthInput,
  PageDivider,
  SelectBox,
  AuthButton,
  AuthBackButton,
} from '../../../../lib';

import './AuthLinkedInUserInfo.css';
import { RiErrorWarningFill } from '../../../../icon';
import { useDispatch, useSelector } from 'react-redux';
import { authAction } from '../../../../Redux/Slices/AuthSlides/AuthenticationStates';

const AuthLinkedInUserInfo = ({ settypeOfSignUp }) => {
  const options = [
    {
      label: 'Aarth.io',
      value: 'Aarth.io',
    },
    {
      label: 'Eccenca',
      value: 'Eccenca',
    },
    {
      label: 'Tcs',
      value: 'Tcs',
    },
    {
      label: 'the home',
      value: 'the home',
    },
  ];
  const dispatch = useDispatch();
  const authenticationStore = useSelector(s => s.authStore);
  const [selectComponyName, setSelectCompanyName] = React.useState('');
  const [isFocuse, setIsFocuse] = React.useState(false);
  let initialData = {
    emailAddress: '',
    company: '',
  };
  const [userDetails, setUserDetails] = React.useState(initialData);
  const getEmailAddress = e => {
    setUserDetails(p => {
      return {
        emailAddress: e.target.value,
        company: p.company,
      };
    });
  };

  const getCompanyName = e => {
    setUserDetails(p => {
      return {
        emailAddress: p.emailAddress,
        company: e.target.value,
      };
    });
  };

  let totalData = [
    userDetails.emailAddress,
    userDetails.company,
    selectComponyName,
  ];
  //console.logtotalData);

  function validateEmail(emailAdress) {
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (emailAdress.match(regexEmail)) {
      return true;
    } else {
      return false;
    }
  }
  const setSignUpUsingEmail = () => {
    settypeOfSignUp('email');
    // setProgressStep(0);
    dispatch(authAction.setProgressStep(0));
  };
  return (
    <div className="AuthuserInfoByLinkedIn">
      <div>
        <div className="AuthuserInfoByLinkedInHeading">
          <b>Sign up with Linkedin </b>
        </div>
        <div className="AuthuserInfoByLinkedInInputContainer">
          <AuthInput
            value={userDetails.emailAddress}
            placeHolder="Email"
            onChange={getEmailAddress}
            type="email"
            // onFocus={() => console.log('hello')}
            // onBlur={() => console.log('hey')}
          />
          <AuthInput
            value={userDetails.company}
            placeHolder="Company"
            onChange={getCompanyName}
            type="text"
            // onFocus={() => console.log('hello')}
            // onBlur={() => console.log('hey')}
          />
          <SelectBox
            options={options}
            selectComponyName={selectComponyName}
            setSelectCompanyName={setSelectCompanyName}
            selectBoxTitle="Group"
            selectBoxMessage="Please select your organisation’s name from the list"
            selectBoxMessageIcon={<RiErrorWarningFill color="grey" />}
            isFocuse={isFocuse}
            setIsFocuse={setIsFocuse}
          />
        </div>
        <PageDivider text="Or" />
      </div>
      <div className="SignupWithEmailLinkedInContainer">
        <div className="SignupWithEmailLinkedInContainerHeading">
          <b>SignUp with email</b>
        </div>
        <div
          className="SignupWithEmailLinkedInContainerHeadingButton"
          style={{ color: 'orange' }}
          onClick={setSignUpUsingEmail}
        >
          Continue
        </div>
      </div>
      <div className="SignupWithEmailLinkedInContainerHeadingButtonContainer">
        <AuthBackButton onClick={() => console.log('hey')} />
        <AuthButton
          disabled={
            totalData.includes('') || !validateEmail(userDetails.emailAddress)
              ? true
              : false
          }
          buttonText="Next"
          onClick={() =>
            dispatch(
              authAction.setProgressStep(authenticationStore.progressStep + 1)
            )
          }
          type="button"
          width="5.8em"
          height="3em"
        />
      </div>
    </div>
  );
};

export default AuthLinkedInUserInfo;
