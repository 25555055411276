import React from 'react';
import { useSelector } from 'react-redux';
import { AuthLinkedInUserInfo, AuthUserDataSavePage } from '../../../index';

const AuthDefaultSignUp = ({
  setProgressStep,
  settypeOfSignUp,

  setIsSigninSuccessfull,
}) => {
  const authenticationStore = useSelector(s => s.authStore);
  return (
    <div>
      {authenticationStore.progressStep === 1 && (
        <>
          <AuthLinkedInUserInfo
            setProgressStep={setProgressStep}
            settypeOfSignUp={settypeOfSignUp}
          />
        </>
      )}
      {authenticationStore.progressStep === 2 && (
        <>
          <AuthUserDataSavePage
            setProgressStep={setProgressStep}
            settypeOfSignUp={settypeOfSignUp}
            setIsSigninSuccessfull={setIsSigninSuccessfull}
          />
        </>
      )}
    </div>
  );
};

export default AuthDefaultSignUp;
