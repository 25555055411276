import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { ShowOptions, SubstituteGraphStaticSidebar } from '../index';

const UnderTheHoodRightSideFixedContainer = ({
  dataRef,
  subOptionGraphdata,
  subnodes,
  index,
  subGraphdata,
  subLink,
  setData,
  nodeClickStatus,
  setSelectedSubstituteNodes,
  data,
  setChangeSave,
  deleteNodes,
  length,
  checkedState,
  setCheckedState,
  checkedBoxState,
  setCheckeBoxdState,
  subGraphTitle,
  subGraphTitleValue,
  substituteInfo,
  isGraphUpdate,
}) => {
  const location = useLocation();

  const preferenceStore = useSelector(s => s.preferenceStore);
  return (
    <div className="rightSideFixedContainerInHood">
      {location.pathname.split('/')[6] === 'smartSubstitution' && (
        <>
          <>
            <div className="mainRenderTextInsmartSub">{subGraphTitle}</div>
            <div className="recipeName">{subGraphTitleValue}</div>
            {!isGraphUpdate && (
              <>
                <h2 className="subaboutTheAntology">{substituteInfo}</h2>
                {nodeClickStatus.includes(true) && (
                  <div className="substitutionOptopnsContainer" ref={dataRef}>
                    <ShowOptions
                      subnodes={subnodes}
                      index={index}
                      subGraphdata={subGraphdata}
                      subLink={subLink}
                      setData={setData}
                      setSelectedSubstituteNodes={setSelectedSubstituteNodes}
                      data={data}
                      setChangeSave={setChangeSave}
                      subOptionGraphdata={subOptionGraphdata}
                      deleteNodes={deleteNodes}
                      length={length}
                      checkedState={checkedState}
                      setCheckedState={setCheckedState}
                      checkedBoxState={checkedBoxState}
                      setCheckeBoxdState={setCheckeBoxdState}
                    />
                  </div>
                )}
                {!nodeClickStatus.includes(true) && (
                  <SubstituteGraphStaticSidebar />
                )}
              </>
            )}
            {isGraphUpdate && (
              <div className="subSaveinfo">
                Voila! The new recipe has been prepared.{' '}
              </div>
            )}
          </>
        </>
      )}
      {location.pathname.split('/')[6] === 'recipeGraph' && (
        <>
          <div className="recipeGraphHeading">
            {location.pathname.split('/')[1] === 'process'
              ? 'Process Graph'
              : 'Recipe Graph'}
          </div>
          <div className="padThai">
            {preferenceStore.selectedRecipeData.Recipes}
          </div>
          <div className="padThaiParagraph">
            A knowledge graph showcasing all the classes that the above
            mentioned recipe has. It shows the mapping of semantic data to
            ontology.
          </div>
        </>
      )}
    </div>
  );
};

export default UnderTheHoodRightSideFixedContainer;
