import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { setDatainLocalMemory } from '../../../utils';
import {
  recipeGraphActive,
  recipeGraphInActive,
  smartSubActiveSvg,
  smartSubInActiveSvg,
  vocabGraphActiveSvg,
  vocabGraphInActiveSvg,
  processMoniterActiveSvg,
  processMoniterInActiveSvg,
  radDataActiveSvg,
  radDataInActiveSvg,
  ActiveReuseIcon,
  InActiveReuseIcon,
} from '../../../assets/index';
import { useDispatch, useSelector } from 'react-redux';
import { statsSliceActions, vocabularyComponentActions } from '../../../Redux';
const HoodVerticalNavigation = ({
  setNodeClickStatus,
  data,
  setData,
  subnodes,
  setSubKey,
  setshowGraphTitleInBanner,
  setIsGraphUpdate,
  setSubGraphTitleValue,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const preferenceStore = useSelector(s => s.preferenceStore);
  // console.log(isClickProcess);
  const rawDataClick = () => {
    navigate('rawData');
    setDatainLocalMemory(
      'url',
      `${location.pathname.split('/')[0]}/${location.pathname.split('/')[1]}/${
        location.pathname.split('/')[2]
      }/${location.pathname.split('/')[3]}/${
        location.pathname.split('/')[4]
      }/uth/rawData`
    );

    setshowGraphTitleInBanner(
      location.pathname.split('/')[1] === 'process'
        ? 'Process Data'
        : 'Raw Data'
    );
    setNodeClickStatus(new Array(subnodes.length).fill(false));
    if (!data) {
      return;
    }
    let length = subnodes.length;
    data.nodes.length = length;
    let nodesData = data.nodes.map(el => {
      let result = {
        id: el.id,
        name: el.name,
        actualName: el.actualName === undefined ? '' : el.actualName,
        edgeTypes: el.edgeTypes === undefined ? '' : el.edgeTypes,
        p: el.p === undefined ? '' : el.p,
        quantity: el.quantity === undefined ? '' : el.quantity,
        value: el.value === undefined ? '' : el.value,
        status: el?.status,
      };
      return result;
    });
    let linksNode = nodesData.map(el => {
      let result = {
        source: nodesData[0].id,
        target: el.id,
        edgeTypes: 'hasIngredient',
        name: el.name,
      };
      return result;
    });
    linksNode.shift();

    let odata = {
      nodes: nodesData,
      links: linksNode,
    };
    setData(odata);
    setSubKey('');
  };
  const recipeGraphClick = () => {
    // dispatch(recipeAndProcessComponentActions.clearDataInPackCircleNodes([]));
    navigate('recipeGraph');
    setDatainLocalMemory(
      'url',
      `${location.pathname.split('/')[0]}/${location.pathname.split('/')[1]}/${
        location.pathname.split('/')[2]
      }/${location.pathname.split('/')[3]}/${
        location.pathname.split('/')[4]
      }/uth/recipeGraph`
    );

    setshowGraphTitleInBanner(
      location.pathname.split('/')[1] === 'process'
        ? 'Process Graph'
        : 'Recipe Graph'
    );
    if (!data) {
      return;
    }
    setNodeClickStatus(new Array(subnodes.length).fill(false));
    let length = subnodes.length;
    data.nodes.length = length;
    let nodesData = data.nodes.map(el => {
      let result = {
        id: el.id,
        name: el.name,
        actualName: el.actualName === undefined ? '' : el.actualName,
        edgeTypes: el.edgeTypes === undefined ? '' : el.edgeTypes,
        p: el.p === undefined ? '' : el.p,
        quantity: el.quantity === undefined ? '' : el.quantity,
        value: el.value === undefined ? '' : el.value,
        status: el?.status,
      };
      return result;
    });
    let linksNode = nodesData.map(el => {
      let result = {
        source: nodesData[0].id,
        target: el.id,
        edgeTypes: 'hasIngredient',
        name: el.name,
      };
      return result;
    });
    linksNode.shift();

    let odata = {
      nodes: nodesData,
      links: linksNode,
    };
    setData(odata);
    setSubKey('');
    setIsGraphUpdate(false);
  };
  const vocabularyGraphClick = () => {
    dispatch(
      vocabularyComponentActions.setVocabularyContentStateHandler(
        'introduction'
      )
    );
    navigate('vocabularyGraph');
    setDatainLocalMemory(
      'url',
      `${location.pathname.split('/')[0]}/${location.pathname.split('/')[1]}/${
        location.pathname.split('/')[2]
      }/${location.pathname.split('/')[3]}/${
        location.pathname.split('/')[4]
      }/uth/vocabularyGraph`
    );

    setshowGraphTitleInBanner('Vocabulary Graph');

    if (!data) {
      return;
    }
    let length = subnodes.length;
    data.nodes.length = length;
    let nodesData = data.nodes.map(el => {
      let result = {
        id: el.id,
        name: el.name,
        actualName: el.actualName === undefined ? '' : el.actualName,
        edgeTypes: el.edgeTypes === undefined ? '' : el.edgeTypes,
        p: el.p === undefined ? '' : el.p,
        quantity: el.quantity === undefined ? '' : el.quantity,
        value: el.value === undefined ? '' : el.value,
        status: el?.status,
      };
      return result;
    });
    let linksNode = nodesData.map(el => {
      let result = {
        source: nodesData[0].id,
        target: el.id,
        edgeTypes: 'hasIngredient',
        name: el.name,
      };
      return result;
    });

    linksNode.shift();

    let odata = {
      nodes: nodesData,
      links: linksNode,
    };

    setData(odata);
    setSubKey('');
    setIsGraphUpdate(false);
    setNodeClickStatus(new Array(subnodes.length).fill(false));
  };
  const smartSubstitutionClick = () => {
    setSubGraphTitleValue(preferenceStore.selectedRecipeData.Recipes);
    setshowGraphTitleInBanner('Smart Substitution');
    navigate('smartSubstitution');
    setDatainLocalMemory(
      'url',
      `${location.pathname.split('/')[0]}/${location.pathname.split('/')[1]}/${
        location.pathname.split('/')[2]
      }/${location.pathname.split('/')[3]}/${
        location.pathname.split('/')[4]
      }/uth/smartSubstitution`
    );
    setNodeClickStatus(new Array(subnodes.length).fill(false));
    setIsGraphUpdate(false);
  };
  const processMonetoring = () => {
    //console.log'hello man');

    setshowGraphTitleInBanner('Process Monitoring');
    navigate('processMonitoring');
  };
  const reuseStatsPage = () => {
    //console.log'hello man');

    setshowGraphTitleInBanner('Reuse');
    navigate('reuseStatsPage');
    dispatch(statsSliceActions.clearSubstituteAndProcessChange());
  };

  return (
    <>
      <div
        style={{ left: '0em' }}
        // style={{ left: '0em' }}
        className="leftSideFixedContainerInHood"
      >
        <div className="processNavigatorContainerInHood">
          <div onClick={rawDataClick} className="flexIconAndTextInHood">
            <div
              className={
                location.pathname.split('/').length === 6 ||
                location.pathname.split('/')[6] === 'rawData'
                  ? // hoodTimeLIneNavigation === 'rawData'
                    'iconContainerTimeline varticalRawBarRawData'
                  : 'iconContainerTimeline '
              }
            >
              <img
                className="UTHNavigateIcons"
                src={
                  location.pathname.split('/').length === 6 ||
                  location.pathname.split('/')[6] === 'rawData'
                    ? radDataActiveSvg
                    : radDataInActiveSvg
                }
                alt="rawData"
              />
            </div>
            <div
              className={
                // hoodTimeLIneNavigation === 'rawData'
                location.pathname.split('/').length === 6 ||
                location.pathname.split('/')[6] === 'rawData'
                  ? 'timelineTextInHood timeStatisticActive textBoldInUTHNavigation'
                  : 'timelineTextInHood '
              }
            >
              {location.pathname.split('/')[1] === 'process'
                ? 'Process Data'
                : 'Raw Data'}
            </div>

            <div className="lineInHood"></div>
          </div>
          {/* ================================================= */}
          <div
            className={
              location.pathname.split('/')[1] === 'inventory'
                ? 'timelineLineInProcessHood'
                : 'timelineLineInHood'
            }
          ></div>
          <div onClick={vocabularyGraphClick} className="flexIconAndTextInHood">
            <div
              className={
                // hoodTimeLIneNavigation === 'vocabularyGraph'
                location.pathname.split('/')[6] === 'vocabularyGraph'
                  ? 'iconContainerTimeline  vocabularyGraphInHood'
                  : 'iconContainerTimeline '
              }
            >
              <img
                className="UTHNavigateIcons vocabGraphSvgIcon"
                src={
                  location.pathname.split('/')[6] === 'vocabularyGraph'
                    ? vocabGraphActiveSvg
                    : vocabGraphInActiveSvg
                }
                alt="vocabG"
              />
            </div>
            <div
              className={
                // hoodTimeLIneNavigation === 'vocabularyGraph'
                location.pathname.split('/')[6] === 'vocabularyGraph'
                  ? 'timelineTextInHood recipeInstructions1 textBoldInUTHNavigation'
                  : 'timelineTextInHood '
              }
            >
              Vocabulary Graph
            </div>

            <div className="lineInHood "></div>
          </div>
          {/* ================================================ */}

          <div
            className={
              location.pathname.split('/')[1] === 'inventory'
                ? 'timelineLineInProcessHood'
                : 'timelineLineInHood'
            }
          ></div>
          <div onClick={recipeGraphClick} className="flexIconAndTextInHood">
            <div
              className={
                // hoodTimeLIneNavigation === 'recipeGraph'
                location.pathname.split('/')[6] === 'recipeGraph'
                  ? 'iconContainerTimeline recipeGraphInHood'
                  : 'iconContainerTimeline '
              }
            >
              <img
                className="UTHNavigateIcons"
                src={
                  location.pathname.split('/')[6] === 'recipeGraph'
                    ? recipeGraphActive
                    : recipeGraphInActive
                }
                alt="RecipeG"
              />
            </div>
            <div
              className={
                // hoodTimeLIneNavigation === 'recipeGraph'
                location.pathname.split('/')[6] === 'recipeGraph'
                  ? 'timelineTextInHood ingredientDetailsText textBoldInUTHNavigation'
                  : 'timelineTextInHood '
              }
            >
              {location.pathname.split('/')[1] === 'process'
                ? 'Process Graph'
                : 'Recipe Graph'}
            </div>

            <div className="lineInHoodInHood"></div>
          </div>
          {/* ======================================================= */}
          <div
            className={
              location.pathname.split('/')[1] === 'inventory'
                ? 'timelineLineInProcessHood lastLine'
                : 'timelineLineInHood lastLine'
            }
          ></div>
          {location.pathname.split('/')[1] === 'inventory' && (
            <div
              onClick={smartSubstitutionClick}
              className="flexIconAndTextInHood"
            >
              <div
                className={
                  location.pathname.split('/')[6] === 'smartSubstitution'
                    ? 'iconContainerTimeline smartSubstitutionInHood'
                    : 'iconContainerTimeline '
                }
              >
                <img
                  className="UTHNavigateIcons"
                  src={
                    location.pathname.split('/')[6] === 'smartSubstitution'
                      ? smartSubActiveSvg
                      : smartSubInActiveSvg
                  }
                  alt="smartSubG"
                />
              </div>

              <div
                className={
                  location.pathname.split('/')[6] === 'smartSubstitution'
                    ? 'timelineTextInHood nutritionInformation1 textBoldInUTHNavigation'
                    : 'timelineTextInHood '
                }
              >
                Smart Substitution
              </div>

              <div className="lineInHood"></div>
            </div>
          )}
          {location.pathname.split('/')[1] === 'process' && (
            <>
              <div
                onClick={processMonetoring}
                className="flexIconAndTextInHood"
              >
                <div
                  className={
                    // hoodTimeLIneNavigation === 'processMonitoring'
                    location.pathname.split('/')[6] === 'processMonitoring'
                      ? 'iconContainerTimeline smartSubstitutionInHood'
                      : 'iconContainerTimeline '
                  }
                >
                  <img
                    className="UTHNavigateIcons"
                    src={
                      location.pathname.split('/')[6] === 'processMonitoring'
                        ? processMoniterActiveSvg
                        : processMoniterInActiveSvg
                    }
                    alt="moniteringG"
                  />
                </div>

                <div
                  className={
                    // hoodTimeLIneNavigation === 'processMonitoring'
                    location.pathname.split('/')[6] === 'processMonitoring'
                      ? 'timelineTextInHood nutritionInformation1 textBoldInUTHNavigation'
                      : 'timelineTextInHood '
                  }
                >
                  Process Monitoring
                </div>

                <div className="lineInHood"></div>
              </div>
              <div onClick={reuseStatsPage} className="flexIconAndTextInHood">
                <div
                  className={
                    location.pathname.split('/')[6] === 'reuseStatsPage'
                      ? 'iconContainerTimeline processMonitering'
                      : 'iconContainerTimeline '
                  }
                >
                  <img
                    src={
                      location.pathname.split('/')[6] === 'reuseStatsPage'
                        ? ActiveReuseIcon
                        : InActiveReuseIcon
                    }
                    alt="reuseIcon"
                    className="reuseButtonClassIn reuseInUth"
                  />
                </div>

                <div
                  className={
                    location.pathname.split('/')[6] === 'reuseStatsPage'
                      ? 'timelineTextInHood nutritionInformation1 textBoldInUTHNavigation'
                      : 'timelineTextInHood '
                  }
                >
                  Reuse/Repurpose
                </div>

                <div className="lineInHood"></div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default HoodVerticalNavigation;
