import React from 'react';
import {
  SideBarInventoryButton,
  SideNavBarHomeButton,
  SideBarProcessButton,
  SideBarStatsButton,
} from '..';
import { MdFeedback, AiFillCheckCircle } from '../../icon';
import { Modal } from '../../lib';
import Rating from '@mui/material/Rating';
// import { getFirestore } from 'firebase/firestore';
import { fireStoreDb } from '../../firebase';
import { collection, addDoc } from 'firebase/firestore';
// import useMediaQuary from '../../utils/useMediaQuery';
import { getItemFromMemory } from '../../utils';
import '../../style/SideNavBar.css';
import { useSelector } from 'react-redux';
// import SideBarDemandForcastingButton from './SideBarDemandForcastingButton';

/**
 * [Component] SideNavBar
 
 * @description Displays the navbar for further navigation

 * @param {*} isClickHome calls the function to render Home component
 * @param {*} isClickInventory calls the function to render Inventory component
 * @param {*} isClickTime calls the function to render Time component 
 * @param {*} isClickIngredient calls the function to render Ingredient component
 * @param {*} isClickHealth calls the function to render Health component
 * @param {*} setIsClickHome accepts boolean.
 * @param {*} setIsClickInventory accepts boolean.
 * @param {*} setIsClickTime accepts boolean.
 * @param {*} setIsClickIngredient accepts boolean.
 * @param {*} setIsClickHealth  accepts boolean.
 
* @returns Renders the Time, Health, Home, Ingredient and Inventory components on respective function call
 */

export const SideNavBar = () => {
  const authenticationStore = useSelector(s => s.authStore);
  const userData = getItemFromMemory('applicationUser');
  // console.log(userData);

  const [show, setShow] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');
  let emailSendAt = new Date().toLocaleString();

  React.useEffect(() => {
    let FeedBackEmail = userData !== undefined ? userData?.email : '';
    setEmail(FeedBackEmail);
  }, [userData]);

  const getEmailAddress = e => {
    setEmail(e.target.value);
  };
  const getmessage = e => {
    setMessage(e.target.value);
  };
  const totalInfo = [email];
  const [feedbackAdded, setFeedbackAdded] = React.useState(false);
  // setShow(false)

  const getModelClose = () => {
    const feedbackCollectionRef = collection(fireStoreDb, 'feedback');
    // setFeedbackAdded(true);
    // const addFeedback = () => {
    // console.log('hey');
    addDoc(feedbackCollectionRef, {
      to: ['feedback-cc@eccenca.com'],
      replyTo: `${email === '' ? 'agent@gmail.com' : email}`,
      message: {
        subject: `feedback from ${
          authenticationStore.userPersonalDetails.length === 0
            ? 'agent'
            : authenticationStore.userPersonalDetails?.username
        }`,
        html: `<!DOCTYPE html>
        <body>
        <tr>
            <td align="center" valign="top">
                <table width="556" border="0" cellspacing="0" cellpadding="0" align="center"
                    class="m_-8417353500393176574em_wrapper" style="width:556px">
                    <tbody>
                        <tr>
                            <td align="center" valign="top" width="55" style="width:55px">
                                &nbsp;</td>
                            <td align="center" valign="top">
                                <table width="100%" border="0" cellspacing="0" cellpadding="0" align="center">
                                    <tbody>
                                        <h1 style="font-family:'Graphik Web',Helvetica,Arial,sans-serif;color:#f7962c">Curry Company</h1>
                                    </tbody>
                                </table>
                            </td>
                            <td align="center" valign="top" width="55" style="width:55px">
                                &nbsp;</td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td align="center" valign="top">
                <table width="556" border="0" cellspacing="0" cellpadding="0" align="center"
                    class="m_-8417353500393176574em_wrapper" style="width:556px">
                    <tbody>
                        <tr>
                            <td align="center" valign="top" width="55" style="width:55px">
                                &nbsp;</td>
                            <td align="center" valign="top">
                                <table width="100%" border="0" cellspacing="0" cellpadding="0" align="center">
                                    <tbody>
                                        <tr>
                                            <td align="left" class="m_-8417353500393176574em_defaultlink" valign="top"
                                                style="font-family:'Graphik Web',Helvetica,Arial,sans-serif;font-size:16px;line-height:22px;color:#000000">
                                                <div style="width:100%; display:flex "> 
                                                <div style='marging-top:15px;'>feedback</div>   
                                                <div style='text-align:right ; margin-left:72%'>
                                                <span>&#11088;</span>${value}
                                                </div> 
                                            </div> 
                                                <hr>
                                                <span id="res" style="font-size:14px;color:#363636">
                                                ${message}    
                                                </span>
        
                                                <br><br>
                                                <span style="font-size:14px;color:#363636">
                                                    This email was sent at ${emailSendAt}</span> 
                                                    <br>
                                                    <br>
                                                    <span style="font-size:14px;color:#363636">
                                                        from,
                                                        <br>
                                                        ${
                                                          authenticationStore
                                                            .userPersonalDetails
                                                            .length === 0
                                                            ? 'agent'
                                                            : authenticationStore
                                                                .userPersonalDetails
                                                                ?.username
                                                        }
                                                 <br>
                                                 ${
                                                   email === ''
                                                     ? 'agent@gmail.com'
                                                     : email
                                                 }</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td align="center" valign="top" width="55" style="width:55px">
                                &nbsp;</td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        </body>
        </html>`,
      },
    })
      .then(response => {
        console.log(response);
      })
      .catch(e => {
        console.log(e);
      });
    // };

    setShow(false);
    setFeedbackAdded(true);
  };
  const getModelIconClose = () => {
    setShow(false);
  };
  const getModelNotificationIconClose = () => {
    setFeedbackAdded(false);
  };
  const getFeedback = () => {
    setShow(true);
    setShow(true);
    setMessage('');
    setEmail('');
    setValue(0);
    // getModelClose();
    setFeedbackAdded(false);
  };

  return (
    <div className="buttonFlexSideNavbar">
      <div>
        <SideNavBarHomeButton />

        <SideBarInventoryButton />
        <SideBarProcessButton />
        {/* <SideBarDemandForcastingButton /> */}
      </div>
      <div>
        <SideBarStatsButton />
        <div
          className="sideNavBarFeedback-button five-step"
          onClick={getFeedback}
        >
          <div className="sideNavBarFeedback-button-text">
            <div className="sidebarFeedback-button-icon-text">
              <MdFeedback />
            </div>
            <div> Feedback/Support</div>
          </div>
        </div>
      </div>
      <Modal
        isdiable={value > 0 || message.length > 0 ? false : true}
        title="Feedback / Support"
        onClose={() => getModelClose()}
        onCloseCancel={() => getModelIconClose()}
        show={show}
        width="20rem"
        height={`28rem`}
        marginTop="0vh"
        marginLeft="0em"
        isButton={true}
        closeIcon={true}
        marginleft={'0em'}
        customClass={'feedBackMsg'}
        color={value > 0 || message.length > 0 ? '#ffa500' : '#e8eaed'}
      >
        <div className="sidebar-model-content">
          <div className="sidebar-model-content-header-text-rating">
            <div className="sidebar-model-content-header-text">
              Your feedback allows us to improve the experience we provide.
            </div>
            <div className="sidebar-model-content-header-rating">
              <Rating
                name="simple-controlled"
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
              />
            </div>
          </div>
          <div>
            <div className="model-input-email">
              <label className="modelInputLabel" htmlFor="modelInput">
                Email ID<span className="modelInputLabel-star">*</span>
              </label>
              <input
                id="modelInput"
                placeholder="Enter your Email ID"
                value={email}
                onChange={getEmailAddress}
              />
            </div>
            <div className="molal-email-validation">
              Email address is required.
            </div>
          </div>

          <div className="model-message">
            <div className="model-input-email">
              <label className="modelInputLabel" htmlFor="modelMessage">
                Message
              </label>
              <textarea
                id="modelMessage"
                placeholder="Leave us your message…"
                value={message}
                onChange={getmessage}
              />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isdiable={totalInfo.includes('') ? true : false}
        title=""
        onClose={() => getModelClose()}
        onCloseCancel={() => getModelNotificationIconClose()}
        show={feedbackAdded}
        width="20rem"
        height="15rem"
        marginTop="30vh"
        marginLeft="0em"
        isButton={false}
        closeIcon={true}
        customClass={'feedBackMsg thankYouBox'}
      >
        <div className="sidebar-model-content-notifaction">
          <div>
            <AiFillCheckCircle
              size="3em"
              style={{ background: 'green', borderRadius: '60%' }}
              color="#e8fadc"
            />
          </div>
          <div>
            <h1 className="thankYouTitle">Thank you!</h1>
          </div>
          <div className="sidebar-model-content-notifaction-message">
            Your feedback allows us to improve the experience we provide.
          </div>
        </div>
      </Modal>
    </div>
  );
};
