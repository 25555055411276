import React from 'react';
import { useLocation } from 'react-router';
import {
  fullRecipeDetailsActions,
  invProcessStepActions,
  useDispatch,
  useSelector,
} from '../../../Redux';

/**
 * [Component] recipeInstructions
 * @description fetches the instructions of selected recipe card.
 
 * @param {Array} result store array of data.
 
 * @returns instructions of the selected recipe.
 */

function RecipeInstructions({ setShowGenerators }) {
  let result;
  const location = useLocation();
  const dispatch = useDispatch();
  const fullRecipeDetailsStore = useSelector(
    state => state.fullRecipeDetailsStore
  );
  result =
    fullRecipeDetailsStore.fullRecipeDetails.rows[0].instructions.split('.');

  React.useEffect(() => {
    if (location.pathname.split('/')[5] === 'recipeInstructions') {
      dispatch(invProcessStepActions.setAllStatusSuccess());
    }
  }, [location]);

  const showGeneratorFunction = () => {
    dispatch(fullRecipeDetailsActions.getChangeIngredientTeminologyShow(true));
    setShowGenerators('processGenerator');
  };

  return (
    <>
      {location.pathname.split('/')[1] !== 'inventory' && (
        <div className="recipeInstructionsContentContainer">
          <div className="processChallengeInstructionTitleFlex">
            <h2>Recipe Instructions</h2>
            <button
              onClick={showGeneratorFunction}
              className="alterCookingButton"
            >
              Alter Cooking Condition
            </button>
          </div>

          <ul>
            {fullRecipeDetailsStore.fullRecipeDetails.rows.length !== 0 &&
              result.map((el, i) => {
                if (el === '') {
                  return;
                }
                return <li key={i}>{el}</li>;
              })}
          </ul>
        </div>
      )}
      {location.pathname.split('/')[1] === 'inventory' && (
        <>
          <div className="recipeInstructionsContentContainer">
            <h2 className="recipeInstructions">Recipe Instructions</h2>

            <ul>
              {fullRecipeDetailsStore.fullRecipeDetails.rows.length !== 0 &&
                result.map((el, i) => {
                  if (el === '') {
                    return;
                  }
                  return <li key={i}>{el}</li>;
                })}
            </ul>
          </div>
        </>
      )}
    </>
  );
}

export default RecipeInstructions;
