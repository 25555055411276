import React from 'react';

const ProcessLinkText = ({ link, showLink, i }) => {
  const textRef = React.useRef(null);
  return (
    <>
      <text
        ref={textRef}
        opacity={
          showLink === i
            ? 1
            : link.edgeType === 'hasProcess'
            ? 0
            : link.edgeType === 'hasTexture'
            ? 0
            : link.edgeType === 'hasFlavor'
            ? 0
            : 1
        }
        textAnchor="middle"
        className="_graphLineLabel"
        fontWeight="bold"
        style={{ fontSize: '9px' }}
      >
        {link.edgeType}
      </text>
    </>
  );
};

export default ProcessLinkText;
