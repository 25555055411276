import { configureStore } from '@reduxjs/toolkit';
import { authReducers } from '../Slices/AuthSlides/AuthenticationStates';

import { cascadingScreenReducer } from '../Slices/CascadingScreen/CascadingScreenSlice';
import { cuisineScreenReducers } from '../Slices/CuisineSelectionScreen/CuisineSelectionSlice';
import { fullRecipeDetailsReducer } from '../Slices/Inventory/FullRecipeDetailsSlice';
import { invPRocessStepReducers } from '../Slices/Inventory/processStepSlice';
import { sideNavReducers } from '../Slices/NavBar/NavBarSlice';
import { preferenceScreenReducers } from '../Slices/PreferenceScreen/PreferenceSlice';
import { rawAndProcessReducers } from '../Slices/UTH/RawDataComponent/ProcessDataRawDataSlice';
import { recipeAndProcessComponentReducers } from '../Slices/UTH/RecipeAndProcessComponent/RecipeAndRawDataSlice';
import { substituteGraphReducers } from '../Slices/UTH/SmartSubstitutionComponent/SmartSubstitutionSlice';
import { statsSliceReducer } from '../Slices/UTH/StatsComponent/StatsPageSlice';
import { vocabularyComponentReducers } from '../Slices/UTH/VocabularyComponent/VocabularyComponentSlice';
const store = configureStore({
  reducer: {
    sideBar: sideNavReducers,
    inventoryProcessSteps: invPRocessStepReducers,
    cascadingStore: cascadingScreenReducer,
    cuisineStore: cuisineScreenReducers,
    preferenceStore: preferenceScreenReducers,
    rawAndProcessStore: rawAndProcessReducers,
    substituteGraphStore: substituteGraphReducers,
    fullRecipeDetailsStore: fullRecipeDetailsReducer,
    recipeAndProcessGraph: recipeAndProcessComponentReducers,
    vocabularyComponentStore: vocabularyComponentReducers,
    statsPageStore: statsSliceReducer,
    authStore: authReducers,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export default store;
