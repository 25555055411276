import React from 'react';
import './LinkedinSignUpOptionWithEmailHeader.css';
const LinkedinSignUpOptionWithEmailHeader = () => {
  return (
    <div className="AuthSignUpEmailInHeaderContainer">
      {/* <div className="AuthSignUpEmailInHeader">
        <b>Sign up with email</b>
      </div> */}
      <div className="fieldMarkerDiscription">
        Fields marked with * are mandatory
      </div>
    </div>
  );
};

export default LinkedinSignUpOptionWithEmailHeader;
