/* eslint-disable no-undef */
import React from 'react';
import '../../../style/ingredientRenderContainer.css';
import {
  Loading,
  RecipeTimeInformation,
  IngredientRenderContainerTitle,
  TimelineProcessNavigation,
} from '../../index';
import { useFetch, useGraphFetch } from '../../../utils';
import { useLocation, useParams } from 'react-router';
import {
  rawAndProcessDataActions,
  substituteComponentAction,
  fullRecipeDetailsActions,
  cascadingScreenActions,
  useDispatch,
  useSelector,
} from '../../../Redux';

/**
 * [Component] Ingredient Render Container
 * @description Fetches data of selected card.
 
 * @param {*} setTimeLineNavigation Changes string value depending on function call.
 * @param {Array} selectedRecipeData Data of a specific card .
 * @param {*} setIngredientsLastPageSelection activate substitute ingredient button.
 * @param {Array} totalSelectResult stores selected item and display its data.
 * @param {*} setIngredientTerminolgyShow Changes boolean depending on function call.
 * @param {String} recipeFetchedStatus changes state depending on the API fetch.
 * @param {Array} fullRecipeDetails stores the data after fetching the API.
 
 * @returns Displays the selected recipe time statistics and time line process.
*/

function IngredientRenderContainer({ LoadingColor }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  // console.log(params);
  const preferenceStore = useSelector(state => state.preferenceStore);
  const fullRecipeDetailsStore = useSelector(
    state => state.fullRecipeDetailsStore
  );

  React.useEffect(() => {
    if (location.pathname.split('/').length === 5) {
      dispatch(cascadingScreenActions.changeIngredientTitle(''));
    }
  }, [location]);

  const [showGenerators, setShowGenerators] = React.useState('');

  const Ingredient_substitute_Query_First_Point =
    // eslint-disable-next-line no-undef
    process.env
      .REACT_APP_RECIPE_FULL_INFORMATION_AFTER_CLICKING_RECIPE_CARD_QUERY_ID_POINT_ONE;
  const Ingredient_substitute_Query_Second_Point =
    // eslint-disable-next-line no-undef
    process.env
      .REACT_APP_RECIPE_FULL_INFORMATION_AFTER_CLICKING_RECIPE_CARD_QUERY_ID_POINT_TWO;
  // const SelectedRecipeDetails = preferenceStore.selectedRecipeData.iri;
  const SelectedRecipeDetails = params.item
    .replaceAll('.$', '<')
    .replaceAll('$', '>');

  const [recipeFetchedStatus, fullRecipeDetails] = useFetch(
    `${Ingredient_substitute_Query_First_Point}${
      SelectedRecipeDetails === undefined ? `<>` : SelectedRecipeDetails
    }${Ingredient_substitute_Query_Second_Point}`
  );

  const subGraphdata_Recipe_QerryId_First_End =
    process.env.REACT_APP_SMART_SUBSTITUTION_GRAPH_INITIAL_QUERY_ID_POINT_ONE;
  const subGraphdata_Recipe_QuerryId_Last_End =
    process.env.REACT_APP_SMART_SUBSTITUTION_GRAPH_INITIAL_QUERY_ID_POINT_TWO;
  const [substituteStatusData, subGraphdataData] = useGraphFetch(
    `${subGraphdata_Recipe_QerryId_First_End}${
      preferenceStore.selectedRecipeData.iri === ''
        ? `<>`
        : preferenceStore.selectedRecipeData.iri
    }${subGraphdata_Recipe_QuerryId_Last_End}`
  );

  React.useEffect(() => {
    dispatch(rawAndProcessDataActions.getApiInitialData(fullRecipeDetails));
    // setSubGraphdata(subGraphdataData);
    dispatch(
      fullRecipeDetailsActions.getFullRecipeDetailsData({
        data: fullRecipeDetails,
        status: recipeFetchedStatus,
      })
    );
    // console.log(fullRecipeDetails);

    dispatch(
      substituteComponentAction.getSubGraphDataFromApi(subGraphdataData)
    );
    dispatch(
      substituteComponentAction.getSubstituteDataStatusFromApi(
        substituteStatusData
      )
    );
  }, [substituteStatusData, recipeFetchedStatus]);

  // console.log(rawAndProcessStorefromStore?.rawDataPageData);

  return (
    <div className="flexTimelineStatisticContainer">
      {fullRecipeDetailsStore?.fullRecipeDetails.length !== 0 && (
        <IngredientRenderContainerTitle
          color="#f3a044"
          recipeFetchedStatus={recipeFetchedStatus}
        />
      )}

      <div className="flexTimelineStatistic">
        {recipeFetchedStatus === 'success' && <TimelineProcessNavigation />}
        {recipeFetchedStatus === 'error' && (
          <div
            style={{
              width: '100%',
              height: '80vh',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {' '}
            <Loading color={LoadingColor} />{' '}
          </div>
        )}
        {recipeFetchedStatus !== 'success' && (
          <div
            style={{
              width: '100%',
              height: '80vh',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {' '}
            <Loading color={LoadingColor} />{' '}
          </div>
        )}

        {fullRecipeDetailsStore.recipeFetchedStatus === 'success' &&
          fullRecipeDetailsStore.fullRecipeDetails.length !== 0 && (
            <RecipeTimeInformation
              title="Time Statistics"
              // timelineNavigation={timelineNavigation}
              LoadingThemeColor={LoadingColor}
              showGenerators={showGenerators}
              setShowGenerators={setShowGenerators}
            />
          )}
      </div>
    </div>
  );
}

export default IngredientRenderContainer;
