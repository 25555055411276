import React from 'react';
import '../../style/Home.css';
import { InventoryStartButton, Inventorysteps } from '../index';

/**
 * [Component] Inventorychallenge
 
 * @description Card component to display information

 * @param {*} getInventoryClick renders Inventory component screen on function call.

 * @returns the topcard containing description, steps and button to navigate to inventory challenge
 */

function Inventorychallange({
  getInventoryClick,
  containerClassName,
  stepsTitleClassName,
  challegeSvgImage,
  challangeImageClassName,
  processTitle,
  stepsBelowFirstImage,
  stepsBelowSecondImage,
  stepsBelowThirdImage,
  stepsBelowFourthImage,
  content1,
  content2,
  content3,
  content4,
  stepClass,
}) {
  const inventoryStepsMapArray = [
    {
      step: 'STEP-1',
      content: content1,

      png: stepsBelowFirstImage,
    },
    {
      step: 'STEP-2',
      content: content2,

      png: stepsBelowSecondImage,
    },
    {
      step: 'STEP-3',
      content: content3,

      png: stepsBelowThirdImage,
    },
    {
      step: 'STEP-4',
      content: content4,
      png: stepsBelowFourthImage,
    },
  ];
  return (
    <div className={`${containerClassName} ${stepClass}`}>
      <div className="inventoryTitleImgFlex">
        <div className="titleParaGraphFlex">
          <h4 className="Inventory_title">{processTitle}</h4>
          <p className="Inventory_Title_below_content">
            Discover all the recipes based on your Ingredient selection
          </p>
        </div>
        <img src={challegeSvgImage} className={challangeImageClassName} />
      </div>

      <div className="stepBarFlex">
        {inventoryStepsMapArray.map(e => (
          <Inventorysteps
            key={e.step}
            step={e.step}
            content={e.content}
            png={e.png}
            stepsTitleClassName={stepsTitleClassName}
          />
        ))}

        <InventoryStartButton getInventoryClick={getInventoryClick} />
      </div>
    </div>
  );
}

export default Inventorychallange;
