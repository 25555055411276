import React from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { BiSearch } from '../../../icon';

import {
  Loading,
  IngredientRemoveButton,
  IngredientSelectButton,
} from '../../index';
import { ToolTipInfo } from '../../../lib';
import { useLocation } from 'react-router';
import {
  invProcessStepActions,
  cascadingScreenActions,
  useDispatch,
  useSelector,
} from '../../../Redux';
import { AppColors } from '../../../Constants/AppColors';
const CascadingScreen = ({
  saperatedData,
  cascadingStatus,
  LoadingColor,
  categoriesButtonActive,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const cascadingsSreenStore = useSelector(state => state.cascadingStore);

  const getIngredientDetailsClickFromCategories = (e, i) => {
    dispatch(
      cascadingScreenActions.getIngredientDetailsClickFromCategories({
        element: e,
        index: i,
      })
    );
  };

  const recipeCategories = saperatedData.map((e, i) => (
    <button
      onClick={
        cascadingsSreenStore.cascadingLoadStatus !== 'Loading'
          ? () => getIngredientDetailsClickFromCategories(e, i)
          : null
      }
      className={`categoriesButtons ${
        cascadingsSreenStore.activeCategoriesButton === i &&
        categoriesButtonActive
      }`}
      key={e.searchfilter}
    >
      {e.searchfilter.substring(0, 1).toUpperCase() +
        e.searchfilter.substring(1).toLowerCase()}
    </button>
  ));

  const slicedDataFromclickCategory = cascadingsSreenStore.clickCategory
    .slice(0, 9)
    .filter((el, i, arr) => i === arr.indexOf(el) && el);

  const ingredientList = slicedDataFromclickCategory.map((ele, i) => (
    <IngredientSelectButton
      key={i}
      value={ele}
      onClick={() => getElementFromIngredientSelect(ele, i)}
    />
  ));

  const getElementFromIngredientSelect = ele => {
    dispatch(
      cascadingScreenActions.getElementFromIngredientSelect({
        element: ele,
        otherDispatch: dispatch(
          invProcessStepActions.whileSelectIngredientStatus()
        ),
      })
    );
  };
  const removeSelectedIngredients = el => {
    dispatch(cascadingScreenActions.removeSelectedIngredients(el));
  };

  const listOfSelectedIngredients =
    cascadingsSreenStore.selectedIngredients.map(el => (
      <IngredientRemoveButton
        key={el}
        value={el}
        onClick={() => removeSelectedIngredients(el)}
        removeIcon="x"
      />
    ));
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const firstLetterToUpperCase =
    inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
  const OPTIONS_LIMIT = 5;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };
  // ==================================input functional code===============================
  const getFocus = () => {
    dispatch(cascadingScreenActions.setTrueNotification());
  };
  const getBlur = () => {
    dispatch(cascadingScreenActions.setFalseNotification());
  };
  const getSelect = e => {
    dispatch(
      cascadingScreenActions.getSelectFromOverAllInputSearch({
        element: e.target.textContent,
        otherDispatch: dispatch(
          invProcessStepActions.whileSelectIngredientStatus()
        ),
      })
    );
  };

  // ========================================================
  const categoriesRef = React.useRef();
  React.useLayoutEffect(() => {
    scrollToTop();
  }, [cascadingStatus]);

  function scrollToTop() {
    categoriesRef.current.scrollTop = 0;
  }

  return (
    <div className="PickUpCardContainerForCascading">
      <div className="catogeriesInCascadingScreen">
        <div className="boxTitlesInCascadingWithCategories">
          Ingredient Categories:
          <ToolTipInfo
            title="This section contains the ingredient categories. The categories will get refreshed once the user selects any ingredient/s."
            placement="top"
          />
        </div>
        {cascadingsSreenStore.cascadingLoadStatus === 'Loading' && (
          <Loading color={LoadingColor} />
        )}

        <div ref={categoriesRef} className="categotiesContainer">
          {cascadingsSreenStore.cascadingLoadStatus !== 'Loading' &&
            recipeCategories}
        </div>
      </div>
      <div className="ingredientsListInCascadingScreen">
        <div className="boxTitlesInCascadingWithIngredientList">
          Ingredients available in the category :
          <b>
            {cascadingsSreenStore.selectedCategoryTitle
              .substring(0, 1)
              .toUpperCase() +
              cascadingsSreenStore.selectedCategoryTitle
                .substring(1)
                .toLowerCase()}
          </b>
          <ToolTipInfo
            title="The ingredients belonging in your selected category will be displayed in this space. Only some are shown upfront. You can search among the rest via the search bar given below"
            placement="bottom-start"
          />
        </div>
        {cascadingsSreenStore.clickCategory.length !== 0 && (
          <div className="searchInputInCascadingScreen">
            <Autocomplete
              ListboxProps={{
                style: {
                  maxHeight: '14em',
                  fontSize: '0.7em',
                  fontFamily: `'Roboto', sans-serif`,
                  // padding: '0px, 0px',
                },
              }}
              noOptionsText={
                <span style={{ padding: '-1em', fontSize: '.7em' }}>
                  Search not found
                </span>
              }
              clearOnBlur
              selectOnFocus
              onChange={getSelect}
              onBlur={getBlur}
              onFocus={getFocus}
              open={open}
              onOpen={() => {
                if (inputValue) {
                  setOpen(true);
                }
              }}
              onClose={() => setOpen(false)}
              inputValue={firstLetterToUpperCase}
              onInputChange={(e, value) => {
                setInputValue(value);

                if (!value) {
                  setOpen(false);
                }
              }}
              sx={{
                display: 'inline-block',
                '& input': {
                  width: '12rem',

                  bgcolor: 'background.paper',
                  color: theme =>
                    theme.palette.getContrastText(
                      theme.palette.background.paper
                    ),
                },
                '.MuiAutocomplete-noOptions': {
                  fontSize: '30em',
                },
              }}
              id="custom-input-demo"
              filterOptions={filterOptions}
              options={cascadingsSreenStore.clickCategory}
              renderInput={params => (
                <div className="input-iconflex" ref={params.InputProps.ref}>
                  <div
                    className="iconContainerInInputSearchInInventoryy"
                    style={{
                      backgroundColor:
                        location.pathname.split('/')[1] === 'process'
                          ? cascadingsSreenStore.notifaction
                            ? `${AppColors.process.base_color}`
                            : `${AppColors.input_inActive_bg_color}`
                          : cascadingsSreenStore.notifaction
                          ? `${AppColors.inventory.input_active_color}`
                          : `${AppColors.input_inActive_bg_color}`,
                      border:
                        location.pathname.split('/')[1] === 'process'
                          ? cascadingsSreenStore.notifaction
                            ? `.08rem solid ${AppColors.process.base_color}`
                            : `.08rem solid ${AppColors.input_blur_color}`
                          : cascadingsSreenStore.notifaction
                          ? `.08rem solid ${AppColors.inventory.input_active_color}`
                          : `.08rem solid ${AppColors.input_blur_color}`,
                    }}
                  >
                    <BiSearch
                      size={'1.3em'}
                      className="icon"
                      style={{
                        color: cascadingsSreenStore.notifaction
                          ? 'white'
                          : `${AppColors.input_blur_color}`,
                      }}
                    />
                  </div>
                  <input
                    className="input-materialui"
                    placeholder={`Search ingredients...`}
                    type="text"
                    {...params.inputProps}
                    style={{
                      border:
                        location.pathname.split('/')[1] === 'process'
                          ? cascadingsSreenStore.notifaction
                            ? `.08rem solid ${AppColors.process.base_color}`
                            : `.08rem solid ${AppColors.input_blur_color}`
                          : cascadingsSreenStore.notifaction
                          ? `.08rem solid ${AppColors.inventory.input_active_color}`
                          : `.08rem solid  ${AppColors.input_blur_color}`,
                    }}
                  />
                </div>
              )}
            />
          </div>
        )}

        <div className="ingredientListContainers"> {ingredientList}</div>
      </div>
      <div className="selectedIngredientFromCascadingScreen">
        <div className="boxTitlesInCascadingWithSelectedItem">
          Your Selection:
          <ToolTipInfo
            title="Your selected ingredients will be displayed in this section. You can deselect by clicking on the cross icon."
            placement="bottom"
          />
        </div>
        <div className="selectedIngredientContainer">
          {listOfSelectedIngredients}
        </div>
      </div>
    </div>
  );
};

export default CascadingScreen;
