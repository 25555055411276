import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthArrowBack from '../../lib/Auth/AuthButton/AuthArrowBack';
import AuthButton from '../../lib/Auth/AuthButton/AuthButton';
import AuthHeader from '../../lib/Auth/AuthHeader/AuthHeader';
import AuthInput from '../../lib/Auth/AuthInput/AuthInput';
import { authAction } from '../../Redux/Slices/AuthSlides/AuthenticationStates';
import AuthSuccessNotifactionPage from '../AuthSignUp/AuthSuccessNotifactionPage/AuthSuccessNotifactionPage';
import { OtpComponent, ForgetResetPasswordFields } from '../index';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase';
// eslint-disable-next-line react/no-unescaped-entities
const AppForgetPasswordFooter = ({ typeofActionClick, settypeOfSignUp }) => {
  const authStore = useSelector(s => s.authStore);
  const dispatch = useDispatch();
  const [emailAddress, setEmailAddress] = React.useState('');

  const [forgetStep, setForgrtStep] = React.useState(1);

  const getEmailChange = e => {
    setEmailAddress(e.target.value);
  };

  function validateEmail(emailAdress) {
    // eslint-disable-next-line no-useless-escape
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (emailAdress.match(regexEmail)) {
      return true;
    } else {
      return false;
    }
  }

  const goBackFromSecondStep = () => {
    setForgrtStep(1);

    dispatch(authAction.setProgressStep(authStore.progressStep - 1));

    dispatch(authAction.setIsHeader(true));
  };
  const goBackFromThirdStep = () => {
    setForgrtStep(2);

    dispatch(authAction.setIsHeader(true));
  };
  const getArrowClick = () => {
    dispatch(
      authAction.setForgetPasswordStatus(!authStore.forgetPasswordStatus)
    );

    dispatch(authAction.setLogin(true));
  };
  const sendPasswordReset = async email => {
    try {
      await sendPasswordResetEmail(auth, email);
      dispatch(authAction.setForgetPasswordTostSuccessMessage('success'));
    } catch (err) {
      dispatch(authAction.setForgetPasswordTostSuccessMessage('Error'));
    }
  };

  return (
    <div className="ForgetContainer">
      {forgetStep === 1 && (
        <>
          <AuthArrowBack onClick={getArrowClick} />

          <AuthHeader
            title="Reset Password"
            userType="Enter the email associated with your account and we'll send an email with instructions to reset your password"
            required={true}
            onClick={typeofActionClick}
            margintop={'2em'}
          />
          {/* <div className="emailResetMessage">
            Enter the email address associated with your account. We will send
            you instructions to reset your password.
          </div> */}
          <div className="forgetPasswordFieldContainer">
            <AuthInput
              value={emailAddress}
              placeHolder="Email ID"
              onChange={getEmailChange}
              type="email"
            />
            <div className="AuthButtonContainer">
              <AuthButton
                buttonText="Send"
                width="7em"
                height="3em"
                type="submit"
                onClick={() => sendPasswordReset(emailAddress)}
                disabled={!validateEmail(emailAddress)}
              />
            </div>
          </div>
        </>
      )}
      {forgetStep === 2 && (
        <>
          <AuthArrowBack onClick={goBackFromSecondStep} />
          <AuthHeader
            title="Enter OTP"
            userType="Please Enter the OTP send to your registered Email"
            required={true}
            margintop={'2em'}
          />
          <OtpComponent setForgrtStep={setForgrtStep} />
        </>
      )}
      {forgetStep === 3 && (
        <>
          <AuthArrowBack onClick={goBackFromThirdStep} />
          <AuthHeader
            title="Create Password"
            userType="Enter the email associated with your account and we'll send an email with instructions to reset your password"
            required={true}
            margintop={'2em'}
          />
          <ForgetResetPasswordFields setForgrtStep={setForgrtStep} />
        </>
      )}
      {/* {forgetStep === 3 && } */}
      {forgetStep === 4 && (
        <AuthSuccessNotifactionPage settypeOfSignUp={settypeOfSignUp} />
      )}
    </div>
  );
};

export default AppForgetPasswordFooter;
