import React from 'react';

import { stickerBackgroundColor, stickerColor } from '../../../utils';

import { Loading } from '../../index';
import { useLocation, useNavigate } from 'react-router';
import {
  invProcessStepActions,
  preferenceScreenActions,
  cascadingScreenActions,
  fullRecipeDetailsActions,
  useDispatch,
} from '../../../Redux';

/**
 * [Component] PreferenceCard
 * @description Renders the API data and shows as cards.
 
 * @param {*} setPreferenceNextPage render component on function call.
 * @param {*} setData stores the data of a specific card on function call.
 * @param {*} setIngredientTitle sets the title of component.
 * @param {*} setIngredientsSecondPageSelection set the state when function call.
 * @param {Array} allRecipeListData stores the data after fetching the API.
 * @param {*} setIsSuccess set the state when function call.
 
 * @returns the recipe cards.
*/

const RecipeCard = ({ dynamicCardRecipesData, LoadingColor }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  //console.logdynamicCardRecipesData);

  React.useEffect(() => {
    // dispatch(cascadingScreenActions.changeIngredientTitle());
  }, [location]);

  const getClick = el => {
    // setData(el);
    dispatch(preferenceScreenActions.setQueryKeyWhileClickingRecipeCard(el));

    dispatch(cascadingScreenActions.changeIngredientTitle(''));
    dispatch(invProcessStepActions.clickRecipeCard());

    dispatch(invProcessStepActions.setStepIcon(true));
    dispatch(fullRecipeDetailsActions.getChangeIngredientTeminologyShow(false));
    navigate(`${el.iri.replaceAll('<', '.$').replaceAll('>', '$')}`);
  };
  dynamicCardRecipesData.length === 0 &&
    dispatch(cascadingScreenActions.changeIngredientTitle(''));

  return (
    <>
      {dynamicCardRecipesData.length === 0 ? (
        // <div className="resultNotFoundContainer">
        //   <img
        //     className="recipeNotFoundImage"
        //     src={resultNotFound}
        //     alt="result not found"
        //   />

        //   <div className="resultNotFoundTitle">Recipe Not found</div>
        // </div>
        <div className="loadingContainerInPreferenceCard">
          <Loading color={LoadingColor} />
        </div>
      ) : (
        <>
          {dynamicCardRecipesData.map(el => {
            return (
              <div
                onClick={() => getClick(el)}
                className="cardContainer"
                key={el.Recipes}
              >
                <div
                  className="imageContainer"
                  style={{
                    backgroundImage: `url(${el.img})`,
                  }}
                >
                  <div className="RecipeTitle">{el.Recipes}</div>
                  <div className="stickerContainerNew">
                    <div
                      className="preferenceCardStickerNew"
                      style={{
                        background: stickerBackgroundColor(el.cuisine),
                        color: stickerColor(el.cuisine),
                      }}
                    >
                      {el.cuisine}
                    </div>
                  </div>
                </div>
                <div className="contentContainer ">
                  <div className="grid-containerHealth">
                    {el.property.map((e, index) => {
                      return (
                        <div
                          key={e}
                          className="grid-item"
                          style={{
                            textAlign: index % 2 === 0 ? 'left' : 'right',
                          }}
                        >
                          <div className="spitTitleAndValues">
                            <div>{e} :</div>
                            <div className="recipeCardValues">
                              {el.value[index]}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/* <div className="IngredientCount">
                    <div>IngredientCount : </div>
                    <div className="CountIngredient">{el.ingredient_count}</div>
                  </div> */}
                </div>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default RecipeCard;
