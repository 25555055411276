import React from 'react';

import qs from 'qs';
import axios from 'axios';
import ApplicationToken from './ApplicationToken';

// var tokenKey = ApplicationToken(false);

const useVocabularyRecursiveFetch = queryArray => {
  var collectArray = [];
  var originalResult = [];
  let containerStorage = [];

  // eslint-disable-next-line no-unused-vars
  const [status, setStatus] = React.useState('idle');
  const [result, setResult] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [findClass, setFindClass] = React.useState([]);
  const [findChildNodes, setFindChildNodes] = React.useState([]);

  // eslint-disable-next-line no-unused-vars
  const [parentLoopData, setParentLoopData] = React.useState([]);
  const [loopStatus, setLoopStatus] = React.useState('loopStart');

  const stats_main_classes_list_query_first_point =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_STATS_MAIN_CLASSES_LIST_POINT_ONE;
  const stats_main_classes_list_query_last_point =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_STATS_MAIN_CLASSES_LIST_POINT_TWO;

  var stats_recursive_for_getting_classCount_point_one =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_STATS_RECURSIVE_CLASSES_COUNT_POINT_ONE;

  var stats_recursive_for_getting_classCount_point_two =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_STATS_RECURSIVE_CLASSES_COUNT_POINT_TWO;

  // console.log(passVariable);
  const InitialFunction = value => {
    var data = qs.stringify({
      query: `${stats_main_classes_list_query_first_point}${
        value[0]?.vocabValue === '' ? `<>` : value[0]?.vocabValue
      } WHERE {   BIND (${
        value[0]?.vocabValue === '' ? `<>` : value[0]?.vocabValue
      }${stats_main_classes_list_query_last_point}`,
    });

    // eslint-disable-next-line no-undef
    var Query = window?._env_?.REACT_APP_QUERY;

    const queryCall = React.useCallback(
      async token => {
        if (!token) {
          return;
        } else {
          setStatus('Loading');

          var config = {
            method: 'POST',
            url: `${Query}`,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization:
                token?.data?.token_type + ' ' + token?.data?.access_token,
            },
            data: data,
          };

          axios(config)
            .then(response => {
              setParentLoopData(response.data.results.bindings);
              setResult(response.data.results.bindings);
              originalResult = response.data.results.bindings
                .filter(ek => ek.value !== undefined && ek)
                .map(el => el.value.value);
              setFindClass(originalResult);

              //   console.log(originalResult);

              setStatus('success');
              // console.log(originalResult);
              RecursiveFunction(queryArray, 1);
            })
            .catch(function (error) {
              setStatus('error');
              if (error.response.status === 401) {
                ApplicationToken(true, queryCall);
              }
            });
        }
      },
      [data]
    );

    React.useEffect(() => {
      if (!JSON.parse(window.localStorage.getItem('applicationToken'))) {
        ApplicationToken(true, queryCall);
        // tokenKey = ApplicationToken(true, '');
      } else {
        queryCall(
          JSON.parse(window.localStorage.getItem('applicationToken')),
          ''
        );
      }
    }, []);
  };
  const RecursiveFunction = async (piArray, i) => {
    // console.log('hello');
    if (piArray.length !== i) {
      // console.log('inside');
      // console.log(piArray);

      // eslint-disable-next-line no-undef
      var Query = window._env_.REACT_APP_QUERY;

      const queryCall1 = async (token, piArray, i) => {
        // console.log(piArray);
        var data;

        data = qs.stringify({
          query: `${stats_recursive_for_getting_classCount_point_one}${
            piArray[i].vocabValue === '' ? `<>` : piArray[i].vocabValue
          } WHERE {   BIND (${
            piArray[i].vocabValue === '' ? `<>` : piArray[i].vocabValue
          }${stats_recursive_for_getting_classCount_point_two}`,
        });
        // console.log(data);

        if (!token) {
          return;
        } else {
          setStatus('Loading');

          var config = {
            method: 'POST',
            url: `${Query}`,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization:
                token.data.token_type + ' ' + token.data.access_token,
            },
            data: data,
          };

          axios(config)
            .then(response => {
              collectArray.push(...response.data.results.bindings);

              containerStorage.push(...response.data.results.bindings);
              setFindChildNodes(containerStorage);
              //   console.log(response.data.results.bindings);
              setStatus('success');
              RecursiveFunction(piArray, i + 1);
            })
            .catch(function (error) {
              setStatus('error');
              if (error.response.status === 401) {
                ApplicationToken(true, queryCall1, piArray, i);
              }
            });
          // await authInitiate();
          // console.log(piArray);
        }
      };

      queryCall1(
        JSON.parse(window.localStorage.getItem('applicationToken')),
        piArray,
        i
      );
    } else {
      setLoopStatus('LoopEnd');
    }
  };

  InitialFunction(queryArray);

  return [loopStatus, result, findChildNodes];
};
export default useVocabularyRecursiveFetch;
