import React from 'react';
import '../../../style/TopBar.css';
import { essencaLogo } from '../../../assets';
import { FiLogOut, MdOutlineLogin } from '../../../icon';
import { getDatabase, ref, child, get } from 'firebase/database';
import { auth } from '../../../firebase';
import { NavLink } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { authAction } from '../../../Redux/Slices/AuthSlides/AuthenticationStates';
// MdOutlineLogin

/**
 * [component] TopBar
 
 * @description displays the header
 
 * @param {*} isClickHome calls the function to render Home component
 * @param {*} isClickInventory calls the function to render Inventory component
 * @param {*} isClickTime calls the function to render Time component
 * @param {*} isClickIngredient calls the function to render Ingredient component
 * @param {*} isClickHealth calls the function to render Health component

 * @return renders the header component
 */
//  isClickHome,
// getHomeClick,
const sandBoxLogin = window?._env_?.REACT_APP_SANDBOX;
function TopBar({ getSignOut }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authenticationStore = useSelector(s => s.authStore);
  const userId = auth?.currentUser?.uid;

  React.useEffect(async () => {
    const dbRef = await ref(getDatabase());
    const api = await child(dbRef, `users/${userId}`);

    get(api)
      .then(snapshot => {
        if (snapshot.exists()) {
          dispatch(authAction.setUserPersonalDetails(snapshot.val()));
        } else {
          //console.log'No data available');
        }
      })
      .catch(error => {
        console.error(error);
      });
  }, [userId]);

  const text_truncate = function (str, length, ending) {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = '...';
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };
  const gotoHomePage = () => {
    if (location.pathname.split('/')[1] !== 'home') {
      navigate(`${location.pathname.split('/')[0]}/home`);
    }
  };

  return (
    <div
      className={
        location.pathname.split('/')[1] === 'home'
          ? 'TopBar'
          : location.pathname.split('/')[1] === 'inventory'
          ? 'Inv TopBar'
          : location.pathname.split('/')[1] === 'process'
          ? 'TimeBorder TopBar'
          : location.pathname.split('/')[1] === 'demand'
          ? 'Ing TopBar'
          : location.pathname.split('/')[1] === 'stats'
          ? 'Inv TopBar'
          : 'TopBar'
      }
    >
      <div className="leftContentInTopbar">
        <img
          onClick={gotoHomePage}
          src={essencaLogo}
          className="EccencaLogoInHome"
          id="companyLogo"
        />
      </div>
      <div className="rightSideInTopBar">
        <div
          className="nameInTopBar"
          style={{
            marginRight: '1.1em',
          }}
        >
          <div className="smallUser">
            {/* <img
              style={{ width: '30px' }}
              src="https://cdn.zeplin.io/61407dc892d6142539013dcb/assets/452D573B-BF22-48C1-8E3B-38C1BC2CAFCF.png"
              alt={'non'}
            /> */}
            <div className="profileName HiColor">
              {sandBoxLogin === 'true' ? '' : 'Hi'}
            </div>
            <div className="profileName">
              {sandBoxLogin === 'true' ? (
                <></>
              ) : (
                <>
                  {authenticationStore.currentUserForApp === null
                    ? authenticationStore.userPersonalDetails?.username ===
                      undefined
                      ? `guest`
                      : `guest`
                    : text_truncate(
                        `${authenticationStore.userPersonalDetails?.username}`,
                        8
                      )}
                </>
              )}
            </div>
          </div>
        </div>
        {authenticationStore.currentUserForApp !== null ? (
          <div className="logoutInTopBar" style={{ marginRight: '1.1em' }}>
            {sandBoxLogin === 'true' ? (
              <></>
            ) : (
              <>
                <div className="logOutText" onClick={getSignOut}>
                  Logout
                </div>
                <FiLogOut
                  size="1.2em"
                  style={{ fontWeight: 'lighter' }}
                  onClick={getSignOut}
                />
              </>
            )}
          </div>
        ) : (
          <NavLink
            to="/login"
            className={isActive => (!isActive ? '' : 'unselectedBackContent')}
          >
            {sandBoxLogin === 'true' ? (
              <></>
            ) : (
              <>
                <div
                  className="logoutInTopBar"
                  style={{
                    marginRight: '1.1em',
                    // fontSize: '1.2em',
                    color: 'black',
                  }}
                >
                  <div>signin</div>

                  <MdOutlineLogin
                    size="1.2em"
                    style={{ fontWeight: 'lighter' }}
                  />
                </div>
              </>
            )}
          </NavLink>
        )}
      </div>
    </div>
  );
}

export default TopBar;
