import * as d3 from 'd3';
const GraphFitScreen = (svg, zoom, view) => {
  var bounds = view.node().getBBox();
  var parent = view.node().parentElement;
  var fullWidth = parent.clientWidth,
    fullHeight = parent.clientHeight;
  var width = bounds.width,
    height = bounds.height;
  var midX = bounds.x + width / 2,
    midY = bounds.y + height / 2;
  if (width == 0 || height == 0) return; // nothing to fit
  var scale = (0.79 || 0.75) / Math.max(width / fullWidth, height / fullHeight);
  var translate = [fullWidth / 2 - scale * midX, fullHeight / 2 - scale * midY];
  svg
    .transition()
    .duration(200 || 0)
    .call(zoom.transform, d3.zoomIdentity.translate(...translate).scale(scale));
};

export default GraphFitScreen;
