/* eslint-disable no-constant-condition */
import React from 'react';
import { handIcon, potIcon } from '../../../assets';
import { ToolTipInfo } from '../../../lib';
import { useFetch } from '../../../utils';
import { Loading } from '../../../Component/index';
import { ProcessGeneratorManipulator } from '../../../Component/index';

const ProcessChallegeGeneratorContainer = ({
  LoadingThemeColor,
  changedTimeAndTempValues,
  setChangedTimeAndTempValues,
  sb1Data,
}) => {
  //console.logRecipeQuerry.iri);

  const [passedParameters, setPassedParameters] = React.useState([]);
  const [resetButtonClick, setResetButtonClick] = React.useState(false);

  const getFinalOutPut = () => {
    setResetButtonClick(false);
    const apiParameters = sb1Data.rows.map((ek, i) => {
      const Data = {
        p_iri: ek.sb1_iri_Value,
        temp: changedTimeAndTempValues.tempValues[i],
        time: changedTimeAndTempValues.timeValues[i],
      };
      return Data;
    });

    setPassedParameters(apiParameters);
  };

  const process_generator_and_Process_monitoring_time_temp_caluculation_first_point =
    // eslint-disable-next-line no-undef
    process.env
      .REACT_APP_PROCESS_MONITORING_AND_PROCESS_GENERATOR_TIME_AND_TEMP_CALUCULATION_QUERY_ID_POINT_ONE;
  const process_generator_and_Process_monitoring_time_temp_caluculation_last_point =
    // eslint-disable-next-line no-undef
    process.env
      .REACT_APP_PROCESS_MONITORING_AND_PROCESS_GENERATOR_TIME_AND_TEMP_CALUCULATION_QUERY_ID_POINT_TWO;

  // eslint-disable-next-line no-unused-vars
  const [finalOutputSb1Status, finalOutputProcessData] =
    useFetch(`${process_generator_and_Process_monitoring_time_temp_caluculation_first_point}
      BIND (${
        passedParameters[0] === undefined ? `<>` : passedParameters[0].p_iri
      } as ?p1) .  BIND (${
      passedParameters[0] === undefined ? 0 : passedParameters[0].time
    } as ?p1_time) .  BIND (${
      passedParameters[0] === undefined ? 0 : passedParameters[0].temp
    } as ?p1_temp) .  BIND (${
      passedParameters[1] === undefined ? `<>` : passedParameters[1].p_iri
    } as ?p2) .  BIND (${
      passedParameters[1] === undefined ? 0 : passedParameters[1].time
    } as ?p2_time) .  BIND (${
      passedParameters[1] === undefined ? 0 : passedParameters[1].temp
    } as ?p2_temp) .  BIND (${
      passedParameters[2] === undefined ? `<>` : passedParameters[2].p_iri
    } as ?p3) .  BIND (${
      passedParameters[2] === undefined ? 0 : passedParameters[2].time
    } as ?p3_time) .  BIND (${
      passedParameters[2] === undefined ? 0 : passedParameters[2].temp
    } as ?p3_temp) .  BIND (${
      passedParameters[3] === undefined ? `<>` : passedParameters[3].p_iri
    } as ?p4) .  BIND (${
      passedParameters[3] === undefined ? 0 : passedParameters[3].time
    } as ?p4_time) .  BIND (${
      passedParameters[3] === undefined ? 0 : passedParameters[3].temp
    } as ?p4_temp) .  BIND (${
      passedParameters[4] === undefined ? `<>` : passedParameters[4].p_iri
    } as ?p5) .  BIND (${
      passedParameters[4] === undefined ? 0 : passedParameters[4].time
    } as ?p5_time) .  BIND (${
      passedParameters[4] === undefined ? 0 : passedParameters[4].temp
    } as ?p5_temp) .  BIND (${
      passedParameters[5] === undefined ? `<>` : passedParameters[5].p_iri
    } as ?p6) .  BIND (${
      passedParameters[5] === undefined ? 0 : passedParameters[5].time
    } as ?p6_time) .  BIND (${
      passedParameters[5] === undefined ? 0 : passedParameters[5].temp
    } as ?p6_temp) .  BIND (${
      passedParameters[6] === undefined ? `<>` : passedParameters[6].p_iri
    } as ?p7) .  BIND (${
      passedParameters[6] === undefined ? 0 : passedParameters[6].time
    } as ?p7_time) .  BIND (${
      passedParameters[6] === undefined ? 0 : passedParameters[6].temp
    }${process_generator_and_Process_monitoring_time_temp_caluculation_last_point}`);
  // console.log(finalOutputProcessData);
  const edibleText =
    passedParameters?.length === 0
      ? sb1Data?.rows[0]?.final
      : finalOutputProcessData?.length !== 0
      ? resetButtonClick
        ? sb1Data?.rows[0]?.final
        : finalOutputProcessData?.rows[0]?.total
      : '';

  return (
    <>
      <div className="processTimeStatisticContainer">
        {sb1Data.rows.length === 0 && <Loading color={LoadingThemeColor} />}
        {sb1Data.rows.length !== 0 && (
          <>
            <div className="saveResetbuttonsAndRecipeListFlexImportentInProcessChallenge">
              <ProcessGeneratorManipulator
                sb1ProcessData={sb1Data}
                changedTimeAndTempValues={changedTimeAndTempValues}
                setChangedTimeAndTempValues={setChangedTimeAndTempValues}
                getFinalOutPut={getFinalOutPut}
                setResetButtonClick={setResetButtonClick}
              />
            </div>

            <div className={'leftSideContainerSubstituteGenerator '}>
              <div className="processGeneratorRightContainerTitle">
                Process Rating{' '}
              </div>
              <p className="processGeneratorRightContainerPara">
                The process rating of a recipe is the rating achieved by
                involving the IoT devices in the process of cooking.
              </p>
              <div className="propertiesContainerInProcess ">
                <div className="propertiesSmallContainerInProcess forBorder">
                  <div className="processGeneratorPropertiesContainerHedding1">
                    Recipe flavour
                  </div>
                  <div className="idleTextAndSvgImageFlex">
                    <div className="idleText">
                      {
                        passedParameters.length === 0
                          ? sb1Data?.rows[0]?.flavor
                          : finalOutputProcessData.length !== 0
                          ? resetButtonClick
                            ? sb1Data?.rows[0]?.flavor
                            : finalOutputProcessData?.rows[0]?.final_flavour
                          : ''
                        //  finalOutputProcessData?.rows[0]?.final_flavour
                      }
                    </div>
                    <img className="potImage" alt="handImage" src={potIcon} />
                  </div>
                </div>
                <div className="propertiesSmallContainerInProcess">
                  <div className="processGeneratorPropertiesContainerHedding2">
                    Recipe texture
                  </div>
                  <div className="idleTextAndSvgImageFlexRight">
                    <div className="idleText1">
                      {/* {sb1ProcessData.rows[0].texture} */}
                      {passedParameters.length === 0
                        ? sb1Data?.rows[0]?.texture
                        : finalOutputProcessData.length !== 0
                        ? resetButtonClick
                          ? sb1Data?.rows[0]?.texture
                          : finalOutputProcessData?.rows[0]?.texture
                        : ''}
                    </div>
                    <img className="handImage" alt="potImage" src={handIcon} />
                  </div>
                </div>
              </div>
              <div className="processGeneratorRightContainerFinalHedding">
                Final outcome{' '}
                <ToolTipInfo title="The final outcome about the recipe is decided based on the flavour and texture outcome" />
              </div>
              <div
                style={{
                  color: edibleText === 'edible' ? '#5ac58a' : 'rgb(193, 6, 6)',
                }}
                className="processGeneratorRightContainerEdibleHedding"
              >
                {/* {sb1ProcessData.rows[0].final} */}
                {edibleText}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ProcessChallegeGeneratorContainer;
