import { useLocation } from 'react-router';

const InstructionsInRawData = ({
  UnderTheHoodRecipeInstructionMapArray,
  className,
}) => {
  const location = useLocation();
  var poppedData = [];
  if (UnderTheHoodRecipeInstructionMapArray.length !== 0) {
    if (location.pathname.split('/')[1] !== 'process') {
      UnderTheHoodRecipeInstructionMapArray.pop();
      poppedData = UnderTheHoodRecipeInstructionMapArray;
    } else {
      poppedData = UnderTheHoodRecipeInstructionMapArray;
    }
  }

  const instructionDeatails = poppedData.map(e => (
    <li className={className} key={e}>
      <span className="recipeRaw-list">
        {' '}
        {e
          .replaceAll('[', '')
          .replaceAll('(', '')
          .replaceAll(']', '')
          .replaceAll(')', '')}
      </span>
    </li>
  ));
  return <>{instructionDeatails}</>;
};

export default InstructionsInRawData;
