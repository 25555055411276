import React from 'react';
import './MobileScreenNotification.css';
import { titleIcon, mobileScreenImg, eccencaLogo } from '../../assets';

const MobileScreenNotification = () => {
  return (
    <div className="mobileScreenNotificationContainer">
      <div className="mobiletitleIcon">
        <img src={titleIcon} alt="image" />
      </div>
      <div className="mobileScreenNotificationContainerContentFooter">
        <div className="mobileScreenNotificationtextHeading">
          Please switch to a laptop/desktop
        </div>
        <div className="mobileScreenDescriptionImg">
          <img src={mobileScreenImg} alt="title" />
        </div>

        <div className="mobileScreenDescription">
          This content is best experienced when viewing on a laptop / desktop.
          You can also switch to ‘desktop site’ in your browser options. Thank
          you!
        </div>
      </div>
      <div className="mobileScreenFooter">
        <img src={eccencaLogo} alt="eccencaLogo" />
      </div>
    </div>
  );
};

export default MobileScreenNotification;
