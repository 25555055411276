import React from 'react';

import {
  LinkedinSignUpOptionWithEmailHeader,
  AuthSignUpPageUserPersonalInfo,
} from '../index';
import { AuthButton, AuthBackButton } from '../../lib';
import { authContext } from '../../App';

import Checkbox from '@mui/material/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { authAction } from '../../Redux/Slices/AuthSlides/AuthenticationStates';

const AuthSignUpInitialStep = () => {
  const dispatch = useDispatch();

  const authenticationStore = useSelector(s => s.authStore);
  const { RegisterUser } = React.useContext(authContext);
  const [isValid, setIsValid] = React.useState(false);

  let checkBoxValue = authenticationStore?.signUpDetails?.agreePolicy
    ? true
    : '';

  const userPersonalInformation = [
    authenticationStore?.signUpDetails?.companyName,
    checkBoxValue,
  ];

  return (
    <div className="AuthSignUpWithEmail">
      <LinkedinSignUpOptionWithEmailHeader />
      <AuthSignUpPageUserPersonalInfo
        isValid={isValid}
        setIsValid={setIsValid}
      />
      <div className="PrivacyPolicyText">
        By checking the box below, you agree and acknowledge that the data you
        submit be used for sales purposes provided that you can choose to opt
        out of it at anytime. For more information on our use of data and of
        Hatchbuck for this service, please review our{' '}
        {
          <a
            className="privacyPolicyButton"
            href="https://eccenca.com/privacy"
            rel="noreferrer"
            target="_blank"
          >
            {' '}
            Privacy Policy.
          </a>
        }{' '}
      </div>
      <div className="nextButtonAuthSignupPageContainers">
        <AuthBackButton
          onClick={() =>
            dispatch(
              authAction.setProgressStep(authenticationStore.progressStep - 1)
            )
          }
        />
        <AuthButton
          buttonText="Finish"
          width="5.8em"
          height="3em"
          type="button"
          onClick={RegisterUser}
          disabled={userPersonalInformation.includes('')}
          diableColor={
            userPersonalInformation.includes('') ? '#e8eaed' : 'orange'
          }
        />
      </div>
      <div className="privacyPolicy">
        <div className="examplePrivacyPolicy">
          <Checkbox
            type="checkbox"
            onChange={() =>
              dispatch(
                authAction.getPrivacyCheckBox(
                  !authenticationStore.signUpDetails.agreePolicy
                )
              )
            }
            value={authenticationStore?.signUpDetails?.agreePolicy}
            checked={authenticationStore?.signUpDetails?.agreePolicy}
            name={'i agree'}
            sx={{
              color: 'gray',
              '&.Mui-checked': {
                color: 'orange',
              },
              '& .MuiSvgIcon-root': {
                fontSize: 12,
              },
            }}
          />

          <span className="radio-button__label checkLabel">I agree</span>
        </div>
      </div>
    </div>
  );
};

export default AuthSignUpInitialStep;
