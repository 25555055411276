import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import '../../style/Home.css';

/**
 * [Component] SideNavBarHomeButton
 
 * @description Toogle state on the click of Home button

 * @param {*} isClickInventory calls the function to render Home component 
 * @param {*} setIsClickHome accepts boolean.
 * @param {*} setIsClickInventory accepts boolean.
 * @param {*} setIsClickTime accepts boolean.
 * @param {*} setIsClickIngredient accepts boolean.
 * @param {*} setIsClickHealth  accepts boolean.
 
* @returns Renders the Home component on function call
 */

const SideNavBarHomeButton = () => {
  const navigate = useNavigate();

  const getHomeButtonClick = () => {
    navigate('/home');
  };
  const location = useLocation();

  return (
    <button
      style={{ borderTopRightRadius: '20px' }}
      className={
        location.pathname.split('/')[1] === 'home'
          ? 'ButtonActive ButtonActive-Active'
          : 'ButtonActive'
      }
      onClick={getHomeButtonClick}
    >
      Home
    </button>
  );
};

export default SideNavBarHomeButton;
