import React from 'react';
import { CascadingScreen } from '../../index';

/**
 * [Component] InventoryPickUpCardsContainer
 
 * @description store ingredient details and navigate to next page

 * @param {*} setIngredientsSelection set the state of ingredientsSelection on function call
 * @param {*} ingredientsSelection changes state of process step on function call
 * @param {Array} totalSelect stores the selected data
 * @param {*} setVegStatus store veg ingredient on function call
 * @param {*} setMeatStatus store meat status on function call
 * @param {*} setdairyStatus store dairy status on function call
 * @param {*} setSeaStatus store sea food status on function call
 * @param {*} setCereStatus store cereal status on function call
 
 * @returns renders the container card component to store inventory pick up card
 */
function CascadingContainer({
  saperatedData,
  cascadingStatus,
  LoadingColor,
  categoriesButtonActive,
}) {
  return (
    <>
      <CascadingScreen
        saperatedData={saperatedData}
        cascadingStatus={cascadingStatus}
        LoadingColor={LoadingColor}
        categoriesButtonActive={categoriesButtonActive}
      />
    </>
  );
}

export default CascadingContainer;
