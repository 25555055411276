import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  ingredientTerminologyShow: '',
  timelineProccesButtonBlock: '',
  fullRecipeDetails: [],
  showGenerators: false,
  setShowGenerators: true,
  arrayOfKeys: [],
  arrayOfResult: [],
  arrayOfdata: [],
  activeInActiveStatus: [],
  results: [],
  recipeKeys: [],
  activeInActiveFind: [],
  recipeFetchedStatus: '',
  subIndex: 0,
  dummyArrayDataForSubstitution: [],
  saveButtonActive: false,
  resetButtonActive: false,
  refArrayOfResult: [],
  refArrayOfKeys: [],
  refActiveInActiveStatus: [],
  substituteDataValue: '',
  varIng: '',
  substituteButtonToggle: '',
  substituteReplaceDataStatus: 'idle',
  substituteOccur: false,
};
const fullRecipeDeatailsSlice = createSlice({
  name: 'fullRecipeDetailsState',
  initialState,
  reducers: {
    getChangeIngredientTeminologyShow: (state, { payload }) => {
      state.ingredientTerminologyShow = payload;
    },
    getFullRecipeDetailsData: (state, { payload }) => {
      state.recipeFetchedStatus = payload.status;
      if (payload.status === 'success') {
        state.fullRecipeDetails = payload.data;
        state.arrayOfResult = state.fullRecipeDetails.rows.map(e => {
          const data = e.ingredient_details;
          return data;
        });
        state.arrayOfKeys = state.fullRecipeDetails.rows.map(e => {
          const data = e.ing_value;
          return data;
        });
        state.activeInActiveStatus = state.fullRecipeDetails.rows.map(e => {
          const Data = e.sub_status;
          return Data;
        });
        state.refArrayOfResult = state.fullRecipeDetails.rows.map(e => {
          const data = e.ingredient_details;
          return data;
        });
        state.refArrayOfKeys = state.fullRecipeDetails.rows.map(e => {
          const data = e.ing_value;
          return data;
        });
        state.refActiveInActiveStatus = state.fullRecipeDetails.rows.map(e => {
          const Data = e.sub_status;
          return Data;
        });
        state.dummyArrayDataForSubstitution = state.fullRecipeDetails.rows.map(
          (el, i) => {
            const data = {
              ing: state.refArrayOfResult[i],
              ing_sub: '',
              ing_value: state.refArrayOfKeys[i],
              sub_status: state.refActiveInActiveStatus[i],
            };
            return data;
          }
        );
        state.arrayOfdata = state.fullRecipeDetails.rows.map((el, i) => {
          const data = {
            ing: state.arrayOfResult[i],
            ing_sub: '',
            ing_value: state.arrayOfKeys[i],
            sub_status: state.activeInActiveStatus[i],
          };
          return data;
        });
      }
    },

    getSubstituteIngredientandKey: (state, { payload }) => {
      for (let d = 0; d < state.arrayOfdata.length; d++) {
        if (state.arrayOfdata[d].ing_sub !== '') {
          state.arrayOfResult.splice(d, 1, state.arrayOfdata[d].ing_sub);
          state.arrayOfResult = [...state.arrayOfResult];
          const Fun = () => {
            return payload.otherDispatcher;
          };
          Fun();
          const Fun1 = () => {
            return payload.successStatusStepBar;
          };
          Fun1();
          state.substituteOccur = true;
        } else {
          state.arrayOfResult = [...state.arrayOfResult];
          // console.log(state.arrayOfResult);
        }
        if (state.arrayOfdata[d].ing_sub !== '') {
          state.arrayOfKeys.splice(d, 1, state.arrayOfdata[d].ing_value);
          state.arrayOfKeys = [...state.arrayOfKeys];
          const Fun2 = () => {
            return payload.successStatusStepBar;
          };
          Fun2();
        } else {
          state.arrayOfKeys = [...state.arrayOfKeys];
        }
        if (state.arrayOfdata[d].ing_sub !== '') {
          state.activeInActiveStatus.splice(
            d,
            1,
            state.arrayOfdata[d].sub_status
          );
          state.activeInActiveStatus = [...state.activeInActiveStatus];
          const successBar = () => {
            return payload.successStatusStepBar;
          };
          successBar();
        } else {
          state.activeInActiveStatus = [...state.activeInActiveStatus];
        }
      }
      state.arrayOfdata = state.arrayOfdata.map((x, i) =>
        x.ing_sub !== ''
          ? {
              ...x,
              ing: state.arrayOfResult[i],
              ing_sub: '',
              ing_value: state.arrayOfKeys[i],
              sub_status: state.activeInActiveStatus[i],
            }
          : x
      );
      state.saveButtonActive = false;
      state.resetButtonActive = false;
      state.substituteButtonToggle = null;
    },

    getReset: state => {
      state.arrayOfdata = state.fullRecipeDetails.rows.map((el, i) => {
        const data = {
          ing: state.arrayOfResult[i],
          ing_sub: '',
          ing_value: state.arrayOfKeys[i],
          sub_status: state.activeInActiveStatus[i],
        };
        return data;
      });
      state.saveButtonActive = false;
      state.substituteButtonToggle = null;
      state.resetButtonActive = false;
    },
    getSteIndex: (state, { payload }) => {
      state.subIndex = payload;
      state.substituteButtonToggle = payload;
    },
    getIngredientKey: (state, { payload }) => {
      state.substituteDataValue = payload;
    },
    getItemClick: (state, { payload }) => {
      const updattedData = state.arrayOfdata.map((x, i) =>
        i === state.subIndex
          ? { ...x, ing_sub: payload.ing_sub, ing_value: payload.sub_iri }
          : x
      );
      state.arrayOfdata = updattedData;
      state.saveButtonActive = state.arrayOfdata.some(el => el.ing_sub !== '');
    },
    getRemoveItem: (state, { payload }) => {
      state.arrayOfdata = state.arrayOfdata.map((x, i) =>
        payload.ing_sub === x.ing_sub
          ? { ...x, ing_sub: '', ing_value: state.arrayOfKeys[i] }
          : x
      );

      state.saveButtonActive = state.arrayOfdata.some(el => el.ing_sub !== '');
    },
    changeSaveButtonStatus: (state, { payload }) => {
      state.saveButtonActive = payload;
    },
    changeResetButtonActive: (state, { payload }) => {
      state.resetButtonActive = payload;
    },
    setSubstitutionOptionsKey: (state, { payload }) => {
      state.varIng = payload;
    },
    setLateLoadingIssueInOptions: (state, { payload }) => {
      state.substituteReplaceDataStatus = payload;
    },
    setSubstitutionStatus: (state, { payload }) => {
      state.substituteOccur = payload;
    },
  },
});
export const fullRecipeDetailsActions = fullRecipeDeatailsSlice.actions;
export const fullRecipeDetailsReducer = fullRecipeDeatailsSlice.reducer;
