import React from 'react';
import { stickerBackgroundColor, stickerColor, useFetch } from '../../../utils';
import {
  Loading,
  RecipeInstructions,
  SubstituteGenerator,
  RecipeDetails,
  ProcessChallegeGeneratorContainer,
  AlterCookingConditionSb1,
} from '../../../Component/index';
import { ToolTipInfo } from '../../../lib';
import { Outlet, Route, Routes, useLocation } from 'react-router';
import {
  fullRecipeDetailsActions,
  useDispatch,
  useSelector,
} from '../../../Redux';

/**
 * [Component] TimeStatistics
 * @description fetches data of recipe time statistics.
 
 * @param {string} title has a default string value.
 * @param {Array} selectedRecipeData store Api data of a specific card. 
 * @param {String} timelineNavigation depends on string render Component.
 * @param {Boolean} ingredientTerminologyShow  display component on boolean.
 * @param {*} setIngredientTerminolgyShow on function call set boolean .
 * @param {*} setIngredientsLastPageSelection set the state when function call. 
 * @param {*} setTimelineProccesButtonBlock stores the data after fetching the API.
 * @param {*} timelineProccesButtonBlock has a default string value.
 * @param {*} fullRecipeDetails store specific card ingredient details .
 
 * @returns complete detailed data of time statistics.
*/

function RecipeTimeInformation({
  title,
  LoadingThemeColor,
  showGenerators,
  setShowGenerators,
}) {
  const location = useLocation();
  const dispatch = useDispatch();
  const preferenceStore = useSelector(state => state.preferenceStore);
  const fullRecipeDetailsStore = useSelector(s => s.fullRecipeDetailsStore);

  const reciepeData = [preferenceStore.selectedRecipeData];

  const newFullrecipeDetails =
    fullRecipeDetailsStore.fullRecipeDetails.rows.filter((e, i) => i === 0);

  const recipeDetails = newFullrecipeDetails.map(e => (
    <ul className="time-Specifications" key={e.Recipes}>
      <div className="dots-Ref">
        <li>Preparation Time</li>
        <div className="timeDetail">{`${e.ptime}`}</div>
      </div>
      <div className="dots-Ref">
        <li>Cooking Time</li>
        <div className="timeDetail">{`${e.ctime}`}</div>
      </div>
      <div className="dots-Ref">
        <li>Total Duration</li>
        <div className="timeDetail">{`${e.tdtime}`}</div>
      </div>
    </ul>
  ));

  const process_generator_Data_first_query_id =
    // eslint-disable-next-line no-undef
    process.env
      .REACT_APP_PROCESS_GENERATOR_QUERY_IN_PROCESS_CHALLENGE_ID_POINT_ONE;
  const process_generator_Data_last_query_id =
    // eslint-disable-next-line no-undef
    process.env
      .REACT_APP_PROCESS_GENERATOR_QUERY_IN_PROCESS_CHALLENGE_ID_POINT_TWO;

  const [sb1ProcessStatus, sb1ProcessData] = useFetch(
    `${process_generator_Data_first_query_id}${newFullrecipeDetails[0].recipie_value}${process_generator_Data_last_query_id}`
  );

  const timeAndTempValues = {
    timeValues: [],
    tempValues: [],
  };
  const [changedTimeAndTempValues, setChangedTimeAndTempValues] =
    React.useState(timeAndTempValues);

  const showGeneratorFunction = () => {
    dispatch(fullRecipeDetailsActions.getChangeIngredientTeminologyShow(true));
    setShowGenerators('processGenerator');
  };
  return (
    <>
      {!fullRecipeDetailsStore.ingredientTerminologyShow && (
        <div
          className={`timeStatisticsContainer ${
            location.pathname.split('/')[1] === 'process' &&
            'processTimeStatisticContainer'
          }`}
        >
          <Routes>
            <Route path="/" element={<Layout />}>
              {location.pathname.split('/')[1] === 'inventory' ? (
                <Route
                  index
                  // path="/"
                  element={
                    <div className="ingredientListAndServingsFlex">
                      <RecipeDetails setShowGenerators={setShowGenerators} />
                    </div>
                  }
                />
              ) : (
                <Route
                  // path="/"
                  index
                  element={
                    <>
                      {location.pathname.split('/')[1] === 'process' ? (
                        <>
                          {sb1ProcessStatus !== 'success' && (
                            <Loading color={LoadingThemeColor} />
                          )}
                          {sb1ProcessStatus === 'success' && (
                            <AlterCookingConditionSb1
                              showGeneratorFunction={showGeneratorFunction}
                              sb1ProcessData={sb1ProcessData}
                            />
                          )}
                        </>
                      ) : (
                        <RecipeInstructions
                          setShowGenerators={setShowGenerators}
                        />
                      )}
                    </>
                  }
                />
              )}
              <Route
                path="ingredient/*"
                element={
                  <div className="ingredientListAndServingsFlex">
                    <RecipeDetails setShowGenerators={setShowGenerators} />
                  </div>
                }
              />
              <Route
                path="timeStatistics/*"
                element={
                  <div className="dots">
                    <div className="staticTitle">
                      {title === 'Time Statistics' ? (
                        <>
                          {title}
                          <ToolTipInfo
                            title="The value for each property is listed below."
                            placement="right"
                          />
                        </>
                      ) : (
                        title
                      )}
                    </div>

                    {recipeDetails}
                  </div>
                }
              />
              <Route
                path="recipeInstructions/*"
                element={
                  <>
                    {location.pathname.split('/')[1] === 'process' ? (
                      <>
                        {sb1ProcessStatus !== 'success' && (
                          <Loading color={LoadingThemeColor} />
                        )}
                        {sb1ProcessStatus === 'success' && (
                          <AlterCookingConditionSb1
                            showGeneratorFunction={showGeneratorFunction}
                            sb1ProcessData={sb1ProcessData}
                          />
                        )}
                      </>
                    ) : (
                      <RecipeInstructions
                        setShowGenerators={setShowGenerators}
                      />
                    )}
                  </>
                }
              />
            </Route>
          </Routes>

          <div
            style={{
              background: `url(${reciepeData[0].img})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
            className="imgContainer"
          >
            <div className="stickerContainer">
              <div
                className="preferenceCardSticker"
                style={{
                  background: stickerBackgroundColor(
                    preferenceStore.selectedRecipeData.cuisine
                  ),
                  color: stickerColor(
                    preferenceStore.selectedRecipeData.cuisine
                  ),
                }}
              >
                {preferenceStore.selectedRecipeData.cuisine}
              </div>
            </div>
          </div>
        </div>
      )}

      {fullRecipeDetailsStore.ingredientTerminologyShow && (
        <>
          {showGenerators === 'substituteGenerator' && <SubstituteGenerator />}
          {showGenerators === 'processGenerator' && (
            <ProcessChallegeGeneratorContainer
              LoadingThemeColor={LoadingThemeColor}
              changedTimeAndTempValues={changedTimeAndTempValues}
              setChangedTimeAndTempValues={setChangedTimeAndTempValues}
              sb1Data={sb1ProcessData}
            />
          )}
        </>
      )}
    </>
  );
}
const Layout = () => {
  return <Outlet />;
};

export default RecipeTimeInformation;
