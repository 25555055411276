import React from 'react';
import { useSelector } from 'react-redux';

/**
 * [Component] ProcessButtons
 
 * @description displays the Next button

 * @param {*} nextProcess changes to the next step on function call
 * @param {*} proceedTonextPage changes state on function call
 * @param {Array} totalSelect stores the selected data in an array
 * @param {*} goBackClick changes state on function call
 
 * @returns renders the next component on function call
 */

function ProcessButtons({
  nextProcess,

  text,
  buttonClassName,
}) {
  const cascadingStore = useSelector(state => state.cascadingStore);
  return (
    <>
      {/* {proceedTonextPage === 'idle' && ( */}
      <button
        className={buttonClassName}
        onClick={nextProcess}
        disabled={cascadingStore.totalSelectResult.length === 0 ? true : false}
        style={{
          opacity: cascadingStore.totalSelectResult.length === 0 ? '0.5' : '1',
        }}
      >
        {text}
      </button>
      {/* )} */}
    </>
  );
}

export default ProcessButtons;
