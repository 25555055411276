import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { AuthCart } from '../../lib';
import AuthLogin from './AuthLogin';

const Login = () => {
  const authenticationStore = useSelector(state => state.authStore);
  if (authenticationStore.currentUserForApp) {
    return <Navigate to={'/home'} replace />;
  }

  return (
    <AuthCart>
      <AuthLogin />
    </AuthCart>
  );
};

export default Login;
