import React from 'react';
import AuthCart from '../../lib/Auth/AuthCart/AuthCart';
import AppForgetPasswordFooter from './AppForgetPasswordFooter';

const ForgetPasswordPage = () => {
  return (
    <AuthCart>
      <AppForgetPasswordFooter />
    </AuthCart>
  );
};

export default ForgetPasswordPage;
