import React from 'react';

const SelectBoxOption = ({
  setGroupInputValue,
  setShowInfo1,
  onClickOutside,
  show,
}) => {
  const groupArray = ['Aarth.io', 'Eccenca', 'Tcs', 'Wipro'];
  const ref = React.useRef(null);

  React.useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  if (!show) return null;
  return (
    <div
      ref={ref}
      onClickOutside={() => {
        setShowInfo1(false);
      }}
      className="info-box"
    >
      <div className="OptionsContainer">
        {groupArray.map((el, i) => (
          <div
            onClick={() => setGroupInputValue(el, i)}
            className="elementsItems"
            key={i}
          >
            {el}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectBoxOption;
