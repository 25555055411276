import React from 'react';
import IngredientsListInRawData from './IngredientsListInRawData';
import InstructionsInRawData from './InstructionsInRawData';
import NutritionBoxsInRawData from './NutritionBoxsInRawData';
import { image2, image3 } from '../../../assets';

function RawDataPageInUnderTheHood({ rawAndProcessStore }) {
  // const rawAndProcessStore = useSelector(state => state.rawAndProcessStore);
  // console.log(rawAndProcessStore);
  const findCookingTime = rawAndProcessStore?.rawDataPageData?.rows?.map(
    ele => {
      const Data = {
        Title: 'Time Statistics ',
        property1: 'Preparation Time',
        value1: ele.ptime,
        property2: 'Cooking Time',
        value2: ele.ctime,
        property3: 'Total Time',
        value3: ele.tdtime,
        property4: null,
        value4: null,
        bordercolor: '#1e84bb',
        image: image2,
        width: '8.5em',
        marginright: '0.3em',
        margintop: '0.3em',
      };
      return Data;
    }
  );

  const findIngredientDetails = rawAndProcessStore?.rawDataPageData?.rows?.map(
    ek => {
      const Data = {
        Title: 'Ingredient Details',
        property1: 'Ingredient Count',
        value1: ek.ingredient_count,
        property2: 'Servings',
        value2: ek.serving,
        property3: null,
        property4: null,
        value3: null,
        value4: null,
        bordercolor: '#9ef7d1',
        image: image3,
        width: '13em',
        marginright: '0.3em',
        margintop: '0.4em',
      };
      return Data;
    }
  );
  const nutritionDetails = [
    // ...findNutrition,
    findCookingTime[0],
    findIngredientDetails[0],
  ];

  const UnderTheHoodIngredientsMapArray =
    rawAndProcessStore?.rawDataPageData?.rows;

  const UnderTheHoodRecipeInstructionMapArray =
    rawAndProcessStore?.rawDataPageData?.rows[0]?.instructions?.split('.');

  return (
    <>
      <div className="rawData">
        <div className="rawDataHeader">
          <div className="headerRecipe">Recipe :</div>
          <div className="headerPadThai">
            {UnderTheHoodIngredientsMapArray[0]?.Recipes}
          </div>
        </div>
        <div className="recipeFlexBox">
          {nutritionDetails?.map((el, i) => (
            <NutritionBoxsInRawData
              key={i}
              title={el.Title}
              value1={el.value1}
              value2={el.value2}
              value3={el.value3}
              value4={el.value4}
              property1={el.property1}
              property2={el.property2}
              property3={el.property3}
              property4={el.property4}
              bordercolor={el.bordercolor}
              image={el.image}
              index={i}
            />
          ))}
        </div>
        <div className="ingredientHeading">Ingredients : </div>
        <div className="ingredientFlexbox">
          {UnderTheHoodIngredientsMapArray?.map((el, i) => (
            <IngredientsListInRawData
              key={i}
              ingredients={el.ingredient_details}
            />
          ))}
        </div>
        <div className="recipeInstructionHeading">Recipe Instructions</div>
        <div className="recipeInstruction">
          <InstructionsInRawData
            UnderTheHoodRecipeInstructionMapArray={
              UnderTheHoodRecipeInstructionMapArray
            }
            className="recipeInstructionDataInInventoryChallege"
          />
        </div>
      </div>
    </>
  );
}

export default RawDataPageInUnderTheHood;
