/* eslint-disable no-inner-declarations */
import React from 'react';
import SubstituteButtonModel from './SubstituteButtonModal/SubstituteButtonModel';
import { green } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';

import { useGraphFetch } from '../../../utils';

const ShowOptions = ({
  subnodes,
  index,
  subGraphdata,
  subLink,
  setData,
  setSelectedSubstituteNodes,
  data,
  setChangeSave,
  subOptionGraphdata,
  deleteNodes,
  length,
  checkedState,
  setCheckedState,
  checkedBoxState,
  setCheckeBoxdState,
}) => {
  const [itemIndex, setIndex] = React.useState([]);

  const handleOnChange = (item, position) => {
    const updatedCheckedState = checkedState.map((item, index) => {
      let data = index === position ? !item : false;

      return data;
    });

    setCheckedState(updatedCheckedState);
    // setButtonValue('');
    setSelectedSubstituteNodes(item);
    setIndex(item);
  };

  const setParticularCheckbox = position => {
    const updatedCheckedState = checkedBoxState.map((item, index) => {
      let data = index === position ? !item : false;

      return data;
    });
    setCheckeBoxdState(updatedCheckedState);
    setChangeSave(true);
  };

  let butttonData = '';
  for (let i = 0; i < checkedBoxState.length; i++) {
    if (checkedBoxState[i] === true) {
      butttonData = subOptionGraphdata[i]?.value;
    }
  }
  const smartSubQuerryIdFirstPoint =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_SMART_SUBSTITUTE_PROPERTY_API_CALL_FIRST_POINT;
  const smartSubQuerryIdLastPoint =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_SMART_SUBSTITUTE_PROPERTY_API_CALL_LAST_POINT;

  // eslint-disable-next-line no-unused-vars
  const [substituteSubOptionPropertyStatus, substituteSubOptionPropertyData] =
    useGraphFetch(
      `${smartSubQuerryIdFirstPoint}${
        butttonData === '' ? `<>` : butttonData
      }${smartSubQuerryIdLastPoint}`
    );

  React.useEffect(() => {
    if (substituteSubOptionPropertyData.length !== 0) {
      let newfetchSubPropertNodes = substituteSubOptionPropertyData.map(
        (e, i) => {
          let slashSplit = e.p.value.split('/');

          let hashtagSplit = slashSplit[slashSplit.length - 1].split('#');
          const specialCharectorRemovedNode =
            hashtagSplit[hashtagSplit.length - 1];
          let slashSplit1 = e.class.value.split('/');

          let hashtagSplit1 = slashSplit1[slashSplit1.length - 1].split('#');
          const specialCharectorRemovedNode1 =
            hashtagSplit1[hashtagSplit1.length - 1];
          let data = {
            id: '10' + `${i + 1}`,
            name: `${`${e.object.value}:${specialCharectorRemovedNode1}`}`,
            value: e.value.value,
            property: specialCharectorRemovedNode,
            actualName: e.object.value,
            edgeTypes: specialCharectorRemovedNode,
          };
          return data;
        }
      );
      let repacebaleNodeNodename = deleteNodes.split(':');
      let newNodeQuantity = repacebaleNodeNodename[1];
      let parentsSubNodes = {
        id: `${20}` + `${parseInt(itemIndex.id)}`,
        name: `${substituteSubOptionPropertyData[0].ingredient.value}:${newNodeQuantity}`,
        value: substituteSubOptionPropertyData[0].value.value,
        p: substituteSubOptionPropertyData[0].p.value,
        actualName: substituteSubOptionPropertyData[0].ingredient.value,
        edgeTypes: substituteSubOptionPropertyData[0].p.value,
      };

      let newfetchNodes = subGraphdata.map((e, i) => {
        let slashSplit = e.p.value.split('/');

        let hashtagSplit = slashSplit[slashSplit.length - 1].split('#');
        const specialCharectorRemovedNode =
          hashtagSplit[hashtagSplit.length - 1];
        let slashSplit1 = e.class.value.split('/');
        let hashtagSplit1 = slashSplit1[slashSplit1.length - 1].split('#');
        const specialCharectorRemovedNode1 =
          hashtagSplit1[hashtagSplit1.length - 1];
        let data = {
          id: index + `${i + 1}`,
          name: `${`${e.object.value}:${specialCharectorRemovedNode1}`}`,
          value: e.value.value,
          property: specialCharectorRemovedNode,
          actualName: e.object.value,
          edgeTypes: specialCharectorRemovedNode,
        };
        return data;
      });

      let outputArray = [];
      for (let i = 0; i < newfetchSubPropertNodes.length; i++) {
        for (let j = 0; j < newfetchNodes.length; j++) {
          if (newfetchSubPropertNodes[i].name == newfetchNodes[j].name) {
            newfetchSubPropertNodes[i].id = newfetchNodes[j].id;
          }
        }
        outputArray[i] = newfetchSubPropertNodes[i];
      }

      let totalNodesForLink = [parentsSubNodes, ...outputArray];

      data.nodes.length = subnodes.length;

      let subOriginalNodes = data.nodes;

      let originalNodes = [
        ...subOriginalNodes,
        ...newfetchNodes,
        ...totalNodesForLink,
      ];

      const getUniqueListBy = (data = [], key) => {
        const set = new Set();
        return data.reduce((arr, e) => {
          if (!set.has(e[key])) {
            set.add(e[key]);
            arr.push({ ...e });
          }
          return arr;
        }, []);
      };

      let replaceableNodes = getUniqueListBy(originalNodes, 'id');

      // // // ****************************************************************************links commection ********************************************************************

      let newfetchSubPropertLinks = totalNodesForLink.map(e => {
        let data = {
          source: totalNodesForLink[0].id,
          target: e.id,
          edgeTypes: e.p === undefined ? e.property : e.p,
          name: e.name,
        };
        return data;
      });

      newfetchSubPropertLinks.shift();

      let sourcePropertyLink = newfetchNodes.map(e => {
        let data = {
          source: index,
          target: e.id,
          edgeTypes: e.property,
          name: e.name,
        };
        return data;
      });
      data.links.length = subLink.length;
      let originalLinksData = data.links;
      let defaultLink = originalLinksData.map(e => {
        let data = {
          source: e.source.id,
          target: e.target.id,
          edgeTypes: e.edgeTypes,
          name: e.name,
        };
        return data;
      });

      let originalLink = [
        ...defaultLink,
        ...sourcePropertyLink,
        ...newfetchSubPropertLinks,
      ];

      let dataSet = {
        nodes: replaceableNodes,
        links: originalLink,
      };
      if (checkedBoxState.some(eg => eg !== false)) {
        setData(dataSet);
        // if (alterNodeClik === 0) {
        //   zoomInAtNodeClick();
        // }
      } else {
        const spareLinks = dataSet.links.slice(
          0,
          dataSet.links.length - sourcePropertyLink.length
        );

        const spareNodes = totalNodesForLink.filter(
          eg => !newfetchNodes.map(ed => ed.id).includes(eg.id) && eg
        );

        const included1 = spareNodes.map(JSON.stringify);
        const remainingnodes = dataSet.nodes
          .map(JSON.stringify)
          .filter(eg => !included1.includes(eg) && eg)
          .map(JSON.parse);

        const revertedData = {
          nodes: remainingnodes,
          links: spareLinks,
        };
        setData(revertedData);
        // zoomInACtiveNodeClick();
      }

      // ****************************************************************************links commection ********************************************************************
    }
  }, [substituteSubOptionPropertyData, checkedBoxState]);
  React.useEffect(() => {
    setCheckedState(new Array(length).fill(false));
    setCheckeBoxdState(new Array(length).fill(false));
  }, [length]);
  return (
    <div className="showOptionContainer">
      {subOptionGraphdata.map((e, i) => {
        return (
          <div key={i}>
            <div className="showOptionButton">
              {checkedState[i] && (
                <div className="ButtonTitle" value={e.name}>
                  <Checkbox
                    type="checkbox"
                    onChange={() => setParticularCheckbox(i)}
                    value={checkedBoxState[i]}
                    name={e}
                    sx={{
                      color: green[0],
                      '&.Mui-checked': {
                        color: green[600],
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: 12,
                      },
                    }}
                  />
                </div>
              )}
              <button
                className="checkBoxOption"
                onClick={() => handleOnChange(e, i)}
                style={{
                  color: checkedState[i] ? '#171717' : '#b5b5b5',
                  paddingLeft: !checkedState[i] ? '0.9em' : '0',
                  fontWeight: checkedState[i] ? 'bold' : 'normal',
                }}
              >
                {e.name}
              </button>
            </div>

            {checkedState[i] && (
              <SubstituteButtonModel property={e.properties} match={e.match} />
            )}
            {subOptionGraphdata.length - 1 === i ? null : (
              <hr className="SubstitutionButtonSaperateLine" />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ShowOptions;
