import React from 'react';
import { useDispatch } from 'react-redux';
import { MdOutlineCancel } from '../../icon';
import { authAction } from '../../Redux/Slices/AuthSlides/AuthenticationStates';
import './AppTour.css';

const AppTour = () => {
  const dispatch = useDispatch();

  const getFeedBackButton = () => {
    dispatch(authAction.setIstourOpen(true));
    dispatch(authAction.getFeedbackCancelIcon(false));
  };
  return (
    <div className="feedback-modal">
      <div className="feedback-modal-icon">
        <div className="feedback-modal-step-heading">
          Welcome to the curry company(beta)!
        </div>
        <div
          onClick={() => dispatch(authAction.getFeedbackCancelIcon(true))}
          style={{ cursor: 'pointer' }}
        >
          <MdOutlineCancel color="#bcc2ca" size="1.4rem" />
        </div>
      </div>
      <div className="feedback-modal-step-description">
        We, at eccenca, want to make automating decisions through digitizing
        knowledge tangible for you!
      </div>
      <div className="feedback-modal-step-description-next-page">
        That is why we have created the ‘Curry Company(beta)’. In this company,
        we will be introducing more sample use cases around the concept of
        planning, sourcing, making, delivering, servicing and recycling food.
      </div>
      <div className="feedback-modal-step-description-button-container">
        <div
          className="feedback-modal-step-description-button"
          onClick={getFeedBackButton}
        >
          Take A Tour
        </div>
      </div>
    </div>
  );
};

export default AppTour;
