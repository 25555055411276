import React from 'react';
import { AuthInput, RadioButton, AuthCountryPhoneField } from '../../../lib';

import './AuthSignUpPageUserPersonalInfo.css';
import { authContext } from '../../../App';
import { useDispatch, useSelector } from 'react-redux';
import { authAction } from '../../../Redux/Slices/AuthSlides/AuthenticationStates';

const AuthSignUpPageUserPersonalInfo = ({ isValid, setIsValid }) => {
  const dispatch = useDispatch();
  const authenticationStore = useSelector(state => state.authStore);
  const { setUserpersonalInfo } = React.useContext(authContext);

  return (
    <>
      <div className="AuthSiginUpWithEmailInitalStep">
        <AuthInput
          value={authenticationStore?.signUpDetails?.companyName}
          placeHolder="Company Name*"
          onChange={e => dispatch(authAction.getCompanyName(e.target.value))}
          type="text"
        />

        <AuthCountryPhoneField
          isValid={isValid}
          setIsValid={setIsValid}
          setUserpersonalInfo={setUserpersonalInfo}
        />

        <AuthInput
          value={authenticationStore?.signUpDetails?.domine}
          placeHolder="Industry"
          onChange={e => dispatch(authAction.getDomineName(e.target.value))}
          type="text"
        />

        <div className="AuthCheckBoxContainer">
          <div>Do you belong to an Eccenca partner company ? *</div>
          <RadioButton />
        </div>
      </div>
    </>
  );
};

export default AuthSignUpPageUserPersonalInfo;
