import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthHeader } from '../../lib';
import { authAction } from '../../Redux/Slices/AuthSlides/AuthenticationStates';

import '../AuthLogin/AuthLogin.css';
import {
  AppSignUpFooter,
  AuthDefaultSignUp,
  AuthSuccessNotifactionPage,
} from '../index';

const AppSignUp = ({ settypeOfSignUp }) => {
  const dispatch = useDispatch();
  const authenticationStore = useSelector(s => s.authStore);
  const typeofActionClick = () => {
    dispatch(authAction.getUserName(''));
    dispatch(authAction.getEmail(''));
    dispatch(authAction.getPassword(''));
    dispatch(authAction.getConfirmPassword(''));
    dispatch(authAction.setLogin(!authenticationStore.isLogin));
    // setProgressStep(0);
    dispatch(authAction.setProgressStep(0));
    // settypeOfSignUp('email');
  };

  // eslint-disable-next-line no-unused-vars
  const [showEmailError, setShowEmailError] = React.useState(false);

  //console.logisSignInSuccessfull);

  return (
    <>
      {!authenticationStore.isSignInSuccessfull ? (
        <div className="AuthLoginContainer">
          <AuthHeader
            title="Create an account"
            userType="Already have an account?"
            typeOfAction="Sign in"
            onClick={typeofActionClick}
          />

          {authenticationStore.typeofSignUp === 'email' && (
            <AppSignUpFooter setShowEmailError={setShowEmailError} />
          )}
          {authenticationStore.typeofSignUp === 'linkedin' && (
            <AuthDefaultSignUp settypeOfSignUp={settypeOfSignUp} />
          )}
        </div>
      ) : (
        <AuthSuccessNotifactionPage settypeOfSignUp={settypeOfSignUp} />
      )}
    </>
  );
};

export default AppSignUp;
