import React from 'react';

import qs from 'qs';
import axios from 'axios';
import ApplicationToken from './ApplicationToken';

// var tokenKey = ApplicationToken(false);

const useSubstituteFetch = query => {
  const [status, setStatus] = React.useState('idle');
  const [result, setResult] = React.useState([]);
  const newResult = query.substituteDataValue.toLowerCase();
  var data = qs.stringify({
    query: `${query.api}`,
  });

  // eslint-disable-next-line no-undef
  const Query = window?._env_?.REACT_APP_QUERY;

  const queryCall = React.useCallback(
    async token => {
      if (!token) {
        return;
      } else {
        setStatus('Loading');
        var config = {
          method: 'POST',
          url: `${Query}`,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization:
              token?.data?.token_type + ' ' + token?.data?.access_token,
          },
          data: data,
        };
        setStatus('Loading');

        axios(config)
          .then(response => {
            // console.log(response.data.results.bindings);
            const rows = response.data.results.bindings.map(e => {
              // console.log(e);
              const Data = {
                ing_sub: e.ing_sub.value,
                ingre: e.ingre.value,
                sub_iri: e.sub_iri.value,
              };
              return Data;
            });
            const allRecipeListData = {
              columns: [
                { friendly_name: 'ingre', type: 'string', name: 'ingre' },
                { friendly_name: 'ing_sub', type: 'string', name: 'ing_sub' },
              ],
              rows: [...rows],
            };
            // console.log(allRecipeListData);
            const substitutebleData = allRecipeListData.rows.map(el => {
              const lowerCaseResultSubIng = el.ing_sub.toLowerCase();
              const lowerCaseResultIng = el.ingre.toLowerCase();

              const result = {
                sub_iri: el.sub_iri,
                ingre: el.ingre,
                ing_sub: newResult.replace(
                  lowerCaseResultIng,
                  lowerCaseResultSubIng
                ),
              };

              return result;
            });

            setStatus('success');
            setResult(substitutebleData);
          })
          .catch(function (error) {
            setStatus('error');
            if (error.response.status === 401) {
              ApplicationToken(true, queryCall);
            }
          });
      }
    },
    [data]
  );

  React.useEffect(() => {
    if (!JSON.parse(window.localStorage.getItem('applicationToken'))) {
      ApplicationToken(true, queryCall);
      // tokenKey = ApplicationToken(true, '');
    } else {
      queryCall(
        JSON.parse(window.localStorage.getItem('applicationToken')),
        ''
      );
    }
  }, [query.api]);

  return [status, result];
};

export default useSubstituteFetch;
