import React from 'react';
import '../../style/Inventory.css';
import { useLocation, useNavigate } from 'react-router';
import {
  invProcessStepActions,
  cascadingScreenActions,
  cuisineScreenActions,
  useDispatch,
} from '../../Redux';

/**
 * [Component] SideBarInventoryButton
 
 * @description Toogle state on the click of Inventory button

 * @param {*} isClickInventory calls the function to render Inventory component 
 * @param {*} setIsClickHome accepts boolean.
 * @param {*} setIsClickInventory accepts boolean.
 * @param {*} setIsClickTime accepts boolean.
 * @param {*} setIsClickIngredient accepts boolean.
 * @param {*} setIsClickHealth  accepts boolean.
 
* @returns Renders the inventory component on function call
 */

const SideBarInventoryButton = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const InventoryClick = () => {
    navigate('/inventory');
    dispatch(cuisineScreenActions.clearSelectedCuisineContainer());
    if (location.pathname.split('/')[1] !== 'inventory') {
      dispatch(invProcessStepActions.gotoInitialStateRefresh());
      dispatch(cascadingScreenActions.clearSelectedIngredients());
      dispatch(cascadingScreenActions.getHideTopInput());
      dispatch(
        cascadingScreenActions.changeIngredientTitle(
          'To view the corresponding recipes, first select the ingredients you want to use. Add an ingredient either by browsing the available ingredients by category or by searching for a specific ingredient in the search box on the right. After ingredient selection, click on the ‘Next’ button to go to cuisine selection..'
        )
      );
    }
  };
  return (
    <button
      className={
        location.pathname.split('/')[1] === 'inventory'
          ? 'invButton invButtonActive'
          : 'invButton first-step'
      }
      onClick={InventoryClick}
    >
      Inventory Challenge
    </button>
  );
};

export default SideBarInventoryButton;
