import React from 'react';
import {
  TopBar,
  Home,
  Inventory,
  SideNavBar,
  SignUp,
  ProcessChallenge,
  Login,
  Statististics,
  ForgetPasswordPage,
} from './Component';
import './style/App.css';
import './style/AppMediaQueries.css';
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from 'firebase/auth';
import { auth } from './firebase';
import { getDatabase, ref, set } from 'firebase/database';
import { MdOutlineCancel } from './icon';
import { AppTour, ProtectedRoutes, MobileScreenNotification } from './lib';
import { useMediaQuery } from '@mui/material';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import {
  invProcessStepActions,
  cascadingScreenActions,
  useDispatch,
  useSelector,
  fullRecipeDetailsActions,
} from './Redux';
import { deleteItemFromMemory, setDatainLocalMemory } from './utils';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import { eccencaLogo, titleIcon, mobileScreenImg } from './assets';
import { authAction } from './Redux/Slices/AuthSlides/AuthenticationStates';
import DemandForecasting from './Component/Menu/DemandForecasting';
import ApplicationToken from './utils/ApplicationToken';
export const LoginContext = React.createContext();
export const authContext = React.createContext();

/**
 * @componentName  SideNavBar
 * @description  SideNavBar Component manage page State.
 */
/**
 * @componentName  TopBar
 * @description TopBar Component for `userNme `,`App Logo` and `logout function `
 */
/**
 * @componentName Home
 * @description  At the starting of App, initially  render Home Component for that isClickHome initial value is true
 */
/**
 * @componentName Inventory
 * @description  Based on boolean  render Inventory Component
 */

const App = () => {
  React.useEffect(() => {
    if (window.localStorage.getItem('applicationToken') === null) {
      ApplicationToken(true, '');
    }
  }, []);
  const authStore = useSelector(s => s.authStore);
  const navigate = useNavigate();
  const location = useLocation();
  const invProcessBarSteps = useSelector(state => state.inventoryProcessSteps);
  const dispatch = useDispatch();
  const goBackClick = () => {};

  const getIngredientButtonClick = () => {
    if (
      invProcessBarSteps.proceedTonextPage === 'idle' ||
      invProcessBarSteps.ingredientsSecondPageSelection === 'success'
    ) {
      return;
    }
    if (invProcessBarSteps.cuisinePageSelection === 'success') {
      return;
    }
    dispatch(invProcessStepActions.selectTheIngredients());

    dispatch(cascadingScreenActions.getHideTopInput());
    navigate(`${location.pathname.split('/')[1]}`);
  };

  const getRecipeCatalogueButtonClick = () => {
    if (
      location.pathname.split('/').length === 5 ||
      location.pathname.split('/').length === 6
    ) {
      navigate(
        `${location.pathname.split('/')[1]}/${
          location.pathname.split('/')[2]
        }/${location.pathname.split('/')[3]}`
      );
      dispatch(invProcessStepActions.catalogueRecipe());
      dispatch(invProcessStepActions.setStepIcon(false));
      dispatch(fullRecipeDetailsActions.setSubstitutionStatus(false));
    } else {
      return;
    }
  };

  const getPreferencesButtonClick = () => {
    if (
      location.pathname.split('/').length !== 4 ||
      invProcessBarSteps.ingredientsSecondPageSelection === 'success'
    ) {
      return;
    }
    navigate(
      `${location.pathname.split('/')[1]}/${location.pathname.split('/')[2]}`
    );

    dispatch(invProcessStepActions.selectCuisineType());
  };

  const RegisterUser = async () => {
    try {
      dispatch(authAction.setRegisterLoading(true));
      const { user } = await createUserWithEmailAndPassword(
        auth,
        authStore.signUpDetails.email,
        authStore.signUpDetails.userConfirmPassword
      );
      dispatch(authAction.setUser(user));

      dispatch(authAction.setRegisterLoading(true));

      dispatch(authAction.setErrorStatus(false));
      setDatainLocalMemory('applicationUser', user);

      await writeUserData(user);
    } catch (e) {
      if (e.code !== undefined) {
        let errorStatus = e.code;
        let errorMessage = errorStatus.split('auth/');
        dispatch(
          authAction.setRegisterError(errorMessage[1].replaceAll('-', ' '))
        );
        dispatch(authAction.setRegisterLoading(false));
        dispatch(authAction.setErrorStatus(true));
        dispatch(authAction.setProgressStep(authStore.progressStep - 1));
      }
    }
  };

  const writeUserData = async user => {
    if (user.length !== 0) {
      const db = getDatabase();
      const userId = await user.uid;
      set(ref(db, 'users/' + userId), {
        email: authStore.signUpDetails.email,
        username: authStore.signUpDetails.userName,
        companyName: authStore.signUpDetails.companyName,
        phoneNumber: authStore.signUpDetails.phoneNumber,
        domine: authStore.signUpDetails.domine,
        belongsTo: authStore.signUpDetails.belongsTo,
      });
      dispatch(authAction.setProgressStep(0));
      dispatch(authAction.getFeedbackCancelIcon(true));
    }
  };

  let authValue = {
    RegisterUser: RegisterUser,
    writeUserData: writeUserData,
  };

  const getSignOut = async () => {
    dispatch(invProcessStepActions.setStepIcon(false));
    await signOut(auth);
    deleteItemFromMemory('applicationUser');
    deleteItemFromMemory(
      'firebase:host:curry-company-default-rtdb.firebaseio.com'
    );
    dispatch(authAction.setUserPersonalDetails([]));
    navigate('/login');
  };
  onAuthStateChanged(auth, currentUser => {
    dispatch(authAction.setCurrentUserForApp(currentUser));
    if (currentUser) {
      if (currentUser.metadata.createdAt === currentUser.metadata.lastLoginAt) {
        // setShowTour(true);
        dispatch(authAction.getShowTour(true));
      } else {
        // setShowTour(false);
        dispatch(authAction.getShowTour(false));
      }
    }
    if (!currentUser) {
      // setShowTour(true);
      dispatch(authAction.getShowTour(true));
    }
  });
  const steps = [
    {
      selector: '.first-step',
      content: ({ goTo }) => (
        <div className="stepInsideContainer">
          <div className="stepInsideContainerHeading">
            <div>
              <h2 className="tourTitlesTop">Inventory Challenge</h2>
            </div>
            <div
              onClick={() => dispatch(authAction.setIstourOpen(false))}
              style={{ cursor: 'pointer' }}
            >
              <MdOutlineCancel color="#bcc2ca" size="2.1em" />
            </div>
          </div>
          <div className="stepInsideContainernumberOfStep">1/6</div>
          <div className="stepInsideContainerdescription">
            In the inventory challenge, we leverage the expert knowledge of
            various chefs and apply it to suit a user’s requirements and
            recommend ingredient substitutions based on the textures and
            flavours. There are 4 steps in this challenge and a ‘Under the hood’
            section which showcases the internal working.
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: '2em',
            }}
          >
            <button
              style={{
                padding: '.5em  1em',
                outline: 'none',
                border: 'none',
                borderRadius: '.5em',
                backgroundColor: '#f7962c',
                color: 'white',
                marginLeft: '1em',
                fontSize: '.8rem',
              }}
              onClick={() => goTo(1)}
              className="processStepNext1"
            >
              Next
            </button>
          </div>
        </div>
      ),
      position: 'top',

      style: {
        backgroundColor: 'white',
      },
    },
    {
      selector: '.second-step',

      content: ({ goTo }) => (
        <div className="stepInsideContainer">
          <div className="stepInsideContainerHeading">
            <div>
              <h2 className="tourTitlesTop">Inventory Challenge</h2>
            </div>
            <div
              onClick={() => dispatch(authAction.setIstourOpen(false))}
              style={{ cursor: 'pointer' }}
            >
              <MdOutlineCancel color="#bcc2ca" size="2.1em" />
            </div>
          </div>

          <div className="stepInsideContainernumberOfStep">2/6</div>
          <div className="stepInsideContainerdescription">
            Here are the 4 steps in which the inventory challenge can be
            completed. At the 4th step the ingredient substitution can be done
            for the selected ingredient. Click on the ‘Start Cooking’ button to
            get started with inventory challenge!
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: '2em',
            }}
          >
            <button
              style={{
                padding: '.5em  1em',
                outline: 'none',
                border: 'none',
                borderRadius: '.5em',
                backgroundColor: '#f2f2f2',
                color: '#f7962c',
                fontSize: '.8rem',
              }}
              className="processStepNext1"
              onClick={() => goTo(0)}
            >
              Back
            </button>
            <button
              style={{
                padding: '.5em  1em',
                outline: 'none',
                border: 'none',
                borderRadius: '.5em',
                backgroundColor: '#f7962c',
                color: 'white',
                marginLeft: '1em',
                fontSize: '.8rem',
              }}
              className="processStepNext1"
              onClick={() => goTo(2)}
            >
              Next
            </button>
          </div>
        </div>
      ),
      position: 'top',

      style: {
        backgroundColor: 'white',
      },
    },
    {
      selector: '.thierd-step',

      content: ({ goTo }) => (
        <div className="stepInsideContainer">
          <div className="stepInsideContainerHeading">
            <div>
              <h2 className="tourTitlesTop">Process challenge</h2>
            </div>
            <div
              onClick={() => dispatch(authAction.setIstourOpen(false))}
              style={{ cursor: 'pointer' }}
            >
              <MdOutlineCancel color="#bcc2ca" size="2.1em" />
            </div>
          </div>

          <div className="stepInsideContainernumberOfStep">3/6</div>
          <div className="stepInsideContainerdescription">
            Monitor and tweak the process to manage the cooking conditions such
            as time and temperature of a recipe to see how the texture and
            flavour turn out. There are 4 steps in this challenge and a ‘Under
            the hood’ section which showcases the internal working.
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: '2em',
            }}
          >
            <button
              style={{
                padding: '.5em  1em',
                outline: 'none',
                border: 'none',
                borderRadius: '.5em',
                backgroundColor: '#f2f2f2',
                color: '#f7962c',
                fontSize: '.8rem',
              }}
              className="processStepNext1"
              onClick={() => goTo(1)}
            >
              Back
            </button>
            <button
              style={{
                padding: '.5em  1em',
                outline: 'none',
                border: 'none',
                borderRadius: '.5em',
                backgroundColor: '#f7962c',
                color: 'white',
                marginLeft: '1em',
                fontSize: '.8rem',
              }}
              className="processStepNext1"
              onClick={() => goTo(3)}
            >
              Next
            </button>
          </div>
        </div>
      ),
      position: 'top',

      style: {
        backgroundColor: 'white',
      },
    },

    {
      selector: '.fourth-step',

      content: ({ goTo }) => (
        <div className="stepInsideContainer">
          <div className="stepInsideContainerHeading">
            <h2 className="tourTitlesTop">Process challenge</h2>
            <div
              onClick={() => dispatch(authAction.setIstourOpen(false))}
              style={{ cursor: 'pointer' }}
            >
              <MdOutlineCancel color="#bcc2ca" size="2.1em" />
            </div>
          </div>

          <div className="stepInsideContainernumberOfStep">4/6</div>
          <div className="stepInsideContainerdescription">
            Here are the 4 steps in which the process challenge can be
            completed. At the 4th step the process monitoring can be done. Click
            on the ‘Start Cooking’ button to get started with inventory
            challenge!
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: '2em',
            }}
          >
            <button
              style={{
                padding: '.5em  1em',
                outline: 'none',
                border: 'none',
                borderRadius: '.5em',
                backgroundColor: '#f2f2f2',
                color: '#f7962c',
                marginLeft: '1em',
                fontSize: '.8rem',
              }}
              className="processStepNext1"
              onClick={() => goTo(2)}
            >
              Back
            </button>
            <button
              style={{
                padding: '.5em  1em',
                outline: 'none',
                border: 'none',
                borderRadius: '.5em',
                backgroundColor: '#f7962c',
                color: 'white',
                marginLeft: '1em',
                fontSize: '.8rem',
              }}
              className="processStepNext1"
              onClick={() => goTo(4)}
            >
              Next
            </button>
          </div>
        </div>
      ),
      position: 'right',

      style: {
        backgroundColor: 'white',
      },
    },

    {
      selector: '.five-step',

      content: ({ goTo }) => (
        <div className="stepInsideContainer">
          <div className="stepInsideContainerHeading">
            <h2 className="tourTitlesTop">Feedback / Support</h2>
            <div
              onClick={() => dispatch(authAction.setIstourOpen(false))}
              style={{ cursor: 'pointer' }}
            >
              <MdOutlineCancel color="#bcc2ca" size="2.1em" />
            </div>
          </div>

          <div className="stepInsideContainernumberOfStep">5/6</div>

          <div className="stepInsideContainerdescription">
            We would really appreciate if you could spare some time to help us
            make our application better. Just enter your email ID and let us
            know your query/feedback. You can also consider rating us.
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: '2em',
            }}
          >
            <button
              style={{
                padding: '.5em  1em',
                outline: 'none',
                border: 'none',
                borderRadius: '.5em',
                backgroundColor: '#f2f2f2',
                color: '#f7962c',
                fontSize: '.8rem',
              }}
              className="processStepNext1"
              onClick={() => goTo(3)}
            >
              Back
            </button>
            <button
              style={{
                padding: '.5em  1em',
                outline: 'none',
                border: 'none',
                borderRadius: '.5em',
                backgroundColor: '#f7962c',
                color: 'white',
                marginLeft: '1em',
                fontSize: '.8rem',
              }}
              className="processStepNext1"
              onClick={() => goTo(5)}
            >
              Next
            </button>
          </div>
        </div>
      ),
      position: 'right',

      style: {
        backgroundColor: 'white',
      },
    },
    {
      selector: '.sixe-step',
      content: ({ goTo }) => (
        <div className="stepInsideContainer">
          <div className="stepInsideContainerHeading">
            <div>
              <h2 className="tourTitlesTop">Reuse/Repurpose</h2>
            </div>
            <div>
              <div
                onClick={() => dispatch(authAction.setIstourOpen(false))}
                style={{ cursor: 'pointer' }}
              >
                <MdOutlineCancel color="#bcc2ca" size="2.1em" />
              </div>
            </div>
          </div>

          <div className="stepInsideContainernumberOfStep">6/6</div>
          <div className="stepInsideContainerdescription">
            The acquired knowledge can be reused to resolve any new use cases!
            We can keep on adding new use cases to make the knowledge base
            richer! This section reflects our take on reuse and repurpose of the
            knowledge.
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: '2em',
            }}
          >
            <button
              style={{
                padding: '.5em  1em',
                outline: 'none',
                border: 'none',
                borderRadius: '.5em',
                backgroundColor: '#f2f2f2',
                color: '#f7962c',
                fontSize: '.8rem',
              }}
              className="processStepNext1"
              onClick={() => goTo(4)}
            >
              Back
            </button>
            <button
              style={{
                padding: '.5em  1em',
                outline: 'none',
                border: 'none',
                borderRadius: '.5em',
                backgroundColor: '#f7962c',
                color: 'white',
                marginLeft: '1em',
                fontSize: '.8rem',
              }}
              className="processStepNext1"
              onClick={() => dispatch(authAction.setIstourOpen(false))}
            >
              Finish
            </button>
          </div>
        </div>
      ),
      position: 'right',

      style: {
        backgroundColor: 'white',
      },
    },
  ];

  const isSmall = useMediaQuery('(max-width: 700px) and (min-width: 1px)');
  function detectBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (
      navigator.userAgent.indexOf('MSIE') != -1 ||
      !!document.documentMode == true
    ) {
      return 'IE'; //crap
    } else {
      return 'Unknown';
    }
  }
  // console.log(location.pathname.split('/').length);
  const sandBoxLogin = window?._env_?.REACT_APP_SANDBOX;
  return (
    <>
      {isSmall ? (
        <MobileScreenNotification />
      ) : detectBrowser() === 'Safari' ? (
        <>
          <authContext.Provider value={authValue}>
            {location.pathname.split('/')[1] === 'home' ||
            location.pathname.split('/')[1] === 'inventory' ||
            location.pathname.split('/')[1] === 'process' ||
            location.pathname.split('/')[1] === 'demand' ||
            location.pathname.split('/')[1] === 'stats' ? (
              <>
                <div>
                  <TopBar getSignOut={getSignOut} />
                </div>
                {location.pathname.split('/')[5] !== 'uth' && (
                  <div className="sideBarContainer">
                    <SideNavBar />
                  </div>
                )}
              </>
            ) : null}

            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/usernamerecovery"
                element={<ForgetPasswordPage />}
              />

              <Route path="/signup" element={<SignUp />} />

              <Route
                path="/home"
                element={
                  <div>
                    <div
                      onClick={() =>
                        dispatch(authAction.getFeedbackCancelIcon(false))
                      }
                    >
                      {authStore.feedbackCancelIcon && (
                        <>{authStore.showTour && <AppTour />}</>
                      )}

                      <div>
                        <>
                          <Home steps={steps} />
                        </>
                      </div>
                    </div>
                  </div>
                }
              />

              <Route
                path="/inventory/*"
                element={
                  <div>
                    <div
                      onClick={() =>
                        dispatch(authAction.getFeedbackCancelIcon(false))
                      }
                    >
                      <div>
                        <>
                          <Inventory
                            goBackClick={goBackClick}
                            getIngredientButtonClick={getIngredientButtonClick}
                            getRecipeCatalogueButtonClick={
                              getRecipeCatalogueButtonClick
                            }
                            getPreferencesButtonClick={
                              getPreferencesButtonClick
                            }
                          />
                        </>
                      </div>
                    </div>
                  </div>
                }
              />

              <Route
                path="/process/*"
                element={
                  <div>
                    <div
                      onClick={() =>
                        dispatch(authAction.getFeedbackCancelIcon(false))
                      }
                    >
                      <div>
                        <>
                          <ProcessChallenge
                            goBackClick={goBackClick}
                            getIngredientButtonClick={getIngredientButtonClick}
                            getRecipeCatalogueButtonClick={
                              getRecipeCatalogueButtonClick
                            }
                            getPreferencesButtonClick={
                              getPreferencesButtonClick
                            }
                          />
                        </>
                      </div>
                    </div>
                  </div>
                }
              />
              <Route
                path="/demand/*"
                element={
                  <div>
                    <div
                      onClick={() =>
                        dispatch(authAction.getFeedbackCancelIcon(false))
                      }
                    >
                      <div>
                        <>
                          <DemandForecasting
                            goBackClick={goBackClick}
                            getIngredientButtonClick={getIngredientButtonClick}
                            getRecipeCatalogueButtonClick={
                              getRecipeCatalogueButtonClick
                            }
                            getPreferencesButtonClick={
                              getPreferencesButtonClick
                            }
                          />
                        </>
                      </div>
                    </div>
                  </div>
                }
              />
              <Route
                path="/stats"
                element={
                  <div>
                    <div
                      onClick={() =>
                        dispatch(authAction.getFeedbackCancelIcon(false))
                      }
                    >
                      <div>
                        <>
                          <Statististics />
                        </>
                      </div>
                    </div>
                  </div>
                }
              />

              <Route
                element={
                  <ProtectedRoutes
                    isAllowed={!!authStore.currentUserForApp}
                    defaultPath={sandBoxLogin === 'true' ? '/home' : '/login'}
                  />
                }
              >
                <Route index element={<h1>Lending page</h1>} />
              </Route>
              <Route path="*" element={<h1>404 page not found</h1>} />
            </Routes>
          </authContext.Provider>
        </>
      ) : (
        <DeviceOrientation lockOrientation={'landscape'}>
          <Orientation orientation="landscape" alwaysRender={false}>
            <authContext.Provider value={authValue}>
              {location.pathname.split('/')[1] === 'home' ||
              location.pathname.split('/')[1] === 'inventory' ||
              location.pathname.split('/')[1] === 'process' ||
              location.pathname.split('/')[1] === 'demand' ||
              location.pathname.split('/')[1] === 'stats' ? (
                <>
                  <div>
                    <TopBar getSignOut={getSignOut} />
                  </div>
                  {location.pathname.split('/')[5] !== 'uth' && (
                    <div className="sideBarContainer">
                      <SideNavBar />
                    </div>
                  )}
                </>
              ) : null}

              <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                  path="/usernamerecovery"
                  element={<ForgetPasswordPage />}
                />

                <Route path="/signup" element={<SignUp />} />

                <Route
                  path="/home"
                  element={
                    <div>
                      <div
                        onClick={() =>
                          dispatch(authAction.getFeedbackCancelIcon(false))
                        }
                      >
                        {authStore.feedbackCancelIcon && (
                          <>{authStore.showTour && <AppTour />}</>
                        )}

                        <div>
                          <>
                            <Home steps={steps} />
                          </>
                        </div>
                      </div>
                    </div>
                  }
                />

                <Route
                  path="/inventory/*"
                  element={
                    <div>
                      <div
                        onClick={() =>
                          dispatch(authAction.getFeedbackCancelIcon(false))
                        }
                      >
                        <div>
                          <>
                            <Inventory
                              goBackClick={goBackClick}
                              getIngredientButtonClick={
                                getIngredientButtonClick
                              }
                              getRecipeCatalogueButtonClick={
                                getRecipeCatalogueButtonClick
                              }
                              getPreferencesButtonClick={
                                getPreferencesButtonClick
                              }
                            />
                          </>
                        </div>
                      </div>
                    </div>
                  }
                />

                <Route
                  path="/process/*"
                  element={
                    <div>
                      <div
                        onClick={() =>
                          dispatch(authAction.getFeedbackCancelIcon(false))
                        }
                      >
                        <div>
                          <>
                            <ProcessChallenge
                              goBackClick={goBackClick}
                              getIngredientButtonClick={
                                getIngredientButtonClick
                              }
                              getRecipeCatalogueButtonClick={
                                getRecipeCatalogueButtonClick
                              }
                              getPreferencesButtonClick={
                                getPreferencesButtonClick
                              }
                            />
                          </>
                        </div>
                      </div>
                    </div>
                  }
                />
                <Route
                  path="/demand/*"
                  element={
                    <div>
                      <div
                        onClick={() =>
                          dispatch(authAction.getFeedbackCancelIcon(false))
                        }
                      >
                        <div>
                          <>
                            <DemandForecasting
                              goBackClick={goBackClick}
                              getIngredientButtonClick={
                                getIngredientButtonClick
                              }
                              getRecipeCatalogueButtonClick={
                                getRecipeCatalogueButtonClick
                              }
                              getPreferencesButtonClick={
                                getPreferencesButtonClick
                              }
                            />
                          </>
                        </div>
                      </div>
                    </div>
                  }
                />
                <Route
                  path="/stats"
                  element={
                    <div>
                      <div
                        onClick={() =>
                          dispatch(authAction.getFeedbackCancelIcon(false))
                        }
                      >
                        <div>
                          <>
                            <Statististics />
                          </>
                        </div>
                      </div>
                    </div>
                  }
                />

                <Route
                  element={
                    <ProtectedRoutes
                      isAllowed={!!authStore.currentUserForApp}
                      defaultPath={sandBoxLogin === 'true' ? '/home' : '/login'}
                    />
                  }
                >
                  <Route index element={<h1>Lending page</h1>} />
                </Route>
                <Route path="*" element={<h1>404 page not found</h1>} />
              </Routes>
            </authContext.Provider>
          </Orientation>
          {/* Will stay in DOM, but is only visible in portrait */}
          <Orientation orientation="portrait" alwaysRender={false}>
            <div className="rotateYourName">
              <div className="rotateYourNametitle">
                <img src={titleIcon} title="image" />
              </div>
              <div className="rotateYourScreenContainerTitle">
                <div className="rotateYourNameHeading">
                  Please rotate your device.
                </div>
                <div className="rotateYourNameDescriptionImg">
                  <img src={mobileScreenImg} alt="title" />
                </div>
                <div className="rotateYourNameDescription">
                  This content is best experienced when viewing on a laptop /
                  desktop.
                  <br /> You can also switch to ‘desktop site’ in your browser
                  options.
                  <br /> Thank you!
                </div>
              </div>
              <div>
                <div className="rotateYourNameFooter">
                  <img src={eccencaLogo} alt="eccencaLogo" />
                </div>
              </div>
            </div>
          </Orientation>
        </DeviceOrientation>
      )}
    </>
  );
};

export default App;
