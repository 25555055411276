import { select } from 'd3-selection';
import {
  forceCenter,
  forceLink,
  forceManyBody,
  forceSimulation,
} from 'd3-force';
import React, { useEffect } from 'react';
import './SubstitutionGraph.css';
import { tick } from './Events';
import * as d3 from 'd3';
import { ZoomButton, convertRemToPixels } from '../../../lib';
import { Zoomed } from '../../../utils';
import { Loading } from '../../index';
import {
  forwaedIcon,
  forwardActiveIcon,
  logoSvgInSubstitution,
  RecipeNode,
} from '../../../assets';
import { drag } from '../processMonitoring/ProcessGraphSetting';

import { useSelector } from 'react-redux';

const Graph = ({
  data,
  id,
  saveButtonActive,
  setSubKey,
  setIndex,
  substituteStatus,

  setNodeClickStatus,
  defaultData,
  selectedSubstituteNodes,
  setData,
  changeSave,
  setChangeSave,
  deleteNodes,
  setDeleteNodes,
  subnodes,
  setCheckedState,
  setCheckeBoxdState,
  length,
  nodeClickStatus,
  setSubGraphTitle,
  setSubGraphTitleValue,

  setSubstituteInfo,
  setIsGraphUpdate,
  checkedBoxState,
  ...restProps
}) => {
  const preferenceStore = useSelector(s => s.preferenceStore);
  const [showLink, setshowLink] = React.useState('');
  const [clickShowLink, setclickShowLink] = React.useState('');
  const textRef = React.useRef(null);
  // console.log(checkedBoxState);

  let backButtonCondition = subnodes.length;

  function zoomInAtNodeClickfirstRender() {
    let zoom = d3.zoom().on('zoom', zoomed);
    let zooIdSelect = select('._graphZoom1');
    function zoomed(event) {
      zooIdSelect.attr('transform', event.transform);
    }
    const svg = select(`#${id}`).call(zoom);
    zoom.scaleTo(svg.transition().duration(0), convertRemToPixels(0.05));
  }
  React.useEffect(() => {
    zoomInAtNodeClickfirstRender();
  }, []);
  useEffect(() => {
    if (!data) {
      return null;
    }
    let zoom = d3.zoom().on('zoom', zoomed);
    function zoomed(event) {
      zooIdSelect.attr('transform', event.transform);
    }

    const svg = select(`#${id}`).call(zoom);
    const link = svg.selectAll('._graphLine').data(data.links);
    const node = svg.selectAll('._graphNode').data(data.nodes);
    let zooIdSelect = select('._graphZoom1');

    Zoomed(
      '#zoom_in_force1',
      '#zoom_out_force1',
      '#substitutionGraphZoomReset',
      svg,
      zoom,
      zooIdSelect
    );
    const linkLabel = svg
      .selectAll('._graphLineLabel')
      .data(data.links)
      .call(getBB);

    const Background = svg
      .selectAll('._graphLineLabelBackground')
      .data(data.links);
    Background.attr('width', function (d) {
      return d.bbox.width;
    })
      .attr('height', function (d) {
        return d.bbox.height;
      })
      .style('fill', 'white');

    const simulation = forceSimulation(data.nodes)
      .force(
        'link',
        forceLink() // This force provides links between nodes
          .id(function (d) {
            return d.id;
          }) // This provide the id of a node
          .links(data.links)
      )
      .force('charge', forceManyBody().strength(-1 * 10000)) // This adds repulsion between nodes. Play with the -400 for the repulsion strength
      .force(
        'center',
        forceCenter(
          svg._groups[0][0].parentElement.clientWidth / 2,
          svg._groups[0][0].parentElement.clientHeight / 2
        )
      )
      .on('tick', () => tick(node, link, linkLabel, Background)); // https://github.com/d3/d3-force#

    node.call(drag(simulation));

    // add interactions
  }, [data]);

  if (!data) {
    return null;
  }

  const getClick = (e, i) => {
    if (data.nodes[0].id === e.id || !e.status || e.status === 'Inactive') {
      return;
    } else {
      let length = subnodes.length;
      if (!nodeClickStatus[i]) {
        if (i < length) {
          setSubKey(e.value);
          setIndex(e.id);
          data.links.filter((el, j) => {
            if (e.name === el.target.name || e.name === el.source.name) {
              setclickShowLink(j);
              // setshowLink(j);
              // console.log(i);
            }
          });
        }

        setSubKey(e.value);
        setIndex(e.id);

        setDeleteNodes(e.name);
        setSubGraphTitle('Ingredient');
        setSubGraphTitleValue(e.name);
        setSubstituteInfo('Substitutes available:');
        const updatedCheckedState = nodeClickStatus.map((item, index) => {
          let data = index === i ? !item : false;

          return data;
        });

        setNodeClickStatus(updatedCheckedState);
      } else if (nodeClickStatus[i]) {
        setChangeSave(false);
        setNodeClickStatus(new Array(subnodes.length).fill(false));
        data.nodes.length = length;
        let nodesData = data.nodes.map(el => {
          let result = {
            id: el.id,
            name: el.name,
            actualName: el.actualName === undefined ? '' : el.actualName,
            edgeTypes: el.edgeTypes === undefined ? '' : el.edgeTypes,
            p: el.p === undefined ? '' : el.p,
            quantity: el.quantity === undefined ? '' : el.quantity,
            value: el.value === undefined ? '' : el.value,
            status: el.status,
          };
          return result;
        });
        let linksNode = nodesData.map(el => {
          let result = {
            source: nodesData[0].id,
            target: el.id,
            edgeTypes: 'hasIngredient',
            name: el.name,
          };
          return result;
        });
        linksNode.shift();

        let odata = {
          nodes: nodesData,
          links: linksNode,
        };

        setData(odata);
        setSubKey('');
        setSubGraphTitle('Recipe');
        setSubGraphTitleValue(preferenceStore.selectedRecipeData.Recipes);
        setSubstituteInfo('Ingredient substitution');
        // zoomInACtiveNodeClick();
      }

      setIsGraphUpdate(false);
    }
  };

  const setSave = () => {
    data.nodes.length = defaultData.nodes.length;
    let defaultNodes = data.nodes;
    let filterOutNodes = defaultNodes.filter(e => e.name !== deleteNodes);
    let getDeleteIndex = defaultNodes.filter(e => e.name === deleteNodes);

    let repacebaleNodeNodename = deleteNodes.split(':');
    let newNodeQuantity = repacebaleNodeNodename[1];
    let newNode = {
      id: `${20}` + `${getDeleteIndex[0]?.id}`,
      name: `${selectedSubstituteNodes?.name}:${newNodeQuantity}`,
      value: selectedSubstituteNodes?.value,
      edgeTypes: selectedSubstituteNodes?.properties,
    };

    const nodesData = [...filterOutNodes, newNode];
    const newLinks = nodesData.map(e => {
      let data = {
        source: nodesData[0].id,
        target: e.id,
        edgeTypes: 'hasIngredient',
        name: e.name,
      };
      return data;
    });
    newLinks.shift();

    let updatedGraphData = {
      nodes: nodesData,
      links: newLinks,
    };

    setData(updatedGraphData);
    setNodeClickStatus(new Array(subnodes.length).fill(false));
    setCheckedState(new Array(length).fill(false));
    setCheckeBoxdState(new Array(length).fill(false));
    setclickShowLink('');

    setChangeSave(false);
    setSubGraphTitle('Recipe');
    setSubGraphTitleValue(preferenceStore.selectedRecipeData.Recipes);
    setSubstituteInfo('Ingredient substitution');
    setIsGraphUpdate(true);
  };

  function hashCode(str) {
    let hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  function pickColor(str, colorvalue) {
    // let color = `hsl(${hashCode(str) % 360}, 100%, 90%)`;
    // console.log(color);
    return `hsl(${hashCode(str) % 360}, 100%, ${colorvalue}%)`;
  }

  const findEdgeRelation = (ek, position) => {
    let Olength = subnodes.length;

    if (position > Olength - 1) {
      data.links.filter((el, i) => {
        if (ek === el.target || ek === el.source) {
          setshowLink(i);
        }
      });
    }
  };
  const setWidth = originallength => {
    switch (true) {
      case originallength <= 3:
        return `${originallength * 30}`;
      case originallength < 4:
        return `${originallength * 28}`;
      case originallength > 20:
        return `${originallength * 8}`;
      case originallength > 13:
        return `${originallength * 9}`;
      case originallength > 10:
        return `${originallength * 9}`;

      case originallength < 10:
        return `${originallength * 20}`;

      default:
        return `${originallength * 20}`;
    }
  };
  var rx = /[a-z]/gi;

  function getBB(selection) {
    selection.each(function (d) {
      d.bbox = this.getBBox();
    });
  }
  const getOpacity = (linkName, position) => {
    let Olength = subnodes.length;

    if (position > Olength - 1) {
      data.links.filter((el, i) => {
        if (
          linkName.target.name === el.target.name ||
          linkName.target.name === el.source.name
        ) {
          setshowLink(i);
        }
      });
    }
  };

  return (
    <div className="SubstituteGraph">
      <svg
        id={id}
        width="100%"
        height="100%"
        {...restProps}
        // ref={substituteGraphRef}
      >
        <g className="_graphZoom1">
          {data.links.map((link, i) => {
            return (
              <g key={i}>
                <line
                  strokeDasharray={(2, 3)}
                  className="_graphLine"
                  stroke={
                    link.edgeTypes.split('/')[
                      link.edgeTypes.split('/').length - 1
                    ] === 'hasIngredient'
                      ? clickShowLink === i
                        ? 'black'
                        : 'gray'
                      : pickColor(link.name, 80)
                  }
                  opacity={
                    link.edgeTypes.split('/')[
                      link.edgeTypes.split('/').length - 1
                    ] === 'hasIngredient'
                      ? clickShowLink === i
                        ? 1
                        : 0.4
                      : 1
                  }
                  onMouseOver={() => getOpacity(link, i)}
                />

                <rect
                  className="_graphLineLabelBackground"
                  opacity={showLink === i ? 1 : clickShowLink === i ? 1 : 0}
                />

                <text
                  ref={textRef}
                  style={{ fontSize: '10px' }}
                  opacity={showLink === i ? 1 : clickShowLink === i ? 1 : 0}
                  textAnchor="middle"
                  className="_graphLineLabel"
                  fontWeight={showLink === i ? 'bold' : 'normal'}
                >
                  {
                    link.edgeTypes.split('/')[
                      link.edgeTypes.split('/').length - 1
                    ]
                  }
                </text>
              </g>
            );
          })}
          {data.nodes.map((node, i) => {
            return (
              <g key={i} className="_graphNode">
                <rect
                  style={{
                    width: setWidth(node.name.length),
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    node?.status !== 'Inactive' ? getClick(node, i) : null
                  }
                  className={` ${i !== 0 && 'rectangle'}`}
                  x={-50}
                  y={-15}
                  rx={22}
                  fill={
                    data.nodes.length > subnodes.length
                      ? pickColor(node.name, 99)
                      : 'white'
                  }
                  // fill={'white'}
                  stroke={
                    node.id > subnodes.length
                      ? pickColor(node.name, 80)
                      : '#8ee556'
                  }
                  strokeWidth={node.id > subnodes.length ? 2 : 1}
                  onMouseOver={() => findEdgeRelation(node, i)}
                  onMouseOut={() => setshowLink('')}
                />

                {i === 0 && (
                  <image
                    x={
                      node.name.match(rx).length +
                        node.name.match(/([\s]+)/g).length <
                      20
                        ? -45
                        : -50
                    }
                    y={
                      node.name.match(rx).length +
                        node.name.match(/([\s]+)/g).length <
                      20
                        ? -65
                        : -75
                    }
                    width={
                      node.name.match(rx).length +
                        node.name.match(/([\s]+)/g).length <
                      20
                        ? 100
                        : 140
                    }
                    xlinkHref={RecipeNode}
                    onMouseOver={() => findEdgeRelation(node, i)}
                    onMouseOut={() => setshowLink('')}
                  ></image>
                )}
                {i !== 0 && i < backButtonCondition && (
                  <image
                    x={
                      node.name.length > 10
                        ? `${node.name.length * 2.6}`
                        : node.name.length < 6
                        ? `${node.name.length * 2}`
                        : node.name.length <= 3
                        ? `${node.name.length - 1}`
                        : `${node.name.length * 5.2}`
                    }
                    y="-33"
                    width={40}
                    style={{ cursor: 'pointer' }}
                    xlinkHref={
                      node?.status !== 'Inactive'
                        ? nodeClickStatus[i] === true
                          ? forwardActiveIcon
                          : forwaedIcon
                        : null
                    }
                  ></image>
                )}

                {i !== 0 && (
                  <circle
                    className={i !== 0 ? 'OuterNodes' : 'centerNodes'}
                    r={22.2}
                    cx={i !== 0 ? -27.4 : 0}
                    cy={i !== 0 ? 7.5 : 0}
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      node?.status !== 'Inactive' ? getClick(node, i) : null
                    }
                    stroke={
                      node.id > subnodes.length
                        ? pickColor(node.name, 80)
                        : '#8ee556'
                    }
                    fill={
                      node.id > subnodes.length
                        ? pickColor(node.name, 85)
                        : '#8ee556'
                    }
                    onMouseOver={() => findEdgeRelation(node, i)}
                    onMouseOut={() => setshowLink('')}
                  />
                )}
                {i !== 0 && (
                  <text
                    fontWeight="bold"
                    x={0}
                    y={5}
                    onClick={() =>
                      node?.status !== 'Inactive' ? getClick(node, i) : null
                    }
                    style={{ cursor: 'pointer', fontSize: '10px' }}
                  >
                    {node.name.split(':')[0]}
                  </text>
                )}
                {i !== 0 && (
                  <text
                    fontWeight="bold"
                    opacity="0.5"
                    x={0}
                    y={20}
                    onClick={() =>
                      node?.status !== 'Inactive' ? getClick(node, i) : null
                    }
                    style={{ cursor: 'pointer', fontSize: '10px' }}
                  >
                    {node.name.split(':')[1]}
                  </text>
                )}

                {i === 0 &&
                  (node.name.match(rx).length +
                    node.name.match(/([\s]+)/g).length <
                  20 ? (
                    <>
                      <text
                        style={{ fontSize: '10px' }}
                        x={
                          -(
                            (node.name.match(rx).length +
                              node.name.match(/([\s]+)/g).length) *
                            2
                          )
                        }
                        y={-15}
                      >
                        {node.name}
                      </text>
                    </>
                  ) : node.name.match(rx).length +
                      node.name.match(/([\s]+)/g).length <
                    45 ? (
                    <>
                      <text
                        style={{ fontSize: '10px' }}
                        x={
                          -(
                            (node.name.match(rx).length +
                              node.name.match(/([\s]+)/g).length) /
                            2
                          )
                        }
                        y={-15}
                      >
                        {node.name.substring(
                          0,
                          Math.round(
                            (node.name.match(rx).length +
                              node.name.match(/([\s]+)/g).length) /
                              2
                          )
                        )}
                      </text>
                      <text
                        style={{ fontSize: '10px' }}
                        x={
                          -(
                            (node.name.match(rx).length +
                              node.name.match(/([\s]+)/g).length) /
                            2
                          )
                        }
                        y={0}
                      >
                        {node.name.substring(
                          Math.round(
                            (node.name.match(rx).length +
                              node.name.match(/([\s]+)/g).length) /
                              2
                          ),
                          node.name.match(rx).length +
                            node.name.match(/([\s]+)/g).length +
                            5
                        )}
                      </text>
                    </>
                  ) : node.name.match(rx).length +
                      node.name.match(/([\s]+)/g).length >
                    45 ? (
                    <>
                      <text
                        x={
                          -(
                            (node.name.match(rx).length +
                              node.name.match(/([\s]+)/g).length) /
                            3
                          )
                        }
                        y={-15}
                      >
                        {node.name.substring(
                          0,
                          Math.round(
                            (node.name.match(rx).length +
                              node.name.match(/([\s]+)/g).length) /
                              3
                          )
                        )}
                      </text>
                      <text
                        x={
                          -(
                            (node.name.match(rx).length +
                              node.name.match(/([\s]+)/g).length) /
                            3
                          )
                        }
                        y={0}
                      >
                        {node.name.substring(
                          Math.round(
                            (node.name.match(rx).length +
                              node.name.match(/([\s]+)/g).length) /
                              3
                          ),
                          ((node.name.match(rx).length +
                            node.name.match(/([\s]+)/g).length) /
                            3) *
                            2
                        )}
                      </text>
                      <text
                        x={
                          -(
                            (node.name.match(rx).length +
                              node.name.match(/([\s]+)/g).length) /
                            3
                          )
                        }
                        y={15}
                      >
                        {node.name.substring(
                          ((node.name.match(rx).length +
                            node.name.match(/([\s]+)/g).length) /
                            3) *
                            2,
                          node.name.match(rx).length +
                            node.name.match(/([\s]+)/g).length +
                            5
                        )}
                      </text>
                    </>
                  ) : (
                    <text
                      x={
                        node.name.length > 10
                          ? `-${node.name.length * 2.5}`
                          : node.name.length < 6
                          ? `${node.name.length / 0.5}`
                          : `${node.name.length / 0.2}`
                      }
                      y={
                        node.name.length > 10
                          ? `-${node.name.length / 8}`
                          : node.name.length < 6
                          ? `-${node.name.length / 4}`
                          : `-${node.name.length / 2}`
                      }
                    >
                      {node.name}
                    </text>
                  ))}

                {i !== 0 && (
                  <image
                    x="-34"
                    y="-3"
                    width={12}
                    xlinkHref={logoSvgInSubstitution}
                    onClick={() =>
                      node?.status !== 'Inactive' ? getClick(node, i) : null
                    }
                    style={{ cursor: 'pointer' }}
                  ></image>
                )}
              </g>
            );
          })}
        </g>
      </svg>
      <ZoomButton
        zoomOutId={'zoom_out_force1'}
        zoomInId={'zoom_in_force1'}
        zoomResetId={'substitutionGraphZoomReset'}
        zoomCenter="substituteGraphCenter"
      />
      {saveButtonActive && (
        <button className="saveSubstitutionChanges">save</button>
      )}

      {substituteStatus !== 'success' && (
        <div className="loadingSuboption">
          <Loading color={'pink'} />
        </div>
      )}
      {changeSave && checkedBoxState.includes(true) && (
        <div className="subGraphbuttonContainer">
          <div onClick={setSave} className="saveButtonForsmartSub">
            Save
          </div>
        </div>
      )}
    </div>
  );
};

export default Graph;
