import { select } from 'd3-selection';
import {
  forceCenter,
  forceLink,
  forceManyBody,
  forceSimulation,
} from 'd3-force';
import React, { useEffect } from 'react';

import { tick } from './Events';

import {
  ProcessGraphcontext,
  ProcessMonitoringLink,
  ProcessLinkText,
} from '../index';
import { drag, getBB } from './ProcessGraphSetting';
import * as d3 from 'd3';
import { ZoomButton, convertRemToPixels } from '../../../lib';
import {
  Zoomed,
  setWidth,
  useProcessMonetring,
  pickColor,
} from '../../../utils';
import { flavour, handicon } from '../../../assets';
import { Loading } from '../../index';

const Graph = ({ ...restProps }) => {
  const [showLink, setclickShowLink] = React.useState('');
  const [clickedState, setClickedState] = React.useState('');
  const [findClickState, setFindClickState] = React.useState('');
  var rx = /[a-z]/gi;

  var {
    data,
    id,
    RecipeNode,
    logoSvgInSubstitution,
    forwaedIcon,

    fixedProcessdata,
    initialData,
    setProcessData,
  } = React.useContext(ProcessGraphcontext);
  // const InitialData = data;

  // console.log(initialData);
  const process_time_temp_query_key_point_one =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_PROCESS_MONITORING_TIME_TEMP_QUERY_KEY_POINT_ONE;
  const process_time_temp_query_key_point_two =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_PROCESS_MONITORING_TIME_TEMP_QUERY_KEY_POINT_TWO;

  useProcessMonetring(
    `${process_time_temp_query_key_point_one}${clickedState?.node_value_obj}${process_time_temp_query_key_point_two}`,

    clickedState,
    fixedProcessdata,
    setProcessData
  );
  const ZoomHandler = () => {
    let zoom = d3.zoom().on('zoom', zoomed);
    let zooIdSelect = select('._graphZoomProcess');
    function zoomed(event) {
      zooIdSelect.attr('transform', event.transform);
    }
    const svg = select(`#${id}`).call(zoom);
    zoom.scaleTo(svg.transition().duration(0), convertRemToPixels(0.0555));
  };
  useEffect(() => {
    ZoomHandler();
  }, []);

  useEffect(() => {
    if (!data) {
      return null;
    }
    let zoom = d3.zoom().on('zoom', zoomed);
    function zoomed(event) {
      zooIdSelect.attr('transform', event.transform);
    }
    const svg = select(`#${id}`).call(zoom);

    const link = svg.selectAll('._graphLine').data(data.links);
    const node = svg.selectAll('._graphNode').data(data.nodes);

    let zooIdSelect = select('._graphZoomProcess');
    Zoomed(
      '#zoom_in_force_process',
      '#zoom_out_force_process',
      '#processGraphZoomReset',
      svg,
      zoom,
      zooIdSelect
    );

    const linkLabel = svg
      .selectAll('._graphLineLabel')

      .data(data.links)
      .call(getBB);

    const Background = svg
      .selectAll('._graphLineLabelBackground')
      .data(data.links);
    Background.attr('width', function (d) {
      return d.bbox.width;
    })
      .attr('height', function (d) {
        return d.bbox.height;
      })
      .style('fill', 'white');

    const simulation = forceSimulation(data.nodes)
      .force(
        'link',
        forceLink() // This force provides links between nodes
          .id(function (d) {
            return d.id;
          }) // This provide the id of a node
          .links(data.links) // and this the list of links
      )
      .force('charge', forceManyBody().strength(-1 * 6000)) // This adds repulsion between nodes. Play with the -400 for the repulsion strength
      .force(
        'center',
        forceCenter(
          svg._groups[0][0].parentElement.clientWidth / 2,
          svg._groups[0][0].parentElement.clientHeight / 2
        )
      ) // This force attracts nodes to the center of the svg area
      .on('tick', () => tick(node, link, linkLabel, Background)); // https://github.com/d3/d3-force#simulation_tick

    node.call(drag(simulation));

    if (!data) {
      return null;
    }

    // add interactions
  }, [data]);

  if (!data) {
    return null;
  }
  const getClick = (e, i) => {
    if (findClickState !== i) {
      setClickedState(e);
      data.links.filter((el, j) => {
        if (
          e.node_value_obj === el.target.node_value_obj ||
          e.node_value_obj === el.source.node_value_obj
        ) {
          setclickShowLink(j);
        }
      });
      setFindClickState(i);
    } else if (findClickState === i) {
      setProcessData(initialData);
      setFindClickState('');
    }
  };

  return (
    <>
      {data.length === 0 && (
        <div className="loadingSuboption">
          <Loading color={'#0174b2'} />
        </div>
      )}
      <>
        {' '}
        <svg id={id} width="100%" height="100%" {...restProps}>
          <g className="_graphZoomProcess">
            {data.links.map((link, i) => {
              return (
                <g key={i}>
                  <ProcessMonitoringLink
                    link={link}
                    showLink={showLink}
                    i={i}
                  />
                  <rect
                    className="_graphLineLabelBackground"
                    opacity={showLink === i ? 1 : 0}
                  />

                  <ProcessLinkText link={link} showLink={showLink} i={i} />
                </g>
              );
            })}
            {data.nodes.map((node, i) => {
              return (
                <g key={i} className="_graphNode">
                  <>
                    <rect
                      style={{
                        width: setWidth(node?.name?.length),
                      }}
                      className={` ${i !== 0 && 'rectangle'}`}
                      x={-50}
                      y={-15}
                      rx={22}
                      fill={node.id > 10 ? pickColor(node.name, 99) : 'white'}
                      stroke={
                        node.id > 10 ? pickColor(node.name, 80) : '#c51162'
                      }
                      strokeWidth={node.id > 10 ? 2 : 1}
                      onClick={() => getClick(node, i)}
                    />
                    <>
                      {i === 0 && (
                        <image
                          x={
                            node.length === 0
                              ? node.name.match(rx).length +
                                  node.name.match(/([\s]+)/g).length <
                                20
                                ? -45
                                : -50
                              : -45
                          }
                          y={
                            node.length === 0
                              ? node.name.match(rx).length +
                                  node.name.match(/([\s]+)/g).length <
                                20
                                ? -65
                                : -75
                              : -65
                          }
                          width={
                            node.length === 0
                              ? node.name.match(rx).length +
                                  node.name.match(/([\s]+)/g).length <
                                20
                                ? 100
                                : 140
                              : 100
                          }
                          xlinkHref={RecipeNode}
                          // onMouseOver={() => console.log('hey')}
                          // onMouseOut={() => console.log('hey')}
                        ></image>
                      )}
                    </>
                    <>
                      {i !== 0 && (
                        <image
                          x={
                            node.name.length > 10
                              ? `${node.name.length * 2.6}`
                              : node.name.length < 6
                              ? `${node.name.length * 2}`
                              : node.name.length <= 3
                              ? `${node.name.length - 1}`
                              : `${node.name.length * 5.2}`
                          }
                          y="-33"
                          width={40}
                          style={{ cursor: 'pointer' }}
                          xlinkHref={forwaedIcon}
                          onClick={() => getClick(node, i)}
                        ></image>
                      )}

                      {i !== 0 && (
                        <circle
                          className={i !== 0 ? 'OuterNodes' : 'centerNodes'}
                          r={22.2}
                          cx={i !== 0 ? -27.4 : 0}
                          cy={i !== 0 ? 7.5 : 0}
                          stroke={
                            node.id > 10 ? pickColor(node.name, 80) : '#c51162'
                          }
                          fill={
                            node.id > 10 ? pickColor(node.name, 85) : '#fae5ef'
                          }
                          onClick={() => getClick(node, i)}
                        />
                      )}
                      {i !== 0 && (
                        <text
                          fontWeight="bold"
                          x={0}
                          y={5}
                          onClick={() => getClick(node, i)}
                          style={{ fontSize: '12px' }}
                        >
                          {node.name.split(':')[0]}
                        </text>
                      )}
                      {i !== 0 && (
                        <text
                          fontWeight="bold"
                          style={{ fontSize: '12px' }}
                          opacity="0.5"
                          x={0}
                          y={20}
                          onClick={() => getClick(node, i)}
                        >
                          {node.name.split(':')[1]}
                        </text>
                      )}

                      {i === 0 &&
                        (node.name.match(rx).length +
                          node.name.match(/([\s]+)/g).length <
                        20 ? (
                          <>
                            <text
                              style={{ fontSize: '12px' }}
                              x={
                                -(
                                  (node.name.match(rx).length +
                                    node.name.match(/([\s]+)/g).length) *
                                  2
                                )
                              }
                              y={-15}
                            >
                              {node.name}
                            </text>
                          </>
                        ) : node.name.match(rx).length +
                            node.name.match(/([\s]+)/g).length <
                          45 ? (
                          <>
                            <text
                              style={{ fontSize: '12px' }}
                              x={
                                -(
                                  (node.name.match(rx).length +
                                    node.name.match(/([\s]+)/g).length) /
                                  2
                                )
                              }
                              y={-15}
                              onClick={() => getClick(node, i)}
                            >
                              {node.name.substring(
                                0,
                                Math.round(
                                  (node.name.match(rx).length +
                                    node.name.match(/([\s]+)/g).length) /
                                    2
                                )
                              )}
                            </text>
                            <text
                              style={{ fontSize: '12px' }}
                              x={
                                -(
                                  (node.name.match(rx).length +
                                    node.name.match(/([\s]+)/g).length) /
                                  2
                                )
                              }
                              y={0}
                              onClick={() => getClick(node, i)}
                            >
                              {node.name.substring(
                                Math.round(
                                  (node.name.match(rx).length +
                                    node.name.match(/([\s]+)/g).length) /
                                    2
                                ),
                                node.name.match(rx).length +
                                  node.name.match(/([\s]+)/g).length +
                                  5
                              )}
                            </text>
                          </>
                        ) : node.name.match(rx).length +
                            node.name.match(/([\s]+)/g).length >
                          45 ? (
                          <>
                            <text
                              style={{ fontSize: '12px' }}
                              x={
                                -(
                                  (node.name.match(rx).length +
                                    node.name.match(/([\s]+)/g).length) /
                                  3
                                )
                              }
                              y={-15}
                              onClick={() => getClick(node, i)}
                            >
                              {node.name.substring(
                                0,
                                Math.round(
                                  (node.name.match(rx).length +
                                    node.name.match(/([\s]+)/g).length) /
                                    3
                                )
                              )}
                            </text>
                            <text
                              style={{ fontSize: '12px' }}
                              x={
                                -(
                                  (node.name.match(rx).length +
                                    node.name.match(/([\s]+)/g).length) /
                                  3
                                )
                              }
                              y={0}
                              onClick={() => getClick(node, i)}
                            >
                              {node.name.substring(
                                Math.round(
                                  (node.name.match(rx).length +
                                    node.name.match(/([\s]+)/g).length) /
                                    3
                                ),
                                ((node.name.match(rx).length +
                                  node.name.match(/([\s]+)/g).length) /
                                  3) *
                                  2
                              )}
                            </text>
                            <text
                              style={{ fontSize: '12px' }}
                              x={
                                -(
                                  (node.name.match(rx).length +
                                    node.name.match(/([\s]+)/g).length) /
                                  3
                                )
                              }
                              y={15}
                              onClick={() => getClick(node, i)}
                            >
                              {node.name.substring(
                                ((node.name.match(rx).length +
                                  node.name.match(/([\s]+)/g).length) /
                                  3) *
                                  2,
                                node.name.match(rx).length +
                                  node.name.match(/([\s]+)/g).length +
                                  5
                              )}
                            </text>
                          </>
                        ) : (
                          <text
                            style={{ fontSize: '12px' }}
                            x={
                              node.name.length > 10
                                ? `-${node.name.length * 2.5}`
                                : node.name.length < 6
                                ? `${node.name.length / 0.5}`
                                : `${node.name.length / 0.2}`
                            }
                            y={
                              node.name.length > 10
                                ? `-${node.name.length / 8}`
                                : node.name.length < 6
                                ? `-${node.name.length / 4}`
                                : `-${node.name.length / 2}`
                            }
                            onClick={() => getClick(node, i)}
                          >
                            {node.name}
                          </text>
                        ))}
                      {i !== 0 && (
                        <image
                          x="-34"
                          y="-3"
                          width={12}
                          xlinkHref={
                            node.name === 'Ideal:Flavor'
                              ? flavour
                              : node.name === 'Ideal:Texture'
                              ? handicon
                              : logoSvgInSubstitution
                          }
                          onClick={() => getClick(node, i)}
                        ></image>
                      )}
                    </>
                  </>
                </g>
              );
            })}
          </g>
        </svg>
        <div className="zoomButtonContainer">
          <ZoomButton
            zoomOutId={'zoom_out_force_process'}
            zoomInId={'zoom_in_force_process'}
            zoomResetId={'processGraphZoomReset'}
            zoomCenter="processCenter"
          />
        </div>
      </>
    </>
  );
};

export default Graph;
