import React from 'react';
import { useLocation } from 'react-router';
import { ToolTipInfo } from '../../lib';

const ProcessCheckButtonComponent = ({
  title,
  checkBoxStatus,
  getChange,
  getClick,

  tolTiptitle,
  classNameForBorder,
}) => {
  // console.log(getClick);
  const location = useLocation();
  return (
    <div className="checkBoxAndUseCaseFlex">
      <input
        className="CheckBoxStats"
        onChange={getChange}
        type="checkbox"
        checked={checkBoxStatus}
      />
      <div className={classNameForBorder}>
        <div className="contentWithTooltip">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div className="checkBoxTitle">{title}</div>
            <div
              style={{
                // background: 'green',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginTop: '-0.5em',
                marginLeft: '0.1em',
              }}
            >
              <ToolTipInfo title={tolTiptitle} />
            </div>
          </div>
        </div>
        {location.pathname.split('/')[1] !== 'stats' && (
          <button
            onClick={getClick}
            style={{
              opacity: location.pathname.split('/')[1] === 'stats' ? '.4' : '1',
              cursor:
                location.pathname.split('/')[1] === 'stats'
                  ? 'not-allowed'
                  : 'pointer',
            }}
            className="useCaseBtn"
          >
            Reuse
          </button>
        )}
      </div>
    </div>
  );
};

export default ProcessCheckButtonComponent;
