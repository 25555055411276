import React from 'react';
import { pickColor } from '../../../../utils';

const ProcessMonitoringLink = ({ link, showLink, i }) => {
  // let originalLastNodes = nodesFromApi.length - 1;
  // let lastParentToChildNodes = nodesFromApi[originalLastNodes].id;

  // console.log(link);
  // showLink === i

  // 'text/hasIng'
  return (
    <>
      <line
        strokeDasharray={(2, 3)}
        className="_graphLine"
        stroke={
          link.edgeType === 'hasProcess'
            ? showLink === i
              ? 'black'
              : 'gray'
            : link.edgeType === 'hasTexture'
            ? showLink === i
              ? 'black'
              : 'gray'
            : link.edgeType === 'hasFlavor'
            ? showLink === i
              ? 'black'
              : 'gray'
            : pickColor(link.name, 80)
        }
        opacity={1}
        onMouseOver={() => console.log('hey')}
      />
    </>
  );
};

export default ProcessMonitoringLink;

// pickColor(link.name, 80)
