import React from 'react';

/**
 *[Component] Inventorysteps
 
 *@description responsible for displaying the steps and description

 * @param {string} step accepts string as step number
 * @param {string} content accepts string as description for steps 
 * @param {*} png accepts svg
 *
 * @returns the step description for customizing recipe
 */

const Inventorysteps = ({ step, content, png, stepsTitleClassName }) => {
  return (
    <>
      <div className="stepContainerFlex">
        <h4 className={stepsTitleClassName}>{step}</h4>
        <div className="steps_below_content">{content}</div>
        <div className="steps_below_images_Container">
          <img className="steps_below_images" src={png} alt="stepimg" />
        </div>
      </div>
    </>
  );
};

export default Inventorysteps;
