const IngredientsListInRawData = ({ ingredients }) => {
  return (
    <>
      <div className="ingredientList">
        <li className="ingredientListData">
          <span className="ingredientListData-list">{ingredients}</span>
        </li>
      </div>
    </>
  );
};

export default IngredientsListInRawData;
