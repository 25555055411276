import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  substituteReuseNavigation: '',
  ProcessReuseNavigation: '',
  StatsSubstitutionStatus: '',
  statsProcessStatus: '',
  StatsSubstitutionData: '',
  statsProcessData: '',
  substitutionCheck: false,
  processCheck: false,
};
const statsPageSlice = createSlice({
  name: 'statsStateManagement',
  initialState,
  reducers: {
    getSubstituteChange: state => {
      if (!state.processCheck) {
        // eslint-disable-next-line no-self-assign
        state.processCheck = state.processCheck;
        state.substitutionCheck = !state.substitutionCheck;
      }
    },
    getProcessChange: state => {
      if (state.substitutionCheck) {
        // eslint-disable-next-line no-self-assign
        state.substitutionCheck = state.substitutionCheck;
        state.processCheck = !state.processCheck;
      }
    },
    clearSubstituteAndProcessChange: state => {
      state.substitutionCheck = false;
      state.processCheck = false;
    },
  },
});

export const statsSliceActions = statsPageSlice.actions;
export const statsSliceReducer = statsPageSlice.reducer;
