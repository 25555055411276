import React from 'react';
import './AuthSuccessNotifactionPage.css';
import { successImage } from '../../../assets';
import { AuthButton } from '../../../lib';
import { useDispatch } from 'react-redux';
import { authAction } from '../../../Redux/Slices/AuthSlides/AuthenticationStates';

const AuthSuccessNotifactionPage = ({ settypeOfSignUp }) => {
  const dispatch = useDispatch();
  const getSave = () => {
    //console.log'hey');
    // setIsLogin(true);
    dispatch(authAction.setLogin(true));
    // setProgressStep(0);
    dispatch(authAction.setProgressStep(0));

    settypeOfSignUp('email');
    // setIsHeader(false);
    dispatch(authAction.setIsHeader(false));
  };
  return (
    <div className="AuthSuccessfulLoginContainer">
      <div className="AuthSuccessHeading">
        <b>Create an account</b>
      </div>
      <div className="AuthSuccessDescription">
        <b>Congratulations! Your account has been successfully created.</b>
      </div>
      <div className="AuthSuccessDescriptionImageContainer">
        <div>
          <img src={successImage} alt="signup completed" width="150em" />
        </div>
      </div>
      <div className="AuthSignUpSuccessFooter">
        We have sent your credentials to your registered Email ID. Welcome to
        eccenca education services!
      </div>
      <div className="AuthSignUpSuccessFooterButton">
        <AuthButton
          disabled={false}
          buttonText="Continue To Login"
          onClick={getSave}
          type="button"
          width="15em"
          height="3em"
        />
      </div>
    </div>
  );
};

export default AuthSuccessNotifactionPage;
