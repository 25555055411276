import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  selectedRecipeData: '',
};
const prefereceSlice = createSlice({
  name: 'recipeCardsState',
  initialState,
  reducers: {
    setQueryKeyWhileClickingRecipeCard: (state, { payload }) => {
      state.selectedRecipeData = payload;
    },
  },
});

export const preferenceScreenActions = prefereceSlice.actions;
export const preferenceScreenReducers = prefereceSlice.reducer;
