import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './AuthHeader.css';

const AuthHeader = ({
  title,
  userType,
  typeOfAction,
  onClick,

  margintop,
}) => {
  const authenticationStore = useSelector(state => state.authStore);
  return (
    <div style={{ marginTop: margintop }} className="AppLoginHeaderContainer">
      <div className="AppLoginHeader">
        <div className="AppLoginTittle">
          <b>{title}</b>
        </div>

        {authenticationStore.isHeader && (
          <div>
            <b className="signInTitle">{userType}</b>{' '}
            <span onClick={onClick}>
              <b className="AppLoginTittlediscription">
                <NavLink
                  to={typeOfAction !== 'Sign in' ? '/signup' : '/login'}
                  className={isActive =>
                    !isActive ? '' : 'unselectedBackContent'
                  }
                >
                  {typeOfAction}
                </NavLink>
              </b>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthHeader;
