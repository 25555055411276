import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  progressStep: 0,
  isLogin: true,
  typeofSignUp: 'email',
  isSignInSuccessfull: false,
  forgetPasswordStatus: false,
  isHeader: true,
  userAuthInfo: {
    email: '',
    confirmPassword: '',
  },
  registerError: '',
  registerLoading: false,
  user: [],
  userPersonalDetails: [],
  signUpDetails: {
    userName: '',
    email: '',
    userPassword: '',
    userConfirmPassword: '',
    companyName: '',
    partner: 'yes',
    domine: '',
    phoneNumber: '',
    group: '',
    agreePolicy: false,
    belongsTo: false,
  },
  errorStatus: false,
  currentUserForApp: null,
  showTour: false,
  feedbackCancelIcon: true,
  stepIcon: false,
  forgetPasswordTostSuccessMessage: 'idle',
};

const authenticationSlice = createSlice({
  name: 'cuisineSectionStates',
  initialState,
  reducers: {
    setProgressStep: (state, { payload }) => {
      state.progressStep = payload;
    },
    setLogin: (state, { payload }) => {
      state.isLogin = payload;
    },
    setIsSigninSuccessfull: (state, { payload }) => {
      state.isSignInSuccessfull = payload;
    },
    setForgetPasswordStatus: (state, { payload }) => {
      state.forgetPasswordStatus = payload;
    },
    setIsHeader: (state, { payload }) => {
      state.isHeader = payload;
    },
    setRegisterError: (state, { payload }) => {
      state.registerError = payload;
    },
    setRegisterLoading: (state, { payload }) => {
      state.registerError = payload;
    },
    setUser: (state, { payload }) => {
      state.registerError = payload;
    },
    setUserPersonalDetails: (state, { payload }) => {
      state.userPersonalDetails = payload;
    },
    getCompanyName: (state, { payload }) => {
      state.signUpDetails.companyName = payload;
    },
    getDomineName: (state, { payload }) => {
      state.signUpDetails.domine = payload;
    },
    getMobileNumber: (state, { payload }) => {
      state.signUpDetails.phoneNumber = payload;
    },
    getCheckBelongingsTo: (state, { payload }) => {
      state.signUpDetails.partner = payload.partner;
      state.signUpDetails.belongsTo = payload.belongsTo;
    },
    getUnCheckBelongingsTo: (state, { payload }) => {
      state.signUpDetails.partner = payload.partner;
      state.signUpDetails.belongsTo = payload.belongsTo;
    },
    getPrivacyCheckBox: (state, { payload }) => {
      state.signUpDetails.agreePolicy = payload;
    },
    getUserName: (state, { payload }) => {
      state.signUpDetails.userName = payload;
    },
    getEmail: (state, { payload }) => {
      state.signUpDetails.email = payload;
    },
    getPassword: (state, { payload }) => {
      state.signUpDetails.userPassword = payload;
    },
    getConfirmPassword: (state, { payload }) => {
      state.signUpDetails.userConfirmPassword = payload;
    },
    setErrorStatus: (state, { payload }) => {
      state.errorStatus = payload;
    },
    setForgetPasswordTostSuccessMessage: (state, { payload }) => {
      state.forgetPasswordTostSuccessMessage = payload;
    },
    getFeedbackCancelIcon: (state, { payload }) => {
      state.feedbackCancelIcon = payload;
    },
    setIstourOpen: (state, { payload }) => {
      state.isTourOpen = payload;
    },
    setCurrentUserForApp: (state, { payload }) => {
      state.currentUserForApp = payload;
    },
    getShowTour: (state, { payload }) => {
      state.showTour = payload;
    },
    setStepIcon: (state, { payload }) => {
      state.showTour = payload;
    },
  },
});

export const authAction = authenticationSlice.actions;
export const authReducers = authenticationSlice.reducer;
