import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router';
import { useGraphFetch, useVocabularyRecursiveFetch } from '../../../utils';
import { Loading } from '../../index';
import { VocabularyGraph, VocabularyGraphInfo } from '../index';

const VocabularyComponent = ({ LoadingThemeColor }) => {
  const location = useLocation();
  const vocabularyComponentStore = useSelector(s => s.vocabularyComponentStore);

  const vocabulary_Main_Circle_QueryId =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_SB1_VOCABULARY_BIG_CIRCLE_QUERY_ID;

  const process_challenge_Main_Circle_queryId =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_SB2_VOCABULARY_BIG_CIRCLE_QUERY_ID;

  const [loopStatus, result, findChildNodes] = useVocabularyRecursiveFetch(
    location.pathname.split('/')[1] === 'process'
      ? {
          query: `${process_challenge_Main_Circle_queryId}`,
          condition: location.pathname.split('/')[1] === 'process',
        }
      : {
          query: `${vocabulary_Main_Circle_QueryId}`,
          condition: location.pathname.split('/')[1] === 'process',
        }
  );
  const vocabulary_sb1_Internal_links_connection_queryId =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_SB1_VOCABULARY_INTERNAL_LINKS_QUERY_ID;
  const vocabulary_sb2_Internal_links_connection_queryId =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_SB2_VOCABULARY_INTERNAL_LINKS_QUERY_ID;

  // eslint-disable-next-line no-unused-vars
  const [vocabularyLinksStatus, vocabularyLinks] = useGraphFetch(
    location.pathname.split('/')[1] === 'process'
      ? `${vocabulary_sb1_Internal_links_connection_queryId}`
      : `${vocabulary_sb2_Internal_links_connection_queryId}`
  );
  // console.log(vocabularyLinks);
  // console.warn(vocabularyLinksStatus);

  const vocabulary_first_info_QueryId =
    // eslint-disable-next-line no-undef
    process.env
      .REACT_APP_VOCABULARY_INFO_AFTER_CLICKING_NODES_QUERY_ID_POINT_ONE;
  const vocabulary_second_info_queryId =
    // eslint-disable-next-line no-undef
    process.env
      .REACT_APP_VOCABULARY_INFO_AFTER_CLICKING_NODES_QUERY_ID_POINT_TWO;

  //   REACT_APP_VOCABULARY_NODE_INFO_FIRST_QUERYID
  // REACT_APP_VOCABULARY_NODE_INFO_SECOND_QUERYID

  // eslint-disable-next-line no-unused-vars
  const [vocabSuatus, vocabInfo] = useGraphFetch(
    `${vocabulary_first_info_QueryId}${
      vocabularyComponentStore.vocabUrl
    } {           BIND (${
      vocabularyComponentStore.vocabUrl === ''
        ? `</>`
        : vocabularyComponentStore.vocabUrl
    }${vocabulary_second_info_queryId}`
  );
  // console.warn(vocabSuatus);
  // console.log(vocabUrl);
  //console.logvocabInfo);
  // console.log(findChildNodes, loopStatus);
  var vocabularyNodes = [];
  var linkConnections = [];

  //console.logloopStatus);
  var TotalNodesWithOutParents = [];
  var referenceNodeIdsForExternalNodes = [];
  if (loopStatus === 'LoopEnd') {
    var count = 0;
    const assignUniqueIdsForChildNodes = findChildNodes.map(el => {
      const AddIdsToChildNodes = el.map(ek => {
        count += 1;
        const upDatedNodes = {
          id: count,
          name: ek.value.value
            .split('/')
            [ek.value.value.split('/').length - 1].split('#')
            [
              ek.value.value
                .split('/')
                [ek.value.value.split('/').length - 1].split('#').length - 1
            ].slice(0, -1),
          value: 10,
        };
        return upDatedNodes;
      });
      return AddIdsToChildNodes;
    });
    TotalNodesWithOutParents = assignUniqueIdsForChildNodes.flatMap(ei => {
      const Data = ei;
      return Data;
    });

    vocabularyNodes = result.map((el, i) => {
      const Data = {
        id: `category${i}`,
        category: el.vocab_label.value,
        children: assignUniqueIdsForChildNodes[i],
        name: el.value.value,
        colorCode: el.publisher.value,
      };
      return Data;
    });
    referenceNodeIdsForExternalNodes = vocabularyNodes.map(el => el.name);
  }
  const vocabularyGraphLinks = vocabularyLinks.map(el => {
    const Data = {
      source: el.source.value,
      target: el.target.value,
      edgeType: el.relation_label.value,
      targetDiscription: el.target_def.value,
      sourceDiscription: el.source_def.value,
    };
    return Data;
  });

  let removeHashFromLinks = vocabularyGraphLinks.map((item, i) => {
    let slashSplit = item.source.split('/');

    let hashtagSplit = slashSplit[slashSplit.length - 1].split('#');
    const specialCharectorRemovedSourceLinks =
      hashtagSplit[hashtagSplit.length - 1];
    // =============================================================

    let slashSplitForTarget = item.target.split('/');

    let hashtagSplitForTarget =
      slashSplitForTarget[slashSplitForTarget.length - 1].split('#');
    const specialCharectorRemovedTargetLinks =
      hashtagSplitForTarget[hashtagSplitForTarget.length - 1];

    const Data = {
      id: i,
      source: specialCharectorRemovedSourceLinks,
      // value: item.value,
      // pairs: item.pairs,
      // link: specialCharectorRemovedLink,
      target: specialCharectorRemovedTargetLinks,
      targetDiscription: item.targetDiscription,
      sourceDiscription: item.sourceDiscription,
    };
    return Data;
  });

  // eslint-disable-next-line no-inner-declarations
  const matchNodes = (nodes, connections) => {
    return connections.map((connection, i) => {
      const source = nodes.find(node => node.name === connection.source);
      const target = nodes.find(node => node.name === connection.target);
      return {
        id: i,
        source: source === undefined ? null : source.id,
        target: target === undefined ? null : target.id,
        rating: 1,
        targetDiscription: connection.targetDiscription,
        sourceDiscription: connection.sourceDiscription,
      };
      // console.log(source);
    });
  };

  if (TotalNodesWithOutParents.length !== 0) {
    linkConnections = matchNodes(TotalNodesWithOutParents, removeHashFromLinks)
      .filter(el => el.target !== null && el)
      .filter(el => el.source !== null && el);
  }

  // console.log(vocabularyNodes);
  // console.log(linkConnections);
  const vocabulary_sb1_external_links_QueryId =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_SB1_VOCABULARY_EXTERNAL_LINKS_QUERY_ID;
  const vocabulary_sb2_external_links_QueryId =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_SB2_VOCABULARY_EXTERNAL_LINKS_QUERY_ID;

  const [externalStatus, externalData] = useGraphFetch(
    location.pathname.split('/')[1] === 'process'
      ? `${vocabulary_sb1_external_links_QueryId}`
      : `${vocabulary_sb2_external_links_QueryId}`
  );
  var mapExternlLinksVocabGraph = [];
  var findNameBasedSourceAndLinks = [];
  if (externalStatus === 'success') {
    mapExternlLinksVocabGraph = externalData
      .map(el => {
        const Data = {
          source: el.source.value,
          target: el.target.value,
        };
        return Data;
      })
      .filter(ej => referenceNodeIdsForExternalNodes.includes(ej.source) && ej)
      .filter(ej => referenceNodeIdsForExternalNodes.includes(ej.target) && ej);

    const matchNodesForExternalLinks = (nodes, connections) => {
      return connections.map(connection => {
        const source = nodes.find(node => node.name === connection.source);
        const target = nodes.find(node => node.name === connection.target);
        return {
          source: source === undefined ? null : source.id,
          target: target === undefined ? null : target.id,
        };
        // console.log(source);
      });
    };
    findNameBasedSourceAndLinks = matchNodesForExternalLinks(
      vocabularyNodes,
      mapExternlLinksVocabGraph
    );
  }

  return (
    <>
      <Routes>
        <Route
          path="vocabularyGraph"
          element={
            <>
              {loopStatus !== 'LoopEnd' && (
                <div className="loadingGettingGraph">
                  <Loading color={LoadingThemeColor} />
                </div>
              )}
              {loopStatus === 'LoopEnd' && (
                <>
                  <div className="vocabularyGraphContainer">
                    <VocabularyGraph
                      nodes={vocabularyNodes}
                      link_data={linkConnections}
                      mapExternlLinksVocabGraph={
                        mapExternlLinksVocabGraph.length !== 0
                          ? findNameBasedSourceAndLinks
                          : []
                      }
                    />
                  </div>
                </>
              )}
              <VocabularyGraphInfo vocabInfo={vocabInfo} />
            </>
          }
        />
      </Routes>
    </>
  );
};

export default VocabularyComponent;
